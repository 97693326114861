import { DatePicker } from "antd";
import moment from "moment";
import { Dispatch, SetStateAction, useContext, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select, { PropsValue } from "react-select";
import { PlatformMap, PlatformMapTypes } from "../../../types";
import { Button, FormInput, FormLabel, FormTextarea } from "../../../ui";
import ToastContext from "../../../utils/contexts/ToastContext";
import convertToBase64 from "../../../utils/convertToBase64";
import { getExtension } from "../../../utils/formatters";
import FileDropZone from "../../KitchenNames/components/FileDropZone";
import {
  Brand,
  Location,
  MarketingBillboard,
  MultiSelectOptionType,
  PagesLabelsMap,
  VisibilityMap,
  VisibilityMapTypes,
} from "../types";
import {
  AllLocationsOption,
  getDefaultLocation,
  parseAttributes,
} from "../utils";

type MarketingBillboardFormProps = {
  onSubmit: any;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  billboard?: MarketingBillboard;
  brands: Brand[];
  locations: Location[];
};

const MarketingBillboardForm = ({
  onSubmit,
  setShowModal,
  billboard,
  brands,
  locations,
}: MarketingBillboardFormProps) => {
  const [processingMutation, setProcessingMutation] = useState(false);
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const { RangePicker } = DatePicker;

  const backgroundColor = watch("background_color");
  const image = watch("image");
  const portrait_image = watch("portrait_image");
  const redirectPage = watch("redirect.page", {
    value: billboard?.redirect?.page,
  });

  const brandsOptions = useMemo(() => {
    return (brands || [])
      .map((brand) => ({
        value: brand.slug,
        label: brand.name,
      }))
      .sort((a: MultiSelectOptionType, b: MultiSelectOptionType) =>
        a.label.localeCompare(b.label),
      );
  }, [brands]);

  const { showToast } = useContext(ToastContext);
  const onSubmitHandler = async (data: any) => {
    let locationIds = null;

    if (
      data.locations?.find(
        (location: MultiSelectOptionType) => location.value === "all",
      )
    ) {
      locationIds = locations.map((location: Location) => location.id);
    } else if (data.locations) {
      locationIds = data.locations.map((location: MultiSelectOptionType) =>
        parseInt(location.value, 10),
      );
    }

    const transformedData = {
      billboard_id: billboard?.id,
      title: data.title,
      description: data.description,
      subtitle: data.subtitle,
      redirect: data.redirect?.page?.value
        ? {
            page: data.redirect.page.value,
            attributes: JSON.stringify(
              parseAttributes(data.redirect.attributes),
            ),
            button_label: data.redirect.button_label,
          }
        : undefined,
      image_url: billboard?.image?.url,
      image_blurhash: billboard?.image?.blurhash,
      image:
        typeof data.image === "object"
          ? await convertToBase64(data.image)
          : undefined,
      portrait_image_url: billboard?.portrait_image?.url,
      portrait_image_blurhash: billboard?.portrait_image?.blurhash,
      portrait_image:
        typeof data.portrait_image === "object"
          ? await convertToBase64(data.portrait_image)
          : undefined,
      background_color: data.background_color || "#0000",
      start_time: data.active_range
        ? data.active_range[0].toISOString()
        : undefined,
      end_time: data.active_range
        ? data.active_range[1].toISOString()
        : undefined,
      location_ids: locationIds,
      platforms: data.platforms
        ? data.platforms.map(
            (platform: MultiSelectOptionType) => platform.value,
          )
        : null,
      visibility: data.visibility?.length
        ? data.visibility.map(
            (visibility: MultiSelectOptionType) => visibility.value,
          )
        : null,
    };

    try {
      setProcessingMutation(true);
      await onSubmit(transformedData);
      setProcessingMutation(false);
      showToast({
        description: "Feature Tile was updated",
        variant: "success",
        seconds: 2,
      });

      setShowModal(false);
    } catch (err) {
      setProcessingMutation(false);
      showToast({
        description: "Feature Tile could not be updated.",
        variant: "error",
        seconds: 2,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <div className="my-5">
        <ul>
          <li>
            For{" "}
            <code className="bg-red-50 text-red-600 rounded-md p-1">
              Website
            </code>{" "}
            and{" "}
            <code className="bg-red-50 text-red-600 rounded-md p-1">
              Mobile App
            </code>
            , if there are multiple tiles active, they will be shown in carousel
            (most recent updated show first).
          </li>
        </ul>
      </div>
      <div className="grid grid-cols-3 gap-6 my-5">
        <div>
          <FormLabel
            title="Title"
            htmlFor="title"
            information="Main title of tile. Limited to 33 characters otherwise it looks bad."
          />
          <Controller
            name="title"
            control={control}
            defaultValue={billboard?.title ?? ""}
            rules={{
              required: { value: true, message: "This field is required" },
              maxLength: {
                value: 33,
                message:
                  "Titles have a limit of 33 characters (otherwise it will look bad on some devices)",
              },
            }}
            render={({ field }) => (
              <FormInput type="text" id="title" {...field} />
            )}
          />
          <p className="pt-2 text-red-500">
            {errors.title?.message?.toString()}
          </p>
        </div>
        <div>
          <FormLabel
            title="Subtitle"
            htmlFor="subtitle"
            information="Small text shown above the title of the tile"
          />
          <Controller
            name="subtitle"
            control={control}
            defaultValue={billboard?.subtitle ?? ""}
            rules={{ required: false }}
            render={({ field }) => (
              <FormInput type="text" id="subtitle" {...field} />
            )}
          />
        </div>
        <div>
          <FormLabel
            title="Locations"
            htmlFor="locations"
            information="Locations that this tile will apply towards."
          />
          <Controller
            name="locations"
            control={control}
            rules={{ required: false }}
            defaultValue={
              getDefaultLocation(billboard, locations) || [AllLocationsOption]
            }
            render={({ field }) => {
              const locationOptions = locations.map((location: Location) => ({
                value: location.id,
                label: location.name,
              }));

              const value: PropsValue<MultiSelectOptionType> =
                field.value.length === locations.length
                  ? [AllLocationsOption]
                  : field.value;

              return (
                <Select
                  options={[AllLocationsOption, ...locationOptions]}
                  isMulti
                  closeMenuOnSelect={false}
                  isSearchable={false}
                  {...field}
                  value={value}
                  isOptionSelected={(option: MultiSelectOptionType) => {
                    if (
                      option.value !== "all" &&
                      field.value.find(
                        (val: MultiSelectOptionType) => val.value === "all",
                      )
                    ) {
                      return false;
                    } else {
                      return field.value.find(
                        (val: MultiSelectOptionType) =>
                          val.value === option.value,
                      );
                    }
                  }}
                  onChange={(value) => {
                    if (
                      value.find(
                        (val: MultiSelectOptionType) => val.value === "all",
                      )
                    ) {
                      field.onChange([AllLocationsOption]);
                    } else {
                      field.onChange(value);
                    }
                  }}
                />
              );
            }}
          />
        </div>
      </div>
      <div className="grid grid-cols-3 gap-6 my-5">
        <div>
          <FormLabel
            title="Redirect page"
            htmlFor="redirect_page"
            information={
              <p>
                Places to which the user will be redirected after clicking the
                tile
              </p>
            }
          />
          <Controller
            name={"redirect.page"}
            control={control}
            defaultValue={
              billboard?.redirect
                ? {
                    value: billboard.redirect.page,
                    label: PagesLabelsMap[billboard.redirect.page],
                  }
                : undefined
            }
            rules={{ required: false }}
            render={({ field }) => {
              return (
                <Select
                  options={[
                    { value: "brand_page", label: "Brand Page" },
                    { value: "custom", label: "Custom URL" },
                  ]}
                  isSearchable={false}
                  isClearable
                  {...field}
                />
              );
            }}
          />
        </div>
        {redirectPage?.value === "brand_page" && (
          <div>
            <FormLabel
              title="Redirect Brand"
              htmlFor={"redirect.attributes.brand_slug"}
              information="Brand to which the user will be redirected when the tile is clicked."
            />
            <Controller
              name={"redirect.attributes.brand_slug"}
              control={control}
              defaultValue={
                billboard?.redirect?.page === "brand_page"
                  ? {
                      value: billboard.redirect.brand_slug,
                      label: brandsOptions.find(
                        (brand: MultiSelectOptionType) =>
                          // @ts-ignore
                          brand.value === billboard.redirect?.brand_slug,
                      )?.label,
                    }
                  : undefined
              }
              rules={{
                required: { value: true, message: "This field is required" },
              }}
              render={({ field }) => {
                return (
                  <>
                    <p>
                      Brand-specific tiles only show if the brand is active at
                      the given location
                    </p>
                    <Select
                      {...field}
                      options={brandsOptions}
                      isSearchable={false}
                    />
                  </>
                );
              }}
            />
            <p className="text-red-500">
              {errors.brand_slug?.message?.toString()}
            </p>
          </div>
        )}
        {redirectPage?.value === "custom" && (
          <div>
            <FormLabel
              title="Redirect Url"
              htmlFor={"redirect.attributes.url"}
              information="Url to which the user will be redirected when the tile is clicked. Will open WebView on mobile."
            />
            <Controller
              name={"redirect.attributes.url"}
              control={control}
              defaultValue={
                billboard?.redirect?.page === "custom"
                  ? billboard?.redirect?.url
                  : undefined
              }
              rules={{
                required: { value: true, message: "This field is required" },
              }}
              render={({ field }) => (
                <FormInput type="url" id="redirect.attributes.url" {...field} />
              )}
            />
            <p className="text-red-500">{errors.url?.message?.toString()}</p>
          </div>
        )}
        {redirectPage?.value && (
          <div>
            <FormLabel
              title="Button label"
              htmlFor={"redirect.button_label"}
              information="Label shown on the button of the tile. If not present, the button won't be shown"
            />
            <Controller
              name={"redirect.button_label"}
              control={control}
              defaultValue={billboard?.redirect?.button_label ?? ""}
              rules={{ required: false }}
              render={({ field }) => (
                <FormInput
                  type="text"
                  id={"redirect.button_label"}
                  {...field}
                />
              )}
            />
          </div>
        )}
      </div>
      <div className="grid grid-cols-3 gap-6 my-5">
        <div>
          <FormLabel
            title="Platforms"
            htmlFor="platforms"
            information={
              <p>
                Platforms in which the tile will show up:
                <code className="bg-red-50 text-red-600 rounded-md p-1">
                  Guest Web
                </code>{" "}
                and{" "}
                <code className="bg-red-50 text-red-600 rounded-md p-1">
                  Guest Mobile
                </code>
                .
              </p>
            }
          />
          <Controller
            name="platforms"
            control={control}
            rules={{ required: false }}
            defaultValue={
              billboard?.platforms?.map((platform) => ({
                value: platform,
                label: PlatformMap[platform as keyof PlatformMapTypes],
              })) || []
            }
            render={({ field }) => {
              return (
                <Select
                  options={[
                    {
                      value: "guest_web",
                      label: "Website",
                    },
                    { value: "guest_mobile", label: "Mobile App" },
                  ]}
                  isMulti
                  closeMenuOnSelect={false}
                  isSearchable={false}
                  {...field}
                />
              );
            }}
          />
        </div>
        <div>
          <FormLabel
            title="Background Color"
            htmlFor="background_color"
            information="Pick background color of the tile"
          />
          <Controller
            name="background_color"
            control={control}
            rules={{
              required: { value: true, message: "This field is required" },
            }}
            defaultValue={billboard?.background_color || "#000"}
            render={({ field }) => {
              return (
                <>
                  <FormInput type="color" {...field} />
                  <p>
                    Picked color:{" "}
                    <b>
                      {backgroundColor || billboard?.background_color || "#000"}
                    </b>
                  </p>
                </>
              );
            }}
          />
          <p className="text-red-500">
            {errors.background_color?.message?.toString()}
          </p>
        </div>
        <div>
          <FormLabel
            title="Visibility"
            htmlFor="visibility"
            information={
              <p>
                Visibility for Logged in users or anonymous users (
                <code className="bg-red-50 text-red-600 rounded-md p-1">
                  Logged In
                </code>
                {", "}
                <code className="bg-red-50 text-red-600 rounded-md p-1">
                  Anonymous
                </code>
                ).
              </p>
            }
          />
          <Controller
            name="visibility"
            control={control}
            rules={{ required: false }}
            defaultValue={
              billboard?.visibility?.map((visibility) => ({
                value: visibility,
                label: VisibilityMap[visibility as keyof VisibilityMapTypes],
              })) || []
            }
            render={({ field }) => {
              return (
                <Select
                  options={[
                    { value: "logged_in", label: "Logged In" },
                    {
                      value: "anonymous",
                      label: "Anonymous",
                    },
                  ]}
                  isMulti
                  closeMenuOnSelect={false}
                  isSearchable={false}
                  {...field}
                />
              );
            }}
          />
        </div>
        <div className="col-span-3">
          <FormLabel
            title="Description"
            htmlFor="description"
            information="Description of tile shown below the title"
          />
          <Controller
            name="description"
            control={control}
            defaultValue={billboard?.description ?? ""}
            rules={{
              required: { value: true, message: "This field is required" },
            }}
            render={({ field }) => (
              <FormTextarea rows={4} id="description" {...field} />
            )}
          />
          <p className="text-red-500">
            {errors.description?.message?.toString()}
          </p>
        </div>
        <div className="col-span-3">
          <FormLabel
            title="Active Range"
            htmlFor="active_range"
            information="When the tile should be shown."
          />
          <Controller
            name="active_range"
            control={control}
            rules={{
              required: { value: true, message: "This field is required" },
            }}
            defaultValue={[
              moment(
                billboard?.start_time
                  ? parseInt(billboard.start_time, 10)
                  : moment("00:00:00", "HH:mm:ss"),
              ),
              moment(
                billboard?.end_time
                  ? parseInt(billboard.end_time, 10)
                  : moment("23:59:59", "HH:mm:ss"),
              ),
            ]}
            render={(props) => (
              <RangePicker
                {...props.field}
                // @ts-ignore
                popupClassName="ant-btn-color"
                showTime={{
                  defaultValue: [
                    moment("00:00:00", "HH:mm:ss"),
                    moment("23:59:59", "HH:mm:ss"),
                  ],
                }}
                getPopupContainer={(triggerNode: any) => {
                  return triggerNode?.parentNode;
                }}
                format="MM/DD/YYYY HH:mm (Z)"
                onChange={props.field.onChange}
              />
            )}
          />
          <p className="text-red-500">
            {errors.active_range?.message?.toString()}
          </p>
        </div>
        <div className="col-span-3">
          <FormLabel
            title="Tile Image (landscape)"
            htmlFor="image"
            information="Image visible on larger viewports. On smaller is replaced by portrait image when set."
          />
          <Controller
            name="image"
            control={control}
            defaultValue={billboard?.image?.url ?? ""}
            rules={{
              required: { value: true, message: "This field is required" },
            }}
            render={({ field }) => {
              const extension = getExtension(billboard?.image?.url) || "jpg";
              return (
                <FileDropZone
                  className="h-40 text-center"
                  onChange={(files: File[]) => {
                    field.onChange(files?.[0]);
                  }}
                >
                  {billboard?.image?.url || image ? (
                    <img
                      alt="Media that shows up behind tile"
                      src={
                        typeof image === "object"
                          ? URL.createObjectURL(image)
                          : billboard?.image?.url.replace(
                              `.${extension}`,
                              `-640w.${extension}`,
                            )
                      }
                      className="h-40 object-contain"
                    />
                  ) : null}
                </FileDropZone>
              );
            }}
          />
          <p className="text-red-500">{errors.image?.message?.toString()}</p>
        </div>
        <div className="col-span-3">
          <FormLabel
            title="Tile Image (portrait)"
            htmlFor="portrait_image"
            information="Image visible on smaller viewports. If not provided, fallbacks to landscape image."
          />
          <Controller
            name="portrait_image"
            control={control}
            defaultValue={billboard?.portrait_image?.url ?? ""}
            render={({ field }) => {
              const extension =
                getExtension(billboard?.portrait_image?.url) || "jpg";
              return (
                <FileDropZone
                  className="h-40 text-center"
                  onChange={(files: File[]) => {
                    field.onChange(files?.[0]);
                  }}
                >
                  {billboard?.portrait_image?.url || portrait_image ? (
                    <img
                      alt="Media that shows up behind tile"
                      src={
                        typeof portrait_image === "object"
                          ? URL.createObjectURL(portrait_image)
                          : billboard?.portrait_image?.url.replace(
                              `.${extension}`,
                              `-640w.${extension}`,
                            )
                      }
                      className="h-40 object-contain"
                    />
                  ) : null}
                </FileDropZone>
              );
            }}
          />
        </div>
      </div>
      <Button loading={processingMutation} className="mr-3">
        Save
      </Button>
      <Button
        disabled={processingMutation}
        type="button"
        backgroundColor="bg-gray-500"
        onClick={() => {
          setShowModal(false);
        }}
      >
        Cancel
      </Button>
    </form>
  );
};

export default MarketingBillboardForm;
