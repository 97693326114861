import { useQuery, gql } from "@apollo/client";

const OPERATIONS_CUSTOMERS_QUERY = gql`
  query OPERATIONS_CUSTOMERS_QUERY($input: CustomersInput) {
    customers(input: $input) {
      total_customer_count
      page_number
      limit
      customers {
        id
        email
        phone_number
        first_name
        last_name
      }
    }
  }
`;

type UseCustomersProps = {
  pageNumber: number;
  limit: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
};

export const useCustomers = ({
  pageNumber,
  limit,
  firstName,
  lastName,
  email,
  phoneNumber,
}: UseCustomersProps) => {
  const { data, error, loading, refetch } = useQuery(
    OPERATIONS_CUSTOMERS_QUERY,
    {
      variables: {
        input: {
          page_number: pageNumber,
          limit,
          first_name: firstName,
          last_name: lastName,
          email,
          phone_number: phoneNumber,
        },
      },
    },
  );

  return { data, error, loading, refetch };
};
