import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_OPERATIONS_UI_DSN,
  environment: process.env.REACT_APP_SENTRY_ENV,
  release: process.env.REACT_APP_RELEASE,
  integrations: [
    Sentry.rewriteFramesIntegration(),
    Sentry.captureConsoleIntegration({ levels: ["error"] }),
    Sentry.extraErrorDataIntegration(),
    Sentry.contextLinesIntegration(),
    Sentry.httpClientIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 1,
});
