interface TagPillProps {
  color: PillColor;
  content: string;
}

export const TagPill: React.FC<TagPillProps> = ({ color, content }) => (
  <span
    className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${color}`}
  >
    {content}
  </span>
);

export enum PillColor {
  green = "bg-green-100 text-green-800",
  purple = "bg-purple-100 text-purple-800",
  blue = "bg-blue-100 text-blue-800",
  red = "bg-red-100 text-red-800",
  yellow = "bg-yellow-100 text-yellow-800",
  black = "bg-black text-white",
}
