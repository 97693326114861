import { TrashIcon } from "@heroicons/react/24/outline";
import { useQueryClient } from "@tanstack/react-query";
import { useListModifiersQuery } from "opsapi/hooks/operations/modifiers";
import {
  LIST_MODIFIERS_LISTS,
  useListModifiersListsQuery,
  useSyncModifiersListsQuery,
} from "opsapi/hooks/operations/modifiersLists";
import { useContext, useState } from "react";
import Loader from "../../../../ui/components/Loader/Loader";
import { PageContent } from "../../../../ui/components/PageContent/PageContent";
import ToastContext from "../../../../utils/contexts/ToastContext";
import { FlatButton } from "../../_components/FlatButton";
import { ItemsContainer } from "../../_components/ItemsContainer";
import { DeleteModifiersListDialog } from "../components/DeleteModifiersListDialog";

export const ListModifiersLists = () => {
  const queryClient = useQueryClient();
  const { showToast } = useContext(ToastContext);
  const [includeDeleted, setIncludeDeleted] = useState(false);
  const modifiersListParams = {
    params: { query: { include_deleted: includeDeleted } },
  };
  const { data: modifiersLists, isLoading: isModifiersListsLoading } =
    useListModifiersListsQuery(modifiersListParams);
  const { data: modifiers, isLoading: isModifiersLoading } =
    useListModifiersQuery();
  const [showDeleteModal, setShowDeleteModal] = useState<string | null>(null);

  const { mutate: syncModifiersLists, isPending: isSyncingPending } =
    useSyncModifiersListsQuery({
      reactQuery: {
        onSuccess: (data) => {
          showToast({
            description: `Result: ${JSON.stringify(data)}`,
            variant: "success",
            seconds: 5,
          });
          queryClient.invalidateQueries({
            queryKey: [LIST_MODIFIERS_LISTS],
          });
        },
        onError: (error) => {
          showToast({
            description: error.message,
            variant: "error",
            seconds: 10,
          });
        },
      },
    });

  const toggleIncludeDeleted = () => {
    setIncludeDeleted((prev) => !prev);
  };

  if (isModifiersListsLoading || isModifiersLoading) {
    return <Loader loading={true} />;
  }

  if (!modifiersLists) {
    return <div>No brand sections found</div>;
  }

  if (!modifiers) {
    return <div>No modifiers found</div>;
  }

  return (
    <PageContent>
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-3xl font-semibold text-gray-900">
          Modifiers Lists
        </h1>
        <div className="flex items-center gap-2">
          <button className="underline" onClick={() => toggleIncludeDeleted()}>
            {includeDeleted ? "Hide" : "Show"} deleted
          </button>
          <FlatButton
            onClick={() => syncModifiersLists({})}
            loading={isSyncingPending}
          >
            Sync
          </FlatButton>
        </div>
      </div>

      <div className="flex flex-col w-full h-full gap-2">
        {modifiersLists.map((modifiersList) => (
          <div className="flex w-full" key={modifiersList.id}>
            <ItemsContainer
              key={modifiersList.id}
              items={modifiers.filter((modifier) =>
                modifier.modifiers_lists?.find(
                  (modifiers_list) => modifiers_list.id === modifiersList.id,
                ),
              )}
              title={`${modifiersList.brand.name} - ${modifiersList.name}`}
              shouldCollapse={false}
              showImages={false}
            />
            {modifiersList.deleted_at ? (
              <TrashIcon title="Deleted" className="m-2 h-5 w-5 text-red-500" />
            ) : (
              <TrashIcon
                title="Delete"
                className="m-2 h-5 w-5 cursor-pointer hover:text-red-500 hidden"
                onClick={() => setShowDeleteModal(modifiersList.slug)}
              />
            )}
            <DeleteModifiersListDialog
              showModal={showDeleteModal === modifiersList.slug}
              hideModal={() => setShowDeleteModal(null)}
              modifiersList={modifiersList}
            />
          </div>
        ))}
      </div>
    </PageContent>
  );
};
