import { Menu, Transition } from "@headlessui/react";
import { AdjustmentsVerticalIcon, HomeIcon } from "@heroicons/react/24/outline";
import {
  BookOpenIcon,
  BuildingOfficeIcon,
  BuildingStorefrontIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ClockIcon,
  CreditCardIcon,
  DocumentChartBarIcon,
  DocumentTextIcon,
  MapIcon,
  MapPinIcon,
  PhotoIcon,
  QueueListIcon,
  RectangleGroupIcon,
  RectangleStackIcon,
  SquaresPlusIcon,
  UserGroupIcon,
  UserIcon,
} from "@heroicons/react/24/solid";
import { Profile, useAuth } from "@localkitchens/passwordless-auth";
import { Fragment, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { USER_ROLES } from "../../constants";

type PageLayoutProps = {
  children: any;
  isEngineer: boolean;
  isKitchen: boolean;
};

type NavItem = {
  name: string;
  href: string;
  icon: React.ForwardRefExoticComponent<
    Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
      title?: string | undefined;
      titleId?: string | undefined;
    } & React.RefAttributes<SVGSVGElement>
  >;
  current: boolean;
  isSubmenu?: boolean;
  submenuGroup?: string;
}[];

const buildNav = (user: { roles: String[] }) => {
  const navigation = [
    { name: "Home", href: "/", icon: HomeIcon, current: false },
    {
      name: "Feature Tiles",
      href: "/feature-tiles",
      icon: PhotoIcon,
      current: false,
    },
    { name: "Brands", href: "/brands", icon: BookOpenIcon, current: false },
    { name: "Coupons", href: "/coupons", icon: CreditCardIcon, current: false },
    {
      name: "Custom Hours",
      href: "/custom-hours",
      icon: ClockIcon,
      current: false,
    },
    {
      name: "Locations",
      href: "/locations",
      icon: BuildingOfficeIcon,
      current: false,
    },
    ...(user?.roles?.includes(USER_ROLES.ADMIN)
      ? [
          {
            name: "Storefronts V2",
            href: "/storefronts",
            icon: BuildingStorefrontIcon,
            current: false,
            submenuGroup: "storefronts",
          },
          {
            name: "Locations",
            href: "/storefronts/locations/",
            icon: MapPinIcon,
            current: false,
            isSubmenu: true,
            submenuGroup: "storefronts",
          },
          {
            name: "Brands",
            href: "/storefronts/brands/",
            icon: BookOpenIcon,
            current: false,
            isSubmenu: true,
            submenuGroup: "storefronts",
          },
          {
            name: "Brands Sections",
            href: "/storefronts/brand-sections/",
            icon: RectangleStackIcon,
            current: false,
            isSubmenu: true,
            submenuGroup: "storefronts",
          },
          {
            name: "Categories",
            href: "/storefronts/categories/",
            icon: RectangleGroupIcon,
            current: false,
            isSubmenu: true,
            submenuGroup: "storefronts",
          },
          {
            name: "Modifiers Lists",
            href: "/storefronts/modifiers-lists/",
            icon: QueueListIcon,
            current: false,
            isSubmenu: true,
            submenuGroup: "storefronts",
          },
          {
            name: "Modifiers",
            href: "/storefronts/modifiers/",
            icon: AdjustmentsVerticalIcon,
            current: false,
            isSubmenu: true,
            submenuGroup: "storefronts",
          },
          {
            name: "Items",
            href: "/storefronts/items/",
            icon: SquaresPlusIcon,
            current: false,
            isSubmenu: true,
            submenuGroup: "storefronts",
          },
          {
            name: "Locations Items",
            href: "/storefronts/locations-items/",
            icon: MapIcon,
            current: false,
            isSubmenu: true,
            submenuGroup: "storefronts",
          },
          {
            name: "Locations Modifiers",
            href: "/storefronts/locations-modifiers/",
            icon: MapIcon,
            current: false,
            isSubmenu: true,
            submenuGroup: "storefronts",
          },
        ]
      : []),
    { name: "Loyalty", href: "/loyalty", icon: UserGroupIcon, current: false },
    {
      name: "Menus",
      href: "/menu-manager",
      icon: DocumentTextIcon,
      current: false,
    },
    {
      name: "Orders",
      href: "/orders",
      icon: DocumentChartBarIcon,
      current: false,
    },
    {
      name: "Employees",
      href: "/employees",
      icon: UserIcon,
      current: false,
    },
  ];
  return navigation;
};

export const PageLayout = ({ children }: PageLayoutProps) => {
  const { logout, user } = useAuth();
  const location = useLocation();
  const history = useHistory();

  let navigation: NavItem = buildNav(user || { roles: [] });
  const [expandSubmenuGroup, setExpandSubmenuGroup] = useState<string | null>(
    navigation.find(
      (item) => location.pathname.startsWith(item.href) && item.href !== "/",
    )?.submenuGroup ?? null,
  );

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <div className="hidden md:flex md:flex-shrink-0">
        <div className="flex flex-col w-64">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex-1 flex flex-col min-h-0">
            <div className="flex items-center justify-center h-16 flex-shrink-0 px-4 bg-white">
              <img
                className="h-4 sm:h-6 rounded-sm"
                src="https://cdn.localkitchens.co/local-kitchens/local-kitchens-logotype-master.svg"
                alt="Local Kitchens"
              />
            </div>
            <div className="flex-1 flex flex-col overflow-y-auto">
              <nav className="flex-1 px-2 py-4 bg-gray-800">
                {navigation.map((item, idx) => (
                  <div key={idx} className="flex w-full items-center">
                    <button
                      onClick={() => {
                        history.push(item.href);
                        window.scrollTo(0, 0);
                      }}
                      className={`
                        ${
                          item.href === location.pathname
                            ? item.isSubmenu
                              ? "bg-gray-600 text-white pl-8"
                              : "bg-gray-900 text-white"
                            : item.isSubmenu
                            ? "bg-gray-300 text-gray-600 pl-8 hover:bg-gray-500"
                            : "text-gray-300 hover:bg-gray-700 hover:text-white"
                        }
                        group flex items-center px-2 py-2 text-sm font-medium w-full
                        ${
                          expandSubmenuGroup === item.submenuGroup ||
                          !item.isSubmenu
                            ? ""
                            : "hidden"
                        }
                      `}
                    >
                      <item.icon
                        className="mr-3 flex-shrink-0 h-6 w-6"
                        aria-hidden="true"
                      />
                      {item.name}
                    </button>
                    {item.submenuGroup &&
                      !item.isSubmenu &&
                      expandSubmenuGroup !== item.submenuGroup && (
                        <ChevronDownIcon
                          className="ml-auto h-6 w-6 text-gray-400 hover:text-gray-300"
                          aria-hidden="true"
                          onClick={() =>
                            setExpandSubmenuGroup(item.submenuGroup ?? null)
                          }
                        />
                      )}
                    {item.submenuGroup &&
                      !item.isSubmenu &&
                      expandSubmenuGroup === item.submenuGroup && (
                        <ChevronUpIcon
                          className="ml-auto h-6 w-6 text-gray-400 hover:text-gray-300"
                          aria-hidden="true"
                          onClick={() => setExpandSubmenuGroup(null)}
                        />
                      )}
                  </div>
                ))}
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
          <div className="flex-1 px-4 flex justify-between">
            <div className="flex-1 flex" />
            <div className="ml-4 flex items-center md:ml-6">
              {/* Profile dropdown */}
              <Menu as="div" className="ml-3 relative">
                <div>
                  <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span className="sr-only">Open user menu</span>
                    <Profile style={{ height: "32px", width: "32px" }} />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <Menu.Item>
                      <button
                        className="block px-4 py-2 text-sm text-gray-700 w-full text-left"
                        onClick={() => logout()}
                      >
                        Log out
                      </button>
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>

        <main className="flex-1 relative overflow-y-auto h-full py-6 px-4 focus:outline-none">
          {children}
        </main>
      </div>
    </div>
  );
};
