import { useQuery, gql } from "@apollo/client";

const SYNC_EVENTS_QUERY = gql`
  query SYNC_EVENTS_QUERY {
    menu_sync_events {
      location_id
      brand_id
      inserted_at
      synced_by_email
    }
  }
`;

const useSyncEvents = () => {
  const { data, error, loading, refetch } = useQuery(SYNC_EVENTS_QUERY);
  return { data, error, loading, refetch };
};

export default useSyncEvents;
