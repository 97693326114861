import React, { useEffect, useState } from "react";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  children?: React.ReactNode;
  textColor?: string;
  backgroundColor?: string;
  disabled?: boolean;
  loading?: boolean;
}

export const Button = ({
  className,
  children,
  textColor,
  backgroundColor,
  disabled,
  loading,
  ...props
}: ButtonProps) => {
  const [disableButton, setDisableButton] = useState(false);
  useEffect(() => {
    if (disabled !== undefined) {
      setDisableButton(disabled);
    } else {
      setDisableButton(false);
    }
  }, []);
  return (
    <button
      type="submit"
      className={
        disableButton
          ? `opacity-80 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md ${
              textColor || "text-white"
            } ${backgroundColor || "bg-lfg-primary"} ${className}`
          : `hover:opacity-80 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md ${
              textColor || "text-white"
            } ${backgroundColor || "bg-lfg-primary"} ${className}`
      }
      {...props}
      disabled={disabled}
    >
      {loading ? (
        <div className="loader ease-linear rounded-full border-2 border-gray-200 h-5 w-5" />
      ) : (
        children
      )}
    </button>
  );
};
