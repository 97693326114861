import { useHistory, useRouteMatch } from "react-router-dom";
import classNames from "../../utils/classNames";
import useQueryParams from "../../utils/hooks/useQueryParams";
import { KitchenNamesController } from "../KitchenNames/KitchenNamesController";
import MenuEditorController from "../MenuEditor/MenuEditorController";
import { MenusController } from "../Menus/MenusController";

const MenuManager = () => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { params } = useQueryParams();

  const tabSelected = params.get("tab");
  const renderPage = () => {
    switch (tabSelected) {
      case "syncing":
        return <MenusController />;
      case "kitchen_names":
        return <KitchenNamesController />;
      default:
        return <MenuEditorController />;
    }
  };
  return (
    <main className="flex-1 relative overflow-y-auto focus:outline-none">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 my-5">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <h1 className="text-3xl font-semibold text-gray-900 mr-3">
              Menu Manager
            </h1>
          </div>
        </div>
        <nav className="my-4 flex space-x-4" aria-label="Tabs">
          <div
            className={classNames(
              tabSelected === "menu_editor" || !tabSelected
                ? "border-lfg-primary text-lfg-primary"
                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
              "cursor-pointer whitespace-nowrap py-2 px-1 border-b-4 font-medium text-sm",
            )}
            onClick={() => history.push(`${url}?tab=menu_editor`)}
          >
            Bulk Editor
          </div>
          <div
            className={classNames(
              tabSelected === "kitchen_names"
                ? "border-lfg-primary text-lfg-primary"
                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
              "cursor-pointer whitespace-nowrap py-2 px-1 border-b-4 font-medium text-sm",
            )}
            onClick={() => history.push(`${url}?tab=kitchen_names`)}
          >
            Items
          </div>
          <div
            className={classNames(
              tabSelected === "syncing"
                ? "border-lfg-primary text-lfg-primary"
                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
              "cursor-pointer whitespace-nowrap py-2 px-1 border-b-4 font-medium text-sm",
            )}
            onClick={() => history.push(`${url}?tab=syncing`)}
          >
            Syncing
          </div>
        </nav>
      </div>
      {renderPage()}
    </main>
  );
};

export default MenuManager;
