import {
  LocationsQueryHookResult,
  useLocationsQuery,
} from "../../../graphql/useLocations.query.generated";
import { LocationV2 } from "../../../types";

const useLocations = (input: any) => {
  const result: LocationsQueryHookResult = useLocationsQuery();
  const { data, loading, refetch } = result;
  return {
    data: (data?.locations || []) as LocationV2[],
    loading,
    refetch,
  };
};

export default useLocations;
