import { useMutation, gql } from "@apollo/client";

const DELETE_BRAND = gql`
  mutation DeleteBrand($id: Int!) {
    delete_brand(id: $id)
  }
`;

export const useDeleteBrand = () => {
  const [deleteBrand] = useMutation(DELETE_BRAND);

  return deleteBrand;
};
