import { CogIcon } from "@heroicons/react/24/solid";
import moment from "moment";
import { Dispatch, SetStateAction, useState } from "react";
import { ColumnInfo, ColumnVisibility } from "../../types";
import { Loading } from "../../ui/components/Loading";
import { Pagination } from "../../ui/components/Pagination/Pagination";
import { FilterForm } from "./FilterForm";
import { Table } from "./Table";
import TableSettingsModal from "./TableSettingsModal";
import { ordersTableCols } from "./constants";

type OrdersProps = {
  data: any;
  pageNumber: number;
  limit: number;
  totalOrderCount: number;
  loading: boolean;
  filterParams: any;
  setPageNumber: Dispatch<SetStateAction<number>>;
  setFilterParams: Dispatch<SetStateAction<any>>;
};

const defaultColumnVisibility: ColumnVisibility = ordersTableCols.reduce(
  (allVisibility: ColumnVisibility, colInfo: ColumnInfo) => ({
    [colInfo.name]: colInfo.defaultDisplay,
    ...allVisibility,
  }),
  {},
);

export const Orders = ({
  data,
  pageNumber,
  limit,
  totalOrderCount,
  loading,
  filterParams,
  setPageNumber,
  setFilterParams,
}: OrdersProps) => {
  const startDatetime = moment
    .unix(filterParams.startDate / 1000)
    .format("MM/DD/YYYY");
  const endDatetime = moment
    .unix(filterParams.endDate / 1000)
    .format("MM/DD/YYYY");
  const filteredOrderCount = data?.sales
    ? data?.sales[0]?.total_order_count
    : 0;

  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);
  const [displayCols, setDisplayCols] = useState<ColumnVisibility>(
    defaultColumnVisibility,
  );

  return (
    <main className="flex-1 relative focus:outline-none">
      <div className="py-6">
        <div className="mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-3xl font-semibold text-gray-900">
            SEARCH FOR ORDERS
          </h1>
          <FilterForm
            setPageNumber={setPageNumber}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
        </div>
        <div className="mx-auto px-4 sm:px-6 md:px-8">
          <div className="py-8">
            {loading ? (
              <Loading />
            ) : (
              <>
                <div className="flex justify-between my-3">
                  <p className="text-xl font-medium text-gray-900">
                    Current Date Range:
                    {filterParams.id === ""
                      ? ` ${startDatetime} - ${endDatetime}`
                      : " All"}
                  </p>
                  <div className="flex font-medium text-gray-900 relative">
                    <p className="mt-auto">
                      Filtered {filteredOrderCount} orders
                    </p>
                    <CogIcon
                      className="h-6 w-6 ml-5 mt-auto cursor-pointer"
                      onClick={() => setShowSettingsModal(true)}
                    />
                  </div>
                </div>
                <Table sales={data?.sales} displayCols={displayCols} />
              </>
            )}
          </div>
        </div>
        <div className="mx-auto px-4 sm:px-6 md:px-8">
          <div className="py-2">
            <Pagination
              pageNumber={pageNumber}
              limit={limit}
              totalCount={totalOrderCount}
              setPageNumber={setPageNumber}
            />
          </div>
        </div>
      </div>
      <TableSettingsModal
        showModal={showSettingsModal}
        setShowModal={setShowSettingsModal}
        displayCols={displayCols}
        setDisplayCols={setDisplayCols}
      />
    </main>
  );
};
