import { useQuery, gql } from "@apollo/client";

const TOAST_MENU_QUERY = gql`
  query TOAST_MENU_QUERY($location_id: Int) {
    toast_ids_by_location(location_id: $location_id) {
      name
      id
    }
  }
`;

interface ToastMenuProps {
  location_id: number;
}
const useToastMenuData = ({ location_id }: ToastMenuProps) => {
  const { data, error, loading, refetch } = useQuery(TOAST_MENU_QUERY, {
    variables: {
      location_id,
    },
  });
  return { data, error, loading, refetch };
};

export default useToastMenuData;
