import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import AddStore from "./AddStore";
import { AddLocation } from "./Locations/AddLocation";
import { EditLocation } from "./Locations/EditLocation";
import LocationDetail from "./Locations/LocationDetail";
import Locations from "./Locations/Locations";

interface Props {}

export const LocationsController = ({}: Props) => {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Locations />
      </Route>
      <Route path={`${path}/:locationSlug/add-brand`}>
        <AddStore />
      </Route>
      <Route path={`${path}/:locationSlug/edit`}>
        <EditLocation />
      </Route>
      <Route path={`${path}/new`}>
        <AddLocation />
      </Route>
      <Route path={`${path}/:locationSlug`}>
        <LocationDetail />
      </Route>
    </Switch>
  );
};
