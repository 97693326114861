import { useBulkUpdateMenuEntitiesStatusQuery } from "../../../graphql/useBulkUpdateMenuEntitiesStatus.query.generated";

const useBulkUpdateMenuEntitiesStatus = (startTimestamp: string) => {
  const { data, startPolling, stopPolling } =
    useBulkUpdateMenuEntitiesStatusQuery({
      variables: {
        start_timestamp: startTimestamp,
      },
      fetchPolicy: "no-cache",
      skip: !startTimestamp,
    });

  const status = data?.bulk_update_menu_entities_status;

  return { status, startPolling, stopPolling };
};

export default useBulkUpdateMenuEntitiesStatus;
