import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/solid";

const colorMap = {
  success: "green",
  info: "blue",
  error: "red",
  warning: "yellow",
};

export type AlertProps = {
  description?: string;
  variant: "success" | "info" | "error" | "warning";
  onClose?: () => void;
};

export const Alert = ({ variant, description, onClose }: AlertProps) => {
  const color = colorMap[variant] || "yellow";

  const onClick = () => {
    onClose && onClose();
  };

  return (
    <div
      className={`flex w-3/4 space-x-4 items-start bg-${color}-50 border border-${color}-400 text-${color}-700 p-2 rounded relative shadow-md`}
      role="alert"
      onClick={onClick}
    >
      {variant === "info" ? (
        <InformationCircleIcon
          className={`flex-shrink-0 w-6 h-6 text-${color}`}
        />
      ) : null}
      {variant === "success" ? (
        <CheckCircleIcon className={`flex-shrink-0 w-6 h-6 text-${color}`} />
      ) : null}
      {variant === "error" ? (
        <ExclamationCircleIcon
          className={`flex-shrink-0 w-6 h-6 text-${color}`}
        />
      ) : null}
      {variant === "warning" ? (
        <ExclamationTriangleIcon
          className={`flex-shrink-0 w-6 h-6 text-${color}`}
        />
      ) : null}
      {description && (
        <div className="w-full pr-6">
          <span className="block whitespace-pre-line">{description}</span>
        </div>
      )}
      {onClose && (
        <span className="absolute top-0 bottom-0 right-0 p-2">
          <svg
            className={`fill-current h-6 w-6 text-${color}-500`}
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <title>Close</title>
            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
          </svg>
        </span>
      )}
    </div>
  );
};
