import { gql, useMutation } from "@apollo/client";

const UPDATE_EMPLOYEE_MUTATION = gql`
  mutation UPDATE_EMPLOYEE($id: Int, $employee_input: EmployeeInput) {
    update_employee(id: $id, employee_input: $employee_input) {
      id
      first_name
      last_name
      email
      pin_code
    }
  }
`;

export const useUpdateEmployee = () => {
  const [updateEmployee] = useMutation(UPDATE_EMPLOYEE_MUTATION);
  return updateEmployee;
};
