import { PencilIcon } from "@heroicons/react/24/solid";
import { useListLocationsQuery } from "opsapi/hooks/operations/locations";
import { useListStorefrontsQuery } from "opsapi/hooks/operations/storefronts";
import { useHistory, useParams } from "react-router";
import { Button } from "../../../ui";
import Loader from "../../../ui/components/Loader/Loader";
import { FlatButton } from "../_components/FlatButton";
import { SyncStateBar } from "../_components/SyncStateBar";

export const ListStorefronts = () => {
  const history = useHistory();
  const { locationSlug } = useParams<{ locationSlug: string }>();
  const { data: locations, isLoading: isLocationsLoading } =
    useListLocationsQuery();
  const storefrontsParams = {
    params: { query: { location_slug: locationSlug } },
  };
  const { data: storefronts, isLoading: isStorefrontsLoading } =
    useListStorefrontsQuery(storefrontsParams);

  if (isLocationsLoading || isStorefrontsLoading) {
    return <Loader loading={true} />;
  }

  if (!locations) {
    return <div>No locations found</div>;
  }

  const currentLocation = locations.find(
    (location: { slug: string }) => location.slug === locationSlug,
  );

  if (currentLocation === undefined) {
    return <h1>Location not found</h1>;
  }

  return (
    <div className="flex flex-col w-full h-full">
      <h1 className="text-3xl font-semibold text-gray-900 mb-4">
        Location: {currentLocation.name}
      </h1>

      <div className="flex w-full h-full">
        <div className="flex flex-col w-full h-full gap-2">
          {storefronts?.map((storefront) => (
            <div key={storefront.id} className="flex w-full gap-2">
              <FlatButton
                key={storefront.slug}
                className="h-[60px] w-[140px] min-w-[240px]"
                onClick={() =>
                  history.push(
                    `/storefronts/${currentLocation.slug}/${storefront.slug}/`,
                  )
                }
              >
                <div className="flex flex-col items-center justify-center w-full">
                  <span className="text-nowrap">{storefront.name}</span>
                  <span className="text-xs font-waight-200">
                    {`<${storefront.type}>`}
                  </span>
                </div>
              </FlatButton>

              <SyncStateBar storefront={storefront} />

              <Button
                className="h-[60px] bg-yellow-500 hover:bg-yellow-700 text-white font-bold items-center"
                onClick={() =>
                  history.push(
                    `/storefronts/${currentLocation.slug}/${storefront.slug}/update`,
                  )
                }
              >
                <PencilIcon className="h-5 w-5" />
              </Button>
            </div>
          ))}
          <FlatButton
            className="h-[60px] rounded-full justify-center m-2"
            onClick={() =>
              history.push(`/storefronts/${currentLocation.slug}/create/`)
            }
          >
            Create new
          </FlatButton>
        </div>
      </div>
    </div>
  );
};
