import { Dispatch, SetStateAction } from "react";
import { Modal } from "../../../ui";
import {
  Brand,
  Location,
  MarketingBillboard,
  UpdateMarketingBillboardInput,
} from "../types";
import MarketingBillboardForm from "./MarketingBillboardForm";

type UpdateBannerModalProps = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  onUpdateBillboard: (data: UpdateMarketingBillboardInput) => Promise<void>;
  billboardToUpdate?: MarketingBillboard;
  brands: Brand[];
  locations: Location[];
};

const UpdateMarketingBillboardModal = ({
  showModal,
  setShowModal,
  onUpdateBillboard,
  billboardToUpdate,
  brands,
  locations,
}: UpdateBannerModalProps) => {
  return (
    <Modal
      title="Edit Feature Tile"
      showModal={showModal}
      setShowModal={setShowModal}
      size={5}
    >
      <MarketingBillboardForm
        onSubmit={onUpdateBillboard}
        setShowModal={setShowModal}
        billboard={billboardToUpdate}
        brands={brands}
        locations={locations}
      />
    </Modal>
  );
};

export default UpdateMarketingBillboardModal;
