import { gql, useMutation } from "@apollo/client";
import { OPERATIONS_BRAND_QUERY } from "./useOperationsBrand";

const MENU_ITEM_UPLOAD_BUILD_GRID_IMAGE = gql`
  mutation UploadMenuItemBuildGridImage(
    $menuItemId: Int!
    $uploadInput: UploadMenuItemImageInput!
  ) {
    upload_menu_item_build_grid_image(
      menuItemId: $menuItemId
      input: $uploadInput
    ) {
      kitchen_name
      id
      name
      image_url
      blurhash
    }
  }
`;

export type UploadMenuItemBuildGridImageProps = {
  imageBase64: string;
  menuItemId: number;
  menuItemName: string;
};

export const useUploadMenuItemBuildGridImage = (brandSlug?: string) => {
  const [uploadMenuItemImage, { loading, data }] = useMutation(
    MENU_ITEM_UPLOAD_BUILD_GRID_IMAGE,
  );

  const onUploadMenuItemBuildGridImage = async ({
    imageBase64,
    menuItemId,
    menuItemName,
  }: UploadMenuItemBuildGridImageProps) => {
    return uploadMenuItemImage({
      variables: {
        menuItemId,
        uploadInput: {
          imageBase64,
          name: menuItemName,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: OPERATIONS_BRAND_QUERY,
          variables: {
            input: {
              brand_slug: brandSlug,
            },
          },
        },
      ],
    });
  };

  return { onUploadMenuItemBuildGridImage, loading, data };
};
