import { Route, Switch, useRouteMatch } from "react-router";
import { CreateStorefront } from "./_pages/CreateStorefront";
import { ListStorefronts } from "./_pages/ListStorefronts";
import { StorefrontsByLocations } from "./_pages/StorefrontsByLocations";
import { UpdateStorefront } from "./_pages/UpdateStorefront";
import { ViewStorefront } from "./_pages/ViewStorefront";
import { BrandsController } from "./Brands/BrandsController";
import { BrandSectionsController } from "./BrandSections/BrandSectionsController";
import { CategoriesController } from "./Categories/CategoriesController";
import { ItemsController } from "./Items/ItemsController";
import { LocationsController } from "./Locations/LocationsController";
import { LocationsItemsController } from "./LocationsItems/LocationsItemsController";
import { LocationsModifiersController } from "./LocationsModifiers/LocationsModifiersController";
import { ModifiersController } from "./Modifiers/ModifiersController";
import { ModifiersListsController } from "./ModifiersLists/ModifiersListsController";

export const StorefrontsController = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <StorefrontsByLocations />
      </Route>
      <Route exact path={`${path}/locations/`}>
        <LocationsController />
      </Route>
      <Route exact path={`${path}/brands/`}>
        <BrandsController />
      </Route>
      <Route exact path={`${path}/brand-sections/`}>
        <BrandSectionsController />
      </Route>
      <Route exact path={`${path}/categories/`}>
        <CategoriesController />
      </Route>
      <Route exact path={`${path}/modifiers-lists/`}>
        <ModifiersListsController />
      </Route>
      <Route exact path={`${path}/modifiers/`}>
        <ModifiersController />
      </Route>
      <Route exact path={`${path}/items/`}>
        <ItemsController />
      </Route>
      <Route exact path={`${path}/locations-items/`}>
        <LocationsItemsController />
      </Route>
      <Route exact path={`${path}/locations-modifiers/`}>
        <LocationsModifiersController />
      </Route>
      <Route exact path={`${path}/:locationSlug/`}>
        <ListStorefronts />
      </Route>
      <Route exact path={`${path}/:locationSlug/create/`}>
        <CreateStorefront />
      </Route>
      <Route exact path={`${path}/:locationSlug/:storefrontSlug/`}>
        <ViewStorefront />
      </Route>
      <Route exact path={`${path}/:locationSlug/:storefrontSlug/update/`}>
        <UpdateStorefront />
      </Route>
    </Switch>
  );
};
