import { Dispatch, SetStateAction } from "react";
import { Modal } from "../../ui/components";
import { CouponForm } from "./CouponForm";

type UpdateCouponModalProps = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  onUpdateCoupon?: any;
  couponToUpdate: any;
};

export const UpdateCouponModal = ({
  showModal,
  setShowModal,
  onUpdateCoupon,
  couponToUpdate,
}: UpdateCouponModalProps) => {
  // const onSubmitHandler = ()
  return (
    <Modal
      title="Edit Coupon"
      showModal={showModal}
      setShowModal={setShowModal}
    >
      <CouponForm
        onSubmit={onUpdateCoupon}
        setShowModal={setShowModal}
        coupon={couponToUpdate}
      />
    </Modal>
  );
};
