import { gql, useQuery } from "@apollo/client";
import { OperationsTag } from "../../../types";

export const TAGS_QUERY = gql`
  query TagsQuery {
    operations_tags {
      id
      name
      collection_name
      is_v2
      is_active
      is_filter
      is_collection
      ordinal
      slug
      logo_url
      logo_url_v2
      logo_blurhash
      logo_blurhash_v2
      hero_image_url
      hero_image_url_v2
      hero_image_blurhash
      hero_image_blurhash_v2
      menu_item_tags {
        id
        tag_id
        menu_item_id
        ordinal
        inserted_at
        updated_at
      }
    }
  }
`;

export const useOperationsTags = () => {
  const {
    data: { operations_tags: tags } = { operations_tags: [] }, // Provide a default value for data
    loading,
    error,
    refetch,
  } = useQuery<{ operations_tags: OperationsTag[] }>(TAGS_QUERY, {
    variables: {},
    fetchPolicy: "network-only",
  });

  return { tags, loading, error, refetch };
};
