import { Route, Switch, useRouteMatch } from "react-router";
import { ListItems } from "./pages/ListItems";

export const ItemsController = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/`}>
        <ListItems />
      </Route>
    </Switch>
  );
};
