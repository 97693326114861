import { TrashIcon } from "@heroicons/react/24/outline";
import { useQueryClient } from "@tanstack/react-query";
import {
  LIST_BRAND_SECTIONS,
  useListBrandSectionsQuery,
  useSyncBrandSectionsQuery,
} from "opsapi/hooks/operations/brandSections";
import { useListItemsQuery } from "opsapi/hooks/operations/items";
import { useContext, useState } from "react";
import Loader from "../../../../ui/components/Loader/Loader";
import { PageContent } from "../../../../ui/components/PageContent/PageContent";
import ToastContext from "../../../../utils/contexts/ToastContext";
import { FlatButton } from "../../_components/FlatButton";
import { ItemsContainer } from "../../_components/ItemsContainer";
import { DeleteBrandSectionDialog } from "../components/DeleteBrandSectionDialog";

export const ListBrandSections = () => {
  const queryClient = useQueryClient();
  const { showToast } = useContext(ToastContext);
  const [includeDeleted, setIncludeDeleted] = useState(false);
  const brandSectionParams = {
    params: { query: { include_deleted: includeDeleted } },
  };
  const { data: brandSections, isLoading: isBrandSectionsLoading } =
    useListBrandSectionsQuery(brandSectionParams);
  const { data: items, isLoading: isItemsLoading } = useListItemsQuery();
  const [showDeleteModal, setShowDeleteModal] = useState<string | null>(null);

  const { mutate: syncBrandSections, isPending: isSyncingPending } =
    useSyncBrandSectionsQuery({
      reactQuery: {
        onSuccess: (data) => {
          showToast({
            description: `Result: ${JSON.stringify(data)}`,
            variant: "success",
            seconds: 5,
          });
          queryClient.invalidateQueries({
            queryKey: [LIST_BRAND_SECTIONS],
          });
        },
        onError: (error) => {
          showToast({
            description: error.message,
            variant: "error",
            seconds: 10,
          });
        },
      },
    });

  const toggleIncludeDeleted = () => {
    setIncludeDeleted((prev) => !prev);
  };

  if (isBrandSectionsLoading || isItemsLoading) {
    return <Loader loading={true} />;
  }

  if (!brandSections) {
    return <div>No brand sections found</div>;
  }

  if (!items) {
    return <div>No items found</div>;
  }

  return (
    <PageContent>
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-3xl font-semibold text-gray-900">Brand Sections</h1>
        <div className="flex items-center gap-2">
          <button className="underline" onClick={() => toggleIncludeDeleted()}>
            {includeDeleted ? "Hide" : "Show"} deleted
          </button>
          <FlatButton
            onClick={() => syncBrandSections({})}
            loading={isSyncingPending}
          >
            Sync
          </FlatButton>
        </div>
      </div>

      <div className="flex flex-col overflow-y-auto w-full h-full gap-2">
        {brandSections.map((brandSection) => (
          <div className="flex w-full">
            <ItemsContainer
              key={brandSection.id}
              items={items.filter(
                (item) => item.brand_section?.id === brandSection.id,
              )}
              title={`${brandSection.brand.name} - ${brandSection.name}`}
              shouldCollapse={false}
            />
            {brandSection.deleted_at ? (
              <TrashIcon title="Deleted" className="m-2 h-5 w-5 text-red-500" />
            ) : (
              <TrashIcon
                title="Delete"
                className="m-2 h-5 w-5 cursor-pointer hover:text-red-500 hidden"
                onClick={() => setShowDeleteModal(brandSection.slug)}
              />
            )}
            <DeleteBrandSectionDialog
              showModal={showDeleteModal === brandSection.slug}
              hideModal={() => setShowDeleteModal(null)}
              brandSection={brandSection}
            />
          </div>
        ))}
      </div>
    </PageContent>
  );
};
