import { useMutation, gql } from "@apollo/client";

const UPDATE_STORE_CREDENTIALS = gql`
  mutation UpdateStoreCredentials(
    $store_id: Int
    $ubereats_credentials: ThirdPartyCredentialsInput
  ) {
    update_store_credentials(
      store_id: $store_id
      ubereats_credentials: $ubereats_credentials
    )
  }
`;

const useUpdateStoreCredentials = () => {
  const [updateStoreCredentials, { error }] = useMutation(
    UPDATE_STORE_CREDENTIALS,
  );
  return { updateStoreCredentials, error };
};

export default useUpdateStoreCredentials;
