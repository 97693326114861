import {
  PaginatedMenuEntitiesFilter,
  PaginatedMenuEntitiesSort,
} from "../../../graphql/types";

import { FieldKeys, TargetType } from "./types";

export const DEFAULT_FILTERS = [] as PaginatedMenuEntitiesFilter[];

export const DEFAULT_ORDER = [
  {
    field: "brand_name",
    order: "ASC",
    ordinal: 1,
  },
  {
    field: "target_type",
    order: "ASC",
    ordinal: 2,
  },
  {
    field: "name",
    order: "ASC",
    ordinal: 3,
  },
] as PaginatedMenuEntitiesSort[];

export const TYPE_OPTIONS = [TargetType.MENU_ITEM, TargetType.MODIFIER_ITEM];

export const TABLE_FIELDS: readonly FieldKeys[] = [
  FieldKeys.BRAND,
  FieldKeys.NAME,
  FieldKeys.TYPE,
];

export const LOCATION_ALIASES: { [key: string]: string } = {
  paloalto: "PA",
  mountainview: "MV",
  sanjose: "SJ",
  losgatos: "LG",
  campbell: "CMP",
  granitebay: "GB",
  sanbruno: "SB",
};

export const DEFAULT_COLUMNS = [
  {
    columnId: FieldKeys.BRAND,
    width: 150,
    resizable: true,
    ordinal: 0,
  },
  {
    columnId: FieldKeys.NAME,
    width: 230,
    resizable: true,
    ordinal: 1,
  },
  {
    columnId: FieldKeys.TYPE,
    width: 110,
    resizable: true,
    ordinal: 2,
  },
];

export const DEFAULT_PAGE_SIZE = 100;
