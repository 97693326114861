import { useEffect, useState } from "react";
import { Table, TableData, TableRow } from "../../ui";
import { Loading } from "../../ui/components/Loading";
import { PageContent } from "../../ui/components/PageContent/PageContent";
import dayjs from "../../utils/dayjs";
import { MenuForm } from "./MenuForm";

type MenusProps = {
  stores: any;
  locations: any;
  syncEvents: any;
  operationBrands: any;
  loading: boolean;
};

type SyncEvent = {
  location_id: number;
  brand_id: number;
  inserted_at: number;
  synced_by_email: string;
};

type TransformedLocation = {
  [key: string]: string;
};

type TransformedOperationsBrand = {
  [key: string]: string;
};

export const Menus = ({
  stores,
  loading,
  syncEvents,
  operationBrands,
  locations,
}: MenusProps) => {
  const [transformedOperationsBrandsData, setTransformedOperationsBrandsData] =
    useState<TransformedOperationsBrand>({});
  const [transformedLocationData, setTransformedLocationData] =
    useState<TransformedLocation>({});

  useEffect(() => {
    if (operationBrands) {
      setTransformedOperationsBrandsData(
        operationBrands.reduce(
          (prev: any, current: any) => ({
            ...prev,
            [current.id]: current.name,
          }),
          {},
        ),
      );
    }
    if (locations) {
      setTransformedLocationData(
        locations.reduce(
          (prev: any, current: any) => ({
            ...prev,
            [current.id]: current.name,
          }),
          {},
        ),
      );
    }
  }, [operationBrands, locations]);

  if (loading) {
    return <Loading />;
  }

  return (
    <PageContent>
      <h1 className="text-2xl font-semibold text-gray-900">Sync menus</h1>
      <MenuForm stores={stores} locations={locations} />
      <h2 className="text-xl font-medium text-gray-900">
        10 Most Recent Menu Syncs
      </h2>
      <Table headers={["Location", "Brand", "Time", "Synced by"]}>
        {syncEvents?.map((syncEvent: SyncEvent, index: number) => (
          <TableRow key={index}>
            <TableData>
              {transformedLocationData &&
                transformedLocationData[syncEvent.location_id]}
            </TableData>
            <TableData>
              {transformedOperationsBrandsData &&
                transformedOperationsBrandsData[syncEvent.brand_id]}
            </TableData>
            <TableData>{dayjs(syncEvent.inserted_at).format("llll")}</TableData>
            <TableData>{syncEvent?.synced_by_email}</TableData>
          </TableRow>
        ))}
      </Table>
    </PageContent>
  );
};
