import { gql, useMutation } from "@apollo/client";
import { MARKETING_BILLBOARDS_QUERY } from "./useMarketingBillboards";

const BULK_UPDATE_MARKETING_BILLBOARDS = gql`
  mutation BulkUpdateMarketingBillboards(
    $marketingBillboards: [MarketingBillboardBulkUpdateInput!]!
  ) {
    bulk_update_marketing_billboards(marketing_billboards: $marketingBillboards)
  }
`;

export const useBulkUpdateMarketingBillboards = () => {
  return useMutation(BULK_UPDATE_MARKETING_BILLBOARDS, {
    refetchQueries: [{ query: MARKETING_BILLBOARDS_QUERY }],
  });
};
