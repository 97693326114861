import { Location, MarketingBillboard, MultiSelectOptionType } from "./types";

export const AllLocationsOption: MultiSelectOptionType = {
  value: "all",
  label: "All Locations",
};

export const parseAttributes = (attributes: {
  [key: string]: string | { value: string; label: string };
}) => {
  const resAttributes: Record<string, string> = {};

  Object.entries(attributes).forEach(([key, val]) => {
    if (typeof val === "object") {
      resAttributes[key] = val.value;
      return;
    }

    resAttributes[key] = val;
  });

  return resAttributes;
};

export const getDefaultLocation = (
  billboard: MarketingBillboard | undefined,
  locations: Location[],
) => {
  return billboard?.locations && billboard.locations.length !== locations.length
    ? billboard?.locations?.map((location) => ({
        value: location.id,
        label: location.name,
      }))
    : [AllLocationsOption];
};
