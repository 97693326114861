export const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "add_job":
      return {
        ...state,
        [action.payload.rowId]: action.payload.value,
      };
    case "remove_job":
      const _state = { ...state };
      delete _state[action.payload.rowId];
      return _state;
    case "remove_all":
      return {};
    default:
      return state;
  }
};
