import { gql, useQuery } from "@apollo/client";

const OPERATIONS_SALES_QUERY = gql`
  query OPERATIONS_SALES_QUERY(
    $id: String
    $page_number: Int
    $limit: Int
    $customer_name: String
    $email: String
    $phone_number: String
    $locationID: String
    $brandID: String
    $providerID: String
    $start_date: Float
    $end_date: Float
    $external_id: String
    $customer_id: String
    $ticket_number: String
  ) {
    sales(
      id: $id
      page_number: $page_number
      limit: $limit
      customer_name: $customer_name
      email: $email
      phone_number: $phone_number
      location_id: $locationID
      brand_id: $brandID
      provider_id: $providerID
      start_date: $start_date
      end_date: $end_date
      external_id: $external_id
      customer_id: $customer_id
      ticket_number: $ticket_number
    ) {
      id
      location {
        id
        name
        slug
      }
      provider {
        id
        name
        slug
      }
      brands {
        id
        name
        slug
        ticker
      }
      customer_name
      customer_phone
      customer_id
      subtotal
      inserted_at
      is_pickup
      is_completed
      is_scheduled
      external_id
      stripe_payment_intent_id
      total_order_count
      raw_cart_total
      raw_cart_subtotal
      raw_cart_tax
      raw_cart_tip
      raw_cart_service_fee_rate
      raw_cart_service_fee
      raw_cart_delivery_fee
      raw_cart_credits_used
      raw_cart_discount_amount
      raw_orders_data
      status
      pickup_time
      line_items {
        id
        ordinal
        name
        customer_name
        price
        quantity
        special_instructions
        is_completed
        modifier_items {
          id
          name
          quantity
          price
        }
      }
    }
  }
`;

type UseSalesProps = {
  id?: string;
  pageNumber?: number;
  limit?: number;
  customerName?: string;
  email?: string;
  phoneNumber?: string;
  creditCard?: string;
  locationID?: string;
  brandID?: string;
  providerID?: string;
  startDate?: number;
  endDate?: number;
  externalID?: string;
  customerId?: string;
  ticketNumber?: string;
};

export const useSales = ({
  id,
  pageNumber,
  limit,
  customerName,
  email,
  phoneNumber,
  locationID,
  brandID,
  providerID,
  startDate,
  endDate,
  externalID,
  customerId,
  ticketNumber,
}: UseSalesProps) => {
  const { data, error, loading, refetch } = useQuery(OPERATIONS_SALES_QUERY, {
    variables: {
      id,
      page_number: pageNumber,
      limit,
      customer_name: customerName,
      email,
      phone_number: phoneNumber,
      locationID,
      brandID,
      providerID,
      start_date: startDate,
      end_date: endDate,
      external_id: externalID,
      customer_id: customerId,
      ticket_number: ticketNumber,
    },
  });

  return { data, error, loading, refetch };
};
