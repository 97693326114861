import { useListItemsQuery } from "opsapi/hooks/operations/items";
import { useListLocationsQuery } from "opsapi/hooks/operations/locations";

import {
  CheckCircleIcon,
  PhotoIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { Table, TableData, TableRow } from "../../../../ui";

export const ListLocationsItems = () => {
  const { data: items, isLoading: isItemsLoading } = useListItemsQuery();
  const { data: locations, isLoading: isLocationsLoading } =
    useListLocationsQuery();

  if (isItemsLoading || isLocationsLoading) {
    return <div>Loading...</div>;
  }

  if (!items) {
    return <div>No items found</div>;
  }

  if (!locations) {
    return <div>No locations found</div>;
  }

  const tableHeaders = [
    <th key="brand" className="text-center sticky left-0 bg-white">
      Item Name
    </th>,
    ...locations.map((location) => location.name),
  ];

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-3xl font-semibold text-gray-900">
          Locations Items
        </h1>
      </div>

      <Table headers={tableHeaders}>
        {items.map((item, idx) => (
          <TableRow
            key={item.id}
            className="group"
            rowColor={idx % 2 ? "bg-white" : "bg-gray-50"}
          >
            <TableData className="bg-white sticky left-0 h-[70px] flex gap-2 items-center group-hover:bg-green-50">
              {item.image_url ? (
                <img
                  src={item.image_url?.replace(".webp", "-320w.webp")}
                  className="object-cover w-8 h-8"
                  alt="item images"
                />
              ) : (
                <PhotoIcon className="w-8 h-8" />
              )}
              {item.brand?.name || "-"} - {item.name}
            </TableData>

            {locations.map((location) => (
              <TableData key={location.id}>
                <div className="flex items-center justify-center">
                  {!!item.locations?.find(
                    (item_location) => item_location.id === location.id,
                  ) ? (
                    <CheckCircleIcon
                      className="h-8 w-8 text-green-500"
                      title={location.state}
                    />
                  ) : (
                    <XMarkIcon
                      className="h-8 w-8 text-red-500"
                      title={location.state}
                    />
                  )}
                </div>
              </TableData>
            ))}
          </TableRow>
        ))}
      </Table>
    </div>
  );
};
