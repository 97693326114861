import React from "react";

interface FormCheckboxProps extends React.HTMLAttributes<HTMLInputElement> {
  id?: string;
  value?: any;
  type?: string;
  placeholder?: string;
  name?: string;
  required?: boolean;
  className?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (event: any) => void;
}

// React.forwardRef is used to address this issue: https://github.com/react-hook-form/react-hook-form/issues/3411
export const FormCheckbox = React.forwardRef(
  (props: FormCheckboxProps, ref: React.Ref<HTMLInputElement>) => {
    const {
      id,
      type,
      value,
      name,
      placeholder,
      required,
      className,
      checked,
      disabled,
      onChange,
      ...rest
    } = props;
    const defaultInputStyle = `flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-7 h-7 m-1 ml-0 rounded-none rounded-md sm:text-sm border-gray-300 ${
      disabled ? "cursor-not-allowed opacity-50" : "cursor-pointer"
    }`;

    return (
      <input
        id={id}
        type={type ?? "checkbox"}
        value={value}
        name={name}
        placeholder={placeholder}
        required={required}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        className={className ? className : defaultInputStyle}
        ref={ref}
        {...rest}
      />
    );
  },
);
