import React from "react";
import classNames from "../../../utils/classNames";

type TableRowProps = {
  rowColor?: string;
  onClick?: (evt?: any) => void;
  children: React.ReactNode;
  className?: string;
  style?: any;
};

export const TableRow = React.forwardRef(
  (
    { rowColor, onClick, children, className, ...props }: TableRowProps,
    ref: any,
  ) => {
    return (
      <tr
        {...props}
        ref={ref}
        className={classNames(
          "hover:bg-green-50",
          rowColor ?? "",
          onClick ? "cursor-pointer" : "",
          className,
        )}
        onClick={onClick}
      >
        {children}
      </tr>
    );
  },
);
