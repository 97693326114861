import { HeartIcon } from "@heroicons/react/24/solid";
import { PageContent } from "../../ui/components/PageContent/PageContent";

export const Home = () => {
  return (
    <PageContent>
      <h1 className="text-3xl font-semibold text-gray-900 mr-3">Home</h1>
      <div className="flex justify-center items-center h-1/3 text-3xl font-semibold">
        Bring communities together...{" "}
        <HeartIcon className="h-12 w-12 text-red-500" />
      </div>
    </PageContent>
  );
};
