import { useMutation, gql } from "@apollo/client";

const UPDATE_LOCATION_ACCOUNT_MUTATION = gql`
  mutation UPDATE_LOCATION_ACCOUNT_MUTATION(
    $location_account_id: Int
    $email: String
  ) {
    update_location_account(
      location_account_id: $location_account_id
      email: $email
    )
  }
`;

export const useUpdateLocationAccount = () => {
  const [updateLocationAccount] = useMutation(
    UPDATE_LOCATION_ACCOUNT_MUTATION,
    {
      refetchQueries: ["LOCATION_ACCOUNTS_BY_LOCATION_ID_QUERY"],
    },
  );

  return updateLocationAccount;
};
