import { Identifier } from "dnd-core";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { useDrop } from "react-dnd";
import { Controller, useForm } from "react-hook-form";
import { MenuItem, OperationsMenuItem } from "../../../types";
import { FormInput, FormLabel } from "../../../ui/components";
import { Button } from "../../../ui/components/Button/Button";
import { Loading } from "../../../ui/components/Loading";
import { DragItem } from "../../../ui/components/SearchAndDrag/DraggableItem";
import { ItemTypes } from "../../../ui/components/SearchAndDrag/SearchAndDrag";
import classNames from "../../../utils/classNames";
import ToastContext from "../../../utils/contexts/ToastContext";
import { useBulkUpdatePairings } from "../../Pairings/hooks/useBulkUpdatePairings";
import { useLazyMenuItemPairings } from "../../Pairings/hooks/useLazyPairings";
import { MenuItemSearchAndDrag } from "../../Tags/MenuItemSearchAndDrag";
import SearchBar from "./SearchBar";

type PairingsFormProps = {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  mode: "create" | "update";
  allMenuItems: MenuItem[];
  menuItem: OperationsMenuItem;
  defaultPairings: MenuItem[];
};

export const PairingsForm = ({
  setShowModal,
  mode,
  allMenuItems,
  menuItem,
  defaultPairings,
}: PairingsFormProps) => {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = useForm();
  const { showToast } = useContext(ToastContext);
  const { bulkUpdatePairings } = useBulkUpdatePairings();
  const { fetchPairings, data: copyPairingsData } = useLazyMenuItemPairings();
  const copyPairingsItems = copyPairingsData?.menu_item_pairings;

  const onSubmitHandler = async (data: any) => {
    setLoading(true);
    try {
      // Extract ids from pairing_menu_item_ids
      const pairingIds = data.pairing_menu_item_ids.map((item: any) =>
        typeof item === "object" ? (item as { id: number }).id : item,
      );

      await bulkUpdatePairings({
        variables: {
          menuItemId: menuItem.id,
          pairingIds: pairingIds,
        },
      });
      showToast({
        description: "Pairings were successfully updated.",
        variant: "success",
        seconds: 3,
      });
      setShowModal(false);
    } catch (err) {
      showToast({
        description: "Pairings were not successfully updated.",
        variant: "error",
        seconds: 3,
      });
    }
    setLoading(false);
  };

  const [, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null; isOver: boolean }
  >({
    accept: ItemTypes.selectedItem,
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
      isOver: monitor.isOver(),
    }),
  });

  if (loading) {
    return (
      <div className="bg-gray-100 bg-opacity-50 w-full h-full absolute items-center flex rounded-lg">
        <Loading />
      </div>
    );
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmitHandler)}
      ref={drop}
      className="relative"
    >
      <div className="grid grid-cols-3 gap-6 my-5">
        <div className="col-span-3">
          <FormLabel
            title="Name"
            htmlFor="name"
            information="Guest-facing name of the menu item that appears on our platforms."
          />
          <Controller
            name="name"
            control={control}
            defaultValue={menuItem.name ?? ""}
            render={({ field }) => (
              <FormInput
                type="text"
                id="name"
                {...field}
                disabled={mode === "update"}
                className={classNames(
                  mode === "update"
                    ? "cursor-not-allowed bg-gray-100"
                    : "cursor-text",
                  "flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-md sm:text-sm border-gray-300",
                )}
              />
            )}
          />
        </div>

        <div className="col-span-3">
          <FormLabel
            title="Copy Pairing From Another Menu Item"
            htmlFor="copy_pairing_items"
            information="Pairing items that will be copied from another menu item."
          />
          <Controller
            name="copy_pairing_items"
            control={control}
            defaultValue={defaultPairings ?? []}
            render={({ field }) => (
              <SearchBar
                placeholder="Enter a Menu Item Name..."
                data={allMenuItems}
                onChange={(value: number) => {
                  field.onChange(value);
                }}
                fetchPairings={fetchPairings}
              />
            )}
          />
        </div>

        <div className="col-span-3">
          <FormLabel
            title="Menu Items"
            htmlFor="pairing_menu_item_ids"
            information="Menu items to show on pairings form"
          />
          <div>
            <p className="text-sm text-red-500 mt-2">
              Note, we only show the first 5 items as a maximum on the pairings
              form, but feel free to add as many as you want to remember them
              for future use.
            </p>
          </div>
          <Controller
            name="pairing_menu_item_ids"
            control={control}
            defaultValue={defaultPairings ?? []}
            render={({ field }) => (
              <MenuItemSearchAndDrag
                selectedValues={field.value.map((item: any) =>
                  typeof item === "object" ? item.id : item,
                )}
                menuItems={allMenuItems}
                onChange={(val: number[]) => {
                  field.onChange(val); // Ensure form state is updated
                }}
                copyPairingsItems={copyPairingsItems}
              />
            )}
          />
        </div>
      </div>
      <Button className="mr-3">Save</Button>
      <Button
        type="button"
        backgroundColor="bg-gray-500"
        onClick={() => setShowModal(false)}
      >
        Cancel
      </Button>
    </form>
  );
};
