import { gql, useQuery } from "@apollo/client";

const PAIRINGS_QUERY = gql`
  query PairingsQuery($menuItemId: Int!) {
    menu_item_pairings(menuItemId: $menuItemId) {
      id
      ordinal
    }
  }
`;

export const useMenuItemPairings = (menuItemId: number) => {
  const { data, loading, error, refetch } = useQuery(PAIRINGS_QUERY, {
    variables: {
      menuItemId,
    },
    fetchPolicy: "network-only",
  });
  return { data, loading, error, refetch };
};
