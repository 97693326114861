import { useMutation, gql } from "@apollo/client";

const CREATE_CUSTOM_HOURS = gql`
  mutation CreateCustomHours($input: CustomHoursInput) {
    create_custom_hours(input: $input)
  }
`;

const useCreateCustomHours = () => {
  const [createCustomHours, { error }] = useMutation(CREATE_CUSTOM_HOURS, {
    refetchQueries: ["CustomHoursQuery"],
  });
  return { createCustomHours, error };
};

export default useCreateCustomHours;
