import { useQuery, gql } from "@apollo/client";

export const ALL_STORES_QUERY = gql`
  query AllStoresQuery {
    all_stores {
      id
      start_timestamp
      ubereats_store_id
      doordash_store_id
      is_open
      is_active
      updated_at
      ordinal
      brand {
        id
        name
      }
      location {
        id
        slug
        name
      }
      store_hours {
        id
        day_of_week
        hours {
          start_day
          end_day
          start_time
          end_time
        }
      }
      menus {
        toast_menu_id
      }
    }
  }
`;

export const useAllStores = () => {
  const { data, error, loading, refetch } = useQuery(ALL_STORES_QUERY, {
    variables: {},
    pollInterval: 2 * 60 * 1000,
    fetchPolicy: "network-only",
  });
  return { data, error, loading, refetch };
};
