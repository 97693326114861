import React from "react";

import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/24/solid";

interface MenuEditorFooterProps {
  onBack: () => void;
  onNext: () => void;
  disableNext: boolean;
  disableBack: boolean;
}

const MenuEditorFooter = React.memo(
  ({ onBack, onNext, disableBack, disableNext }: MenuEditorFooterProps) => {
    return (
      <div
        className={
          "fixed top-[calc(100vh-80px)] right-0 w-full md:w-[calc(100vw-288px)]"
        }
      >
        <div className={"flex-1 align-top"}>
          <div className={"mx-auto max-w-7xl mx-auto px-8 my-5"}>
            <nav className="w-full mx-auto w-full border-t border-gray-200 flex items-center justify-between">
              <div className="-mt-px w-0 flex-1 flex">
                <button
                  onClick={() => onBack()}
                  className={`${
                    disableBack ? "hidden" : ""
                  } border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300`}
                >
                  <ArrowLongLeftIcon
                    className="mr-3 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  Previous
                </button>
              </div>
              <div className="-mt-px w-0 flex-1 flex justify-end">
                <button
                  onClick={() => onNext()}
                  className={`${
                    disableNext ? "hidden" : ""
                  } border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300`}
                >
                  Next
                  <ArrowLongRightIcon
                    className="ml-3 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </nav>
          </div>
        </div>
      </div>
    );
  },
);

export default MenuEditorFooter;
