import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, SetStateAction, useState } from "react";
import { Button } from "../../ui/components/Button/Button";
import { Loading } from "../../ui/components/Loading";
import { PageContent } from "../../ui/components/PageContent/PageContent";
import { Pagination } from "../../ui/components/Pagination/Pagination";
import { CouponsFilterForm } from "./CouponsFilterForm";
import CouponsTable from "./CouponsTable";
import { CreateCouponModal } from "./CreateCouponModal";
import { useCreateCoupon } from "./hooks/useCreateCoupon";
import { useUpdateCoupon } from "./hooks/useUpdateCoupon";

type CouponsProps = {
  data: any;
  pageNumber: number;
  limit: number;
  totalCouponCount: number;
  loading: boolean;
  setPageNumber: Dispatch<SetStateAction<number>>;
  setFilterParams: Dispatch<SetStateAction<any>>;
  refetch: any;
};

export const Coupons = ({
  data,
  pageNumber,
  limit,
  totalCouponCount,
  loading,
  setPageNumber,
  setFilterParams,
  refetch,
}: CouponsProps) => {
  const createCoupon = useCreateCoupon();
  const updateCoupon = useUpdateCoupon();

  const [showCreateCouponModal, setShowCreateCouponModal] =
    useState<boolean>(false);

  const onUpdateCoupon = async (data: any) => {
    await updateCoupon({
      variables: { update_coupon_input: data },
    });
    refetch();
  };

  const onCreateCoupon = async (data: any) => {
    await createCoupon({
      variables: { create_coupon_input: data },
    });
    refetch();
  };

  return (
    <PageContent>
      <div className="flex justify-between">
        <h1 className="text-3xl font-semibold text-gray-900">Coupons</h1>
        <Button
          className="flex items-center"
          type="button"
          onClick={() => setShowCreateCouponModal(true)}
        >
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          Create Coupon
        </Button>
      </div>
      <CouponsFilterForm
        setPageNumber={setPageNumber}
        setFilterParams={setFilterParams}
      />

      {loading ? <Loading /> : null}

      <CouponsTable
        coupons={data?.coupons?.coupons || []}
        onUpdateCoupon={onUpdateCoupon}
      />

      <Pagination
        pageNumber={pageNumber}
        limit={limit}
        totalCount={totalCouponCount}
        setPageNumber={setPageNumber}
      />
      <CreateCouponModal
        showModal={showCreateCouponModal}
        setShowModal={setShowCreateCouponModal}
        onCreateCoupon={onCreateCoupon}
      />
    </PageContent>
  );
};
