import { TrashIcon } from "@heroicons/react/24/outline";
import { useQueryClient } from "@tanstack/react-query";
import {
  LIST_BRANDS,
  useListBrandsQuery,
  useSyncBrandsQuery,
} from "opsapi/hooks/operations/brands";
import { useContext, useState } from "react";
import { Table, TableData, TableRow } from "../../../../ui";
import Loader from "../../../../ui/components/Loader/Loader";
import ToastContext from "../../../../utils/contexts/ToastContext";
import { FlatButton } from "../../_components/FlatButton";
import { DeleteBrandDialog } from "../components/DeleteBrandDialog";

export const ListBrands = () => {
  const queryClient = useQueryClient();
  const { showToast } = useContext(ToastContext);
  const [includeDeleted, setIncludeDeleted] = useState(false);
  const brandParams = {
    params: { query: { include_deleted: includeDeleted } },
  };
  const { data: brands, isLoading: isBrandsLoading } =
    useListBrandsQuery(brandParams);
  const [showDeleteModal, setShowDeleteModal] = useState<string | null>(null);
  const tableHeaders = ["Id", "Name", "State", "Actions"];

  const { mutate: syncBrands, isPending: isSyncingPending } =
    useSyncBrandsQuery({
      reactQuery: {
        onSuccess: (data) => {
          showToast({
            description: `Result: ${JSON.stringify(data)}`,
            variant: "success",
            seconds: 5,
          });
          queryClient.invalidateQueries({
            queryKey: [LIST_BRANDS],
          });
        },
        onError: (error) => {
          showToast({
            description: error.message,
            variant: "error",
            seconds: 10,
          });
        },
      },
    });

  const toggleIncludeDeleted = () => {
    setIncludeDeleted((prev) => !prev);
  };

  if (isBrandsLoading) {
    return <Loader loading={true} />;
  }

  if (!brands) {
    return <div>No brands found</div>;
  }

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-3xl font-semibold text-gray-900">Brands</h1>
        <div className="flex items-center gap-2">
          <button className="underline" onClick={() => toggleIncludeDeleted()}>
            {includeDeleted ? "Hide" : "Show"} deleted
          </button>
          <FlatButton onClick={() => syncBrands({})} loading={isSyncingPending}>
            Sync
          </FlatButton>
        </div>
      </div>

      <div className="flex w-full h-full">
        <Table headers={tableHeaders}>
          {brands.map((brand, idx) => (
            <TableRow
              key={brand.slug}
              rowColor={idx % 2 ? "bg-white" : "bg-gray-50"}
            >
              <TableData>{brand.id}</TableData>
              <TableData className="w-full">{brand.name}</TableData>
              <TableData>{brand.state}</TableData>
              <TableData className="flex gap-1">
                {brand.deleted_at ? (
                  <TrashIcon title="Deleted" className="h-5 w-5 text-red-500" />
                ) : (
                  <TrashIcon
                    title="Delete"
                    className="h-5 w-5 cursor-pointer hover:text-red-500 hidden"
                    onClick={() => setShowDeleteModal(brand.slug)}
                  />
                )}
                <DeleteBrandDialog
                  showModal={showDeleteModal === brand.slug}
                  hideModal={() => setShowDeleteModal(null)}
                  brand={brand}
                />
              </TableData>
            </TableRow>
          ))}
        </Table>
      </div>
    </div>
  );
};
