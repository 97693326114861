import React, { MouseEventHandler } from "react";

type TableDataProps = {
  title?: string;
  children?: React.ReactNode;
  className?: string;
  onClick?: MouseEventHandler<HTMLTableDataCellElement>;
};

export const TableData = ({
  children,
  title,
  className,
  onClick,
}: TableDataProps) => {
  return (
    <td
      className={`px-6 py-4 whitespace-nowrap text-sm text-gray-500 ${className}`}
      onClick={onClick}
    >
      {title ? title : children}
    </td>
  );
};
