import { MouseEvent, useEffect, useState } from "react";

interface ToggleProps {
  initialValue: boolean;
  onChange?: (event: MouseEvent<HTMLDivElement>, value: boolean) => void;
  disabled?: boolean;
}

export const Toggle = ({ initialValue, onChange, disabled }: ToggleProps) => {
  const [value, setValue] = useState<boolean>(initialValue);
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  return (
    <label className="inline-flex relative items-center mr-5 cursor-pointer">
      <input
        type="checkbox"
        className="sr-only peer"
        checked={value}
        onChange={() => {}}
      />
      <div
        className={`w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600 ${
          disabled ? "cursor-not-allowed opacity-50" : ""
        }`}
        onClick={(e) => {
          if (disabled) {
            e.preventDefault();
            return;
          }

          setValue((prevValue) => {
            onChange?.(e, !prevValue);
            return !prevValue;
          });
        }}
      />
    </label>
  );
};

export default Toggle;
