import { sortBy } from "lodash";
import { useEffect, useState } from "react";
import { Table, TableData, TableRow } from "../../ui/components";
import { usdFormatter } from "../../utils/usdFormatter";
import { UpdateCouponModal } from "./UpdateCouponModal";
import { useLocations } from "./hooks/useLocations";
import { useOperationsBrands } from "./hooks/useOperationsBrands";

type Location = {
  slug: string;
  name: string;
};
type LocationMap = {
  [key: string]: string;
};

type Brand = {
  id: number;
  name: string;
};
type BrandMap = {
  [key: number]: string;
};

type CouponsTableProps = {
  coupons: any;
  onUpdateCoupon: any;
};
export default function CouponsTable({
  coupons,
  onUpdateCoupon,
}: CouponsTableProps) {
  const { data: locationsData } = useLocations();
  const { data: operationsBrandsData } = useOperationsBrands();

  const [locationsMap, setLocationsMap] = useState<LocationMap>();
  const [operationsBrandsMap, setOperationsBrandsMap] = useState<BrandMap>({});
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
  const [couponToUpdate, setCouponToUpdate] = useState<any>(null);

  // Create a map from operation brands id to name for ui display
  useEffect(() => {
    if (operationsBrandsData) {
      setOperationsBrandsMap(
        Object.fromEntries(
          operationsBrandsData.operations_brands.map((e: Brand) => [
            e.id,
            e.name,
          ]),
        ),
      );
    }
  }, [operationsBrandsData]);

  // Create a map from location slug to name for ui display
  useEffect(() => {
    if (locationsData) {
      setLocationsMap(
        Object.fromEntries(
          locationsData.locations.map((e: Location) => [e.slug, e.name]),
        ),
      );
    }
  }, [locationsData]);

  const clickUpdateButton = (coupon: any) => {
    setCouponToUpdate({
      ...coupon,
      location_attrs: JSON.parse(coupon.location_attrs),
      brand_attrs: JSON.parse(coupon.brand_attrs),
      order_history_attrs: JSON.parse(coupon.order_history_attrs),
    });
    setShowUpdateModal(true);
  };

  const tableHeaders = [
    "Status",
    "Name",
    "Code",
    "Discount",
    // "Loyalty Rules", commented out since order rules now are definition of new user, will reimplement when coupons with loyalty rules apply
    "Location Rules",
    "Brand Rules",
    "Order Rules",
  ];
  return (
    <>
      <Table headers={tableHeaders}>
        {sortBy(coupons, "inserted_at")
          .reverse()
          .map(function (coupon: any, index: number) {
            return (
              <TableRow
                key={coupon.id}
                rowColor={index % 2 ? "bg-white" : "bg-gray-50"}
                onClick={() => clickUpdateButton(coupon)}
              >
                <TableData>
                  <span
                    className={`inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium ${
                      coupon.is_active
                        ? "bg-green-100 text-green-800"
                        : "bg-red-100 text-red-800"
                    }`}
                  >
                    {`${coupon.is_active ? "Active" : "Inactive"}`}
                  </span>
                </TableData>
                <TableData>
                  <span>{coupon.name}</span>
                </TableData>
                <TableData>{coupon.code}</TableData>
                <TableData>
                  {!!coupon?.credits
                    ? usdFormatter.format(parseInt(coupon.credits) / 100)
                    : !!coupon.discount_rate
                    ? `${parseInt(coupon.discount_rate) / 100}%`
                    : "N/A"}
                </TableData>
                <TableData>
                  {locationsMap &&
                    (locationsMap[JSON.parse(coupon.location_attrs)?.slug] ||
                      "")}
                </TableData>
                <TableData>
                  {coupon?.brand_attrs
                    ? operationsBrandsMap &&
                      (JSON.parse(coupon.brand_attrs)
                        ?.brand_ids.map((id: number) => operationsBrandsMap[id])
                        .join(", ") ||
                        "")
                    : ""}
                </TableData>
                <TableData>
                  {JSON.parse(coupon?.order_history_attrs)?.count === 0
                    ? "New User"
                    : ""}
                </TableData>
              </TableRow>
            );
          })}
      </Table>
      <UpdateCouponModal
        showModal={showUpdateModal}
        setShowModal={setShowUpdateModal}
        couponToUpdate={couponToUpdate}
        onUpdateCoupon={onUpdateCoupon}
      />
    </>
  );
}
