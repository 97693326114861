import { gql, useMutation } from "@apollo/client";

const BULK_UPDATE_PAIRINGS = gql`
  mutation BulkUpdatePairings($menuItemId: Int!, $pairingIds: [Int]) {
    bulk_update_pairings(menuItemId: $menuItemId, pairingIds: $pairingIds)
  }
`;

export const useBulkUpdatePairings = (
  menuItemId?: number,
  pairingIds?: number[],
) => {
  const [bulkUpdatePairings, { loading, error }] = useMutation(
    BULK_UPDATE_PAIRINGS,
    {
      variables: {
        menuItemId,
        pairingIds,
      },
    },
  );
  return { bulkUpdatePairings, loading, error };
};
