import { gql, useQuery } from "@apollo/client";

export const OPERATIONS_SALES_BY_ID_QUERY = gql`
  query OPERATIONS_SALES_BY_ID_QUERY($id: Int) {
    sale_by_id(id: $id) {
      id
      location {
        id
        name
        slug
      }
      provider {
        id
        name
        slug
        revenue_center
      }
      brands {
        id
        name
        slug
      }
      customer_name
      customer_phone
      customer_id
      subtotal
      inserted_at
      is_pickup
      is_completed
      is_scheduled
      is_oversold
      is_resolved
      external_id
      external_delivery_id
      stripe_payment_intent_id
      total_order_count
      raw_cart_total
      raw_cart_subtotal
      raw_cart_tax
      raw_cart_service_fee
      raw_cart_service_fee_rate
      raw_orders_data
      status
      pickup_time
      ticket_counter
      line_items {
        id
        ordinal
        name
        customer_name
        price
        quantity
        special_instructions
        is_completed
        brand {
          name
        }
        modifier_items {
          id
          name
          quantity
          price
        }
      }
      store_order_issues {
        id
        guest_feedback_channel {
          id
          name
        }
        guest_feedback
        comment
        submitted_by
        resolution
        issue_categories {
          id
          name
        }
        store_order_line_item_issues {
          id
          store_order_line_item_id
          store_order_line_item_name
        }
      }
      catering_adjustment {
        adjusted_subtotal
      }
    }
  }
`;

type UseSaleByIdProps = {
  id: number;
};

export const useSaleById = ({ id }: UseSaleByIdProps) => {
  const { data, error, loading, refetch } = useQuery(
    OPERATIONS_SALES_BY_ID_QUERY,
    {
      variables: {
        id,
      },
    },
  );
  return { data, error, loading, refetch };
};
