import { faRocket, faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import DropdownButtons from "../../ui/components/DropdownButtons/DropdownButtons";

interface MenuEditorDropdownProps {
  handleSubmit: () => void;
  handleRefresh: () => void;
}

export const MenuEditorDropdown = React.memo(
  ({ handleSubmit, handleRefresh }: MenuEditorDropdownProps) => {
    return (
      <DropdownButtons
        mainText="Actions"
        items={[
          {
            children: (
              <div className="flex items-center">
                <div className="w-8">
                  <FontAwesomeIcon icon={faRocket} />
                </div>
                <p>Submit Changes</p>
              </div>
            ),
            onClick: () => handleSubmit(),
          },
          {
            children: (
              <div className="flex items-center">
                <div className="w-8">
                  <FontAwesomeIcon icon={faUndo} />
                </div>
                <p>Undo Changes</p>
              </div>
            ),
            onClick: () => handleRefresh(),
          },
        ]}
      />
    );
  },
);
