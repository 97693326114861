import { DatePicker } from "antd";
import moment from "moment-timezone";

import { Dispatch, SetStateAction, useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import {
  FormCheckbox,
  FormInput,
  FormLabel,
  FormOption,
  FormSelect,
} from "../../ui";
import { Button } from "../../ui/components/Button/Button";
import ToastContext from "../../utils/contexts/ToastContext";
import { usdFormatter } from "../Orders/usdFormatter";
import "./CouponForm.css";
import { useLocations } from "./hooks/useLocations";
import { useOperationsBrands } from "./hooks/useOperationsBrands";

type CouponFormProps = {
  onSubmit: any;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  coupon?: any;
};

export const CouponForm = ({
  onSubmit,
  setShowModal,
  coupon,
}: CouponFormProps) => {
  const {
    control,
    handleSubmit,
    watch,
    register,
    formState: { errors, isDirty },
  } = useForm();
  const defaultCouponType = coupon?.coupon_type
    ? coupon.coupon_type.toLowerCase()
    : "amount";
  const defaultCouponTrait = coupon?.order_history_attrs?.type || "EXACT_COUNT";
  const defaultUserType =
    coupon?.order_history_attrs && coupon?.order_history_attrs?.count === 0
      ? "NEW_USERS"
      : "ALL_USERS";
  const defaultMinimumCartItems = coupon?.minimum_cart_items ?? 0;

  const defaultUsersSubsetType = coupon?.users_subset ?? "REGULAR";

  const watchCouponType = watch("coupon_type", defaultCouponType);
  const watchCouponTrait = watch(
    "order_history_attrs.type",
    defaultCouponTrait,
  );
  const watchUserType = watch("user_type", defaultUserType);
  const watchMinimumCartItems = watch(
    "minimum_cart_items",
    defaultMinimumCartItems,
  );

  const { data: brandsData } = useOperationsBrands();
  const { data: locationsData } = useLocations();
  const brandsList: any = brandsData?.operations_brands || [];
  const locationsList: any = locationsData?.locations || [];
  const { showToast } = useContext(ToastContext);

  const onSubmitHandler = async (data: any) => {
    const transformedData = {
      coupon_id: coupon ? coupon.id : undefined,
      name: data.name,
      code: data.code.toUpperCase(),
      is_active: data.is_active === "true",
      location_attrs:
        data.locations === "" ? null : JSON.stringify({ slug: data.locations }),
      loyalty_account_attrs:
        data.loyalty_account_attrs === "" ? null : data.loyalty_account_attrs,
      order_history_attrs:
        data.user_types === "NEW_USERS"
          ? JSON.stringify({
              count: 0,
              days: null,
              type: "EXACT_COUNT",
            })
          : data.order_history_attrs.type === "EXACT_COUNT" &&
            data.user_type === "ALL_USERS"
          ? null
          : JSON.stringify({
              count: parseInt(data.order_history_attrs.count),
              days: data.order_history_attrs.days
                ? parseInt(data.order_history_attrs.days)
                : null,
              type: data.order_history_attrs.type,
            }),
      brand_attrs:
        data.brands === ""
          ? null
          : JSON.stringify({ brand_ids: [parseInt(data.brands)] }),
      display_name: data.display_name,
      credits:
        data.coupon_type === "amount"
          ? parseInt(data.credits.replace(".", ""))
          : null,
      minimum_subtotal: !!data.minimum_subtotal
        ? parseInt(data.minimum_subtotal.replace(".", ""))
        : null,
      discount_rate:
        !!data.discount_rate && data.coupon_type === "percentage"
          ? parseInt(data.discount_rate.replace(".", ""))
          : null,
      max_amount:
        !!data.max_amount && data.coupon_type === "percentage"
          ? parseInt(data.max_amount.replace(".", ""))
          : null,
      coupon_type: data.coupon_type.toUpperCase(),
      free_item_minimum_subtotal: !!data.free_item_minimum_subtotal
        ? parseInt(data.free_item_minimum_subtotal.replace(".", ""))
        : null,
      free_item_ranks: !!data.free_item_ranks?.length
        ? data.free_item_ranks.map(
            (rank: { label: string; value: number }) => rank.value,
          )
        : null,
      minimum_cart_items: !!data.minimum_cart_items
        ? parseInt(data.minimum_cart_items)
        : null,
      expiration_date: data.expiration_date
        ? moment(data.expiration_date).tz("US/Pacific").isDST()
          ? moment.utc(data.expiration_date).startOf("day").add(7, "h")
          : moment.utc(data.expiration_date).startOf("day").add(8, "h")
        : null,
      is_fundraiser: data.is_fundraiser,
      users_subset: data.users_subset,
    };

    try {
      await onSubmit(transformedData);
      showToast({
        description: "Coupon was created",
        variant: "success",
        seconds: 2,
        onClose: () => {},
      });
      setShowModal(false);
    } catch (err: any) {
      showToast({
        description: err?.message || "Coupon could not be saved",
        variant: "error",
        seconds: 3,
        onClose: () => {},
      });
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <div className="grid grid-cols-3 gap-6 my-5">
        <div className="col-span-2">
          <FormLabel
            title="Name"
            htmlFor="name"
            information="Internal name of the coupon that the Local Kitchens team will see."
          />
          <Controller
            name="name"
            control={control}
            defaultValue={coupon ? coupon.name : ""}
            rules={{
              required: { value: true, message: "This field is required" },
            }}
            render={({ field }) => (
              <FormInput type="text" id="name" {...field} />
            )}
          />
          <p className="text-red-800">{errors.name?.message?.toString()}</p>
        </div>
        <div>
          <FormLabel
            title="Code"
            htmlFor="code"
            information="Code that the guest will use (e.g. COUPON10)."
          />
          <Controller
            name="code"
            control={control}
            defaultValue={coupon ? coupon.code : ""}
            rules={{
              required: { value: true, message: "This field is required" },
            }}
            render={({ field }) => (
              <FormInput type="text" id="code" {...field} />
            )}
          />
          <p className="text-red-800">{errors.code?.message?.toString()}</p>
        </div>
        <div className="col-span-3">
          <FormLabel
            title="Display Name"
            htmlFor="display_name"
            information="Longer name that the guest will see (e.g. $5 off for trying a new brand)."
          />
          <Controller
            name="display_name"
            control={control}
            defaultValue={coupon ? coupon?.display_name : ""}
            rules={{ required: false }}
            render={({ field }) => (
              <FormInput type="text" id="display_name" {...field} />
            )}
          />
        </div>
        <div>
          <FormLabel
            title="Locations"
            htmlFor="locations"
            information="Locations that this coupon will apply towards."
          />
          <Controller
            name="locations"
            control={control}
            rules={{ required: false }}
            defaultValue={
              !!coupon?.location_attrs?.slug ? coupon?.location_attrs?.slug : ""
            }
            render={({ field }) => {
              return (
                <FormSelect {...field}>
                  <FormOption title="All Locations" value="" />
                  {locationsList?.map((location: any, index: number) => {
                    return (
                      <FormOption
                        key={index}
                        title={location.name}
                        value={location.slug}
                      />
                    );
                  })}
                </FormSelect>
              );
            }}
          />
        </div>
        <div>
          <FormLabel
            title="User Type"
            htmlFor="user_type"
            disabled={watchCouponTrait === "NO_ORDER_SINCE"}
            information='By selecting "New User", you are applying this coupon to those who have no credits or no lifetime points.'
          />
          <Controller
            name="user_type"
            disabled={watchCouponTrait === "NO_ORDER_SINCE"}
            control={control}
            rules={{ required: false }}
            defaultValue={defaultUserType}
            render={({ field }) => {
              return (
                <FormSelect {...field}>
                  <FormOption title="All Users" value="ALL_USERS" />
                  <FormOption title="New Users" value="NEW_USERS" />
                </FormSelect>
              );
            }}
          />
        </div>
        <div>
          <FormLabel
            title="Brands"
            htmlFor="brands"
            information="Brands that this coupon will apply towards."
          />
          <Controller
            name="brands"
            control={control}
            defaultValue={
              !!coupon?.brand_attrs ? coupon?.brand_attrs?.brand_ids[0] : ""
            }
            render={({ field }) => {
              return (
                <FormSelect {...field}>
                  <FormOption title="All Brands" value="" />
                  {brandsList?.map((brand: any, index: number) => {
                    return (
                      <FormOption
                        key={index}
                        title={brand.name}
                        value={brand.id}
                      />
                    );
                  })}
                </FormSelect>
              );
            }}
          />
        </div>
        <div>
          <FormLabel
            title="Coupon Type"
            htmlFor="coupon_type"
            information='If you choose "Dollars ($)", then this coupon will take an exact number of dollars off. If you choose "Percent (%)", then this will be applied as a percent of the subtotal. If you choose "Free Item", then this will be applied as a free item.'
          />
          <Controller
            name="coupon_type"
            control={control}
            rules={{
              required: { value: true, message: "This field is required" },
            }}
            defaultValue={defaultCouponType}
            render={({ field }) => {
              return (
                <FormSelect {...field}>
                  <FormOption title="Dollars ($)" value="amount" />
                  <FormOption title="Percent (%)" value="percentage" />
                  <FormOption title="Free item" value="free_item" />
                </FormSelect>
              );
            }}
          />
        </div>
        {
          // If there is a coupon, and there are credits, and the form is not dirty yet, then show dollars.
          // Or, if the watchCouponType === "amount", which means the form has been selected to be dollars
          // Or if there is no coupon and the watchCouponType is not defined (initial load on create)
          (!!coupon && coupon?.credits && !isDirty) ||
          watchCouponType === "amount" ||
          (!coupon && watchCouponType === undefined) ? (
            <>
              <div>
                <FormLabel
                  title="Credits"
                  htmlFor="credits"
                  information="How many dollars this coupon will take off."
                />
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm">$</span>
                  </div>
                  <input
                    type="text"
                    id="credits"
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                    placeholder="0.00"
                    defaultValue={
                      coupon && coupon?.credits
                        ? usdFormatter.format(coupon.credits / 100).slice(1)
                        : ""
                    }
                    aria-describedby="price-currency"
                    {...register("credits", {
                      required: {
                        value: watchCouponType === "amount",
                        message: "This field is required",
                      },
                      pattern: {
                        value: /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{2}$/,
                        message:
                          'Invalid format: Credits must be in format "5.00"',
                      },
                    })}
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span
                      className="text-gray-500 sm:text-sm"
                      id="price-currency"
                    >
                      USD
                    </span>
                  </div>
                </div>
                <p className="text-red-800">
                  {errors.credits?.message?.toString()}
                </p>
              </div>
              <div>{/* Empty Div for grid */}</div>
            </>
          ) : watchCouponType === "percentage" ? (
            <>
              <div>
                <FormLabel
                  title="Discount Rate"
                  htmlFor="discount_rate"
                  information="Percentage to take off for this coupon."
                />
                <div className="relative">
                  <input
                    type="text"
                    id="discount_rate"
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                    placeholder="0.00"
                    aria-describedby="discount_rate"
                    defaultValue={
                      coupon && coupon?.discount_rate
                        ? usdFormatter
                            .format(coupon.discount_rate / 100)
                            .slice(1)
                        : ""
                    }
                    {...register("discount_rate", {
                      required: watchCouponType === "percentage",
                      pattern: /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{2}$/,
                    })}
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span
                      className="text-gray-500 sm:text-sm"
                      id="discount_rate"
                    >
                      %
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <FormLabel
                  title="Max Amount"
                  htmlFor="max_amount"
                  information="If this coupon is a percentage off coupon, then this will determine the maximum dollar amount that this coupon can take off."
                />
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm">$</span>
                  </div>
                  <input
                    type="text"
                    id="max_amount"
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                    placeholder="0.00"
                    defaultValue={
                      coupon && coupon?.max_amount
                        ? usdFormatter.format(coupon.max_amount / 100).slice(1)
                        : ""
                    }
                    aria-describedby="price-currency"
                    {...register("max_amount", {
                      required: {
                        value: watchCouponType === "percentage",
                        message: "This field is required",
                      },
                      pattern: {
                        value: /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{2}$/,
                        message:
                          'Invalid format: Max Amount must be in format "5.00"',
                      },
                    })}
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span
                      className="text-gray-500 sm:text-sm"
                      id="price-currency"
                    >
                      USD
                    </span>
                  </div>
                </div>
                <p className="text-red-800">
                  {errors.max_amount?.message?.toString()}
                </p>
              </div>
            </>
          ) : (
            <>
              <div>
                <FormLabel
                  title="Free Item Min. Subtotal"
                  htmlFor="free_item_minimum_subtotal"
                  information="Free item minimum subtotal."
                />
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm">$</span>
                  </div>
                  <input
                    type="text"
                    id="free_item_minimum_subtotal"
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                    placeholder="0.00"
                    defaultValue={
                      coupon
                        ? usdFormatter
                            .format(coupon.free_item_minimum_subtotal / 100)
                            .slice(1)
                        : "5.00"
                    }
                    aria-describedby="price-currency"
                    {...register("free_item_minimum_subtotal", {
                      pattern: {
                        value: /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{2}$/,
                        message:
                          'Invalid format: Free item min. subtotal must be in format "5.00"',
                      },
                    })}
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span
                      className="text-gray-500 sm:text-sm"
                      id="free_item_minimum_subtotal"
                    >
                      USD
                    </span>
                  </div>
                </div>
                <p className="text-red-800">
                  {errors.free_item_minimum_subtotal?.message?.toString()}
                </p>
              </div>
              <div>{/* Empty Div for grid */}</div>
              <div>
                <FormLabel
                  title="Minimum Cart Items"
                  htmlFor="minimum_cart_items"
                  information="The number of items needed in the cart for this coupon to be valid."
                />
                <div className="relative">
                  <input
                    type="text"
                    id="minimum_cart_items"
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                    placeholder="1"
                    defaultValue={defaultMinimumCartItems}
                    aria-describedby="minimum_cart_items"
                    {...register("minimum_cart_items", {
                      required: {
                        value: watchCouponType === "free_item",
                        message: "This field is required",
                      },
                      pattern: {
                        value: /^[0-9]\d*$/,
                        message: "Invalid format",
                      },
                    })}
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span
                      className="text-gray-500 sm:text-sm"
                      id="minimum_cart_items-unit"
                    >
                      Items
                    </span>
                  </div>
                </div>
                <p className="text-red-800">
                  {errors.minimum_cart_items?.message?.toString()}
                </p>
              </div>
              <div>
                <FormLabel
                  title="Free item(s) ranks"
                  htmlFor="free_item_ranks"
                  information="The ranks of the free items (i.e. 2 means the item with second highest price will be free). Must not be greater than the minimum cart items, if empty, fill in minimum cart items first."
                />
                <Controller
                  name="free_item_ranks"
                  control={control}
                  defaultValue={
                    coupon?.free_item_ranks?.map((rank: number) => ({
                      value: rank,
                      label: rank.toString(),
                    })) ?? []
                  }
                  rules={{
                    required: {
                      value: watchCouponType === "free_item",
                      message: "This field is required",
                    },
                    validate: {
                      value: (ranks: { value: number; label: string }[]) => {
                        return ranks.some(
                          (r) => r.value > watchMinimumCartItems,
                        )
                          ? "Free item ranks must be less than or equal to the minimum cart items"
                          : undefined;
                      },
                    },
                  }}
                  render={({ field }) => {
                    const options = Array.from(
                      {
                        length: watchMinimumCartItems,
                      },
                      (_, i) => i + 1,
                    ).map((i) => ({ value: i, label: i.toString() }));
                    return (
                      <Select
                        {...field}
                        options={options}
                        isMulti
                        closeMenuOnSelect={false}
                      />
                    );
                  }}
                />
                <p className="text-red-800">
                  {errors.free_item_ranks?.message?.toString()}
                </p>
              </div>
              <div>{/* Empty Div for grid */}</div>
            </>
          )
        }
        <div>
          <FormLabel
            title="Status"
            htmlFor="is_active"
            information="Whether this coupon is active or not."
          />
          <Controller
            name="is_active"
            control={control}
            defaultValue={coupon && coupon?.is_active ? "true" : "false"}
            render={({ field }) => {
              return (
                <FormSelect {...field}>
                  <FormOption title="Active" value="true" />
                  <FormOption title="Inactive" value="false" />
                </FormSelect>
              );
            }}
          />
        </div>
        <div>
          <FormLabel
            title="Minimum Subtotal"
            htmlFor="minimum_subtotal"
            information="Minimum subtotal will be the minimum subtotal of the order for this coupon to be applied."
          />
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span className="text-gray-500 sm:text-sm">$</span>
            </div>
            <input
              type="text"
              id="minimum_subtotal"
              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
              placeholder="0.00"
              defaultValue={
                coupon
                  ? usdFormatter.format(coupon.minimum_subtotal / 100).slice(1)
                  : "20.00"
              }
              aria-describedby="price-currency"
              {...register("minimum_subtotal", {
                pattern: {
                  value: /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{2}$/,
                  message:
                    'Invalid format: Minimum Subtotal must be in format "5.00"',
                },
              })}
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span className="text-gray-500 sm:text-sm" id="price-currency">
                USD
              </span>
            </div>
          </div>
          <p className="text-red-800">
            {errors.minimum_subtotal?.message?.toString()}
          </p>
        </div>
        <div className="grid grid-cols-3 col-span-3 gap-6">
          <div>
            <FormLabel
              title="Coupon Trait"
              htmlFor="coupon_trait"
              information={
                <div>
                  What the coupon behavior would be.
                  <br />
                  Exact Count: from what order should this coupon start from?
                  <br />
                  Expire After Days: how many days from the exact count should
                  this coupon last?
                </div>
              }
            />
            <Controller
              name="order_history_attrs.type"
              control={control}
              defaultValue={defaultCouponTrait}
              rules={{
                validate: (v) => {
                  switch (v) {
                    case "EXPIRES_AFTER_DAYS":
                      if (watchUserType === "NEW_USERS")
                        return 'New users are not compatible with coupon types of "Expires After Days"';
                      return true;
                    case "NO_ORDER_SINCE":
                      if (watchUserType === "NEW_USERS")
                        return 'New users are not compatible with coupon types of "Expires After Days"';
                      return true;
                    default:
                      return true;
                  }
                },
              }}
              render={({ field }) => {
                return (
                  <FormSelect {...field}>
                    <FormOption title="Exact Count" value="EXACT_COUNT" />
                    <FormOption
                      title="Expires After Days"
                      value="EXPIRES_AFTER_DAYS"
                    />

                    <FormOption title="No Order Since" value="NO_ORDER_SINCE" />
                  </FormSelect>
                );
              }}
            />
            <p className="text-red-800">
              {errors.order_history_attrs?.message?.toString()}
            </p>
          </div>
          {watchCouponTrait === "NO_ORDER_SINCE" && (
            <div>
              <FormLabel
                title="No order since"
                htmlFor="no_order_since"
                information="No orders for X days"
                disabled={watchUserType === "NEW_USERS"}
              />
              <div className="relative">
                <FormInput
                  id="order_history_attrs.count"
                  type="text"
                  disabled={watchUserType === "NEW_USERS"}
                  defaultValue={
                    !!coupon?.order_history_attrs
                      ? coupon?.order_history_attrs?.count
                      : 0
                  }
                  placeholder="0"
                  {...register("order_history_attrs.count", {
                    pattern: {
                      value: /^[0-9]\d*$/,
                      message: "Invalid format: Days should be a number",
                    },
                  })}
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span
                    className="text-gray-500 sm:text-sm"
                    id="orders-count-unit"
                  >
                    Days
                  </span>
                </div>
              </div>
              <p className="text-red-800">
                {errors.order_history_attrs?.message?.toString()}
              </p>
            </div>
          )}
          {watchCouponTrait !== "NO_ORDER_SINCE" && (
            <div>
              <FormLabel
                title="Order History Count"
                htmlFor="order_count"
                information="The number of orders needed in order history for this coupon to be valid"
              />
              <div className="relative">
                <input
                  type="text"
                  id="order-count"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                  placeholder="0"
                  defaultValue={
                    !!coupon?.order_history_attrs
                      ? coupon?.order_history_attrs?.count
                      : 0
                  }
                  aria-describedby="order-count"
                  {...register("order_history_attrs.count", {
                    pattern: {
                      value: /^[0-9]\d*$/,
                      message: "Invalid format: Orders should be a number",
                    },
                    validate: (v) => {
                      return watchUserType === "ALL_USERS" || parseInt(v) === 0
                        ? undefined
                        : "New users cannot have an order history greater than 0";
                    },
                  })}
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span
                    className="text-gray-500 sm:text-sm"
                    id="orders-count-unit"
                  >
                    Orders
                  </span>
                </div>
              </div>

              <p className="text-red-800">
                {errors.order_history_attrs?.message?.toString()}
              </p>
            </div>
          )}
          {watchCouponTrait === "EXPIRES_AFTER_DAYS" && (
            <div>
              <FormLabel
                title="Coupon Length"
                htmlFor="coupon_length"
                information="How long in days does this coupon last for after whatever order count that has been designated. If none given, defaults to their first order. Coupon length will not be accounted for if the coupon trait is expires after days."
              />
              <div className="relative">
                <input
                  type="text"
                  id="coupon-length"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                  placeholder="0"
                  defaultValue={coupon ? coupon?.order_history_attrs?.days : 0}
                  aria-describedby="coupon-length"
                  {...register("order_history_attrs.days", {
                    pattern: {
                      value: /^[0-9]\d*$/,
                      message: "Invalid format: Days should be a number",
                    },
                  })}
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span
                    className="text-gray-500 sm:text-sm"
                    id="orders-count-unit"
                  >
                    Days
                  </span>
                </div>
              </div>
              <p className="text-red-800">
                {errors.order_history_attrs?.message?.toString()}
              </p>
            </div>
          )}
        </div>
        <div>
          <FormLabel
            title="End by date"
            htmlFor="expiration_date"
            information="The coupon status will be set to inactive on this date."
          />
          <Controller
            name="expiration_date"
            control={control}
            defaultValue={
              coupon?.expiration_date
                ? moment(parseInt(coupon?.expiration_date))
                : undefined
            }
            render={(props) => {
              return (
                <DatePicker
                  {...props.field}
                  getPopupContainer={(triggerNode: any) =>
                    triggerNode?.parentNode
                  }
                  disabledDate={(date: moment.Moment) =>
                    date &&
                    date.clone().startOf("day") <= moment().startOf("day")
                  }
                  format="MM/DD/YYYY"
                  onChange={props.field.onChange}
                  className="flex-1 p-2 rounded-md block w-full sm:text-sm border-gray-300 text-black"
                />
              );
            }}
          />
        </div>
        <div>
          <FormLabel
            title="Is Fundraiser"
            htmlFor="is_fundraiser"
            information="Toggled on, if this coupon is for a fundraising event"
          />
          <Controller
            name="is_fundraiser"
            control={control}
            defaultValue={coupon?.is_fundraiser ?? false}
            render={({ field }) => (
              <FormCheckbox
                type="checkbox"
                id="is_fundraiser"
                checked={field.value}
                {...field}
              />
            )}
          />
        </div>
        <div>
          <FormLabel
            title="Users subset"
            htmlFor="users_subset"
            information='By selecting "Users subset", you are allow to use this coupon for a specific group of users.'
          />
          <Controller
            name="users_subset"
            control={control}
            rules={{ required: false }}
            defaultValue={defaultUsersSubsetType}
            render={({ field }) => {
              return (
                <FormSelect {...field}>
                  <FormOption title="All Users" value="REGULAR" />
                  <FormOption title="New kiosk user" value="NEW_KIOSK_USER" />
                </FormSelect>
              );
            }}
          />
        </div>
      </div>
      <Button className="mr-3">Save</Button>
      <Button
        type="button"
        backgroundColor="bg-gray-500"
        onClick={() => setShowModal(false)}
      >
        Cancel
      </Button>
    </form>
  );
};
