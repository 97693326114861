export const slugify = (name?: string): string => {
  if (!name) return "";
  return name
    .toLowerCase()
    .split(" ")
    .join("")
    .replaceAll("/", "")
    .replaceAll("'", "")
    .replaceAll(/\./gi, "");
};

export const displayAsPhoneNumber = (number?: string) => {
  if (!number) {
    return "";
  }
  return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6, 10)}`;
};

export const getExtension = (url?: string | null) => url?.split(".").pop();

export const getResponsiveImageUrl = (imageUrl?: string, res?: string) => {
  if (!imageUrl || !res) {
    return "";
  }

  const extension = getExtension(imageUrl) || "jpg";
  return imageUrl.replace(`.${extension}`, `-${res}.${extension}`);
};
