import { gql, useQuery } from "@apollo/client";

export const LIST_EMPLOYEES_QUERY = gql`
  query LIST_EMPLOYEES_QUERY($input: EmployeesInput) {
    employees(input: $input) {
      total_employees_count
      page_number
      limit
      employees {
        id
        first_name
        last_name
        email
        pin_code
      }
    }
  }
`;

type useListEmployeesProps = {
  pageNumber: number;
  limit: number;
  firstName: string;
  lastName: string;
  email: string;
  pinCode: string;
};
export const useListEmployees = ({
  pageNumber,
  limit,
  firstName,
  lastName,
  email,
  pinCode,
}: useListEmployeesProps) => {
  const { data, error, loading, refetch } = useQuery(LIST_EMPLOYEES_QUERY, {
    variables: {
      input: {
        page_number: pageNumber,
        limit,
        first_name: firstName,
        last_name: lastName,
        email: email,
        pin_code: pinCode,
      },
    },
  });

  return { data, error, loading, refetch };
};
