import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useQueryClient } from "@tanstack/react-query";
import {
  LIST_MODIFIERS_LISTS,
  ModifiersListSchema,
  useDeleteModifiersListQuery,
} from "opsapi/hooks/operations/modifiersLists";
import { Fragment, useContext, useState } from "react";
import { Button } from "../../../../ui";
import ToastContext from "../../../../utils/contexts/ToastContext";

interface DeleteModifiersListDialogProps {
  showModal: boolean;
  hideModal: () => void;
  modifiersList: ModifiersListSchema;
}

export const DeleteModifiersListDialog = ({
  showModal,
  hideModal,
  modifiersList,
}: DeleteModifiersListDialogProps) => {
  const queryClient = useQueryClient();
  const [deleteConfirmation, setDeleteConfirmation] = useState("");
  const { showToast } = useContext(ToastContext);

  const modifiersListParams = {
    params: { path: { slug: modifiersList.slug } },
  };
  const { mutate: deleteModifiersList, isPending: isDeletePending } =
    useDeleteModifiersListQuery(modifiersListParams);

  const onSubmitDeleteHandler = async () => {
    deleteModifiersList(
      {},
      {
        onSuccess: () => {
          showToast({
            description: `Submitted successfully`,
            variant: "success",
            seconds: 2,
          });
          queryClient.invalidateQueries({
            queryKey: [LIST_MODIFIERS_LISTS],
          });
          hideModal();
        },
        onError: (error) => {
          showToast({
            description: error.message,
            variant: "error",
            seconds: 10,
          });
        },
      },
    );
  };

  const deletionConfirmed = deleteConfirmation === modifiersList.slug;

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={showModal}
        onClose={() => hideModal()}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
              <div>
                <div className="flex w-full flex-row justify-between">
                  <Dialog.Title
                    as="h3"
                    className="flex items-start justify-between text-2xl leading-6 font-medium text-gray-900"
                  >
                    Delete ModifiersList
                  </Dialog.Title>

                  <XMarkIcon
                    onClick={() => hideModal()}
                    className="cursor-pointer h-8 w-8 -mt-2 -mr-2"
                    aria-hidden="true"
                  />
                </div>
              </div>
              <div className="flex flex-col my-4 space-y-4">
                <div>
                  Please enter the modifiersList slug `{modifiersList.slug}` and
                  click "Delete" to confirm action.
                </div>
                <input
                  type="slug"
                  name="delete-slug"
                  autoComplete="off"
                  placeholder="Enter the storefront slug to delete..."
                  className="bg-transparent rounded-sm focus:outline-none focus:ring-lfg-primary-lighten focus:ring-2"
                  onChange={(e) => setDeleteConfirmation(e.target.value)}
                  value={deleteConfirmation}
                />
                <div className="flex items-center w-64 mt-8">
                  <Button
                    loading={isDeletePending}
                    disabled={!deletionConfirmed}
                    onClick={() => onSubmitDeleteHandler()}
                    className={`flex justify-center items-center flex-row rounded-md px-8 py-4 text-white bg-red-500 ${
                      deletionConfirmed
                        ? "hover:opacity-80 cursor-pointer"
                        : "opacity-60 cursor-not-allowed"
                    }`}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
