import { gql, useQuery } from "@apollo/client";

const COUPONS_QUERY = gql`
  query CouponsQuery($input: CouponsInput) {
    coupons(input: $input) {
      total_coupon_count
      page_number
      limit
      coupons {
        id
        name
        code
        discount_rate
        display_name
        max_amount
        credits
        brand_attrs
        is_active
        loyalty_account_attrs
        order_history_attrs
        brand_attrs
        location_attrs
        inserted_at
        minimum_subtotal
        coupon_type
        free_item_ranks
        minimum_cart_items
        free_item_minimum_subtotal
        expiration_date
        is_fundraiser
        users_subset
      }
    }
  }
`;

type UseCouponsProps = {
  pageNumber: number;
  limit: number;
  status: string;
  name: string;
  code: string;
};

export const useCoupons = ({
  pageNumber,
  limit,
  status,
  name,
  code,
}: UseCouponsProps) => {
  const { data, error, loading, refetch } = useQuery(COUPONS_QUERY, {
    variables: {
      input: {
        page_number: pageNumber,
        limit,
        status: status,
        name: name,
        code: code,
      },
    },
  });

  return { data, error, loading, refetch };
};
