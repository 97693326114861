import { useQuery, gql } from "@apollo/client";

export const OPERATIONS_PROVIDERS_QUERY = gql`
  query OPERATIONS_PROVIDERS_QUERY {
    providers {
      id
      name
      slug
      revenue_center
    }
  }
`;

export const useProviders = () => {
  const { data, error, loading, refetch } = useQuery(
    OPERATIONS_PROVIDERS_QUERY,
    {
      fetchPolicy: "cache-and-network",
    },
  );
  return { data, error, loading, refetch };
};
