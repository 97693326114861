import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import ReactMarkdown from "react-markdown";
import { Button, Table, TableData, TableRow } from "../../../ui";
import { ConfirmationModal } from "../../../ui/ConfirmationModal";
import ToastContext from "../../../utils/contexts/ToastContext";
import { useBulkUpdateMarketingBillboards } from "../hooks/useBulkUpdateMarketingBillboard";
import useLocations from "../hooks/useLocations";
import useMarketingBillboards from "../hooks/useMarketingBillboards";
import {
  Brand,
  Location,
  MarketingBillboard,
  UpdateMarketingBillboardInput,
} from "../types";
import UpdateMarketingBillboardModal from "./UpdateMarketingBillboardModal";

type MarketingBillboardTableSectionProps = {
  onUpdateBillboard: (data: UpdateMarketingBillboardInput) => Promise<void>;
  onDeleteBillboard: (data: { id: number }) => Promise<void>;
  brands: Brand[];
  locations: Location[];
};

const reorderBillboards = (
  list: MarketingBillboard[],
  from: number,
  to: number,
) => {
  const result = Array.from(list);
  const [removed] = result.splice(from, 1);
  result.splice(to, 0, removed);

  return result;
};

export default function MarketingBillboardTableSection({
  onUpdateBillboard,
  onDeleteBillboard,
  brands,
  locations,
}: MarketingBillboardTableSectionProps) {
  const [billboards, setBillboards] = useState<MarketingBillboard[]>([]);
  const { showToast } = useContext(ToastContext);
  const [bulkUpdateMarketingBillboards] = useBulkUpdateMarketingBillboards();
  const { data } = useMarketingBillboards();
  const { data: locationsData } = useLocations();

  const [billboardToDelete, setBillboardToDelete] =
    useState<MarketingBillboard>();
  const [billboardToUpdate, setBillBoardToUpdate] =
    useState<MarketingBillboard>();

  useEffect(() => {
    if (data?.operations_marketing_billboards) {
      setBillboards(data.operations_marketing_billboards);
    }
  }, [data]);

  const clickUpdateButton = (billboard: MarketingBillboard) => {
    setBillBoardToUpdate({
      ...billboard,
    });
  };

  const handleDeleteClick = async (billboard: MarketingBillboard) => {
    setBillboardToDelete(billboard);
  };

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) return;

    const changedBillboards: Pick<MarketingBillboard, "id" | "ordinal">[] = [];
    const callbacks: Function[] = [];

    const reorderedBillboards = reorderBillboards(
      billboards,
      result.source.index,
      result.destination.index,
    ).map((billboard, index) => {
      if (billboard.ordinal !== index) {
        callbacks.push(() =>
          analytics.track("Feature Tile Ordinal Updated", {
            id: billboard.id,
            previous_ordinal: billboard.ordinal,
            new_ordinal: index,
          }),
        );
        changedBillboards.push({ id: billboard.id, ordinal: index });
      }

      return { ...billboard, ordinal: index };
    });

    bulkUpdateMarketingBillboards({
      variables: { marketingBillboards: changedBillboards },
    })
      .then(() => {
        callbacks.forEach((callback) => callback());
      })
      .catch(() => {
        showToast({
          description: "Feature Tile order has not been updated",
          variant: "error",
        });
      });

    setBillboards(reorderedBillboards);
  };

  const tableHeaders = [
    "Status",
    "Title",
    "Subtitle",
    "Description",
    "Locations",
    "Platforms",
    "Action",
  ];

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="billboards-droppable">
          {(droppable) => (
            <Table
              headers={tableHeaders}
              ref={droppable.innerRef}
              {...droppable.droppableProps}
            >
              {billboards.map(
                (billboard: MarketingBillboard, index: number) => {
                  const billboardActive =
                    billboard.start_time &&
                    billboard.end_time &&
                    moment().isBetween(
                      moment(parseInt(billboard.start_time, 10)),
                      moment(parseInt(billboard.end_time, 10)),
                    );
                  return (
                    <Draggable
                      key={`tile-table-row-${index}`}
                      index={index}
                      draggableId={`draggable-billboard-${index}}`}
                    >
                      {(draggable) => (
                        <TableRow
                          ref={draggable.innerRef}
                          key={index}
                          {...draggable.draggableProps}
                          {...draggable.dragHandleProps}
                          rowColor={index % 2 ? "bg-white" : "bg-gray-50"}
                          onClick={(e) => {
                            e.stopPropagation();
                            clickUpdateButton(billboard);
                          }}
                        >
                          <TableData>
                            <span
                              className={`inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium ${
                                billboardActive
                                  ? "bg-green-100 text-green-800"
                                  : "bg-red-100 text-red-800"
                              }`}
                            >
                              {`${billboardActive ? "Active" : "Inactive"}`}
                            </span>
                          </TableData>
                          <TableData>
                            <p className="w-28 truncate text-ellipsis">
                              {billboard.title}
                            </p>
                          </TableData>
                          <TableData>
                            <ReactMarkdown
                              className="hidden w-28 truncate text-ellipsis react-markdown"
                              linkTarget="_blank"
                            >
                              {billboard.subtitle ?? ""}
                            </ReactMarkdown>
                          </TableData>
                          <TableData>
                            <ReactMarkdown
                              className="hidden w-28 truncate text-ellipsis react-markdown"
                              linkTarget="_blank"
                            >
                              {billboard.description}
                            </ReactMarkdown>
                          </TableData>
                          <TableData className="max-w-xl text-wrap">
                            {billboard.locations?.length ===
                            locationsData?.locations?.length
                              ? "All Locations"
                              : billboard.locations
                                  ?.map((location) => location.name)
                                  .join(", ") || "No Locations"}
                          </TableData>
                          <TableData>
                            {billboard.platforms?.join(", ") || "No Platforms"}
                          </TableData>
                          <TableData>
                            <Button
                              className="flex items-center"
                              type="button"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleDeleteClick(billboard);
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faTrash}
                                className="mr-2"
                              />
                              Remove
                            </Button>
                          </TableData>
                        </TableRow>
                      )}
                    </Draggable>
                  );
                },
              )}
            </Table>
          )}
        </Droppable>
      </DragDropContext>
      <UpdateMarketingBillboardModal
        showModal={!!billboardToUpdate}
        setShowModal={() => setBillBoardToUpdate(undefined)}
        billboardToUpdate={billboardToUpdate}
        onUpdateBillboard={onUpdateBillboard}
        brands={brands}
        locations={locations}
      />
      <ConfirmationModal
        header="Confirm delete"
        message="Are you sure you want to delete this feature tile?"
        show={!!billboardToDelete}
        confirmMessage="Delete"
        onConfirm={() => {
          if (billboardToDelete) {
            onDeleteBillboard(billboardToDelete);
          }
          setBillboardToDelete(undefined);
        }}
        hasConfirm
        hasCancel
        onCancel={() => {
          setBillboardToDelete(undefined);
        }}
      />
    </>
  );
}
