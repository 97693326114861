export type ImageInfo = {
  url: string;
  blurhash?: string;
};

export type Brand = {
  id: number;
  name: string;
  slug: string;
};

export type Location = {
  id: number;
  slug: string;
  name: string;
};

export type MultiSelectOptionType = {
  value: any;
  label: string;
};

export const VisibilityMap = {
  logged_in: "Logged In",
  anonymous: "Anonymous",
};

export const PagesLabelsMap: Record<string, string> = {
  brand_page: "Brand Page",
  custom: "Custom URL",
};

export type VisibilityMapTypes = typeof VisibilityMap;

export type MarketingBillboardRedirectInput = {
  page: string;
  attributes: Record<string, string>;
  button_label?: string;
};

export type Platform = "guest_web" | "guest_mobile";

export type CreateMarketingBillboardInput = {
  title: string;
  subtitle?: string;
  description: string;
  image: string;
  portrait_image?: string;
  background_color: string;
  start_time?: string;
  end_time?: string;
  platforms?: Platform[];
  visibility?: (keyof VisibilityMapTypes)[];
  location_ids: number[];
  redirect: MarketingBillboardRedirectInput;
};

export type UpdateMarketingBillboardInput = {
  billboard_id: number;
  title: string;
  subtitle?: string;
  description: string;
  image?: string;
  image_url?: string;
  image_blurhash?: string;
  portrait_image?: string;
  portrait_image_url?: string;
  portrait_image_blurhash?: string;
  background_color: string;
  start_time?: string;
  end_time?: string;
  platforms?: Platform[];
  visibility?: (keyof VisibilityMapTypes)[];
  location_ids: number[];
  redirect?: MarketingBillboardRedirectInput;
};

export type MarketingBillboardRedirect =
  | {
      page: "brand_page";
      brand_slug: string;
      button_label?: string;
    }
  | { page: "custom"; url: string; button_label?: string };

export type MarketingBillboard = {
  id: number;
  title: string;
  subtitle?: string;
  description: string;
  image?: ImageInfo;
  portrait_image?: ImageInfo;
  background_color: string;
  start_time?: string;
  end_time?: string;
  platforms?: Platform[];
  visibility?: (keyof VisibilityMapTypes)[];
  locations: Location[];
  redirect?: MarketingBillboardRedirect;
  ordinal?: number;
};

export type MarketingBillboardBulkUpdateInput = {
  id: number;
  ordinal: number;
};
