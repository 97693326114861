import React, {
  ReactElement,
  Dispatch,
  SetStateAction,
  useContext,
} from "react";
import { Button, FormInput, FormLabel, Modal } from "../../ui";
import useActivateStore from "./hooks/useActivateStore";
import ToastContext from "../../utils/contexts/ToastContext";

interface Props {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  store?: any;
}

export default function ActivateStoreModal({
  showModal,
  setShowModal,
  store,
}: Props): ReactElement {
  const { activateStore } = useActivateStore();
  const { showToast } = useContext(ToastContext);

  const activateStoreHandler = async () => {
    try {
      const result = await activateStore({
        variables: {
          store_id: store.id,
        },
      });
      showToast({
        description: "Store was successfully activated.",
        variant: "success",
        seconds: 2,
        onClose: () => {},
      });
    } catch (err) {
      showToast({
        description: "Store could not be activated.",
        variant: "error",
        seconds: 2,
        onClose: () => {},
      });
    }
    setShowModal(false);
  };
  return (
    <Modal
      title="Activate Store"
      showModal={showModal}
      setShowModal={setShowModal}
    >
      <div className="my-5">
        <p>
          Are you sure you would like to activate {store.brand.name} in{" "}
          {store.location.name}?
        </p>
      </div>
      <Button className="mr-3" onClick={activateStoreHandler}>
        Yes
      </Button>
      <Button backgroundColor="bg-gray-500" onClick={() => setShowModal(false)}>
        No
      </Button>
    </Modal>
  );
}
