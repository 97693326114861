import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import { useQueryClient } from "@tanstack/react-query";
import {
  LIST_STOREFRONT_INTEGRATIONS,
  Storefront3PIntegrationNameChoices,
  Storefront3PIntegrationNameEnum,
  Storefront3PIntegrationStateEnum,
  useCreateStorefrontIntegrationQuery,
} from "opsapi/hooks/operations/storefrontIntegrations";
import { StorefrontSchema } from "opsapi/hooks/operations/storefronts";
import { Fragment, useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  FormInput,
  FormLabel,
  FormOption,
  FormSelect,
} from "../../../ui";
import ToastContext from "../../../utils/contexts/ToastContext";
import { IntegrationOnboardingDialog } from "./IntegrationOnboardingDialog";

interface IntegrationCreateDialogProps {
  showModal: boolean;
  hideModal: () => void;
  storefront: StorefrontSchema;
  existingIntegrationNames: Storefront3PIntegrationNameChoices[];
}

export const IntegrationCreateDialog = ({
  showModal,
  hideModal,
  storefront,
  existingIntegrationNames,
}: IntegrationCreateDialogProps) => {
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);
  const { showToast } = useContext(ToastContext);
  const queryClient = useQueryClient();

  const {
    mutate: createStorefrontIntegration,
    isPending: isStorefrontIntegrationCreationPending,
  } = useCreateStorefrontIntegrationQuery({});

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: Object.values(Storefront3PIntegrationNameEnum).find(
        (name) =>
          !existingIntegrationNames.includes(name) &&
          name !== Storefront3PIntegrationNameEnum.ubereats,
      ),
      external_store_id: "",
      state: Storefront3PIntegrationStateEnum.deactivated,
    },
  });

  const watchName = watch("name");

  const onSubmitCreateIntegrationHandler = async (data: any) => {
    createStorefrontIntegration(
      { ...data, storefront_slug: storefront.slug },
      {
        onSuccess: () => {
          showToast({
            description: `Submitted successfully`,
            variant: "success",
            seconds: 2,
          });
          queryClient.resetQueries({
            queryKey: [LIST_STOREFRONT_INTEGRATIONS, storefront.slug],
          });
          hideModal();
        },
        onError: (error) => {
          showToast({
            description: error.message,
            variant: "error",
            seconds: 10,
          });
        },
      },
    );
  };

  // just 2 optyions exists: doordash and ubereats
  const canCreateNewIntegration = existingIntegrationNames.length < 2;

  return (
    <>
      <Transition.Root show={showModal} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={showModal}
          onClose={() => {}}
        >
          <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                <div>
                  <div className="flex w-full flex-row justify-between">
                    <Dialog.Title
                      as="h3"
                      className="flex items-start justify-between text-2xl leading-6 font-medium text-gray-900"
                    >
                      Create Storefront Integration
                    </Dialog.Title>

                    <XMarkIcon
                      onClick={() => hideModal()}
                      className="cursor-pointer h-8 w-8 -mt-2 -mr-2"
                      aria-hidden="true"
                    />
                  </div>
                </div>
                <div className="flex flex-col my-4 space-y-4">
                  {!canCreateNewIntegration && (
                    <div>
                      No integrations to create!
                      <br />
                      Both doordash and ubereats integrations are already
                      created.
                    </div>
                  )}
                  <form
                    onSubmit={handleSubmit(onSubmitCreateIntegrationHandler)}
                    className={`relative ${
                      canCreateNewIntegration ? "" : "hidden"
                    }`}
                  >
                    <div className="grid grid-cols-3 gap-6 my-5">
                      <div className="col-span-2">
                        <FormLabel
                          title="Name"
                          htmlFor="name"
                          information={`
                          Select Name of the storefront integration.
                        `}
                        />
                        <Controller
                          name="name"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <FormSelect {...field}>
                              {Object.values(
                                Storefront3PIntegrationNameEnum,
                              ).map((nameOption) => (
                                // Only enable consolidated storefronts
                                <FormOption
                                  key={nameOption}
                                  title={nameOption}
                                  className="capitalize"
                                  value={nameOption}
                                  disabled={
                                    existingIntegrationNames.includes(
                                      nameOption,
                                    ) ||
                                    nameOption ===
                                      Storefront3PIntegrationNameEnum.ubereats
                                  }
                                />
                              ))}
                            </FormSelect>
                          )}
                        />
                        <p className="text-red-800">
                          {errors.name?.message?.toString()}
                        </p>
                      </div>
                      <div className="col-span-2">
                        <FormLabel
                          title="External Store ID"
                          htmlFor="external_store_id"
                          information={`
                            ID of the storefront in the external system.
                            Leave the field blank if not onboarded yet.
                          `}
                        />
                        <Controller
                          name="external_store_id"
                          control={control}
                          render={({ field }) => (
                            <div className="flex items-center">
                              <FormInput
                                type="text"
                                id="external_store_id"
                                {...field}
                              />
                              <a
                                // There is a known issue with Yalla stores:
                                // they were created using onboarding flow in integrations service and have external ids
                                // matching DB records in DB. So that we have no column in the new schema to put actual DD store ids.
                                href={`https://merchant-portal.doordash.com/merchant/store-availability?store_id=${field.value}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <ArrowTopRightOnSquareIcon className="m-1 w-8 h-8" />
                              </a>
                            </div>
                          )}
                        />
                        <p className="text-red-800">
                          {errors.external_store_id?.message?.toString()}
                        </p>
                        {watchName ===
                          Storefront3PIntegrationNameEnum.doordash && (
                          <button
                            className="text-blue-500 underline underline-offset-1"
                            onClick={() => {
                              hideModal();
                              setShowOnboardingModal(true);
                            }}
                          >
                            Start Onboarding if store is not created yet on 3P
                            side
                          </button>
                        )}
                      </div>
                      <div className="col-span-2">
                        <FormLabel
                          title="State"
                          htmlFor="state"
                          information="Activate/deactivate storefront for guests"
                        />
                        <Controller
                          name="state"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <FormSelect {...field}>
                              {Object.values(
                                Storefront3PIntegrationStateEnum,
                              ).map((state) => (
                                // Only enable consolidated storefronts
                                <FormOption
                                  key={state}
                                  title={state}
                                  className="capitalize"
                                  value={state}
                                  disabled={
                                    state !==
                                    Storefront3PIntegrationStateEnum.deactivated
                                  }
                                />
                              ))}
                            </FormSelect>
                          )}
                        />
                        <p className="text-red-800">
                          {errors.state?.message?.toString()}
                        </p>
                      </div>
                    </div>
                    <Button
                      className="mr-3"
                      loading={isStorefrontIntegrationCreationPending}
                    >
                      Save
                    </Button>
                  </form>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <IntegrationOnboardingDialog
        showModal={showOnboardingModal}
        hideModal={() => setShowOnboardingModal(false)}
        storefront={storefront}
      />
    </>
  );
};
