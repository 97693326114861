import { useHistory } from "react-router-dom";
import classNames from "../../../../utils/classNames";

const LocationTab = ({
  isSelected,
  href,
  text,
}: {
  isSelected: boolean;
  href: string;
  text: string;
}) => {
  const history = useHistory();

  return (
    <button
      className={classNames(
        isSelected
          ? "border-lfg-primary text-lfg-primary"
          : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
        "cursor-pointer whitespace-nowrap py-2 px-1 border-b-4 font-medium text-sm",
      )}
      onClick={() => {
        history.push(href);
      }}
    >
      {text}
    </button>
  );
};

export default LocationTab;
