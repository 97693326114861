import { useListLocationsQuery } from "opsapi/hooks/operations/locations";
import { useListModifiersQuery } from "opsapi/hooks/operations/modifiers";

import { CheckCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { Table, TableData, TableRow } from "../../../../ui";
import { PageContent } from "../../../../ui/components/PageContent/PageContent";

export const ListLocationsModifiers = () => {
  const { data: modifiers, isLoading: isModifiersLoading } =
    useListModifiersQuery();
  const { data: locations, isLoading: isLocationsLoading } =
    useListLocationsQuery();

  if (isModifiersLoading || isLocationsLoading) {
    return <div>Loading...</div>;
  }

  if (!modifiers) {
    return <div>No modifiers found</div>;
  }

  if (!locations) {
    return <div>No locations found</div>;
  }

  const tableHeaders = [
    <th key="brand" className="text-center sticky left-0 bg-white">
      Item Name
    </th>,
    ...locations.map((location) => location.name),
  ];

  return (
    <PageContent>
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-semibold text-gray-900">
          Locations Modifiers
        </h1>
      </div>

      <Table headers={tableHeaders}>
        {modifiers.map((modifier, idx) => (
          <TableRow
            key={modifier.id}
            className="group"
            rowColor={idx % 2 ? "bg-white" : "bg-gray-50"}
          >
            <TableData className="bg-white sticky left-0 h-[70px] flex gap-2 items-center group-hover:bg-green-50">
              {modifier.brand?.name || "-"} - {modifier.name}
            </TableData>

            {locations.map((location) => (
              <TableData key={location.id}>
                <div className="flex items-center justify-center">
                  {!!modifier.locations?.find(
                    (modifier_location) => modifier_location.id === location.id,
                  ) ? (
                    <CheckCircleIcon
                      className="h-8 w-8 text-green-500"
                      title={location.state}
                    />
                  ) : (
                    <XMarkIcon
                      className="h-8 w-8 text-red-500"
                      title={location.state}
                    />
                  )}
                </div>
              </TableData>
            ))}
          </TableRow>
        ))}
      </Table>
    </PageContent>
  );
};
