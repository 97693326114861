import { faBan, faPencilAlt, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, FormInput } from "../../ui";
import { useAdjustCateringSubtotal } from "./hooks/useAdjustCateringSubtotal";
import { usdFormatter } from "./usdFormatter";
type Props = {
  order: any;
};

const CateringAdjustmentUI = ({ order }: Props) => {
  const { handleSubmit, control, formState } = useForm();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const adjustCateringSubtotal = useAdjustCateringSubtotal();
  const defaultCateringSubtotal =
    order?.catering_adjustment?.adjusted_subtotal || 0;

  const onSubmitHandler = async (data: any) => {
    const { catering_subtotal } = data;
    const transformedSubtotal = parseInt(
      catering_subtotal.replace("$", "").replace(".", ""),
    );
    try {
      await adjustCateringSubtotal({
        variables: {
          store_order_id: order?.id,
          adjusted_subtotal: transformedSubtotal,
        },
      });
      setIsEditing(false);
    } catch (err) {}
  };
  return (
    <div>
      {isEditing ? (
        <form onSubmit={handleSubmit(onSubmitHandler)} className="flex gap-1">
          <div className="flex flex-col w-48">
            <Controller
              name="catering_subtotal"
              control={control}
              defaultValue={usdFormatter.format(defaultCateringSubtotal / 100)}
              rules={{ pattern: /^\$?[0-9]+(\.[0-9][0-9])$/ }}
              render={({ field }) => {
                return <FormInput type="text" {...field} />;
              }}
            />
            <p className="text-sm text-red-500">
              {formState?.errors?.catering_subtotal?.type === "pattern" &&
                "Wrong format. (e.g. $12.50, $2.00)"}
            </p>
          </div>
          <Button type="submit" className="items-center h-10">
            <FontAwesomeIcon icon={faSave} />
          </Button>
          <Button
            type="button"
            className="items-center h-10"
            backgroundColor="bg-gray-400"
            onClick={() => setIsEditing(false)}
          >
            <FontAwesomeIcon icon={faBan} />
          </Button>
        </form>
      ) : (
        <div className="flex gap-3">
          <p>{usdFormatter.format(defaultCateringSubtotal / 100)}</p>
          <Button
            disabled
            backgroundColor="bg-gray-200"
            className="hover:opacity-100 cursor-not-allowed"
            onClick={() => {
              setIsEditing(true);
            }}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </Button>
        </div>
      )}
    </div>
  );
};

export default CateringAdjustmentUI;
