import { gql, useMutation } from "@apollo/client";
import { MARKETING_BILLBOARDS_QUERY } from "./useMarketingBillboards";

const UPDATE_MARKETING_BILLBOARD = gql`
  mutation UpdateMarketingBillboard(
    $id: Int!
    $update_marketing_billboard_input: UpdateMarketingBillboardInput!
  ) {
    update_marketing_billboard(
      id: $id
      input: $update_marketing_billboard_input
    ) {
      id
      title
      subtitle
      description
      image {
        url
        blurhash
      }
      portrait_image {
        url
        blurhash
      }
      redirect {
        ... on MarketingBillboardBrandPageRedirect {
          page
          brand_slug
          button_label
        }
        ... on MarketingBillboardCustomRedirect {
          page
          url
          button_label
        }
      }
      locations {
        id
        name
        slug
      }
      platforms
      visibility
      background_color
      start_time
      end_time
    }
  }
`;

export const useUpdateMarketingBillboard = () => {
  return useMutation(UPDATE_MARKETING_BILLBOARD, {
    refetchQueries: [{ query: MARKETING_BILLBOARDS_QUERY }],
  });
};
