import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Table, TableData, TableRow } from "../../../ui";
import useCurrentLocation from "../../../utils/hooks/useCurrentLocation";
import { EditLocationAccountModal } from "./EditLocationAccountModal";
import { useLocationAccountsByLocationID } from "./hooks/useLocationAccountsByLocationID";

interface Props {}

export const LocationAccounts = ({}: Props) => {
  const { currentLocation } = useCurrentLocation();
  const { data, loading } = useLocationAccountsByLocationID(
    currentLocation?.id,
  );
  const [selectedLocationAccount, setSelectedLocationAccount] =
    useState<any>(null);
  const [showEditLocationAccountModal, setShowEditLocationAccountModal] =
    useState<boolean>(false);

  const handleEditLocationAccountModal = (location_account: any) => {
    setSelectedLocationAccount(location_account);
    setShowEditLocationAccountModal(true);
  };

  if (loading) {
    return null;
  }
  return (
    <div className="max-w-3xl px-4 sm:px-6 md:px-8">
      <Table headers={["Account", "Actions"]}>
        {data.location_accounts.map((location_account: any, index: number) => (
          <TableRow key={index}>
            <TableData>{location_account.email}</TableData>
            <TableData>
              <Button
                type="button"
                className="mr-3"
                onClick={() => handleEditLocationAccountModal(location_account)}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
            </TableData>
          </TableRow>
        ))}
      </Table>
      <EditLocationAccountModal
        showModal={showEditLocationAccountModal}
        setShowModal={setShowEditLocationAccountModal}
        locationAccount={selectedLocationAccount}
      />
    </div>
  );
};
