import { Dispatch, SetStateAction, useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { useAuth } from "@localkitchens/passwordless-auth";
import {
  GuestFeedbackChannel,
  IssueCategory,
  StoreOrderIssue,
} from "../../types";
import { Button, FormLabel } from "../../ui/components";
import ToastContext from "../../utils/contexts/ToastContext";
import { getStoreOrderIssueFields } from "../../utils/getStoreOrderIssueFields";
import { useGuestFeedbackChannels } from "./hooks/useGuestFeedbackChannels";
import { useIssueCategories } from "./hooks/useIssueCategories";

type IssueFormProps = {
  order: any;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  onSubmit: (data: any) => void;
  storeOrderIssue?: StoreOrderIssue;
};

const RESOLUTIONS = [
  { value: "NONE", label: "NONE" },
  { value: "CREDIT", label: "CREDIT" },
  { value: "REFUND", label: "REFUND" },
  { value: "REFUND_AND_CREDIT", label: "REFUND_AND_CREDIT" },
  { value: "REFIRE", label: "REFIRE" },
];

export const IssueForm = ({
  order,
  setShowModal,
  onSubmit,
  storeOrderIssue,
}: IssueFormProps) => {
  const { showToast } = useContext(ToastContext);
  const { user } = useAuth();
  const { control, handleSubmit } = useForm();
  const { data: issueCategoriesData, loading: loadingIssueCategories } =
    useIssueCategories();
  const {
    data: guestFeedbackChannelsData,
    loading: loadingGuestFeedbackChannels,
  } = useGuestFeedbackChannels();

  const [
    categories,
    lineItems,
    guestFeedbackChannel,
    guestFeedback,
    comment,
    resolution,
  ] = getStoreOrderIssueFields(storeOrderIssue);

  const preparePayload = (data: any) => {
    const store_order_id = order.id;
    const issue_category_ids = data.issueCategories.map(
      (cat: any) => cat.value,
    );
    const store_order_line_item_ids = data.storeOrderLineItems.map(
      (soli: any) => soli.value,
    );
    const guest_feedback_channel_id = data.guestFeedbackChannel.value;
    const guest_feedback = data.guestFeedback;
    const comment = data.comment;
    const resolution = data.resolution.value;
    const auth0_user_id = user?.sub;
    const email = user?.email;

    return {
      store_order_id,
      store_order_line_item_ids,
      issue_category_ids,
      guest_feedback_channel_id,
      guest_feedback,
      comment,
      resolution,
      auth0_user_id,
      email,
    };
  };

  const onSubmitHandler = async (data: any) => {
    try {
      const payload = preparePayload(data);
      let mutationVariables = {};

      if (storeOrderIssue) {
        mutationVariables = {
          id: storeOrderIssue.id,
          // store_order_issue_category_ids:
          store_order_issue_input: payload,
        };
      } else {
        mutationVariables = {
          store_order_issue_input: payload,
        };
      }

      await onSubmit({
        variables: mutationVariables,
        refetchQueries: ["OPERATIONS_SALES_BY_ID_QUERY"],
      });

      setShowModal(false);
      showToast({
        description: "Issue Submitted",
        variant: "success",
        seconds: 2,
        onClose: () => {},
      });
    } catch (err) {
      showToast({
        description: "There was an error submitting the issue",
        variant: "error",
        seconds: 2,
        onClose: () => {},
      });
    }
  };

  if (loadingIssueCategories || loadingGuestFeedbackChannels) {
    return <></>;
  }

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <div>
        <FormLabel title="Issue Categories" htmlFor="issueCategories" />
        <Controller
          name="issueCategories"
          control={control}
          rules={{ required: true }}
          defaultValue={categories ? categories : ""}
          render={({ field }) => {
            const issueCategoriesOptions =
              issueCategoriesData.issue_categories.map(
                (category: IssueCategory) => ({
                  value: category.id,
                  label: category.name,
                }),
              );

            return (
              <Select
                isMulti
                closeMenuOnSelect={false}
                defaultValue={[issueCategoriesOptions[0]]}
                options={issueCategoriesOptions}
                isSearchable={false}
                {...field}
                onChange={(value) => field.onChange(value)}
              />
            );
          }}
        />
      </div>

      <div className="mt-3">
        <FormLabel
          title="Store Order Line Items"
          htmlFor="storeOrderLineItems"
        />
        <Controller
          name="storeOrderLineItems"
          control={control}
          rules={{ required: false }}
          defaultValue={lineItems ? lineItems : ""}
          render={({ field }) => {
            const lineItems = order.line_items.map((lineItem: any) => ({
              value: lineItem.id,
              label: `${lineItem.name} (${lineItem.brand.name})`,
            }));

            return (
              <Select
                isMulti
                closeMenuOnSelect={false}
                defaultValue={[lineItems[0]]}
                options={lineItems}
                isSearchable={false}
                {...field}
                onChange={(value) => field.onChange(value)}
              />
            );
          }}
        />
      </div>

      <div className="mt-3">
        <FormLabel title="Feedback Channel" htmlFor="guestFeedbackChannel" />
        <Controller
          name="guestFeedbackChannel"
          control={control}
          rules={{ required: true }}
          defaultValue={guestFeedbackChannel ? guestFeedbackChannel : ""}
          render={({ field }) => {
            const channels =
              guestFeedbackChannelsData.guest_feedback_channels.map(
                (channel: GuestFeedbackChannel) => ({
                  value: channel.id,
                  label: channel.name,
                }),
              );

            return (
              <Select
                closeMenuOnSelect={true}
                defaultValue={[channels[0]]}
                options={channels}
                isSearchable={false}
                {...field}
                onChange={(value) => field.onChange(value)}
              />
            );
          }}
        />
      </div>

      <div className="mt-3">
        <FormLabel title="Guest Feedback" htmlFor="guestFeedback" />
        <Controller
          name="guestFeedback"
          control={control}
          rules={{ required: true }}
          defaultValue={guestFeedback ? guestFeedback : ""}
          render={({ field }) => (
            <textarea
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
              id="guestFeedback"
              rows={4}
              {...field}
            />
          )}
        />
      </div>

      <div className="mt-3">
        <FormLabel title="Comment" htmlFor="comment" />
        <Controller
          name="comment"
          control={control}
          rules={{ required: false }}
          defaultValue={comment ? comment : ""}
          render={({ field }) => (
            <textarea
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
              id="comment"
              rows={3}
              {...field}
            />
          )}
        />
      </div>

      <div className="mt-3 grid grid-cols-2 gap-6">
        <div>
          <FormLabel title="Resolution" htmlFor="resolution" />
          <Controller
            name="resolution"
            control={control}
            rules={{ required: true }}
            defaultValue={resolution ? resolution : ""}
            render={({ field }) => {
              return (
                <Select
                  closeMenuOnSelect={true}
                  defaultValue={[RESOLUTIONS[0]]}
                  options={RESOLUTIONS}
                  isSearchable={false}
                  {...field}
                  onChange={(value) => field.onChange(value)}
                />
              );
            }}
          />
        </div>
      </div>

      <div className="mt-5">
        {storeOrderIssue && <Button className="mr-3">Edit</Button>}
        {!storeOrderIssue && <Button className="mr-3">Create</Button>}
        <Button
          type="button"
          backgroundColor="bg-gray-500"
          onClick={() => {
            setShowModal(false);
          }}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};
