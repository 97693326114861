import { useMutation, gql } from "@apollo/client";

const CREATE_STORE_MENU_CATEGORY_PREP_STATION = gql`
  mutation CreateStoreMenuCategoryPrepStation(
    $store_menu_category_id: Int
    $prep_station_id: Int
  ) {
    create_store_menu_category_prep_station(
      store_menu_category_id: $store_menu_category_id
      prep_station_id: $prep_station_id
    ) {
      id
    }
  }
`;

export const useCreateStoreMenuCategoryPrepStation = () => {
  const [createStoreMenuCategoryPrepStation] = useMutation(
    CREATE_STORE_MENU_CATEGORY_PREP_STATION,
  );

  return createStoreMenuCategoryPrepStation;
};
