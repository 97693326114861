import React, { Dispatch, SetStateAction } from "react";
import { Button, Modal } from "../../../ui";

interface Props {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  onSubmitHandler: any;
}

export const LocationHoursConfirmationModal = ({
  showModal,
  setShowModal,
  onSubmitHandler,
}: Props) => {
  return (
    <Modal
      title="Confirmation"
      showModal={showModal}
      setShowModal={setShowModal}
    >
      <div className="my-5">
        <p>Are you sure you would like to update this location's hours?</p>
        <p className="font-bold">
          This will update hours for every brand at this location.
        </p>
      </div>
      <div>
        <Button className="mr-3" onClick={onSubmitHandler}>
          Yes
        </Button>
        <Button
          backgroundColor="bg-gray-500"
          onClick={() => setShowModal(false)}
        >
          No
        </Button>
      </div>
    </Modal>
  );
};
