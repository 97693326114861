import { Route, Switch, useRouteMatch } from "react-router";
import { ListLocationsModifiers } from "./pages/ListLocationsModifiers";

export const LocationsModifiersController = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/`}>
        <ListLocationsModifiers />
      </Route>
    </Switch>
  );
};
