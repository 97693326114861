export default (file: File) => {
  return new Promise<FileReader["result"]>((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (e) => {
      resolve(e.target!.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};
