import { gql } from "@apollo/client";
import { useOperations_Brands_List_QueryQuery } from "../../../graphql/useOperationsBrandsList.generated";

export const useOperationsBrandsList = () => {
  const { data, error, loading, refetch } =
    useOperations_Brands_List_QueryQuery({
      fetchPolicy: "cache-and-network",
    });

  return { data, error, loading, refetch };
};
