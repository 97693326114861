import { useCallback, useState } from "react";
import { ToastProps } from "../../ui/components/Toast/Toast";

export const useToast = () => {
  const [toastProps, setToastProps] = useState<ToastProps>({
    variant: "info",
    show: undefined,
  });

  const showToast = useCallback((props: ToastProps) => {
    setToastProps({
      ...props,
      show: new Date().toISOString(),
    });
  }, []);

  const clearToast = useCallback(() => {
    setToastProps({
      variant: "info",
      show: undefined,
    });
  }, []);

  return { clearToast, showToast, toastProps };
};
