import { gql, useQuery } from "@apollo/client";

export const OPERATIONS_RELATED_REFUNDS = gql`
  query OPERATIONS_RELATED_REFUNDS($payment_intent_id: String) {
    related_refunds(payment_intent_id: $payment_intent_id) {
      id
      amount
      date
      payment_intent_id
    }
  }
`;

export const useRelatedRefunds = (id: string) => {
  const { data, error, loading, refetch } = useQuery(
    OPERATIONS_RELATED_REFUNDS,
    {
      variables: {
        payment_intent_id: id,
      },
      skip: !id,
    },
  );

  return { refunds: data?.related_refunds || [], error, loading, refetch };
};
