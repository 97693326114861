import {
  faPause,
  faPencilAlt,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { useHistory } from "react-router";
import { Button, Table, TableData, TableRow } from "../../../ui";
import ToastContext from "../../../utils/contexts/ToastContext";
import { BrandStatusTag } from "./BrandStatusTag";
import { useActivateStores } from "./hooks/useActivateStores";
import useBulkUpdateStores from "../hooks/useBulkUpdateStores";

interface Props {
  stores: any;
}

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const BrandsTable = ({ stores: defaultStores }: Props) => {
  const [stores, setStores] = useState<any[]>(defaultStores);

  const { showToast } = useContext(ToastContext);
  const history = useHistory();
  const { onDeactivateStores, onActivateStores } = useActivateStores();
  const { bulkUpdateStores } = useBulkUpdateStores();

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const reorderedStores = reorder(
      stores,
      result.source.index,
      result.destination!.index,
    );

    const changedStores: any[] = [];
    const storeCallbacks: Function[] = [];

    const newStores = reorderedStores.map((store, index) => {
      if (store.ordinal !== index) {
        storeCallbacks.push(() =>
          analytics.track("Store Ordinal Updated", {
            storeId: store.id,
            previousOrdinal: store.ordinal,
            newOrdinal: index,
          }),
        );

        changedStores.push({ id: store.id, ordinal: index });
      }

      return { ...store, ordinal: index };
    });

    bulkUpdateStores({
      variables: { stores: changedStores },
    })
      .then(() => {
        storeCallbacks.forEach((callback) => callback());
      })
      .catch(() => {
        showToast({
          description: "Store order has not been updated",
          variant: "error",
          seconds: 2,
          onClose: () => {},
        });
      });

    setStores(newStores);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="locations-stores-droppable">
        {(provided) => (
          <Table
            headers={[
              {
                text: "Status",
                information: (
                  <div>
                    <p>Status can be one of the following: </p>
                    <div>
                      <p>
                        <span className="font-bold">Active</span>: Active brands
                        are currently accepting orders within open hours
                      </p>
                      <p>
                        <span className="font-bold">Inactive</span>: Inactive
                        brands are not accepting any orders
                      </p>
                      <p>
                        <span className="font-bold">Paused</span>: Paused stores
                        are active stores that have temporarily paused ordering
                        (usually in cases of emergencies)
                      </p>
                    </div>
                  </div>
                ),
              },
              "Brand",
              "Actions",
            ]}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {stores.map((store: any, index: number) => {
              const status = store.is_active
                ? store.is_open
                  ? "Active"
                  : "Paused"
                : "Inactive";
              return (
                <Draggable
                  key={store.id}
                  draggableId={"" + store.id}
                  index={index}
                >
                  {(provided) => (
                    <TableRow
                      key={index}
                      ref={provided.innerRef}
                      style={{ ...provided.draggableProps.style }}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <TableData className="w-48 lg:w-64 xl:w-96">
                        <BrandStatusTag status={status} />
                      </TableData>
                      <TableData className="w-48 lg:w-64 xl:w-96">
                        {store.brand.name}
                      </TableData>
                      <TableData className="w-48 lg:w-64 xl:w-96">
                        <div className="flex items-center">
                          <Button
                            type="button"
                            className="flex items-center justify-center mr-2"
                            onClick={() =>
                              history.push(`/stores/${store.id}?tab=details`)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faPencilAlt}
                              className="mr-1"
                            />
                            Edit
                          </Button>
                          {status === "Active" && (
                            <Button
                              type="button"
                              className="flex items-center justify-center"
                              backgroundColor="bg-yellow-500"
                              onClick={() =>
                                onDeactivateStores([store.id], "all")
                              }
                            >
                              <FontAwesomeIcon
                                icon={faPause}
                                className="mr-1"
                              />
                              Pause
                            </Button>
                          )}
                          {status === "Paused" && (
                            <Button
                              type="button"
                              className="flex items-center justify-center"
                              backgroundColor="bg-green-500"
                              onClick={() =>
                                onActivateStores([store.id], "all")
                              }
                            >
                              <FontAwesomeIcon icon={faPlay} className="mr-1" />
                              Resume
                            </Button>
                          )}
                        </div>
                      </TableData>
                    </TableRow>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </Table>
        )}
      </Droppable>
    </DragDropContext>
  );
};
