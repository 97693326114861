import { OperationsMenuItem, OperationsModifierItem } from "../../../types";
import { PillColor, TagPill } from "./TagPill";

interface MenuItemTagPillsProps {
  item: OperationsMenuItem | OperationsModifierItem;
}

export const MenuItemTagPills: React.FC<MenuItemTagPillsProps> = ({ item }) => {
  if (item.__typename !== "OperationsMenuItem") return null;

  const { tags } = item as OperationsMenuItem;

  const activeFilterTags =
    tags
      ?.filter((tag) => tag.is_active && tag.is_filter)
      .map((tag) => tag.name) ?? [];

  if (activeFilterTags.length === 0) {
    return <TagPill color={PillColor.red} content="No Tags" />;
  }

  return (
    <div className="flex flex-wrap gap-2 max-w-xs">
      {activeFilterTags.map((tag) => (
        <TagPill key={tag} color={PillColor.blue} content={tag} />
      ))}
    </div>
  );
};
