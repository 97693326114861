import { useState } from "react";
import { Loyalty } from "./Loyalty";
import { useCustomers } from "./hooks/useCustomers";

export const LoyaltyController = () => {
  const limit = 20;
  const [pageNumber, setPageNumber] = useState(1);
  const [filterParams, setFilterParams] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });

  const { data, loading } = useCustomers({
    pageNumber,
    limit,
    ...filterParams,
  });

  return (
    <Loyalty
      data={data}
      limit={limit}
      pageNumber={pageNumber}
      setPageNumber={setPageNumber}
      totalOrderCount={data?.customers?.total_customer_count}
      loading={loading}
      setFilterParams={setFilterParams}
    />
  );
};
