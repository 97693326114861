import { gql, useMutation } from "@apollo/client";
import { OPERATIONS_BRANDS_QUERY } from "./useOperationsBrands";
import { OPERATIONS_BRAND_QUERY } from "./useOperationsBrand";

const DELETE_MENU_ITEM_BUILD_NOTES = gql`
  mutation DeleteMenuItemBuildNotes($id: Int, $custom_chit_id: Int) {
    delete_menu_item_build_notes(id: $id, custom_chit_id: $custom_chit_id) {
      id
    }
  }
`;

export const useDeleteMenuItemBuildNotes = (brandSlug?: string) => {
  const [deleteMenuItemBuildNotes, { loading }] = useMutation(
    DELETE_MENU_ITEM_BUILD_NOTES,
  );

  const onDeleteMenuItemBuildNotes = async ({ id, custom_chit_id }: any) => {
    return await deleteMenuItemBuildNotes({
      variables: {
        id,
        custom_chit_id,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: OPERATIONS_BRAND_QUERY,
          variables: {
            input: {
              brand_slug: brandSlug,
            },
          },
        },
      ],
    });
  };

  return { onDeleteMenuItemBuildNotes, loading };
};
