import { gql, useMutation } from "@apollo/client";
import { TAGS_QUERY } from "./useOperationsTags";

const BULK_UPDATE_TAGS = gql`
  mutation BulkUpdateTags($tags: [TagBulkUpdateInput]) {
    bulk_update_tags(tags: $tags)
  }
`;

const useBulkUpdateTags = () => {
  const [bulkUpdateTags, { loading, error }] = useMutation(BULK_UPDATE_TAGS, {
    refetchQueries: [{ query: TAGS_QUERY }],
  });
  return { bulkUpdateTags, loading, error };
};

export default useBulkUpdateTags;
