import * as Types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OperationsLocationFeaturedItemIdsQueryVariables = Types.Exact<{
  locationId: Types.Scalars['Int'];
}>;


export type OperationsLocationFeaturedItemIdsQuery = { __typename?: 'Query', featured_items: Array<{ __typename?: 'PublicMenuItem', id?: number | undefined }> };


export const OperationsLocationFeaturedItemIdsDocument = gql`
    query OperationsLocationFeaturedItemIds($locationId: Int!) {
  featured_items(location_id: $locationId, only_manual: true) {
    id
  }
}
    `;

/**
 * __useOperationsLocationFeaturedItemIdsQuery__
 *
 * To run a query within a React component, call `useOperationsLocationFeaturedItemIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperationsLocationFeaturedItemIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperationsLocationFeaturedItemIdsQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useOperationsLocationFeaturedItemIdsQuery(baseOptions: Apollo.QueryHookOptions<OperationsLocationFeaturedItemIdsQuery, OperationsLocationFeaturedItemIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OperationsLocationFeaturedItemIdsQuery, OperationsLocationFeaturedItemIdsQueryVariables>(OperationsLocationFeaturedItemIdsDocument, options);
      }
export function useOperationsLocationFeaturedItemIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OperationsLocationFeaturedItemIdsQuery, OperationsLocationFeaturedItemIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OperationsLocationFeaturedItemIdsQuery, OperationsLocationFeaturedItemIdsQueryVariables>(OperationsLocationFeaturedItemIdsDocument, options);
        }
export type OperationsLocationFeaturedItemIdsQueryHookResult = ReturnType<typeof useOperationsLocationFeaturedItemIdsQuery>;
export type OperationsLocationFeaturedItemIdsLazyQueryHookResult = ReturnType<typeof useOperationsLocationFeaturedItemIdsLazyQuery>;
export type OperationsLocationFeaturedItemIdsQueryResult = Apollo.QueryResult<OperationsLocationFeaturedItemIdsQuery, OperationsLocationFeaturedItemIdsQueryVariables>;