import { gql, useMutation } from "@apollo/client";

const ADJUST_CATERING_SUBTOTAL_MUTATION = gql`
  mutation AdjustCateringSubtotal(
    $store_order_id: Int
    $adjusted_subtotal: Int
  ) {
    adjust_catering_subtotal(
      store_order_id: $store_order_id
      adjusted_subtotal: $adjusted_subtotal
    )
  }
`;

export const useAdjustCateringSubtotal = () => {
  const [adjustCateringSubtotal] = useMutation(
    ADJUST_CATERING_SUBTOTAL_MUTATION,
    {
      refetchQueries: ["OPERATIONS_SALES_BY_ID_QUERY"],
    },
  );

  return adjustCateringSubtotal;
};
