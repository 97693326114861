import { gql, useMutation } from "@apollo/client";
import { OPERATIONS_BRAND_QUERY } from "./useOperationsBrand";

const MENU_ITEM_UPLOAD_FINAL_PLATED_IMAGE = gql`
  mutation UploadMenuItemPlatedImage(
    $menuItemId: Int!
    $uploadInput: UploadMenuItemImageInput!
  ) {
    upload_menu_item_final_plated_image(
      menuItemId: $menuItemId
      input: $uploadInput
    ) {
      kitchen_name
      id
      name
      image_url
      blurhash
    }
  }
`;

export type UploadMenuItemPlatedImageProps = {
  imageBase64: string;
  menuItemId: number;
  menuItemName: string;
};

export const useUploadMenuItemPlatedImage = (brandSlug?: string) => {
  const [uploadMenuItemImage, { loading, data }] = useMutation(
    MENU_ITEM_UPLOAD_FINAL_PLATED_IMAGE,
  );

  const onUploadMenuItemPlatedImage = async ({
    imageBase64,
    menuItemId,
    menuItemName,
  }: UploadMenuItemPlatedImageProps) => {
    return uploadMenuItemImage({
      variables: {
        menuItemId,
        uploadInput: {
          imageBase64,
          name: menuItemName,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: OPERATIONS_BRAND_QUERY,
          variables: {
            input: {
              brand_slug: brandSlug,
            },
          },
        },
      ],
    });
  };

  return { onUploadMenuItemPlatedImage, loading, data };
};
