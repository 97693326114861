import React from "react";
import classNames from "../../../utils/classNames";

interface Props {
  children: React.ReactNode;
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
  disabled?: boolean;
  loading?: boolean;
}

export const FlatButton = ({
  children,
  className,
  onClick,
  disabled,
  loading,
}: Props) => {
  return (
    <button
      className={classNames(
        disabled ? "bg-gray-500" : "bg-gray-300",
        "hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center",
        className,
      )}
      onClick={onClick}
    >
      {loading ? (
        <div className="loader ease-linear rounded-full border-2 border-gray-200 h-5 w-5" />
      ) : (
        children
      )}
    </button>
  );
};
