import { gql, useMutation } from "@apollo/client";

const UPDATE_STORE = gql`
  mutation UpdateStore(
    $store_id: Int
    $location_id: Int
    $brand_id: Int
    $ubereats_store_id: String
    $doordash_store_id: String
    $toast_menu_id: String
  ) {
    update_store(
      store_id: $store_id
      location_id: $location_id
      brand_id: $brand_id
      ubereats_store_id: $ubereats_store_id
      doordash_store_id: $doordash_store_id
      toast_menu_id: $toast_menu_id
    )
  }
`;

const useUpdateStore = () => {
  const [updateStore, { error }] = useMutation(UPDATE_STORE, {
    refetchQueries: ["StoreById"],
  });
  return { updateStore, error };
};

export default useUpdateStore;
