import { useCallback, useState } from "react";
import { OperationsMenuItem } from "../../../types";
import { Button } from "../../../ui";
import convertToBase64 from "../../../utils/convertToBase64";
import { UploadMenuItemImageProps } from "../hooks/useUploadMenuItemImage";
import FileDropZone from "./FileDropZone";
import PeekPreview from "./PeekPreview";

interface UploadPeekWindowImageFormType {
  item?: OperationsMenuItem;
  onUploadMenuItemBuildGridImage: (
    args: UploadMenuItemImageProps,
  ) => Promise<any>;
  onUploadMenuItemPlatedImage: (args: UploadMenuItemImageProps) => Promise<any>;
  loading: boolean;
  onSuccess?: () => void;
}

const UploadPeekWindowImageForm = ({
  item,
  onUploadMenuItemBuildGridImage,
  onUploadMenuItemPlatedImage,
  loading,
  onSuccess,
}: UploadPeekWindowImageFormType) => {
  const [buildGridImage, setBuildGridImage] = useState<File>();
  const [finalPlatedImage, setFinalPlatedImage] = useState<File>();
  const [peekWindowPreview, setPeekWindowPreview] = useState<boolean>(false);
  const [loadingImages, setLoadingImages] = useState<boolean>(false);

  const handleChangedBuildGridImage = useCallback((files: File[]) => {
    setBuildGridImage(files[0]);
  }, []);

  const handleChangedFinalPlatedImage = useCallback((files: File[]) => {
    setFinalPlatedImage(files[0]);
  }, []);

  const handleUploadBuildGridImage = async () => {
    if (buildGridImage && item) {
      const base64Image = await convertToBase64(buildGridImage);
      await onUploadMenuItemBuildGridImage({
        imageBase64: base64Image as string,
        menuItemId: item.id,
        menuItemName: item.name,
      });
    }
  };

  const handleUploadFinalPlatedImage = async () => {
    if (finalPlatedImage && item) {
      const base64Image = await convertToBase64(finalPlatedImage);
      await onUploadMenuItemPlatedImage({
        imageBase64: base64Image as string,
        menuItemId: item.id,
        menuItemName: item.name,
      });
    }
  };

  const handleUploadImages = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    setLoadingImages(true);
    if (buildGridImage) {
      await handleUploadBuildGridImage();
    }

    if (finalPlatedImage) {
      await handleUploadFinalPlatedImage();
    }
    setLoadingImages(false);
  };

  const handlePeekPreviewClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    if (!buildGridImage || !finalPlatedImage) handleUploadImages(e);
    setPeekWindowPreview(!peekWindowPreview);
  };

  return (
    <div className="flex flex-col items-center justify-around w-[calc(100vw-5.2rem)] h-full mt-9">
      {peekWindowPreview ? (
        <PeekPreview
          closePeek={(e) => handlePeekPreviewClick(e)}
          buildGridImgLink={item?.build_grid_img_url}
          finalPlatedImgLink={item?.final_plated_img_url}
          packagingImgLink={item?.image_url}
        />
      ) : (
        <>
          <div className="w-full flex justify-around">
            <div className="w-5/12 max-h-[600px] flex flex-col justify-center items-center">
              <div className="text-2xl font-bold text-black">
                Build Grid Image
              </div>
              <FileDropZone onChange={handleChangedBuildGridImage}>
                {buildGridImage && (
                  <div className="max-h-[600px] flex flex-col justify-center items-center w-full">
                    <img
                      src={URL.createObjectURL(buildGridImage)}
                      className="object-contain max-w-[850px] max-h-[600px]"
                    />
                  </div>
                )}
              </FileDropZone>
            </div>

            <div className="w-5/12 max-h-[600px] flex flex-col justify-center items-center">
              <div className="text-2xl font-bold text-black">
                Final Plated Image
              </div>
              <FileDropZone onChange={handleChangedFinalPlatedImage}>
                {finalPlatedImage && (
                  <div className="max-h-[600px] flex flex-col justify-center items-center w-full">
                    <img
                      style={{
                        maxWidth: "850px",
                        maxHeight: "600px",
                        objectFit: "contain",
                      }}
                      src={URL.createObjectURL(finalPlatedImage)}
                      className="object-contain"
                    />
                  </div>
                )}
              </FileDropZone>
            </div>
          </div>
          <div>
            {loadingImages ? (
              <div>Loading...</div>
            ) : (
              <div className="flex gap-5">
                <Button onClick={(e) => handleUploadImages(e)}>Upload</Button>
                <Button onClick={(e) => handlePeekPreviewClick(e)}>
                  Peek Window Preview
                </Button>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default UploadPeekWindowImageForm;
