import { ColumnInfo } from "../../types";

export const GUEST_WEB_PROVIDER_ID = 6;
export const GUEST_MOBILE_PROVIDER_ID = 11;
export const GUEST_KIOSK_PROVIDER_ID = 21;

export enum OrderKeys {
  ORDER_ID = "order_id",
  GUEST_NAME = "guest_name",
  LOCATION = "location",
  PROVIDER = "provider",
  PHONE_NUMBER = "phone_number",
  BRANDS = "brands",
  SUBTOTAL = "subtotal",
  ORDER_DATE = "order_date",
  STATUS = "status",
  REFUND = "refund",
}

//dev note: treat as immutable
export const ordersTableCols: ColumnInfo[] = [
  {
    name: "order_id",
    headerName: "Order ID",
    defaultDisplay: true,
    ordinal: 0,
  },
  {
    name: "guest_name",
    headerName: "Guest Name",
    defaultDisplay: true,
    ordinal: 1,
  },
  {
    name: "location",
    headerName: "Location",
    defaultDisplay: true,
    ordinal: 2,
  },
  {
    name: "provider",
    headerName: "Provider",
    defaultDisplay: true,
    ordinal: 3,
  },
  {
    name: "phone_number",
    headerName: "Phone Number",
    defaultDisplay: false,
    ordinal: 4,
  },
  {
    name: "brands",
    headerName: "Brands",
    defaultDisplay: true,
    ordinal: 5,
  },
  {
    name: "subtotal",
    headerName: "Subtotal",
    defaultDisplay: true,
    ordinal: 6,
  },
  {
    name: "order_date",
    headerName: "Order Date",
    defaultDisplay: true,
    ordinal: 7,
  },
  {
    name: "status",
    headerName: "Status",
    defaultDisplay: true,
    ordinal: 8,
  },
  {
    name: "refund",
    headerName: "Refund",
    defaultDisplay: true,
    ordinal: 9,
  },
];
