import React, { Dispatch, SetStateAction } from "react";
import { Button, Modal } from "../../ui";
import { displayHours } from "./CustomClosuresTable";
import dayjs from "../../utils/dayjs";
import useDeleteCustomHours from "../CustomHours/hooks/useDeleteCustomHours";

interface Props {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  selectedClosure: any;
}

export const DeleteCustomClosureModal = ({
  showModal,
  setShowModal,
  selectedClosure,
}: Props) => {
  const { deleteCustomHours } = useDeleteCustomHours();

  const deleteHours = async (custom_hours_id: number) => {
    try {
      await deleteCustomHours({
        variables: {
          custom_hours_ids: [custom_hours_id],
        },
      });
      setShowModal(false);
    } catch (err) {}
  };

  return (
    <Modal title="Delete" showModal={showModal} setShowModal={setShowModal}>
      <div className="my-5">
        <p>
          Do you want to delete the following closure on{" "}
          <span className="font-bold">
            {new Date(selectedClosure.override_date).toDateString()}:
          </span>
        </p>
        <p>{displayHours(selectedClosure)}</p>
        <p className="font-bold">
          Note: This will only remove the closure for this brand at this
          location; it will not affect other closures on this date
        </p>
      </div>
      <Button
        type="button"
        onClick={() => deleteHours(selectedClosure.id)}
        className="mr-2"
      >
        Delete
      </Button>
      <Button
        type="button"
        onClick={() => setShowModal(false)}
        backgroundColor="bg-gray-500"
      >
        Cancel
      </Button>
    </Modal>
  );
};
