import { gql, useMutation } from "@apollo/client";

const CREATE_PREP_STATION = gql`
  mutation CreatePrepStation(
    $name: String
    $location_id: Int
    $prep_station_type_id: Int
    $printer_id: String
  ) {
    create_prep_station(
      name: $name
      location_id: $location_id
      prep_station_type_id: $prep_station_type_id
      printer_id: $printer_id
    ) {
      id
      name
      printer_id
    }
  }
`;

export const useCreatePrepStation = () => {
  const [createPrepStation] = useMutation(CREATE_PREP_STATION, {
    refetchQueries: ["PREP_STATIONS_QUERY"],
  });

  return createPrepStation;
};
