import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/24/solid";
import { Dispatch, SetStateAction } from "react";

type PaginationProps = {
  pageNumber: number;
  limit: number;
  totalCount: number;
  setPageNumber: Dispatch<SetStateAction<number>>;
};

type Page = {
  number: number;
  linkClass: string;
};

const pageLinks = (currentPage: number, totalPages: number) => {
  const pages = [];
  const defaultClass =
    "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300";
  const activeClass = "border-indigo-500 text-indigo-600";

  for (let i = 1; i <= totalPages; i++) {
    const page: Page = {
      number: i,
      linkClass: i === currentPage ? activeClass : defaultClass,
    };

    pages.push(page);
  }

  const numberOfPageLinks = 6;

  if (totalPages > numberOfPageLinks) {
    let startIndex = currentPage < 3 ? 0 : currentPage - 3;
    let endIndex = startIndex + numberOfPageLinks - 1;

    if (endIndex > totalPages) {
      endIndex = totalPages;
      startIndex = endIndex - numberOfPageLinks + 1;
    }

    return pages.slice(startIndex, endIndex);
  }

  return pages;
};

export const Pagination = ({
  pageNumber,
  limit,
  totalCount,
  setPageNumber,
}: PaginationProps) => {
  const totalPages = Math.ceil(totalCount / limit);
  const pages = pageLinks(pageNumber, totalPages);

  const goToPreviousPage = () => {
    setPageNumber(pageNumber - 1);
  };

  const goToNextPage = () => {
    setPageNumber(pageNumber + 1);
  };

  const goToPage = (number: number) => {
    setPageNumber(number);
  };

  return (
    <nav className="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
      <div className="-mt-px w-0 flex-1 flex">
        <button
          onClick={goToPreviousPage}
          className={`${
            pageNumber === 1 ? "hidden" : ""
          } border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300`}
        >
          <ArrowLongLeftIcon
            className="mr-3 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          Previous
        </button>
      </div>
      <div className="hidden md:-mt-px md:flex">
        {pages.map((page: Page) => (
          <button
            onClick={() => goToPage(page.number)}
            key={page.number}
            className={`${page.linkClass} border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium`}
          >
            {page.number}
          </button>
        ))}
      </div>
      <div className="-mt-px w-0 flex-1 flex justify-end">
        <button
          onClick={goToNextPage}
          className={`${
            pageNumber === totalPages ? "hidden" : ""
          } border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300`}
        >
          Next
          <ArrowLongRightIcon
            className="ml-3 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </button>
      </div>
    </nav>
  );
};
