import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Dispatch, SetStateAction, useState } from "react";
import { Modal } from "..";

interface InformationModalProps {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  children: React.ReactNode;
}

const InformationModal = ({
  showModal,
  setShowModal,
  children,
}: InformationModalProps) => (
  <Modal
    title={
      <span className="flex items-center justify-between text-2xl leading-6 font-medium text-gray-900">
        <FontAwesomeIcon icon={faInfoCircle} className="text-3xl mr-2" />
        Information
      </span>
    }
    showModal={showModal}
    setShowModal={setShowModal}
    size={2}
  >
    <div className="my-5 whitespace-pre-wrap">{children}</div>
  </Modal>
);

interface InformationPopoverProps {
  information: React.ReactNode;
  disabled?: boolean;
}

export const InformationPopover = ({
  information,
  disabled = false,
}: InformationPopoverProps) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <FontAwesomeIcon
        icon={faInfoCircle}
        onClick={() => {
          if (!disabled) {
            setShowModal(true);
          }
        }}
        className={`${
          disabled ? "cursor-not-allowed opacity-50" : "cursor-pointer"
        }`}
      />
      {showModal && (
        <InformationModal showModal={true} setShowModal={setShowModal}>
          {information}
        </InformationModal>
      )}
    </>
  );
};
