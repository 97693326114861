import React from "react";

interface Props {
  children: React.ReactNode;
}

export const PageContent = ({ children }: Props) => {
  return (
    <main className="flex flex-col overflow-y-auto h-full gap-4 focus:outline-none">
      {children}
    </main>
  );
};
