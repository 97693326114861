import { OperationsMenuItem, OperationsModifierItem } from "../../../types";
import { PillColor, TagPill } from "./TagPill";

interface ItemTypePillProps {
  item: OperationsMenuItem | OperationsModifierItem;
}

export const ItemTypePill: React.FC<ItemTypePillProps> = ({ item }) => {
  const pillColor =
    item.item_type === "modifier_item" ? PillColor.purple : PillColor.green;
  const itemText =
    item.item_type === "modifier_item" ? "Modifier Item" : "Menu Item";

  return <TagPill color={pillColor} content={itemText} />;
};
