import { useMutation, gql } from "@apollo/client";

const OPERATIONS_CREATE_ISSUE = gql`
  mutation OPERATIONS_CREATE_ISSUE(
    $store_order_issue_input: StoreOrderIssueInput
  ) {
    create_issue(input: $store_order_issue_input) {
      id
    }
  }
`;

export const useCreateIssue = () => {
  const [createIssue] = useMutation(OPERATIONS_CREATE_ISSUE);

  return createIssue;
};
