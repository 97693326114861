import { useMutation, gql } from "@apollo/client";

const CREATE_STORE_MENU_PREP_STATION = gql`
  mutation CreateStoreMenuPrepStation(
    $store_menu_id: Int
    $prep_station_id: Int
  ) {
    create_store_menu_prep_station(
      store_menu_id: $store_menu_id
      prep_station_id: $prep_station_id
    ) {
      id
    }
  }
`;

export const useCreateStoreMenuPrepStation = () => {
  const [createStoreMenuPrepStation] = useMutation(
    CREATE_STORE_MENU_PREP_STATION,
    {
      refetchQueries: ["PREP_STATIONS_QUERY"],
    },
  );

  return createStoreMenuPrepStation;
};
