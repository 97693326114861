import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { FormLabel, FormSelect } from "../../ui";
import { Button } from "../../ui/components/Button/Button";
import { StoreMenuSyncModal } from "../Stores/StoreMenuSyncModal";

type MenuFormProps = {
  stores: any;
  locations: any;
};

export const MenuForm = ({ stores, locations }: MenuFormProps) => {
  const [selectedLocationId, setSelectedLocationId] = useState<number | null>(
    3,
  );
  const [selectedStoreId, setSelectedStoreId] = useState<number>(
    stores?.find((item: any) => item?.location?.id === 3).id,
  );

  const [filteredStoresByLocation, setFilteredStoresByLocation] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // Filter stores by location
    const filteredStores = stores?.filter(
      (item: any) => item.location.id === selectedLocationId,
    );

    filteredStores?.sort((a: any, b: any) => {
      // First, compare the 'is_active' property in descending order (active items first)
      if (b.is_active - a.is_active !== 0) {
        return b.is_active - a.is_active;
      }
      // If 'is_active' is the same, compare by brand name alphabetically
      return a.brand.name.localeCompare(b.brand.name);
    });

    // Update the state with the sorted array
    setFilteredStoresByLocation(filteredStores);
  }, [selectedLocationId, stores]);

  const changeLocationHandler = (e: React.BaseSyntheticEvent) => {
    const valueToInt = parseInt(e.target.value);
    const filteredStores = stores.filter(
      (item: any) => item.location.id === valueToInt,
    );

    setSelectedLocationId(valueToInt);
    setFilteredStoresByLocation(filteredStores);
    setSelectedStoreId(filteredStores[0].id);
  };

  const changeBrandHandler = (e: React.BaseSyntheticEvent) => {
    setSelectedStoreId(parseInt(e.target.value));
  };

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="grid grid-cols-3 gap-6 items-start">
          <div>
            <FormLabel id="location" title="Location" htmlFor="location" />
            <FormSelect
              id="location"
              onChange={changeLocationHandler}
              value={selectedLocationId}
            >
              {locations?.map((location: any, index: number) => {
                return (
                  <option key={index} value={location.id}>
                    {location.name}
                  </option>
                );
              })}
            </FormSelect>
          </div>
          <div>
            <FormLabel id="stores" title="Brands" htmlFor="brand" />
            <FormSelect
              id="brand"
              onChange={changeBrandHandler}
              value={selectedStoreId}
            >
              {filteredStoresByLocation?.map((item: any, index: any) => (
                <option value={item.id}>
                  {item.brand.name} ({item.is_active ? "Active" : "Inactive"})
                </option>
              ))}
            </FormSelect>
          </div>
          <div className="self-end justify-self-end">
            <Button
              onClick={() => setShowModal(true)}
              className="w-32 items-center"
            >
              <FontAwesomeIcon icon={faSync} className="mr-1" />
              <span>Sync Menu</span>
            </Button>
          </div>
        </div>
      </form>
      {showModal && (
        <StoreMenuSyncModal
          showModal={showModal}
          setShowModal={setShowModal}
          storeId={selectedStoreId}
        />
      )}
    </>
  );
};
