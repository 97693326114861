import { useQuery, gql } from "@apollo/client";

export const OPERATIONS_CATERING_ORDER_QUERY = gql`
  query OperationsCateringOrder($external_id: String!) {
    get_catering_order(external_id: $external_id) {
      id
      invoice_id
      external_id
      invoice_paid_at
      tax
      tax_rate
      tip
      tip_rate
      discount
      total
      subtotal
      customer_delivery_fee
      service_fee
      service_fee_rate
    }
  }
`;

export const useCateringOrder = (external_id: string) => {
  const { data, error, loading, refetch } = useQuery(
    OPERATIONS_CATERING_ORDER_QUERY,
    {
      variables: {
        external_id,
      },
      skip: !external_id,
      fetchPolicy: "cache-and-network",
    },
  );
  return {
    cateringOrder: data?.get_catering_order || null,
    error,
    loading,
    refetch,
  };
};
