import { ApolloError, gql, useQuery } from "@apollo/client";
import { MenuItem } from "../../../types";

const OPERATIONS_ALL_MENU_ITEMS_QUERY = gql`
  query OperationsAllMenuItems {
    operations_all_menu_items {
      id
      name
      image_url
      blurhash
    }
  }
`;

export const useOperationsAllMenuItems = (): {
  data: MenuItem[];
  loading: boolean;
  error?: ApolloError;
} => {
  const { data, error, loading } = useQuery(OPERATIONS_ALL_MENU_ITEMS_QUERY);
  return { data: data?.operations_all_menu_items || [], error, loading };
};
