import { gql, useQuery } from "@apollo/client";

export const LOCATIONS_QUERY = gql`
  query LocationsQuery {
    locations {
      id
      name
      state
      zipcode
      slug
      street
      timezone
      share_link
      is_active
    }
  }
`;

export const useLocations = () => {
  const { data, error, loading, refetch } = useQuery(LOCATIONS_QUERY);
  return { data, error, loading, refetch };
};
