import { useMutation, gql } from "@apollo/client";

const DELETE_STORE_MENU_PREP_STATION = gql`
  mutation DeleteStoreMenuPrepStation($store_menu_prep_station_id: Int) {
    delete_store_menu_prep_station(
      store_menu_prep_station_id: $store_menu_prep_station_id
    ) {
      id
    }
  }
`;

export const useDeleteStoreMenuPrepStation = () => {
  const [createStoreMenuPrepStation] = useMutation(
    DELETE_STORE_MENU_PREP_STATION,
  );

  return createStoreMenuPrepStation;
};
