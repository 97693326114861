import { useLazyQuery } from "@apollo/client";
import { useCallback, useMemo } from "react";
import { MenuItem, MenuItemPairing, PublicMenuItem } from "../../types";
import { MenuItemDraggable } from "../../ui/components/SearchAndDrag/MenuItemDraggable";
import { SearchAndDrag } from "../../ui/components/SearchAndDrag/SearchAndDrag";
import { OPERATIONS_SEARCH_MENU_ITEMS_QUERY } from "../Stores/Locations/components/hooks/useSearchMenuItems";

interface MenuItemSearchAndDragProps {
  menuItems: MenuItem[];
  onChange: (val: number[]) => void;
  selectedValues: number[];
  copyPairingsItems?: MenuItemPairing[];
}

interface SearchMenuItemsResponse {
  search_menu_items: {
    items: PublicMenuItem[];
  };
}

export const MenuItemSearchAndDrag = ({
  menuItems,
  onChange,
  selectedValues = [],
  copyPairingsItems,
}: MenuItemSearchAndDragProps) => {
  const [searchMenuItems, { data, loading: isSearchLoading }] =
    useLazyQuery<SearchMenuItemsResponse>(OPERATIONS_SEARCH_MENU_ITEMS_QUERY);

  const searchResults: MenuItem[] = useMemo(
    () =>
      data?.search_menu_items?.items?.map((storeMenuItem) => ({
        id: storeMenuItem.menu_item_id,
        name: storeMenuItem.name,
        image_url: storeMenuItem.image_url,
        blurhash: storeMenuItem.blurhash,
        description: storeMenuItem.description,
      })) || [],
    [data],
  );

  const onSearchQueryChange = useCallback(
    (query: string) => {
      searchMenuItems({
        variables: {
          query,
          filters: [],
          filter_active_modifiers: false,
          is_internal: true,
        },
      });
    },
    [searchMenuItems],
  );

  const renderItem = useCallback(
    (item: MenuItem, isPlaceholder: boolean, isDragging: boolean) => (
      <MenuItemDraggable
        item={item}
        isPlaceholder={isPlaceholder}
        isDragging={isDragging}
      />
    ),
    [],
  );

  return (
    <SearchAndDrag
      allItems={menuItems}
      onChange={onChange}
      selectedValues={selectedValues}
      onSearchQueryChange={onSearchQueryChange}
      searchResults={searchResults}
      isSearchLoading={isSearchLoading}
      renderItem={renderItem}
      copyPairingsItems={copyPairingsItems}
    />
  );
};
