import { useCallback, useEffect, useState } from "react";

const ItemImage = ({
  url,
  className,
}: {
  url?: string;
  className?: string;
}) => {
  const [src, setSrc] = useState(url);
  const [oldSrc, setOldSrc] = useState(url);

  const handleError = useCallback(() => {
    setSrc(oldSrc);
    if (oldSrc === url) {
      setSrc("");
    }
    setTimeout(() => {
      setSrc(url);
    }, 500);
  }, [url]);

  const handleLoad = () => {
    setOldSrc(src);
  };

  useEffect(() => {
    setSrc(url);
  }, [url]);

  return (
    <img
      className={className}
      src={src}
      onError={handleError}
      onLoad={handleLoad}
      alt={url}
    />
  );
};
export default ItemImage;
