type FormOptionProps = {
  id?: string;
  value?: any;
  keyType?: string;
  title: string;
  selected?: boolean;
  disabled?: boolean;
  className?: string;
};

export const FormOption = ({
  id,
  value,
  keyType,
  title,
  selected,
  disabled,
  className,
}: FormOptionProps) => {
  return (
    <option
      id={id}
      value={value}
      key={`${keyType}-${value}`}
      selected={selected}
      disabled={disabled}
      className={className ? className : ""}
    >
      {title}
    </option>
  );
};
