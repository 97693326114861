import { gql, useMutation } from "@apollo/client";

const RECYCLE_ORDER_MUTATION = gql`
  mutation RecycleOrderMutation($cart_id: String) {
    recycle_order(cart_id: $cart_id) {
      id
    }
  }
`;

export const useRecycleOrderMutation = () => {
  const [recycleOrder, { loading }] = useMutation(RECYCLE_ORDER_MUTATION);

  const handleRecycleOrder = (cartId: string) => {
    return recycleOrder({
      variables: {
        cart_id: cartId,
      },
    });
  };

  return { handleRecycleOrder, loading };
};
