import { DatePicker } from "antd";
import { Dispatch, SetStateAction, useState } from "react";

import moment from "moment";
import { Button } from "../../ui";
import { defaultFilters } from "../../utils/defaultFilters";
import useQueryParams from "../../utils/hooks/useQueryParams";

type FilterFormProps = {
  setPageNumber: Dispatch<SetStateAction<number>>;
  setFilterParams: Dispatch<SetStateAction<any>>;
};

const { RangePicker } = DatePicker;

export const FilterForm = ({
  setPageNumber,
  setFilterParams,
}: FilterFormProps) => {
  const { params, setQueryParam, resetQueryParams } = useQueryParams();
  const filters = ["firstName", "lastName", "email", "phoneNumber"];

  const [{ email, phoneNumber, firstName, lastName }, setFilters] = useState(
    defaultFilters(params, filters),
  );

  const [tempDate, setTempDate] = useState(undefined);

  const handleCalendarChange = (event: any) => {
    if (event) {
      const [startDate, endDate] = event;
      const startUnixDatetime = moment(startDate).startOf("day").valueOf();
      const endUnixDatetime = moment(endDate).endOf("day").valueOf();
      setTempDate(event);
    }
  };

  const handleFilterChanges = (event: any) => {
    const { name, value: _value } = event.target;

    // If this is a phone number field, check for a country code and strip it out
    const value =
      name === "phoneNumber" && _value.trim().startsWith("+1")
        ? _value.trim().replace("+1", "")
        : _value;

    setFilters((previousFilters) => ({ ...previousFilters, [name]: value }));
    setPageNumber(1);
    setFilterParams((previousFilters: any) => ({
      ...previousFilters,
      [name]: value,
    }));
    setQueryParam(name, value);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setPageNumber(1);
    setFilterParams({
      firstName,
      lastName,
      email,
      phoneNumber,
    });
  };

  const handleReset = (event: any) => {
    event.preventDefault();
    setPageNumber(1);
    setTempDate(undefined);
    setFilters(defaultFilters(null, filters));
    setFilterParams(defaultFilters(null, filters));
    resetQueryParams();
  };

  return (
    <form onSubmit={handleSubmit} className="flex gap-8">
      <div className="flex flex-col gap-4 w-1/3">
        <label
          htmlFor="firstName"
          className="block text-sm font-medium text-gray-700"
        >
          First Name
        </label>
        <input
          type="text"
          name="firstName"
          id="firstName"
          autoComplete="firstName"
          className="w-full border-gray-300 rounded-md"
          value={firstName}
          onChange={handleFilterChanges}
        />
        <label
          htmlFor="lastName"
          className="block text-sm font-medium text-gray-700"
        >
          Last Name
        </label>
        <input
          type="text"
          name="lastName"
          id="lastName"
          autoComplete="lastName"
          className="w-full border-gray-300 rounded-md"
          value={lastName}
          onChange={handleFilterChanges}
        />
      </div>

      <div className="flex flex-col gap-4 w-1/3">
        <label
          htmlFor="email"
          className="block text-sm font-medium text-gray-700"
        >
          Email
        </label>
        <input
          type="text"
          name="email"
          id="email"
          autoComplete="email"
          className="w-full border-gray-300 rounded-md"
          value={email}
          onChange={handleFilterChanges}
        />

        <label
          htmlFor="phoneNumber"
          className="block text-sm font-medium text-gray-700"
        >
          Phone Number
        </label>
        <input
          type="text"
          maxLength={12}
          name="phoneNumber"
          id="phoneNumber"
          autoComplete="phoneNumber"
          className="w-full border-gray-300 rounded-md"
          value={phoneNumber}
          onChange={handleFilterChanges}
        />
      </div>

      <div className="flex gap-4 w-1/3 items-end justify-center">
        <Button
          type="button"
          textColor="text-gray-700"
          className="bg-white border-gray-500 w-1/3"
          onClick={(event: any) => handleReset(event)}
        >
          Reset
        </Button>
        <Button type="submit" className="w-1/3">
          Filter
        </Button>
      </div>
    </form>
  );
};
