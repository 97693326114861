import { useMemo, useState } from "react";
import { Loading } from "../../ui/components/Loading";
import { useCustomHours } from "./hooks/useCustomHours";

import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { CustomHoursCreateModal } from "./CustomHoursCreateModal";
import { CustomHoursDetailModal } from "./CustomHoursDetailModal";

const localizer = momentLocalizer(moment);

export const CustomHoursCalendar = () => {
  const {
    data: customHoursData,
    loading: customHoursLoading,
    refetch,
  } = useCustomHours();

  const [showCustomHoursDetailModal, setShowCustomHoursDetailModal] =
    useState<boolean>(false);
  const [showCreateCustomHoursModal, setShowCreateCustomHoursModal] =
    useState<boolean>(false);

  const [selectedData, setSelectedData] = useState<any | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  const uniqueCustomHours = useMemo(() => {
    if (!!customHoursData) {
      let overrideDates = customHoursData.custom_hours.map(
        (item: any, index: number) => {
          return `${item.override_date}__${item.hours}`;
        },
      );
      overrideDates = new Set(overrideDates);
      return Array.from(overrideDates).sort();
    }
    return [];
  }, [customHoursData]);

  const onSelectEventHandler = (event: any) => {
    setSelectedData(event.resource);
    setShowCustomHoursDetailModal(true);
    setShowCreateCustomHoursModal(false);
  };
  const onSelectSlotHandler = (e: any) => {
    if (e.end > new Date()) {
      setSelectedDate(e.start);
      setShowCreateCustomHoursModal(true);
      setShowCustomHoursDetailModal(false);
    }
  };

  if (customHoursLoading) {
    return <Loading />;
  }
  return (
    <div className="h-100">
      <Calendar
        selectable
        events={uniqueCustomHours.map((customHours: any) => {
          const [date, selectedHours] = customHours.split("__");
          return {
            title: "Custom Closure(s)",
            start: new Date(date),
            end: new Date(date),
            allDay: true,
            resource: {
              selectedDateStr: new Date(date),
              selectedHours: selectedHours,
            },
          };
        })}
        views={["month", "week"]}
        localizer={localizer}
        style={{ height: "40rem" }}
        onSelectEvent={onSelectEventHandler}
        onSelectSlot={onSelectSlotHandler}
        dayPropGetter={(date: Date) => ({
          className: "hover:bg-lfg-primary hover:opacity-30",
        })}
      />
      {showCustomHoursDetailModal && (
        <CustomHoursDetailModal
          showModal={showCustomHoursDetailModal}
          setShowModal={setShowCustomHoursDetailModal}
          selectedData={selectedData}
          refetch={refetch}
        />
      )}
      {showCreateCustomHoursModal && (
        <CustomHoursCreateModal
          showModal={showCreateCustomHoursModal}
          setShowModal={setShowCreateCustomHoursModal}
          defaultDate={selectedDate}
        />
      )}
    </div>
  );
};
