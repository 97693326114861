import { useMutation, gql } from "@apollo/client";

const CREATE_LOCATION_ACCOUNT_MUTATION = gql`
  mutation CREATE_LOCATION_ACCOUNT_MUTATION($location_id: Int, $email: String) {
    create_location_account(location_id: $location_id, email: $email)
  }
`;

export const useCreateLocationAccount = () => {
  const [createLocationAccount] = useMutation(
    CREATE_LOCATION_ACCOUNT_MUTATION,
    {
      refetchQueries: ["LOCATION_ACCOUNTS_BY_LOCATION_ID_QUERY"],
    },
  );

  return createLocationAccount;
};
