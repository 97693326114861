import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Loading } from "../../ui";
import { PageContent } from "../../ui/components/PageContent/PageContent";
import CreateMarketingBillboardModal from "./components/CreateFeatureTileModal";
import MarketingBillboardTableSection from "./components/MarketingBillboardTableSection";
import useBrands from "./hooks/useBrands";
import { useCreateMarketingBillboard } from "./hooks/useCreateMarketingBillboard";
import { useDeleteMarketingBillboard } from "./hooks/useDeleteMarketingBillboard";
import useLocations from "./hooks/useLocations";
import { useUpdateMarketingBillboard } from "./hooks/useUpdateMarketingBillboard";
import {
  CreateMarketingBillboardInput,
  UpdateMarketingBillboardInput,
} from "./types";

export const MarketingBillboards = () => {
  const [updateBillboardMutation] = useUpdateMarketingBillboard();
  const [createMarketingBillboard] = useCreateMarketingBillboard();
  const [deleteBillboardMutation] = useDeleteMarketingBillboard();

  const { data: brandsData } = useBrands();
  const { data: locationsData } = useLocations();

  const [
    showCreateMarketingBillboardModal,
    setShowCreateMarketingBillboardModal,
  ] = useState(false);

  const onUpdateBillboard = async (data: UpdateMarketingBillboardInput) => {
    const { billboard_id, ...marketingBillboardUpdateData } = data;

    await updateBillboardMutation({
      variables: {
        id: billboard_id,
        update_marketing_billboard_input: marketingBillboardUpdateData,
      },
    });
  };

  const onDeleteBillboard = async (data: { id: number }) => {
    const { id } = data;

    await deleteBillboardMutation({
      variables: {
        id,
      },
    });
  };

  const onCreateBillboard = async (data: CreateMarketingBillboardInput) => {
    await createMarketingBillboard({
      variables: {
        create_marketing_billboard_input: data,
      },
    });
  };

  if (!brandsData || !locationsData) {
    return <Loading />;
  }

  return (
    <PageContent>
      <div className="flex justify-between">
        <h1 className="text-3xl font-semibold text-gray-900">Feature Tiles</h1>
        <Button
          className="flex items-center"
          type="button"
          onClick={() => setShowCreateMarketingBillboardModal(true)}
        >
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          Create Feature Tile
        </Button>
      </div>
      <MarketingBillboardTableSection
        brands={brandsData.brands}
        locations={locationsData.locations}
        onUpdateBillboard={onUpdateBillboard}
        onDeleteBillboard={onDeleteBillboard}
      />
      <CreateMarketingBillboardModal
        showModal={showCreateMarketingBillboardModal}
        setShowModal={setShowCreateMarketingBillboardModal}
        onCreateBillboard={onCreateBillboard}
        locations={locationsData.locations}
        brands={brandsData.brands}
      />
    </PageContent>
  );
};
