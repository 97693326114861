import { useDoordashTrackingURL } from "./hooks/useDoordashTrackingURL";

type DoordashDriveLinkProps = {
  cartID: string;
};

export const DoordashDriveLink = ({ cartID }: DoordashDriveLinkProps) => {
  const { doordashTrackingURL } = useDoordashTrackingURL(cartID);
  const orderId = extractOrderId(doordashTrackingURL);
  const doordashDriveURL = `https://www.doordash.com/merchant/order/${orderId}?business_id=871922`;

  const url = orderId ? doordashDriveURL : doordashTrackingURL;

  return (
    <a
      href={url}
      className="
              text-blue-600
              hover:text-blue-500
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-blue-500
            "
    >
      Doordash Drive Link
    </a>
  );
};

/**
 * In the future, store the DD order ID and use that directly.
 *
 * There are two known forms of tracking URLs from Doordash, this utility supports both:
 *  1. "https://track.doordash.com/order/0e06dd33-c4c7-4e17-a51f-7008a0bdaa7c/track"
 *  2. "https://doordash.com/drive/portal/track/b137883e-6a80-4572-8439-8c638c2af1b8"
 */
function extractOrderId(url?: string) {
  if (!url) return null;
  let urlObj = new URL(url);

  let parts = urlObj.pathname.split("/");

  let orderIndex = parts.indexOf("order");
  let trackIndex = parts.indexOf("track");

  if (orderIndex >= 0 && orderIndex < parts.length - 1) {
    return parts[orderIndex + 1];
  }

  if (trackIndex >= 0 && trackIndex < parts.length - 1) {
    return parts[trackIndex + 1];
  }

  return null;
}
