import { DatePicker } from "antd";
import moment from "moment";
import { Dispatch, SetStateAction } from "react";
import { OrderFilterFormParams } from "../../types";
import useQueryParams from "../../utils/hooks/useQueryParams";
import { defaultFilters } from "./OrdersController";
import { useProviders } from "./hooks/useProviders";
import { useSalesBrands } from "./hooks/useSalesBrands";
import { useListLocationsQuery } from "opsapi/hooks/operations/locations";
import Loader from "../../ui/components/Loader/Loader";

type FilterFormProps = {
  filterParams: OrderFilterFormParams;
  setPageNumber: Dispatch<SetStateAction<number>>;
  setFilterParams: Dispatch<SetStateAction<any>>;
};

const { RangePicker } = DatePicker;

export const FilterForm = ({
  filterParams,
  setPageNumber,
  setFilterParams,
}: FilterFormProps) => {
  const { data: locations, isLoading: loading } = useListLocationsQuery();
  const { data: providersData } = useProviders();
  const { data: brandsData } = useSalesBrands();
  const { setQueryParam, resetQueryParams } = useQueryParams();

  const {
    id,
    customerName,
    email,
    phoneNumber,
    locationID,
    brandID,
    providerID,
    externalID,
    startDate,
    endDate,
    ticketNumber,
  } = filterParams;

  const handleCalendarChange = (event: any) => {
    if (event) {
      const [startDate, endDate] = event;

      const startUnixDatetime = moment(startDate).startOf("day").valueOf();
      const endUnixDatetime = moment(endDate).endOf("day").valueOf();

      setFilterParams({
        id,
        customerName,
        email,
        phoneNumber,
        locationID,
        brandID,
        providerID,
        startDate: startUnixDatetime,
        endDate: endUnixDatetime,
        externalID,
      });
    }
  };

  const handleFilterChanges = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { name, value: _value } = event.target;

    // If this is a phone number field, check for a country code and strip it out
    const value =
      name === "phoneNumber" && _value.trim().startsWith("+1")
        ? _value.trim().replace("+1", "")
        : _value;

    setFilterParams((previousFilters: OrderFilterFormParams) => ({
      ...previousFilters,
      [name]: value,
    }));
    setQueryParam(name, value);
  };

  const filterOrders = () => {
    setPageNumber(1);
    setFilterParams({
      id,
      customerName,
      email,
      phoneNumber,
      locationID,
      brandID,
      providerID,
      startDate,
      endDate,
      externalID,
      ticketNumber,
    });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    filterOrders();
  };

  const handleReset = (event: any) => {
    event.preventDefault();
    setFilterParams(defaultFilters());
    resetQueryParams();
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="space-y-8 divide-y divide-gray-200"
    >
      {loading && <Loader loading={loading} />}
      <div className="space-y-8 divide-y divide-gray-200 ">
        <div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-2">
              <label
                htmlFor="id"
                className="block text-sm font-medium text-gray-700"
              >
                Order Id
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="id"
                  id="id"
                  autoComplete="id"
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  value={id}
                  onChange={handleFilterChanges}
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="customerName"
                className="block text-sm font-medium text-gray-700"
              >
                Guest Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="customerName"
                  id="customerName"
                  autoComplete="customerName"
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  value={customerName}
                  onChange={handleFilterChanges}
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <div className="mt-1">
                <input
                  type="email"
                  name="email"
                  id="email"
                  autoComplete="email"
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  value={email}
                  onChange={handleFilterChanges}
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="phoneNumber"
                className="block text-sm font-medium text-gray-700"
              >
                Phone Number
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  maxLength={12}
                  name="phoneNumber"
                  id="phoneNumber"
                  autoComplete="phoneNumber"
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  value={phoneNumber}
                  onChange={handleFilterChanges}
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="locationID"
                className="block text-sm font-medium text-gray-700"
              >
                Location
              </label>
              <div className="mt-1">
                <select
                  id="locationID"
                  name="locationID"
                  autoComplete="locationID"
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  value={locationID}
                  onChange={handleFilterChanges}
                  defaultValue={locationID}
                >
                  <option value="">-</option>
                  {locations &&
                    locations.map((location: any) => (
                      <option value={location.id}>{location.name}</option>
                    ))}
                </select>
              </div>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="providerID"
                className="block text-sm font-medium text-gray-700"
              >
                Provider
              </label>
              <div className="mt-1">
                <select
                  id="providerID"
                  name="providerID"
                  autoComplete="providerID"
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  value={providerID}
                  onChange={handleFilterChanges}
                  defaultValue={providerID}
                >
                  <option value="">-</option>
                  {providersData?.providers.map((provider: any) => (
                    <option value={provider.id}>{provider.name}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="brandID"
                className="block text-sm font-medium text-gray-700"
              >
                Brand
              </label>
              <div className="mt-1">
                <select
                  id="brandID"
                  name="brandID"
                  autoComplete="brandID"
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  value={brandID}
                  onChange={handleFilterChanges}
                  defaultValue={brandID}
                >
                  <option value="">-</option>
                  {brandsData?.brands.map((brand: any) => (
                    <option value={brand.id}>{brand.name}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="sm:col-span-2">
              <span className="mb-1 block text-sm font-medium text-gray-700">
                Date Range
              </span>
              <RangePicker
                className="w-full"
                defaultValue={[
                  moment.unix(filterParams.startDate / 1000),
                  moment.unix(filterParams.endDate / 1000),
                ]}
                value={[
                  moment.unix(filterParams.startDate / 1000),
                  moment.unix(filterParams.endDate / 1000),
                ]}
                onCalendarChange={handleCalendarChange}
              />
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="externalID"
                className="block text-sm font-medium text-gray-700"
              >
                External ID
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="externalID"
                  id="externalID"
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  value={externalID}
                  onChange={handleFilterChanges}
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="ticketNumber"
                className="block text-sm font-medium text-gray-700"
              >
                Ticket Number
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="ticketNumber"
                  id="ticketNumber"
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  value={ticketNumber}
                  onChange={handleFilterChanges}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={(event: any) => handleReset(event)}
          >
            Reset
          </button>
          <button
            type="submit"
            className="hover:opacity-80 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-lfg-primary"
          >
            Filter
          </button>
        </div>
      </div>
    </form>
  );
};
