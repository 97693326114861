import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { OperationsBrandListItem } from "../../graphql/types";
import { OperationsModifierItem, PrepStationTypeV2 } from "../../types";
import { Loading } from "../../ui/components/Loading";
import { KitchenNames } from "./KitchenNames";
import { useCreateOrUpdateBuildNotesMutation } from "./hooks/useCreateOrUpdateBuildNotesMutation";
import { useCreateOrMenuItemPrepStationTypeProps } from "./hooks/useCreateOrUpdateMenuItemPrepStationTypeMutation";
import { useDeleteMenuItemBuildNotes } from "./hooks/useDeleteMenuItemBuildNotes";
import { useUpdateKitchenNamesMutation } from "./hooks/useKitchenNameMutation";
import { useMarkAsCustomChit } from "./hooks/useMarkAsCustomChit";
import {
  OPERATIONS_BRAND_QUERY,
  useOperationsBrand,
} from "./hooks/useOperationsBrand";
import { useOperationsBrandsList } from "./hooks/useOperationsBrandsList";
import { usePrepStationTypes } from "./hooks/usePrepStationTypes";
import { useUploadMenuItemBuildGridImage } from "./hooks/useUploadMenuItemBuildGridImage";
import { useUploadMenuItemImageMutation } from "./hooks/useUploadMenuItemImage";
import { useUploadMenuItemPlatedImage } from "./hooks/useUploadMenuItemPlatedImage";

export const KitchenNamesController = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const { data: brandsListData, loading: brandsListLoading } =
    useOperationsBrandsList();

  const brandsList = (brandsListData?.operations_brands_list ||
    []) as OperationsBrandListItem[];

  const brandSlug =
    searchParams.get("brand") || brandsList?.[0]?.slug || undefined;

  // Hooks: Data fetching and mutations
  const { onUpdateKitchenNames, loading: updateKitchenNamesLoading } =
    useUpdateKitchenNamesMutation(brandSlug);
  const { markAsCustomChit, loading: markAsCustomChitLoading } =
    useMarkAsCustomChit();

  const {
    onDeleteMenuItemBuildNotes,
    loading: deleteMenuItemBuildNotesLoading,
  } = useDeleteMenuItemBuildNotes(brandSlug);
  const {
    onCreateOrUpdateBuildNotes,
    loading: createOrUpdateBuildNotesLoading,
  } = useCreateOrUpdateBuildNotesMutation(brandSlug);
  const {
    onCreateOrUpdateMenuItemPrepStationType,
    loading: createOrdUpdateMenuItemPrepStationType,
  } = useCreateOrMenuItemPrepStationTypeProps(brandSlug);
  const { onUploadMenuItemImage, loading: uploadingImage } =
    useUploadMenuItemImageMutation(brandSlug);
  const { onUploadMenuItemBuildGridImage, loading: uploadingBuildGridImage } =
    useUploadMenuItemBuildGridImage(brandSlug);
  const { onUploadMenuItemPlatedImage, loading: uploadingPlatedImage } =
    useUploadMenuItemPlatedImage(brandSlug);

  const { data: brandData, loading: brandLoading } =
    useOperationsBrand(brandSlug);

  const { data: prepStationTypesData, loading: prepStationTypesDataLoading } =
    usePrepStationTypes();

  const handleUpdateModifierItem = async (
    item: OperationsModifierItem,
    data: any,
  ) => {
    const menuItemIds = data.menuItems.map((data: any) => data.value);
    const payload = {
      id: item.custom_chit ? item.custom_chit?.id : null,
      is_active: data.isCustomChit,
      target_id: item.id,
      target_class: "modifier_item",
      menu_item_ids: menuItemIds,
      kitchen_name: data.kitchenName,
    };

    await markAsCustomChit({
      variables: {
        modifier_item: payload,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: OPERATIONS_BRAND_QUERY,
          variables: {
            input: {
              brand_slug: brandSlug,
            },
          },
        },
      ],
    });
  };

  const loading = useMemo(() => {
    return (
      brandsListLoading ||
      brandLoading ||
      updateKitchenNamesLoading ||
      createOrUpdateBuildNotesLoading ||
      !brandsList ||
      deleteMenuItemBuildNotesLoading ||
      prepStationTypesDataLoading ||
      createOrdUpdateMenuItemPrepStationType ||
      markAsCustomChitLoading ||
      !brandData ||
      !brandSlug ||
      uploadingImage
    );
  }, [
    brandsListLoading,
    brandsList,
    brandLoading,
    updateKitchenNamesLoading,
    createOrUpdateBuildNotesLoading,
    markAsCustomChitLoading,
    brandSlug,
    prepStationTypesDataLoading,
    deleteMenuItemBuildNotesLoading,
    uploadingImage,
    brandData,
  ]);

  if (loading) {
    return <Loading />;
  }

  return (
    <KitchenNames
      brandsList={brandsList}
      brand={brandData}
      prepStationTypes={prepStationTypesData?.prep_station_types.filter(
        (type: PrepStationTypeV2) => type.name !== "REGULAR",
      )}
      onUpdateKitchenNames={onUpdateKitchenNames}
      onCreateOrUpdateBuildNotes={onCreateOrUpdateBuildNotes}
      onDeleteMenuItemBuildNotes={onDeleteMenuItemBuildNotes}
      onCreateOrUpdateMenuItemPrepStationType={
        onCreateOrUpdateMenuItemPrepStationType
      }
      onUploadMenuItemImage={onUploadMenuItemImage}
      onUploadMenuItemBuildGridImage={onUploadMenuItemBuildGridImage}
      onUploadMenuItemPlatedImage={onUploadMenuItemPlatedImage}
      uploadingImage={
        uploadingImage || uploadingBuildGridImage || uploadingPlatedImage
      }
      onUpdateModifierItem={handleUpdateModifierItem}
    />
  );
};
