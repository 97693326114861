import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

export default function useQueryParams() {
  const { search } = useLocation();
  const history = useHistory();
  const location = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const setQueryParam = useCallback(
    (name: string, value: string) => {
      if (value) {
        params.set(name, value);
      } else {
        params.delete(name);
      }
      history.replace({
        pathname: location.pathname,
        search: params.toString(),
      });
    },
    [params, history, location.pathname],
  );

  const resetQueryParams = useCallback(() => {
    history.replace({
      pathname: location.pathname,
    });
  }, [history, location.pathname]);

  return {
    params,
    setQueryParam,
    resetQueryParams,
  };
}
