import { gql, useMutation } from "@apollo/client";

const CREATE_COUPON_MUTATION = gql`
  mutation CreateCouponMutation($create_coupon_input: CreateCouponInput) {
    create_coupon(create_coupon_input: $create_coupon_input) {
      id
      name
      code
      discount_rate
      max_amount
      credits
      brand_attrs
      is_active
      loyalty_account_attrs
      order_history_attrs
      brand_attrs
      location_attrs
      coupon_type
      free_item_ranks
      minimum_cart_items
      free_item_minimum_subtotal
      expiration_date
    }
  }
`;

export const useCreateCoupon = () => {
  const [createCoupon] = useMutation(CREATE_COUPON_MUTATION, {
    refetchQueries: ["COUPONS_QUERY"],
  });
  return createCoupon;
};
