import type { Identifier, XYCoord } from "dnd-core";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "./SearchAndDrag";

interface DraggableItemProps<ObjectType extends { id: number }> {
  className?: string;
  item: ObjectType;
  index: number;
  moveCard?: (dragIndex: number, hoverIndex: number) => void;
  addCard?: (item: ObjectType, hoverIndex: number) => void;
  dropCard?: () => void;
  isNew: boolean;
  isPlaceholder: boolean;
  renderItem?: (
    item: ObjectType,
    isPlaceholder: boolean,
    isDragging: boolean,
  ) => React.ReactNode;
}

export interface DragItem {
  index: number;
  type: string;
  isNew: boolean;
}

export const DraggableItem = <ObjectType extends { id: number }>({
  className,
  item,
  index,
  moveCard,
  dropCard,
  addCard,
  isNew,
  isPlaceholder,
  renderItem,
}: DraggableItemProps<ObjectType>) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null; isOver: boolean }
  >({
    accept: isNew
      ? [ItemTypes.selectedItem]
      : [ItemTypes.selectedItem, ItemTypes.availableItem],
    drop: () => {
      dropCard?.();
    },
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: monitor.isOver(),
      };
    },
    hover(dragItem, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = dragItem.isNew ? -1 : dragItem.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Get horizontal middle
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left;

      // Only perform the move when the mouse has crossed half of the items height and width
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        // Still not passed the middle, dragging right
        if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
          return;
        }
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        // Still not passed the middle, dragging left
        if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
          return;
        }
      }

      if (dragItem.isNew) {
        addCard?.(item, hoverIndex);
        dragItem.index = hoverIndex;
        dragItem.isNew = false;
        return;
      }
      // Time to actually perform the action
      moveCard?.(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      dragItem.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: isNew ? ItemTypes.availableItem : ItemTypes.selectedItem,
    item: () => {
      return { ...item, index, isNew };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));
  return (
    <div
      ref={ref}
      className={`relative m-2 ${className}`}
      data-handler-id={handlerId}
    >
      {renderItem && renderItem(item, isPlaceholder, isDragging)}
    </div>
  );
};
