import { Dispatch, SetStateAction } from "react";
import {
  MenuItem,
  OperationsMenuItem,
  OperationsModifierItem,
} from "../../../types";
import { PairingsForm } from "./PairingsForm";
import { useDrop } from "react-dnd";
import { DragItem } from "../../../ui/components/SearchAndDrag/DraggableItem";
import { Identifier } from "dnd-core";
import { Loading, Modal } from "../../../ui/components";
import { useMenuItemPairings } from "../../Pairings/hooks/usePairings";

export const ItemTypes = {
  selectedItem: "selected-item",
  availableItem: "available-item",
};

type RecommendedPairingsModalProps = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  allMenuItems: MenuItem[];
  menuItem:
    | OperationsMenuItem
    | OperationsModifierItem
    | (OperationsModifierItem & OperationsMenuItem);
};

export const RecommendedPairingsModal = ({
  showModal,
  setShowModal,
  allMenuItems,
  menuItem,
}: RecommendedPairingsModalProps) => {
  const [, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null; isOver: boolean }
  >({
    accept: ItemTypes.selectedItem,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: monitor.isOver(),
      };
    },
  });
  const { data: menuPairing, loading: pairingsLoading } = useMenuItemPairings(
    menuItem.id,
  );

  const defaultPairings = menuPairing?.menu_item_pairings;

  if (!menuPairing || pairingsLoading) return <Loading />;

  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
      title="Add recommended pairings"
      ref={drop}
    >
      <PairingsForm
        setShowModal={setShowModal}
        allMenuItems={allMenuItems}
        mode="update"
        menuItem={menuItem}
        defaultPairings={defaultPairings}
      />
    </Modal>
  );
};
