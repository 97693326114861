import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { Dispatch, SetStateAction, useContext } from "react";
import { Button, Modal } from "../../../ui";
import ToastContext from "../../../utils/contexts/ToastContext";
import { useActivateStores } from "./hooks/useActivateStores";

interface Props {
  showModal: any;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  stores: any;
}

export const ConfirmResumeModal = ({
  showModal,
  setShowModal,
  stores,
}: Props) => {
  const { onActivateStores } = useActivateStores();
  const { showToast } = useContext(ToastContext);

  const onSubmitHandler = async () => {
    const pausedActiveStores = stores.filter(
      (store: any) => store.is_active && !store.is_open,
    );
    const storeIds = pausedActiveStores.map((store: any) => store.id);
    try {
      await onActivateStores(storeIds, "all");
      showToast({
        description: "Stores have been activated",
        seconds: 3,
        variant: "success",
        onClose: () => {},
      });
      setShowModal(false);
    } catch (err) {
      showToast({
        description: "Could not activate. Please try again",
        seconds: 3,
        variant: "error",
        onClose: () => {},
      });
    }
  };

  return (
    <Modal
      title="Confirmation"
      showModal={showModal}
      setShowModal={setShowModal}
    >
      <div className="my-5">
        <div className="rounded-md bg-yellow-50 p-4 mb-5">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon
                className="h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-yellow-800">
                Are you sure?
              </h3>
              <div className="mt-2 text-sm text-yellow-700">
                <p>
                  This will also enable ordering on Doordash and Ubereats for
                  all active stores.
                </p>
              </div>
            </div>
          </div>
        </div>
        <p>Are you sure you want to resume ordering for all stores?</p>
        <div className="mt-5">
          <Button
            type="button"
            className="mr-2"
            onClick={() => onSubmitHandler()}
          >
            Yes
          </Button>
          <Button
            type="button"
            backgroundColor="bg-gray-500"
            onClick={() => setShowModal(false)}
          >
            No
          </Button>
        </div>
      </div>
    </Modal>
  );
};
