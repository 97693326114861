import React from "react";

interface FormTextareaProps extends React.HTMLAttributes<HTMLTextAreaElement> {
  id?: string;
  value?: any;
  placeholder?: string;
  name?: string;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  onChange?: (event: any) => void;
  rows?: number;
}

// React.forwardRef is used to address this issue: https://github.com/react-hook-form/react-hook-form/issues/3411
export const FormTextarea = React.forwardRef(
  (props: FormTextareaProps, ref: React.Ref<HTMLTextAreaElement>) => {
    const {
      id,
      value,
      name,
      placeholder,
      required,
      className,
      disabled,
      onChange,
      rows,
      ...rest
    } = props;
    const defaultInputStyle =
      "flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-md sm:text-sm border-gray-300";

    return (
      <textarea
        id={id}
        value={value}
        name={name}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        className={className ? className : defaultInputStyle}
        rows={rows ? rows : 4}
        ref={ref}
        onInput={onChange}
        {...rest}
      />
    );
  },
);
