import { gql, useQuery } from "@apollo/client";

const PRINTERS_BY_LOCATION_ID_QUERY = gql`
  query PRINTERS_BY_LOCATION_ID_QUERY($location_id: Int) {
    printers(location_id: $location_id) {
      id
      external_id
    }
  }
`;

export const usePrintersByLocationID = (locationId: number) => {
  const { data, error, loading, refetch } = useQuery(
    PRINTERS_BY_LOCATION_ID_QUERY,
    {
      variables: { location_id: locationId },
    },
  );

  return { data, error, loading, refetch };
};
