import { useMutation, gql } from "@apollo/client";

const ACTIVATE_STORE = gql`
  mutation ActivateStore($store_id: Int) {
    activate_store(store_id: $store_id)
  }
`;

const useActivateStore = () => {
  const [activateStore, { error }] = useMutation(ACTIVATE_STORE, {
    refetchQueries: ["StoreById"],
  });
  return { activateStore, error };
};

export default useActivateStore;
