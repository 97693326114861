import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useQueryClient } from "@tanstack/react-query";
import {
  LIST_STOREFRONT_INTEGRATIONS,
  Storefront3PIntegrationStateEnum,
  StorefrontIntegrationSchema,
  useTriggerStorefrontIntegrationsQuery,
} from "opsapi/hooks/operations/storefrontIntegrations";
import {
  LIST_STOREFRONTS,
  StorefrontSchema,
  VIEW_STOREFRONT,
} from "opsapi/hooks/operations/storefronts";
import { Fragment, useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, FormCheckbox, FormLabel } from "../../../ui";
import ToastContext from "../../../utils/contexts/ToastContext";

interface IntegrationSyncDialogProps {
  showModal: boolean;
  hideModal: () => void;
  storefrontName: string;
  storefronts?: StorefrontSchema[];
  integrations: StorefrontIntegrationSchema[];
}

export const IntegrationSyncDialog = ({
  showModal,
  hideModal,
  storefrontName,
  storefronts,
  integrations,
}: IntegrationSyncDialogProps) => {
  const { showToast } = useContext(ToastContext);
  const queryClient = useQueryClient();

  const {
    mutate: triggerStorefrontIntegrations,
    isPending: isStorefrontIntegrationsTriggeringPending,
  } = useTriggerStorefrontIntegrationsQuery({});

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      integration_ids: [] as string[],
    },
  });

  // Update the form values when integrations prop changes
  useEffect(() => {
    reset({
      integration_ids: integrations
        .filter(
          (integration) =>
            integration.state === Storefront3PIntegrationStateEnum.active,
        )
        .map((integration) => integration.id.toString()),
    });
  }, [integrations, reset]);

  const onSubmitTriggerIntegrationsHandler = async (data: any) => {
    triggerStorefrontIntegrations(
      {
        integration_ids: data.integration_ids.map((id: string) => parseInt(id)),
      },
      {
        onSuccess: () => {
          showToast({
            description: `Submitted successfully`,
            variant: "success",
            seconds: 2,
          });
          queryClient.invalidateQueries({
            queryKey: [LIST_STOREFRONT_INTEGRATIONS],
          });
          if (storefronts) {
            queryClient.invalidateQueries({
              queryKey: [LIST_STOREFRONTS],
            });
          } else {
            queryClient.invalidateQueries({
              queryKey: [VIEW_STOREFRONT],
            });
          }
          hideModal();
        },
        onError: (error) => {
          showToast({
            description: error.message,
            variant: "error",
            seconds: 10,
          });
        },
      },
    );
  };

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={showModal}
        onClose={() => {}}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
              <div>
                <div className="flex w-full flex-row justify-between">
                  <Dialog.Title
                    as="h3"
                    className="flex items-start justify-between text-2xl leading-6 font-medium text-gray-900"
                  >
                    Trigger Storefront `{storefrontName}` Integrations Syncing
                  </Dialog.Title>

                  <XMarkIcon
                    onClick={() => hideModal()}
                    className="cursor-pointer h-8 w-8 -mt-2 -mr-2"
                    aria-hidden="true"
                  />
                </div>
              </div>
              <div className="flex flex-col my-4 space-y-4">
                <form
                  onSubmit={handleSubmit(onSubmitTriggerIntegrationsHandler)}
                  className="relative"
                >
                  <div className="grid grid-cols-3 gap-6 my-5">
                    <div className="col-span-2">
                      <FormLabel
                        title="Select Integrations"
                        htmlFor="integration_ids"
                        information={`
                          Select integrations to trigger syncing.
                        `}
                      />
                      <Controller
                        name="integration_ids"
                        control={control}
                        render={({ field }) => (
                          <>
                            {integrations.map((integration) => (
                              <div
                                key={integration.id}
                                className="flex items-center"
                              >
                                <FormCheckbox
                                  type="checkbox"
                                  {...field}
                                  checked={field.value.includes(
                                    integration.id.toString(),
                                  )}
                                  disabled={
                                    integration.state !==
                                    Storefront3PIntegrationStateEnum.active
                                  }
                                  className="h-4 w-4"
                                  value={integration.id}
                                  onChange={(e) => {
                                    setValue(
                                      "integration_ids",
                                      field.value.includes(e.target.value)
                                        ? field.value.filter(
                                            (id) => id !== e.target.value,
                                          )
                                        : [e.target.value, ...field.value],
                                    );
                                  }}
                                />
                                <label className="ml-2" htmlFor="column-name">
                                  {storefronts
                                    ? storefronts.find(
                                        (storefront) =>
                                          storefront.id ===
                                          integration.storefront_id,
                                      )?.location.name
                                    : ""}
                                  {storefronts ? " - " : ""}
                                  {integration.name} [{integration.state}]
                                </label>
                              </div>
                            ))}
                          </>
                        )}
                      />
                      <p className="text-red-800">
                        {errors.integration_ids?.message?.toString()}
                      </p>
                    </div>
                  </div>
                  <Button
                    className="mr-3"
                    loading={isStorefrontIntegrationsTriggeringPending}
                  >
                    Sync
                  </Button>
                </form>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
