import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";

type ReactGridHeaderProps = {
  sortOrder?: "ASC" | "DESC" | "DEFAULT";
  onSort?: () => void;
  text?: string;
};

export const ReactGridHeader = React.memo(
  ({ sortOrder, onSort, text }: ReactGridHeaderProps) => {
    return (
      <div
        className={
          "px-1 flex flex-row justify-between w-full text-xs font-medium text-gray-500 uppercase tracking-wider"
        }
      >
        <div>{text}</div>
        <div onClick={() => onSort?.()}>
          {sortOrder === "ASC" && (
            <FontAwesomeIcon
              className={"pointer-events-none"}
              icon={faSortUp}
            />
          )}
          {sortOrder === "DESC" && (
            <FontAwesomeIcon
              className={"pointer-events-none"}
              icon={faSortDown}
            />
          )}
          {sortOrder === "DEFAULT" && (
            <FontAwesomeIcon className={"pointer-events-none"} icon={faSort} />
          )}
        </div>
      </div>
    );
  },
);
