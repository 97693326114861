import { useMutation, gql } from "@apollo/client";

const DELETE_STORE_MENU_CATEGORY_PREP_STATION = gql`
  mutation DeleteStoreMenuCategoryPrepStation(
    $store_menu_category_prep_station_id: Int
  ) {
    delete_store_menu_category_prep_station(
      store_menu_category_prep_station_id: $store_menu_category_prep_station_id
    ) {
      id
    }
  }
`;

export const useDeleteStoreMenuCategoryPrepStation = () => {
  const [deleteStoreMenuCategoryPrepStation] = useMutation(
    DELETE_STORE_MENU_CATEGORY_PREP_STATION,
  );

  return deleteStoreMenuCategoryPrepStation;
};
