import { gql, useMutation } from "@apollo/client";

const CANCEL_ORDER = gql`
  mutation CancelOrder($id: Int) {
    cancel_order(id: $id)
  }
`;

export const useCancelOrder = () => {
  const cancelMutation = useMutation(CANCEL_ORDER, {
    refetchQueries: ["OPERATIONS_SALES_BY_ID_QUERY"],
  });

  return cancelMutation;
};
