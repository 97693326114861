import { useMutation, gql } from "@apollo/client";

const CREATE_STORE_MENU_ITEM_PREP_STATION = gql`
  mutation CreateStoreMenuItemPrepStation(
    $store_menu_item_id: Int
    $prep_station_id: Int
  ) {
    create_store_menu_item_prep_station(
      store_menu_item_id: $store_menu_item_id
      prep_station_id: $prep_station_id
    ) {
      id
    }
  }
`;

export const useCreateStoreMenuItemPrepStation = () => {
  const [createStoreMenuItemPrepStation] = useMutation(
    CREATE_STORE_MENU_ITEM_PREP_STATION,
  );

  return createStoreMenuItemPrepStation;
};
