import React from "react";
import { InformationPopover } from "../InformationPopover/InformationPopover";

type FormLabelProps = {
  id?: string;
  title: string;
  htmlFor: string;
  labelClass?: string;
  information?: React.ReactNode;
  disabled?: boolean;
};

export const FormLabel = ({
  id,
  title,
  htmlFor,
  labelClass,
  information,
  disabled = false,
}: FormLabelProps) => {
  return (
    <div className="flex items-center whitespace-pre">
      <label
        id={id}
        htmlFor={htmlFor}
        className={`${
          labelClass ? labelClass : "block text-base font-medium text-gray-700"
        } ${disabled ? "opacity-40 cursor-not-allowed" : "cursor-pointer"}}`}
      >
        {title}
      </label>
      {!!information && (
        <span className="ml-2">
          <InformationPopover information={information} disabled={disabled} />
        </span>
      )}
    </div>
  );
};
