import * as Types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PaginatedMenuEntitiesQueryVariables = Types.Exact<{
  input: Types.PaginatedMenuEntitiesInput;
}>;


export type PaginatedMenuEntitiesQuery = { __typename?: 'Query', paginated_menu_entities?: Array<{ __typename?: 'MenuEntity', target_type?: Types.TargetType | undefined, target_id?: number | undefined, name?: string | undefined, image_url?: string | undefined, blurhash?: string | undefined, brand?: { __typename?: 'MenuEntityBrand', name?: string | undefined } | undefined, locations?: Array<{ __typename?: 'MenuEntityLocation', location_id?: number | undefined, location_slug?: string | undefined, display_to_guests?: boolean | undefined } | undefined> | undefined } | undefined> | undefined };


export const PaginatedMenuEntitiesDocument = gql`
    query PaginatedMenuEntities($input: PaginatedMenuEntitiesInput!) {
  paginated_menu_entities(input: $input) {
    target_type
    target_id
    name
    image_url
    blurhash
    brand {
      name
    }
    locations {
      location_id
      location_slug
      display_to_guests
    }
  }
}
    `;

/**
 * __usePaginatedMenuEntitiesQuery__
 *
 * To run a query within a React component, call `usePaginatedMenuEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedMenuEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedMenuEntitiesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaginatedMenuEntitiesQuery(baseOptions: Apollo.QueryHookOptions<PaginatedMenuEntitiesQuery, PaginatedMenuEntitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaginatedMenuEntitiesQuery, PaginatedMenuEntitiesQueryVariables>(PaginatedMenuEntitiesDocument, options);
      }
export function usePaginatedMenuEntitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaginatedMenuEntitiesQuery, PaginatedMenuEntitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaginatedMenuEntitiesQuery, PaginatedMenuEntitiesQueryVariables>(PaginatedMenuEntitiesDocument, options);
        }
export type PaginatedMenuEntitiesQueryHookResult = ReturnType<typeof usePaginatedMenuEntitiesQuery>;
export type PaginatedMenuEntitiesLazyQueryHookResult = ReturnType<typeof usePaginatedMenuEntitiesLazyQuery>;
export type PaginatedMenuEntitiesQueryResult = Apollo.QueryResult<PaginatedMenuEntitiesQuery, PaginatedMenuEntitiesQueryVariables>;