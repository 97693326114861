import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { OperationsMenuItem, OperationsModifierItem } from "../../../types";
import { Button, FormCheckbox, FormInput, FormLabel } from "../../../ui";
import { useMarkAsCustomChit } from "../hooks/useMarkAsCustomChit";
import { OPERATIONS_BRAND_QUERY } from "../hooks/useOperationsBrand";

type EditModifierItemFormProps = {
  item: OperationsModifierItem;
  allItems: OperationsMenuItem[] | OperationsModifierItem[];
  onUpdateModifierItem: (item: any, data: any) => Promise<any>;
};

export const EditModifierItemForm = ({
  item,
  allItems,
  onUpdateModifierItem,
}: EditModifierItemFormProps) => {
  const { control, handleSubmit } = useForm();

  const menuItems = allItems
    .filter((menuItem) => menuItem.item_type === "menu_item")
    .filter((menuItem) => {
      const modifierItems = menuItem.modifier_lists
        .map((modifierList) => modifierList.modifier_items)
        .flat();

      return modifierItems.some((modifierItem) => modifierItem.id === item.id);
    });

  const onSubmitHandler = async (data: any) => {
    await onUpdateModifierItem(item, data);
  };

  const customChitMenuItems = item.custom_chit?.menu_items
    ? item.custom_chit?.menu_items?.map((menuItem: any) => ({
        value: menuItem.id,
        label: menuItem.name,
      }))
    : [];

  return (
    <div>
      <div>
        <div className="mt-5">
          <div className="flex text-xl font-medium mb-2 border-b-2">
            <p className="mr-3">Instructions</p>
          </div>
        </div>
        <ul className="list-disc list-inside text-base mt-3 mb-6">
          <li>
            Toggle if you would like this modifier item to be a custom chit
          </li>
          <li>Write the name that will be display in the KDS and receipts</li>
          <li>
            Select which menu items you would like this to appear as a custom
            chit. If none selected, it will show for all menu items by default.
            For example, Tikka Chicken or Tikka Masala should only have Sexy
            Fries menu item as a custom chit
          </li>
          <li>
            Unchecking a custom chit will delete the custom chit and it's
            dependencies
          </li>
        </ul>
      </div>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <div className="mt-5">
          <div className="flex text-xl font-medium mb-2 border-b-2">
            <p className="mr-3">Make it Custom Chit</p>
          </div>
        </div>
        <div className="my-3">
          <FormLabel title="Is Custom Chit" htmlFor="isCustomChit" />
          <Controller
            name="isCustomChit"
            control={control}
            rules={{ required: false }}
            defaultValue={item.custom_chit?.id ? true : false}
            render={({ field }) => (
              <FormCheckbox
                type="checkbox"
                id="isCustomChit"
                checked={field.value}
                {...field}
              />
            )}
          />
        </div>
        <div className="my-3">
          <FormLabel title="Menu Items" htmlFor="menuItems" />
          <Controller
            name="menuItems"
            control={control}
            rules={{ required: false }}
            defaultValue={customChitMenuItems}
            render={({ field }) => {
              const menuItemOptions = menuItems.map((menuItem) => ({
                value: menuItem.id,
                label: menuItem.name,
              }));

              return (
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  defaultValue={[menuItemOptions[0]]}
                  options={menuItemOptions}
                  isSearchable={false}
                  {...field}
                  onChange={(value) => field.onChange(value)}
                />
              );
            }}
          />
        </div>
        <div className="flex justify-end">
          <Button>Update</Button>
        </div>
      </form>
    </div>
  );
};
