import React from "react";

interface FormInputProps extends React.HTMLAttributes<HTMLInputElement> {
  id?: string;
  value?: any;
  type: string;
  placeholder?: string;
  name?: string;
  required?: boolean;
  className?: string;
  checked?: boolean;
  disabled?: boolean;
  minLength?: number;
  maxLength?: number;
  step?: string;
  onChange?: (event: any) => void;
}

// React.forwardRef is used to address this issue: https://github.com/react-hook-form/react-hook-form/issues/3411
export const FormInput = React.forwardRef(
  (props: FormInputProps, ref: React.Ref<HTMLInputElement>) => {
    const {
      id,
      type,
      value,
      name,
      placeholder,
      required,
      className,
      checked,
      disabled,
      minLength,
      maxLength,
      onChange,
      step,
      ...rest
    } = props;
    const defaultInputStyle = disabled
      ? "bg-gray-200 w-full"
      : "flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-md sm:text-sm border-gray-300";

    return (
      <input
        id={id}
        type={type}
        value={value}
        name={name}
        placeholder={placeholder}
        required={required}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        minLength={minLength}
        maxLength={maxLength}
        className={className ? className : defaultInputStyle}
        step={step}
        ref={ref}
        {...rest}
      />
    );
  },
);
