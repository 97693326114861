import { DefaultCellTypes } from "@silevis/reactgrid";
import React from "react";
import { LocationV2 } from "../../../graphql/types";
import { PillColor, TagPill } from "../../KitchenNames/components/TagPill";
import { ReactGridHeader } from "../ReactGridHeader";
import { DEFAULT_COLUMNS, LOCATION_ALIASES, TABLE_FIELDS } from "./constants";
import { SortSelected } from "./types";

export const getLocationCells = (
  row: any,
  locations: LocationV2[],
  callback: Function,
  modifiedCells: { [key: string]: boolean },
): DefaultCellTypes[] => {
  const locationKeys: { [key: string]: any } = {};
  row?.locations?.forEach((location: any) => {
    if (location) locationKeys[location.location_slug] = location;
  });
  if (!locations) return [];
  return locations?.map((location: LocationV2) => {
    let state = "invisible";

    if (!location?.slug || !(location.slug in locationKeys)) {
      state = "inactive";
    } else if (
      location?.slug &&
      locationKeys[location.slug]?.display_to_guests
    ) {
      state = "visible";
    }
    const modifiedState =
      modifiedCells?.[`${row.target_type}_${row.target_id}_${location.slug}`];

    return {
      type: "text",
      text: state,
      nonEditable: true,
      style: {
        background: state === "inactive" ? "rgba(0,0,0,.02)" : "#fff",
      },
      renderer: (state: string): React.ReactNode => {
        if (state === "inactive") return null;
        let defaultChecked = state === "visible";

        if (modifiedState !== undefined) {
          defaultChecked = modifiedState;
        }

        return (
          <>
            <input
              type={"checkbox"}
              defaultChecked={defaultChecked}
              disabled={state === "inactive"}
              onClick={(e) => callback(e, row, location, state)}
            />
          </>
        );
      },
    };
  });
};

export const getHeaderRow = (
  locations: any,
  selectedSort?: SortSelected,
  onSort?: (field: string) => void,
) => {
  return {
    rowId: "header",
    cells: [
      ...TABLE_FIELDS.map((field) => ({
        type: "text",
        text: field,
        nonEditable: true,
        renderer: (text: string) => {
          let sortOrder: "ASC" | "DESC" | "DEFAULT" = "DEFAULT";
          if (selectedSort?.field === field) {
            sortOrder = selectedSort?.order;
          }
          return (
            <ReactGridHeader
              text={text}
              sortOrder={sortOrder}
              onSort={() => onSort?.(text)}
            />
          );
        },
      })),
      ...(locations?.map((location: any) => ({
        type: "text",
        nonEditable: true,
        text: getLocationHeaderText(location?.slug),
        renderer: (text: string): React.ReactNode => {
          return <ReactGridHeader text={text} />;
        },
      })) || []),
    ] as DefaultCellTypes[],
  };
};

export const getLocationHeaderText = (
  locationSlug?: string,
): string | undefined => {
  if (!locationSlug) {
    return;
  }

  if (LOCATION_ALIASES[locationSlug]) {
    return LOCATION_ALIASES[locationSlug];
  }

  return locationSlug?.toUpperCase()?.slice(0, 3);
};

export const getCells = (
  row: any,
  locations: any,
  callback: Function,
  modifiedCells: { [key: string]: boolean },
): DefaultCellTypes[] => {
  return [
    {
      type: "text",
      text: row?.brand?.name,
      nonEditable: true,
      style: {
        background: "#fff",
      },
    },
    {
      type: "text",
      text: row.name,
      nonEditable: true,
      style: {
        background: "#fff",
      },
    },
    {
      type: "text",
      text: row.target_type === "menu_item" ? "Menu Item" : "Modifier Item",
      renderer: (text: string): React.ReactNode => {
        const pillColor =
          row.target_type === "modifier_item"
            ? PillColor.purple
            : PillColor.green;

        return <TagPill color={pillColor} content={text} />;
      },
      nonEditable: true,
      style: {
        background: "#fff",
      },
    },
    ...getLocationCells(row, locations, callback, modifiedCells),
  ];
};

export const getColumnsWithLocations = (locations: any) => {
  return [
    ...DEFAULT_COLUMNS,
    ...(locations?.map((location: any, index: number) => ({
      columnId: location?.slug,
      width: 50,
      resizable: true,
      ordinal: DEFAULT_COLUMNS.length + index,
    })) || []),
  ];
};
