import { Dispatch, SetStateAction, useState } from "react";
import { defaultFilters } from "../../utils/defaultFilters";

import useQueryParams from "../../utils/hooks/useQueryParams";

type FilterFormProps = {
  setPageNumber: Dispatch<SetStateAction<number>>;
  setFilterParams: Dispatch<SetStateAction<any>>;
};

export const CouponsFilterForm = ({
  setPageNumber,
  setFilterParams,
}: FilterFormProps) => {
  const { params, setQueryParam, resetQueryParams } = useQueryParams();

  const filters = ["status", "name", "code"];

  const [{ status, name, code }, setFilters] = useState(
    defaultFilters(params, filters),
  );

  const handleFilterChanges = (event: any) => {
    const { name, value } = event.target;
    setFilters((previousFilters) => ({ ...previousFilters, [name]: value }));
    setPageNumber(1);
    setFilterParams((previousFilters: any) => ({
      ...previousFilters,
      [name]: value,
    }));
    setQueryParam(name, value);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setPageNumber(1);
    setFilterParams({
      status,
      name,
      code,
    });
  };

  const handleReset = (event: any) => {
    event.preventDefault();
    setPageNumber(1);
    setFilters(defaultFilters(null, filters));
    setFilterParams(defaultFilters(null, filters));
    resetQueryParams();
  };

  return (
    <form onSubmit={handleSubmit} className="flex justify-between">
      <div className="flex gap-4 items-center">
        <h1 className=" text-xl font-lfg-normal text-gray-900 text-nowrap mr-8">
          Filter Coupons:
        </h1>
        <label
          htmlFor="status"
          className="block text-sm font-medium text-gray-700"
        >
          Status
        </label>
        <select
          id="status"
          name="status"
          autoComplete="status"
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
          value={status}
          onChange={handleFilterChanges}
        >
          <option value="">-</option>
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
        </select>

        <label
          htmlFor="name"
          className="block text-sm font-medium text-gray-700"
        >
          Name
        </label>
        <input
          type="text"
          name="name"
          id="name"
          autoComplete="name"
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
          value={name}
          onChange={handleFilterChanges}
        />

        <label
          htmlFor="code"
          className="block text-sm font-medium text-gray-700"
        >
          Code
        </label>
        <input
          type="text"
          name="code"
          id="code"
          autoComplete="code"
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
          value={code}
          onChange={handleFilterChanges}
        />
      </div>

      <div className="flex justify-end">
        <button
          type="button"
          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={(event: any) => handleReset(event)}
        >
          Reset
        </button>
        <button
          type="submit"
          className="hover:opacity-80 ml-3 inline-flex justify-center items-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-lfg-primary"
        >
          Filter
        </button>
      </div>
    </form>
  );
};
