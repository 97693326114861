import { useListBrandsQuery } from "opsapi/hooks/operations/brands";
import { useListLocationsQuery } from "opsapi/hooks/operations/locations";
import {
  StorefrontTypeEnum,
  useUpdateStorefrontQuery,
  useViewStorefrontQuery,
} from "opsapi/hooks/operations/storefronts";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";
import {
  Button,
  FormInput,
  FormLabel,
  Table,
  TableData,
  TableRow,
  Toggle,
} from "../../../ui";
import Loader from "../../../ui/components/Loader/Loader";
import ToastContext from "../../../utils/contexts/ToastContext";
import { DeleteStorefrontDialog } from "../_components/DeleteStorefrontDialog";

export const UpdateStorefront = () => {
  const history = useHistory();
  const { locationSlug, storefrontSlug } = useParams<{
    locationSlug: string;
    storefrontSlug: string;
  }>();

  const { showToast } = useContext(ToastContext);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedBrandIds, setSelectedBrandIds] = useState<number[]>([]);

  const { data: locations, isLoading: isLocationsLoading } =
    useListLocationsQuery();
  const storefrontParams = {
    params: { path: { slug: storefrontSlug } },
  };

  const { data: currentStorefront, isLoading: isStorefrontLoading } =
    useViewStorefrontQuery(storefrontParams);
  const { mutate: updateStorefront, isPending: isUpdatePending } =
    useUpdateStorefrontQuery(storefrontParams);

  const { data: brands, isLoading: isBrandsLoading } = useListBrandsQuery();

  useEffect(() => {
    if (currentStorefront?.brands) {
      setSelectedBrandIds(currentStorefront.brands.map((brand) => brand.id));
    }
  }, [currentStorefront?.brands]);

  const onSubmitUpdateHandler = async (data: any) => {
    data.brand_ids = selectedBrandIds;

    updateStorefront(data, {
      onSuccess: () => {
        showToast({
          description: `Submitted successfully`,
          variant: "success",
          seconds: 2,
        });
        history.push(`/storefronts/${locationSlug}/${storefrontSlug}`);
      },
      onError: (error) => {
        showToast({
          description: error.message,
          variant: "error",
          seconds: 10,
        });
      },
    });
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  if (isLocationsLoading || isStorefrontLoading || isBrandsLoading) {
    return <Loader loading={true} />;
  }

  if (!locations) {
    return <div>No locations found</div>;
  }

  if (!brands) {
    return <div>No brands found</div>;
  }

  const currentLocation = locations.find(
    (location: { slug: string }) => location.slug === locationSlug,
  );

  if (currentLocation === undefined) {
    return <h1>Location not found</h1>;
  }
  if (currentStorefront === undefined) {
    return <h1>Storefront not found</h1>;
  }

  const tableHeaders = ["Is Linked", "Name", "Brand State"];

  const toggleBrandSelection = (brandId: number) => {
    if (selectedBrandIds.includes(brandId)) {
      setSelectedBrandIds(selectedBrandIds.filter((id) => id !== brandId));
    } else {
      setSelectedBrandIds([...selectedBrandIds, brandId]);
    }
  };

  const selectableBrands =
    currentStorefront.type === StorefrontTypeEnum.brand
      ? brands.filter((brand) => !brand.is_focal_eligible)
      : brands;

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex justify-between w-full mb-4">
        <h1 className="text-3xl font-semibold text-gray-900">
          {`Edit <${currentStorefront.type}> storefront "${currentStorefront.name}"`}
        </h1>

        <button
          onClick={() => history.goBack()}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
        >
          Back
        </button>
      </div>

      <div className="flex flex-col w-full h-full gap-4">
        <form
          onSubmit={handleSubmit(onSubmitUpdateHandler)}
          className="relative"
        >
          <div className="grid grid-cols-3 gap-6 my-5">
            <div className="col-start-1">
              <FormLabel
                title="Name"
                htmlFor="name"
                information="Storefront names are internal, autogenerated and not visible to guests via any channel"
              />
              <FormInput
                type="text"
                id="slug"
                value={currentStorefront.name}
                disabled={true}
              />
            </div>
            <div>
              <FormLabel
                title="Slug"
                htmlFor="slug"
                information="Slug will be set automatically based on the location and type of the Storefront."
              />
              <FormInput
                type="text"
                id="slug"
                value={currentStorefront.slug}
                disabled={true}
              />
            </div>
            {currentStorefront.type === StorefrontTypeEnum.brand && (
              <div className="col-span-2">
                <FormLabel
                  title="Focal Brand"
                  htmlFor="focal_brand"
                  information={`Select focal brand of the brand storefront.`}
                />
                <p>{currentStorefront.focal_brand?.name ?? "undefined"}</p>
              </div>
            )}
            <div className="col-span-2">
              <FormLabel
                title="Storefront Brands"
                htmlFor="storefront_brands"
                information={`Select brands for this storefront.`}
              />
              <Table headers={tableHeaders}>
                {selectableBrands &&
                  selectableBrands.map((brand, idx) => {
                    const { id, name, state } = brand;
                    return (
                      <TableRow
                        key={idx}
                        rowColor={idx % 2 ? "bg-white" : "bg-gray-50"}
                      >
                        <TableData>
                          <Toggle
                            initialValue={selectedBrandIds.includes(id)}
                            disabled={
                              brand.id === currentStorefront.focal_brand?.id
                            }
                            onChange={() => toggleBrandSelection(brand.id)}
                          />
                        </TableData>
                        <TableData>{name}</TableData>
                        <TableData>{state}</TableData>
                      </TableRow>
                    );
                  })}
              </Table>
            </div>
          </div>
          <Button
            className="mr-3 mt-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-[90px]"
            loading={isUpdatePending}
          >
            Save
          </Button>
        </form>
        <Button
          className="mr-3 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded w-[90px]"
          onClick={() => setShowDeleteModal(true)}
        >
          Delete
        </Button>
      </div>

      <DeleteStorefrontDialog
        showModal={showDeleteModal}
        hideModal={() => setShowDeleteModal(false)}
        storefront={currentStorefront}
      />
    </div>
  );
};
