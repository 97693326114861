/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useCallback, useMemo, useState } from "react";

import { Dialog, Transition } from "@headlessui/react";
import { useHistory } from "react-router";
import { Loading } from "../../ui/components/Loading";
import classNames from "../../utils/classNames";
import useComponentIsMounted from "../../utils/hooks/useComponentIsMounted";
import { RecentOrders } from "./RecentOrders";

import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { useAuth } from "@localkitchens/passwordless-auth";
import { LoyaltyAccount, UserInfo } from "shopapi/hooks/operations";
import { Sale } from "../../types";
import { useSales } from "../Orders/hooks/useSales";
import { usdFormat, usdFormatter } from "../Orders/usdFormatter";
import { DangerZone } from "./DangerZone";

type LoyaltyModalProps = {
  loyaltyAccount: LoyaltyAccount;
  refetchLoyaltyAccount: () => any;
  userInfo: UserInfo;
  showModal: boolean;
  addCreditsToLoyaltyAccount: (input: any) => any;
};

const DEFAULT_CREDITS = "1000";

export const LoyaltyModal = ({
  loyaltyAccount,
  refetchLoyaltyAccount,
  userInfo,
  showModal,
  addCreditsToLoyaltyAccount,
}: LoyaltyModalProps) => {
  const [credits, setCredits] = useState(DEFAULT_CREDITS);
  const [reason, setReason] = useState<string>("disabled");
  const [isAddingCredits, setIsAddingCredits] = useState(false);
  const [view, setView] = useState("guest");
  const history = useHistory();
  const componentIsMounted = useComponentIsMounted();
  const { user: currentUser } = useAuth();

  const roles = currentUser
    ? currentUser["https://app.localkitchens.co/roles"]
    : [];

  const disableAwardCredits = useMemo(
    () =>
      isAddingCredits ? reason === "disabled" : !loyaltyAccount.customer_id,
    [reason, loyaltyAccount.customer_id, isAddingCredits],
  );

  const { data, loading } = useSales({
    customerId: loyaltyAccount.customer_id.toString(),
  });

  const trackAwardLoyalty = useCallback(() => {
    analytics.track("Awarded Loyalty", {
      customerId: loyaltyAccount.customer_id,
      customerEmail: userInfo.email,
      previousTotalCredits: loyaltyAccount?.credits,
      creditsAdded: parseInt(credits),
      newTotalCredits: loyaltyAccount.credits + parseInt(credits),
    });
  }, [loyaltyAccount, userInfo, credits]);

  const insertedAt = useMemo(
    () => (userInfo.inserted_at ? new Date(userInfo.inserted_at) : null),
    [userInfo.inserted_at],
  );
  const deletedAt = useMemo(
    () => (userInfo.deleted_at ? new Date(userInfo.deleted_at) : null),
    [userInfo?.deleted_at],
  );

  const handleClose = () => {
    setCredits(DEFAULT_CREDITS);
    setIsAddingCredits(false);
    history.push("/loyalty");
  };

  const calculateTotalSales = (sales: Array<Sale>) => {
    let total = 0;

    sales.forEach((sale) => {
      if (sale?.provider.id === 7) {
        const data = JSON.parse(sale.raw_orders_data);
        total +=
          data.total - data.tax_amount - data.tip_amount < 0
            ? 0
            : data.total - data.tax_amount - data.tip_amount;
      } else {
        total +=
          sale.raw_cart_total -
            sale.raw_cart_tax -
            sale.raw_cart_tip -
            sale.raw_cart_delivery_fee <
          0
            ? 0
            : sale.raw_cart_total - sale.raw_cart_tax - sale.raw_cart_tip;
      }
    });
    const avg = total / sales.length;
    return sales.length > 0 ? usdFormatter.format(avg / 100) : "-";
  };

  const calculateTotalSubtotal = (sales: Array<Sale>) => {
    let total = 0;
    sales.forEach((sale) => {
      if (sale?.provider.id === 7) {
        const data = JSON.parse(sale.raw_orders_data);
        total += data.total;
      } else {
        total += sale.raw_cart_subtotal;
      }
    });
    // let total = sales.reduce((acc, sale) => (acc += sale.raw_cart_subtotal), 0);
    const avg = total / sales.length;
    return sales.length > 0 ? usdFormatter.format(avg / 100) : "-";
  };

  if (!componentIsMounted || loading) {
    return <Loading />;
  }

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={showModal}
        onClose={handleClose}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle  sm:w-2/3 sm:p-6">
              <div>
                <div className="mt-3 sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="flex items-center justify-between text-2xl leading-6 font-medium text-gray-900"
                  >
                    <div className="flex items-center">
                      <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                        <svg
                          className="h-full w-full text-gray-300"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                      </span>
                      <div className="ml-6">
                        {userInfo.last_name}, {userInfo.first_name}
                      </div>
                    </div>
                    <p className="text-2xl">Customer ID: {userInfo.id}</p>
                  </Dialog.Title>
                </div>
              </div>
              <nav className="mb-4 flex space-x-4" aria-label="Tabs">
                <div
                  className={classNames(
                    view === "guest"
                      ? "border-lfg-primary text-lfg-primary"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                    "cursor-pointer whitespace-nowrap py-2 px-1 border-b-4 font-medium text-sm",
                  )}
                  onClick={() => setView("guest")}
                >
                  Guest Information
                </div>
                <div
                  className={classNames(
                    view === "orders"
                      ? "border-lfg-primary text-lfg-primary"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                    "cursor-pointer whitespace-nowrap py-2 px-1 border-b-4 font-medium text-sm",
                  )}
                  onClick={() => setView("orders")}
                >
                  Recent Orders
                </div>
                {roles?.includes("ADMIN") ? (
                  <div
                    className={classNames(
                      view === "danger"
                        ? "border-lfg-primary text-lfg-primary"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                      "cursor-pointer whitespace-nowrap py-2 px-1 border-b-4 font-medium text-sm",
                    )}
                    onClick={() => setView("danger")}
                  >
                    Danger Zone
                  </div>
                ) : null}
              </nav>
              {view === "guest" ? (
                <>
                  <div className="flex flex-col w-full my-4">
                    <div className="w-full flex justify-between items-center">
                      <span className="font-bold">IS ACTIVE</span>
                      <div className="ml-2">
                        {userInfo.is_active ? (
                          <CheckCircleIcon
                            className={`h-6 w-6 text-green-500`}
                          />
                        ) : (
                          <XCircleIcon className={`h-6 w-6 text-red-500`} />
                        )}
                      </div>
                    </div>
                    <div className="w-full flex justify-between">
                      <div className="font-bold flex items-center">
                        <div className="mr-2">
                          {userInfo.is_email_verified ? (
                            <CheckCircleIcon
                              className={`h-6 w-6 text-green-500`}
                            />
                          ) : (
                            <XCircleIcon className={`h-6 w-6 text-red-500`} />
                          )}
                        </div>
                        <span>
                          EMAIL ({userInfo.is_email_verified ? "" : "not "}
                          verified)
                        </span>
                      </div>{" "}
                      <div>{userInfo.email}</div>
                    </div>
                    <div className="w-full flex justify-between">
                      <div className="font-bold flex items-center">
                        <div className="mr-2">
                          {userInfo.is_phone_number_verified ? (
                            <CheckCircleIcon
                              className={`h-6 w-6 text-green-500`}
                            />
                          ) : (
                            <XCircleIcon className={`h-6 w-6 text-red-500`} />
                          )}
                        </div>
                        <span>
                          PHONE (
                          {userInfo.is_phone_number_verified ? "" : "not "}
                          verified)
                        </span>
                      </div>{" "}
                      <div>{userInfo.phone_number}</div>
                    </div>
                    <div className="w-full flex justify-between">
                      <div className="font-bold">CREATED AT</div>
                      <div>{insertedAt?.toLocaleDateString()}</div>
                    </div>
                    <div className="w-full flex justify-between">
                      <div className="font-bold">SIGNUP TYPE</div>
                      <div>{userInfo.signup_method}</div>
                    </div>
                    {deletedAt ? (
                      <div className="w-full flex justify-between">
                        <div className="font-bold">DELETED AT</div>
                        <div>{deletedAt.toLocaleDateString()}</div>
                      </div>
                    ) : null}
                    <div className="pt-3">
                      <h2 className="w-full text-center">
                        <span className="bg-white px-3 text-sm font-medium">
                          Loyalty
                        </span>
                      </h2>
                      <div className="px-5 pb-5 pt-4 border-b border-t border-r border-l border-gray-600 rounded-md">
                        <div className="w-full flex justify-between hidden">
                          <div className="font-bold uppercase">
                            total spent towards $10 rewards
                          </div>
                          <div>
                            {usdFormat(
                              loyaltyAccount.total_spent_on_cash_rewards,
                            )}
                          </div>
                        </div>
                        <div className="w-full flex justify-between">
                          <div className="font-bold uppercase">
                            $10 reward progress
                          </div>
                          <div>
                            {usdFormat(loyaltyAccount.cash_reward_progress)} /
                            $150
                          </div>
                        </div>
                        <div className="w-full flex justify-between">
                          <div className="font-bold uppercase">$10 rewards</div>
                          <div>
                            {loyaltyAccount.cash_rewards_available} available,{" "}
                            {loyaltyAccount.total_cash_rewards_used_count} used,{" "}
                            {loyaltyAccount.total_cash_rewards_received_count}{" "}
                            total
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="pt-3">
                      <h2 className="w-full text-center">
                        <span className="bg-white px-3 text-sm font-medium">
                          Credits
                        </span>
                      </h2>
                      <div className="px-5 pb-5 pt-4 border-b border-t border-r border-l border-gray-600 rounded-md">
                        <div className="w-full flex justify-between border-b-1">
                          <div className="font-bold uppercase">credits</div>
                          <div>
                            {loyaltyAccount.credits} (pending:{" "}
                            {loyaltyAccount.pending_credits})
                          </div>
                        </div>
                        <div className="w-full flex justify-between items-center">
                          <div className="text-xs column">
                            <p>
                              Credits convert at a rate of 100:1 to dollar
                              discounts and are applied automatically on a
                              guest's next order.
                            </p>
                            <p>
                              That is, 1000 credits is equivalent to $10 off the
                              guest's next meal.
                            </p>
                          </div>
                          <form
                            className={`${
                              isAddingCredits ? "border rounded-sm" : ""
                            } flex flex-col justify-end items-center p-2 w-64`}
                            onSubmit={(e) => {
                              e.preventDefault();
                              if (!isAddingCredits) {
                                setTimeout(() => {
                                  document
                                    .getElementById("credit-input")
                                    ?.focus();
                                }, 100);
                                setIsAddingCredits((current) => !current);
                              } else if (reason !== "disabled") {
                                addCreditsToLoyaltyAccount({
                                  id: loyaltyAccount?.id,
                                  credits: parseInt(credits),
                                  reason,
                                }).then(() => {
                                  refetchLoyaltyAccount();
                                });
                                trackAwardLoyalty();
                                setCredits(DEFAULT_CREDITS);
                                setReason("disabled");
                                setIsAddingCredits((current) => !current);
                              }
                            }}
                          >
                            {isAddingCredits && (
                              <>
                                <select
                                  className="w-full rounded-md"
                                  required
                                  value={reason}
                                  onChange={(e) => setReason(e.target.value)}
                                >
                                  <option value="disabled" disabled>
                                    Select a reason...
                                  </option>
                                  <option value="customer_complaint">
                                    Customer Complaint
                                  </option>
                                  <option value="gift_card">Gift Card</option>
                                  <option value="other">Other</option>
                                </select>
                                <input
                                  type="number"
                                  autoFocus
                                  id="credit-input"
                                  value={credits}
                                  onChange={(e) => {
                                    setCredits(e.target.value);
                                  }}
                                  className="h-10 transition-all duration-300 rounded-md focus:outline-none focus:ring-none focus:ring-offset-0 focus:ring-0 border-lfg-primary focus:border-lfg-primary my-2 w-full"
                                />
                              </>
                            )}
                            <button
                              type="submit"
                              disabled={disableAwardCredits}
                              className={`${
                                isAddingCredits ? "w-32" : "w-full"
                              } ${
                                disableAwardCredits
                                  ? "opacity-60 cursor-not-allowed"
                                  : ""
                              } h-10 inline-flex justify-center items-center transition-all duration-300 rounded-md border border-transparent shadow-sm px-4 py-2 bg-lfg-primary text-base font-medium text-white hover:opacity-80 focus:outline-none focus:ring-none focus:ring-offset-0 focus:ring-indigo-500 sm:text-sm`}
                            >
                              {isAddingCredits ? "Award" : "Award Credits"}
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>

                    <div className="pt-3">
                      <h2 className="w-full text-center">
                        <span className="bg-white px-3 text-sm font-medium">
                          Stats
                        </span>
                      </h2>
                      <div className="px-5 pb-5 pt-4 border-b border-t border-r border-l border-gray-600 rounded-md">
                        <div className="w-full flex justify-between">
                          <div className="font-bold">TOTAL ORDERS</div>
                          <div>{data?.sales?.length}</div>
                        </div>
                        <div className="w-full flex justify-between">
                          <div className="font-bold">AOV</div>
                          <div>{calculateTotalSubtotal(data?.sales ?? [])}</div>
                        </div>
                        <div className="w-full flex justify-between">
                          <div className="font-bold">
                            AOV (AFTER CREDITS/PROMO)
                          </div>
                          <div>{calculateTotalSales(data?.sales ?? [])}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              {view === "orders" ? (
                <RecentOrders customerId={userInfo.id} />
              ) : null}
              {view === "danger" ? (
                <DangerZone
                  customerId={userInfo.id}
                  customerEmail={userInfo.email ?? "no-email"}
                  auth0UserId={userInfo.auth0_user_id ?? "no-id"}
                  deletedAt={deletedAt?.toLocaleDateString() || ""}
                  handleClose={handleClose}
                />
              ) : null}
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-lfg-primary text-base font-medium text-white hover:opacity-80 focus:outline-none focus:ring-none focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                  onClick={handleClose}
                >
                  Back to Guests
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
