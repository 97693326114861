import * as Types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Operations_Brands_List_QueryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type Operations_Brands_List_QueryQuery = { __typename?: 'Query', operations_brands_list?: Array<{ __typename?: 'OperationsBrandListItem', id: number, slug: string, name: string, is_active: boolean } | undefined> | undefined };


export const Operations_Brands_List_QueryDocument = gql`
    query OPERATIONS_BRANDS_LIST_QUERY {
  operations_brands_list {
    id
    slug
    name
    is_active
  }
}
    `;

/**
 * __useOperations_Brands_List_QueryQuery__
 *
 * To run a query within a React component, call `useOperations_Brands_List_QueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperations_Brands_List_QueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperations_Brands_List_QueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useOperations_Brands_List_QueryQuery(baseOptions?: Apollo.QueryHookOptions<Operations_Brands_List_QueryQuery, Operations_Brands_List_QueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operations_Brands_List_QueryQuery, Operations_Brands_List_QueryQueryVariables>(Operations_Brands_List_QueryDocument, options);
      }
export function useOperations_Brands_List_QueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operations_Brands_List_QueryQuery, Operations_Brands_List_QueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operations_Brands_List_QueryQuery, Operations_Brands_List_QueryQueryVariables>(Operations_Brands_List_QueryDocument, options);
        }
export type Operations_Brands_List_QueryQueryHookResult = ReturnType<typeof useOperations_Brands_List_QueryQuery>;
export type Operations_Brands_List_QueryLazyQueryHookResult = ReturnType<typeof useOperations_Brands_List_QueryLazyQuery>;
export type Operations_Brands_List_QueryQueryResult = Apollo.QueryResult<Operations_Brands_List_QueryQuery, Operations_Brands_List_QueryQueryVariables>;