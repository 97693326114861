import { gql, useMutation } from "@apollo/client";

const UPDATE_PRINTER = gql`
  mutation UpdatePrinter($printer_id: Int, $external_id: String) {
    update_printer(printer_id: $printer_id, external_id: $external_id) {
      id
      external_id
    }
  }
`;

export const useUpdatePrinter = () => {
  const [updatePrinter] = useMutation(UPDATE_PRINTER, {
    refetchQueries: ["PRINTERS_BY_LOCATION_ID_QUERY"],
  });

  return updatePrinter;
};
