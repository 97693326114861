import { gql, useQuery } from "@apollo/client";

export const LOCATIONS_QUERY = gql`
  query Locations {
    locations {
      id
      name
      slug
      is_active
    }
  }
`;

const useLocations = () => {
  return useQuery(LOCATIONS_QUERY);
};

export default useLocations;
