import * as Types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetLocationFeaturedItemsMutationVariables = Types.Exact<{
  locationId: Types.Scalars['Int'];
  storeMenuItemIds: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
}>;


export type SetLocationFeaturedItemsMutation = { __typename?: 'Mutation', set_location_featured_items: Array<{ __typename?: 'PublicMenuItem', id?: number | undefined }> };


export const SetLocationFeaturedItemsDocument = gql`
    mutation SetLocationFeaturedItems($locationId: Int!, $storeMenuItemIds: [Int!]!) {
  set_location_featured_items(
    location_id: $locationId
    store_menu_item_ids: $storeMenuItemIds
  ) {
    id
  }
}
    `;
export type SetLocationFeaturedItemsMutationFn = Apollo.MutationFunction<SetLocationFeaturedItemsMutation, SetLocationFeaturedItemsMutationVariables>;

/**
 * __useSetLocationFeaturedItemsMutation__
 *
 * To run a mutation, you first call `useSetLocationFeaturedItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLocationFeaturedItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLocationFeaturedItemsMutation, { data, loading, error }] = useSetLocationFeaturedItemsMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      storeMenuItemIds: // value for 'storeMenuItemIds'
 *   },
 * });
 */
export function useSetLocationFeaturedItemsMutation(baseOptions?: Apollo.MutationHookOptions<SetLocationFeaturedItemsMutation, SetLocationFeaturedItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetLocationFeaturedItemsMutation, SetLocationFeaturedItemsMutationVariables>(SetLocationFeaturedItemsDocument, options);
      }
export type SetLocationFeaturedItemsMutationHookResult = ReturnType<typeof useSetLocationFeaturedItemsMutation>;
export type SetLocationFeaturedItemsMutationResult = Apollo.MutationResult<SetLocationFeaturedItemsMutation>;
export type SetLocationFeaturedItemsMutationOptions = Apollo.BaseMutationOptions<SetLocationFeaturedItemsMutation, SetLocationFeaturedItemsMutationVariables>;