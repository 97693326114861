import {
  CloudArrowUpIcon,
  PauseIcon,
  PlayIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import { CloudIcon } from "@heroicons/react/24/solid";
import {
  StorefrontIntegrationSchema,
  SyncStateColor,
  useListStorefrontIntegrationsQuery,
} from "opsapi/hooks/operations/storefrontIntegrations";
import {
  StorefrontSchema,
  StorefrontStateEnum,
} from "opsapi/hooks/operations/storefronts";
import { useState } from "react";
import { Button } from "../../../ui";
import Loader from "../../../ui/components/Loader/Loader";
import classNames from "../../../utils/classNames";
import { FlatButton } from "./FlatButton";
import { IntegrationCreateDialog } from "./IntegrationCreateDialog";
import { IntegrationEditDialog } from "./IntegrationEditDialog";
import { IntegrationSyncDialog } from "./IntegrationSyncDialog";
import { IntegrationsStorefrontStateUpdateDialog } from "./IntegrationsStorefrontStateUpdateDialog";

interface SyncStateBarProps {
  className?: string;
  storefront: StorefrontSchema;
}

export const SyncStateBar = ({ className, storefront }: SyncStateBarProps) => {
  const [showCreateIntegrationModal, setShowCreateIntegrationModal] =
    useState(false);
  const [showEditIntegrationModal, setShowEditIntegrationModal] =
    useState(false);
  const [showStoreStatusUpdateModal, setShowStoreStatusUpdateModal] =
    useState(false);
  const [showSyncModal, setShowSyncModal] = useState(false);
  const [selectedIntegration, setSelectedIntegration] =
    useState<StorefrontIntegrationSchema | null>(null);

  const storefrontIntegrationsParams = {
    params: { query: { storefront_slug: storefront.slug } },
  };
  const {
    data: storefrontIntegrations,
    isLoading: isStorefrontIntegrationsLoading,
    refetch: refetchStorefrontIntegrations,
  } = useListStorefrontIntegrationsQuery(storefrontIntegrationsParams);

  const editIntegration = (integration: StorefrontIntegrationSchema) => {
    setSelectedIntegration(integration);
    setShowEditIntegrationModal(true);
  };

  const hideEditIntegrationModal = () => {
    setShowEditIntegrationModal(false);
    refetchStorefrontIntegrations();
  };

  const hideCreateIntegrationModal = () => {
    setShowCreateIntegrationModal(false);
    refetchStorefrontIntegrations();
  };

  if (isStorefrontIntegrationsLoading) {
    return (
      <div
        className={`flex justify-between rounded bg-gray-200 w-full h-[60px] px-2 ${className}`}
      >
        <Loader loading={true} />;
      </div>
    );
  }

  if (!storefrontIntegrations) {
    return (
      <div
        className={`flex justify-between rounded bg-gray-200 w-full h-[60px] px-2 ${className}`}
      >
        <div>No integrations found</div>
      </div>
    );
  }

  const syncStateColor = SyncStateColor[storefront.worst_sync_state];

  return (
    <div
      className={classNames(
        "flex justify-between rounded bg-gray-200 w-full h-[60px] px-2 content-center",
        className,
      )}
    >
      <div className="content-center ml-4 w-1/3">
        Sync State:{" "}
        <span className={`uppercase ${syncStateColor}`}>
          {storefront.worst_sync_state}
        </span>
      </div>
      <div className="content-center w-1/3">
        {storefrontIntegrations?.map((integration) => {
          return (
            <FlatButton
              key={integration.id}
              className="h-[40px] w-[140px] m-2 flex justify-center gap-2"
              onClick={() => editIntegration(integration)}
            >
              <div className="flex flex-col content-center h-full">
                <span className="text-xs">{integration.name}</span>
                <span className="text-xs">{integration.state}</span>
              </div>
              <CloudIcon
                className={`h-5 w-5 ${
                  SyncStateColor[integration.last_sync_state]
                }`}
              />
            </FlatButton>
          );
        })}
      </div>
      <div className="flex w-1/3 gap-2 mx-2 justify-end">
        <Button
          className=" hover:bg-blue-700 flex items-center my-2"
          backgroundColor="bg-blue-500"
          onClick={() => setShowSyncModal(true)}
        >
          <CloudArrowUpIcon className="h-5 w-5" />
        </Button>
        <Button
          className=" hover:bg-green-700 flex items-center my-2"
          backgroundColor="bg-green-500"
          onClick={() => setShowCreateIntegrationModal(true)}
        >
          <PlusIcon className="h-5 w-5" />
        </Button>
        {storefront.state === StorefrontStateEnum.active && (
          <Button
            className="hover:bg-orange-700 flex items-center my-2 gap-2 w-[140px]"
            backgroundColor="bg-orange-500"
            onClick={() => setShowStoreStatusUpdateModal(true)}
          >
            <PauseIcon className="h-5 w-5" />
            Deactivate
          </Button>
        )}
        {storefront.state === StorefrontStateEnum.deactivated && (
          <Button
            className="hover:bg-green-700 flex items-center my-2 gap-2 w-[140px]"
            backgroundColor="bg-green-500"
            onClick={() => setShowStoreStatusUpdateModal(true)}
          >
            <PlayIcon className="h-5 w-5" />
            Activate
          </Button>
        )}
      </div>

      <IntegrationCreateDialog
        showModal={showCreateIntegrationModal}
        hideModal={hideCreateIntegrationModal}
        storefront={storefront}
        existingIntegrationNames={
          storefrontIntegrations?.map((integration) => integration.name) ?? []
        }
      />
      {selectedIntegration && (
        <IntegrationEditDialog
          showModal={showEditIntegrationModal}
          hideModal={hideEditIntegrationModal}
          storefront={storefront}
          integration={selectedIntegration}
        />
      )}
      <IntegrationSyncDialog
        showModal={showSyncModal}
        hideModal={() => setShowSyncModal(false)}
        storefrontName={storefront.name}
        integrations={storefrontIntegrations ?? []}
      />

      <IntegrationsStorefrontStateUpdateDialog
        showModal={showStoreStatusUpdateModal}
        hideModal={() => setShowStoreStatusUpdateModal(false)}
        storefront={storefront}
        integrations={storefrontIntegrations ?? []}
      />
    </div>
  );
};
