import { Route, Switch, useRouteMatch } from "react-router";
import { ListBrandSections } from "./pages/ListBrandSections";

export const BrandSectionsController = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/`}>
        <ListBrandSections />
      </Route>
    </Switch>
  );
};
