import { faDownload, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { useCallback, useState } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";

export function removeDuplicateByProps<
  T extends { [key: string]: any },
  K extends keyof T,
>(arr: T[], props: K | K[]) {
  return arr.filter(
    (v, i, a) =>
      a.findIndex((t: T) =>
        Array.isArray(props)
          ? props.every((prop) => t[prop] === v[prop])
          : t[props] === v[props],
      ) === i,
  );
}

const ENABLED_FILES = ["image/jpg", "image/jpeg"];
const UPLOAD_LIMIT = 10485760; // 10MB

interface FileDropZoneProps {
  className?: string;
  onFlag?: (files: File[]) => void;
  onFlagSize?: () => void;
  options?: DropzoneOptions;
  onChange?: (files: File[]) => void;
  multi?: Boolean;
  children?: React.ReactNode;
  disabled?: boolean;
}

const FileDropZone = ({
  className,
  onFlag,
  onFlagSize,
  options,
  onChange,
  multi = false,
  children,
  disabled = false,
}: FileDropZoneProps) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>();
  const errorMessages = [];
  const onDrop = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (acceptedFiles: File[]) => {
      const activeFiles = selectedFiles ? selectedFiles : [];
      const newFiles = removeDuplicateByProps(
        [...activeFiles, ...acceptedFiles],
        ["name", "size", "lastModified"],
      );

      // Find unsupported files
      const flaggedFiles = newFiles.filter(
        (file: File) => !ENABLED_FILES.includes(file.type),
      );

      const totalSize = newFiles.reduce(
        (total: number, file: File) => total + file.size,
        0,
      );

      if (flaggedFiles.length > 0) {
        onFlag?.(flaggedFiles);
      } else {
        if (onChange) {
          onChange(multi ? newFiles : [newFiles[newFiles.length - 1]]);
        }
        let files = multi ? newFiles : [newFiles[newFiles.length - 1]];
        files = files.filter((file) => file);
        setSelectedFiles(files);
      }
    },
    [selectedFiles],
  );

  const { fileRejections, getRootProps, getInputProps, isDragActive } =
    useDropzone({
      ...options,
      maxFiles: multi ? undefined : 1,
      onDrop,
      accept: ENABLED_FILES,
      validator: (file) => {
        if (file.size > UPLOAD_LIMIT) {
          return {
            code: "file-too-large",
            message: "File size exceeds the limit of 10MB",
          };
        }
        return null;
      },
    });
  if (fileRejections[0]?.errors[0]?.message) {
    errorMessages.push(fileRejections[0]?.errors[0]?.message);
  }

  return (
    <div>
      {errorMessages.length > 0 && (
        <div className="text-red-500">{errorMessages.map((msg) => msg)}</div>
      )}
      <div
        {...getRootProps()}
        className={classnames(
          "flex",
          "flex-col",
          "cursor-pointer",
          "justify-center",
          className,
          {
            "border-2 border-dashed rounded-lg": !children,
            "cursor-not-allowed opacity-50": disabled,
          },
        )}
      >
        <input
          name={"files"}
          {...getInputProps({ id: "files" })}
          disabled={disabled}
        />
        {!children && (
          <div className="flex flex-col items-center justify-center h-full">
            <FontAwesomeIcon
              className={classnames("m-1", {
                "cursor-not-allowed opacity-50": disabled,
                "cursor-pointer": !disabled,
              })}
              icon={isDragActive ? faDownload : faUpload}
              size="4x"
              color="rgb(129, 140, 248)"
            />
            <p
              className={classnames("mt-2", "text-center", {
                "cursor-not-allowed opacity-50": disabled,
                "cursor-pointer": !disabled,
              })}
            >
              Drag and drop or browse to choose a file.
            </p>
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default FileDropZone;
