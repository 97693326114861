export const usdFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

export const usdFormat = (amount: number | null | undefined) => {
  if (amount === null || amount === undefined) return "";
  return usdFormatter.format(amount / 100);
};
