/* This example requires Tailwind CSS v2.0+ */
import {
  faAppleAlt,
  faCar,
  faCheck,
  faClock,
  faExclamationTriangle,
  faExternalLinkAlt,
  faInfoCircle,
  faPencilAlt,
  faReceipt,
  faShoppingCart,
  faTimes,
  faUserTag,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ShieldExclamationIcon } from "@heroicons/react/24/outline";
import { ShoppingBagIcon } from "@heroicons/react/24/solid";
import { useAuth } from "@localkitchens/passwordless-auth";
import dayjs from "dayjs";
import moment from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  IssueCategory,
  OrderEvent,
  StoreOrderIssue,
  StoreOrderLineItemIssue,
} from "../../types";
import { ConfirmationModal } from "../../ui/ConfirmationModal";
import { Modal, Table, TableData, TableRow } from "../../ui/components";
import { Button } from "../../ui/components/Button/Button";
import { InformationPopover } from "../../ui/components/InformationPopover/InformationPopover";
import { Loading } from "../../ui/components/Loading";
import ToastContext from "../../utils/contexts/ToastContext";
import { isRefundableSale } from "../../utils/storeOrderUtils";
import { textLimiter } from "../../utils/textLimiter";
import { getCanEditPickupTime, getIsLKDeliveryOrder } from "../../utils/utils";
import { IssueForm } from "../Issues/IssueForm";
import { useCreateIssue } from "../Issues/hooks/useCreateIssue";
import { useUpdateIssue } from "../Issues/hooks/useUpdateIssue";
import CateringAdjustmentUI from "./CateringAdjustmentUI";
import { CateringOrderForm } from "./CateringOrderForm";
import { DoordashDriveLink } from "./DoordashDriveLink";
import { OrderDetailTracker, Step } from "./OrderDetailTracker";
import { GUEST_KIOSK_PROVIDER_ID } from "./constants";
import { useCancelOrder } from "./hooks/useCancelOrder";
import { useCateringOrder } from "./hooks/useCateringOrder";
import { useCreateCateringOrder } from "./hooks/useCreateCateringOrder";
import { useOrderEvents } from "./hooks/useOrderEvents";
import { useRecycleOrderMutation } from "./hooks/useRecycleOrder";
import { useRelatedRefunds } from "./hooks/useRelatedRefunds";
import { useSaleById } from "./hooks/useSaleById";
import { useUpdateCateringOrder } from "./hooks/useUpdateCateringOrder";
import { useUpdatePickupTime } from "./hooks/useUpdatePickupTime";
import { usdFormatter } from "./usdFormatter";

type ModifierItemProps = {
  modifierItem: any;
};

const ModifierItem = ({ modifierItem }: ModifierItemProps) => {
  return (
    <div className="bg-blue-100 px-2 rounded inline-block text-sm mr-2">
      <p className="mr-2 inline-block">{modifierItem.quantity}x</p>
      <p className="inline-block">{modifierItem.name}</p>
      {modifierItem.price > 0 && (
        <p className="ml-2 inline-block">${modifierItem.price / 100}/ea</p>
      )}
    </div>
  );
};

const ticketSummary = (order: any) => {
  if (
    ["localkitchens", "mobile", "guest-kiosk"].includes(order?.provider?.slug)
  ) {
    return (
      <>
        <div className="mt-4 text-lg">
          <div className="flex justify-between">
            <p className="font-bold">Subtotal</p>
            <p className="font-bold text-black">
              {usdFormatter.format(order.subtotal / 100)}
            </p>
          </div>
        </div>
        <div className="mt-2 text-lg">
          <div className="flex justify-between">
            <p className="font-bold">
              Service Fee
              {order.raw_cart_service_fee_rate
                ? ` (${order.raw_cart_service_fee_rate / 100}%)`
                : ""}
            </p>
            <p className="font-bold text-black">
              {usdFormatter.format((order.raw_cart_service_fee || 0) / 100)}
            </p>
          </div>
        </div>
        <div className="mt-2 text-lg">
          <div className="flex justify-between">
            <p className="font-bold">Tax</p>
            <p className="font-bold text-black">
              {usdFormatter.format(order.raw_cart_tax / 100)}
            </p>
          </div>
        </div>
        <div className="mt-2 text-lg">
          <div className="flex justify-between">
            <p className="font-bold">Total</p>
            <p className="font-bold text-black">
              {usdFormatter.format(order.raw_cart_total / 100)}
            </p>
          </div>
        </div>
        <hr />
      </>
    );
  } else {
    return (
      <>
        <div className="mt-4 text-lg">
          <div className="flex justify-between">
            <p className="font-bold">Subtotal</p>
            <p className="font-bold text-black">
              {usdFormatter.format(order.subtotal / 100)}
            </p>
          </div>
        </div>
      </>
    );
  }
};

type EditOrderSectionProps = {
  order: any;
  setIsEditing: Function;
};

const EditOrderSection = ({ order, setIsEditing }: EditOrderSectionProps) => {
  const [isScheduled, setIsScheduled] = useState(order.is_scheduled);
  const [scheduledTime, setScheduledTime] = useState<string>(
    moment.unix(order.pickup_time / 1000).format("YYYY-MM-DD[T]HH:mm"),
  );
  const updatePickupTime = useUpdatePickupTime(order.id);
  const { showToast } = React.useContext(ToastContext);

  const handleScheduledChange = (e: React.BaseSyntheticEvent) => {
    setIsScheduled(e.target.value === "true");
  };

  const handleTimeChange = (e: React.BaseSyntheticEvent) => {
    setScheduledTime(e.target.value);
  };

  const saveOrderUpdate = async (e: React.BaseSyntheticEvent) => {
    const response = await updatePickupTime({
      variables: {
        input: {
          store_order_id: order.id,
          is_scheduled: isScheduled,
          pickup_time: moment(scheduledTime).valueOf(),
        },
      },
    });

    if (response.data.update_pickup_time.status === "200") {
      setIsEditing(false);
      showToast({
        description: "Pickup Time Updated",
        variant: "success",
        seconds: 2,
        onClose: () => {},
      });
    } else {
      showToast({
        description: "Invalid Pickup Time",
        variant: "error",
        seconds: 2,
        onClose: () => {},
      });
    }
  };

  const isLKDeliveryOrder = getIsLKDeliveryOrder(order);

  return (
    <div className="flex flex-col justify-end items-center">
      <div className="flex">
        <div className="mr-2 w-28">
          <select
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-xs w-full border-gray-300 rounded-md h-8 p-2"
            value={isScheduled}
            onChange={handleScheduledChange}
          >
            <option value="true">Scheduled</option>
            <option value="false">ASAP</option>
          </select>
        </div>
        <div className="mr-2">
          <input
            disabled={!isScheduled}
            type="datetime-local"
            className={`shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-xs w-full border-gray-300 rounded-md p-2 h-8 ${
              !isScheduled && "bg-gray-300"
            }`}
            value={scheduledTime}
            onChange={handleTimeChange}
          />
        </div>
        <div>
          <Button onClick={saveOrderUpdate} className="mr-2">
            <FontAwesomeIcon icon={faCheck} />
          </Button>
          <Button
            onClick={() => setIsEditing(false)}
            backgroundColor="bg-red-500"
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
      </div>
      {isLKDeliveryOrder && (
        <div className="w-full flex justify-center items-center text-sm mt-6 bg-yellow-50 rounded-md p-4">
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">
              Changing the pickup time? Remember to:
            </h3>
            <ul className="mt-2 text-sm text-yellow-700 list-disc pl-5 space-y-1">
              <li>
                <p>Update the delivery time in Doordash Drive.</p>
                <DoordashDriveLink cartID={order.external_id} />
              </li>
              <li>
                <p>Contact the kitchen to tell them about the change.</p>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export const OrderDetail = () => {
  const { orderId } = useParams<{ orderId: any }>();
  const [showModal, setShowModal] = useState(false);
  const { user } = useAuth();
  const userRoles = user
    ? new Set(user["https://app.localkitchens.co/roles"])
    : new Set();
  const isEngineer = userRoles.has("ENGINEER");
  const isAdmin = userRoles.has("ADMIN");
  const history = useHistory();

  const [showCateringOrderModal, setShowCateringOrderModal] = useState(false);
  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);

  const [storeOrderIssue, setStoreOrderIssue] = useState<StoreOrderIssue>();
  const defaultSteps = [
    {
      name: "Created",
      description: "",
      href: "#",
      status: "pending",
      key: "created",
    },
    {
      name: "Accepted",
      description: "",
      href: "#",
      status: "pending",
      key: "accepted",
    },
    {
      name: "Cooking",
      description: "",
      href: "#",
      status: "pending",
      key: "cooking",
    },
    {
      name: "Item Marked Ready",
      description: "",
      href: "#",
      status: "pending",
      key: "ready",
    },
    {
      name: "First Item Ready",
      description: "",
      href: "#",
      status: "pending",
      key: "first_item_ready",
    },
    {
      name: "Last Item Ready",
      description: "",
      href: "#",
      status: "pending",
      key: "last_item_ready",
    },
    {
      name: "Order Ready",
      description: "",
      href: "#",
      status: "pending",
      key: "order_ready",
    },
    {
      name: "Completed",
      description: "",
      href: "#",
      status: "pending",
      key: "complete",
    },
  ];
  const [isEditing, setIsEditing] = useState(false);
  const [steps, setSteps] = useState<any>(defaultSteps);
  const [showFraudModal, setShowFraudModal] = useState(false);

  const eventOrder: Record<string, any> = {
    created: 1,
    accepted: 2,
    cooking: 3,
    ready: 4,
    first_item_ready: 5,
    last_item_ready: 6,
    order_ready: 7,
    complete: 8,
  };

  const { data: orderData, loading } = useSaleById({
    id: parseInt(orderId, 10),
  });

  const { refunds } = useRelatedRefunds(
    orderData?.sale_by_id?.stripe_payment_intent_id,
  );

  const refundText = useMemo(() => {
    return refunds.map((refund: any) => {
      const dateString = new Date(parseInt(refund.date)).toLocaleDateString();
      return (
        <p className="mt-2">{`${dateString}: Refunded $${
          refund.amount / 100
        }`}</p>
      );
    });
  }, [refunds]);

  const { handleRecycleOrder, loading: loadingRecycleOrder } =
    useRecycleOrderMutation();
  const { showToast } = useContext(ToastContext);
  const [cancelOrder] = useCancelOrder();

  const onRecycleOrder = useCallback(async () => {
    const cartId = orderData?.sale_by_id?.external_id;
    handleRecycleOrder(cartId)
      .then((res) => {
        const orderId = res.data?.recycle_order.id;
        history.push(`/orders/${orderId}`);
      })
      .catch(() => {
        showToast({
          variant: "error",
          description: "Could not recycle order",
          seconds: 2,
        });
      });
  }, [handleRecycleOrder, orderData]);

  const onCancelOrder = useCallback(async () => {
    try {
      await cancelOrder({
        variables: { id: orderData?.sale_by_id?.id },
      });
      showToast({
        description: "Order has been cancelled",
        seconds: 2,
        variant: "success",
        onClose: () => {},
      });
    } catch (err) {
      showToast({
        description: "Sorry! Order could not be cancelled",
        seconds: 2,
        variant: "error",
        onClose: () => {},
      });
    }
    setShowCancelOrderModal(false);
  }, [cancelOrder, orderData]);

  const { data: orderEventsData, loading: orderEventsLoading } = useOrderEvents(
    {
      external_id: orderData?.sale_by_id?.external_id,
    },
  );
  const { cateringOrder, loading: cateringOrderLoading } = useCateringOrder(
    orderData?.sale_by_id?.external_id,
  );

  const hasInvoiceAttached = useMemo(() => {
    return !!cateringOrder;
  }, [cateringOrder]);

  const sortedEvents = (orderEventsData: Array<OrderEvent>) => {
    if (!orderEventsData) return [];
    const sortedEvents = [...orderEventsData].sort((eventA, eventB) => {
      const eventAName = eventA.name.split(".")[1];
      const eventBName = eventB.name.split(".")[1];

      if (eventOrder[eventAName] > eventOrder[eventBName]) {
        return 1;
      } else {
        return -1;
      }
    });

    return sortedEvents;
  };

  const sortEventsByInsertedAt = (orderEventsData: Array<OrderEvent>) => {
    return [...orderEventsData].sort((eventA, eventB) => {
      return eventA.inserted_at > eventB.inserted_at ? 1 : -1;
    });
  };

  const sortSteps = (steps: Array<Step>) => {
    if (!steps) return [];
    const sortedSteps = steps.sort((stepA, stepB) => {
      if (eventOrder[stepA.key] > eventOrder[stepB.key]) {
        return 1;
      } else {
        return -1;
      }
    });

    return sortedSteps;
  };

  /*
    . get events based on order events data keyed on external_id
    . filter for 'ready' events. if < 1, do nothing.
        else, take only first and last
        modify first and last key to be 'first_item_ready' and 'last_item_ready' respectively
        add back onto array of order events that have 'ready' events filtered out
    . sort events based on status in fixed status order const arr
    . get last event key (ie: 'created', 'complete'), find in const arr to get indx
    . take indx and slice remaining needed stages to display from const and concat to sorted events
  */

  useEffect(() => {
    const newSteps = [];
    let kdsEventsCount = 0;
    let lastEventName = "";
    if (orderEventsData) {
      let events = orderEventsData?.order_events;
      if (events?.length > 0) {
        const kdsReadyEvents = events?.filter(
          (event: OrderEvent) =>
            event.name === "orders.ready" && event.provider === "kds",
        );

        kdsEventsCount = kdsReadyEvents.length;

        if (kdsEventsCount > 1) {
          const sortedReady = sortEventsByInsertedAt(kdsReadyEvents);
          const firstElem = sortedReady[0];
          const lastElem = sortedReady[kdsEventsCount - 1];

          const modFirstElem = { ...firstElem, name: "order.first_item_ready" };
          const modLastElem = { ...lastElem, name: "order.last_item_ready" };

          events = Object.assign([], events);
          events.push(modFirstElem, modLastElem);
        }
      }

      const sortedEventsArr = sortedEvents(events);
      lastEventName = sortedEventsArr[sortedEventsArr.length - 1]?.name;

      const seenSet = new Set();
      for (let i = 0; i < sortedEventsArr?.length; i++) {
        let sortedEvent = sortedEventsArr[i];

        const currEventName = sortedEvent.name.split(".")[1];
        seenSet.add("ready");
        const struct = steps.find((step: Step) => step.key === currEventName);
        if (struct) {
          const clone = { ...struct };

          // TODO add line item name to timestamp
          // const data = JSON.parse(sortedEvent.data);
          // clone.storeOrderLineItemPrepStationTicketId =
          //   sortedEvent?.provider === "kds" &&
          //   data?.store_order_line_item_prep_station_ticket_ids.length > 0 &&
          //   data?.store_order_line_item_prep_station_ticket_ids[0];

          if (
            sortedEvent.name === "orders.ready" &&
            sortedEvent.provider !== "kds"
          ) {
            clone.name = "Order Ready";
            clone.key = "order_ready";
          }

          clone.status = "complete";
          clone.description = {
            provider: sortedEvent.provider,
            timeStamp: dayjs
              .unix(sortedEventsArr[i].inserted_at / 1000)
              .format("llll"),
          };
          newSteps.push(clone);
        }

        seenSet.add(currEventName);
      }
      // mutate status based on order.ready count
      // get ind of obj with specific key
      let pos = defaultSteps
        .map((step) => step.key)
        .indexOf(lastEventName?.split(".")[1]);
      // let upcomingStatuses = defaultSteps.slice(seenSet.size + kdsEventsCount);
      let upcomingStatuses = defaultSteps.slice(pos + 1);

      // if completed, filter out unused first_item_ready/last_item_ready defaults
      if (kdsEventsCount === 1 || lastEventName !== "order.completed") {
        upcomingStatuses = upcomingStatuses.filter(
          (status) => status.key !== "first_item_ready",
        );
        upcomingStatuses = upcomingStatuses.filter(
          (status) => status.key !== "last_item_ready",
        );
      }

      let displaySteps = newSteps.concat(upcomingStatuses);
      if (kdsEventsCount > 1) {
        displaySteps = displaySteps.filter((step) => step.key !== "ready");
      }
      const sortedDisplaySteps = sortSteps(displaySteps);

      displaySteps.length > 0 && setSteps(sortedDisplaySteps);
    }
  }, [orderEventsData]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/reduce
  const groupByBrand = (line_items: any) => {
    return line_items.reduce((acc: any, obj: any) => {
      const key = obj.brand.name;
      const curGroup = acc[key] ?? [];

      return { ...acc, [key]: [...curGroup, obj] };
    }, {});
  };

  const onCreateIssue = useCreateIssue();
  const onUpdateIssue = useUpdateIssue();
  const { createCateringOrderMutation, loading: createCateringOrderLoading } =
    useCreateCateringOrder();
  const { updateCateringOrderMutation, loading: updateCateringOrderLoading } =
    useUpdateCateringOrder();

  if (loading || orderEventsLoading) {
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <Loading />
      </div>
    );
  } else {
    const order = orderData?.sale_by_id;
    const brandToLineItems = groupByBrand(order.line_items);
    const canEditPickupTime = getCanEditPickupTime(order);

    return (
      <>
        <main className="flex-1 relative focus:outline-none">
          <div className="mx-auto px-4 sm:px-6 md:px-8 py-6 bg-white">
            <div className="flex items-center justify-between text-2xl leading-6 font-medium text-gray-900">
              <p className="text-3xl font-semibold text-gray-900">
                ORDER ID: {order?.id} | Ticket #: {order?.ticket_counter}
              </p>
              <div className="flex-col">
                {isEngineer &&
                order?.provider?.id === GUEST_KIOSK_PROVIDER_ID ? (
                  <button
                    disabled={loadingRecycleOrder}
                    onClick={onRecycleOrder}
                    className="hover:opacity-80 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 ml-4"
                  >
                    {loadingRecycleOrder ? "Recycling..." : "Recycle Order"}
                  </button>
                ) : null}
                {isAdmin && order?.status !== "CANCELED" ? (
                  <button
                    onClick={() => {
                      setShowCancelOrderModal(true);
                    }}
                    className="hover:opacity-80 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 ml-4"
                  >
                    Cancel Order
                  </button>
                ) : null}
                <button
                  className="hover:opacity-80 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 ml-4"
                  onClick={() => {
                    setShowModal(true);
                    setStoreOrderIssue(undefined);
                  }}
                >
                  Submit an Issue
                </button>
                {order?.provider?.revenue_center === "CATERING" && (
                  <button
                    className="hover:opacity-80 inline-flex justify-center py-2 px-4 border-2 border-lfg-secondary shadow-sm text-sm font-medium rounded-md text-primary bg-white ml-4"
                    onClick={() => {
                      setShowCateringOrderModal(true);
                    }}
                  >
                    {hasInvoiceAttached ? "Edit invoice" : "Attach invoice"}
                  </button>
                )}
              </div>
            </div>
            <div className="flex flex-col text-lg text-gray-600 mt-5">
              <div className="flex justify-between">
                <div className="flex-1">
                  <p className="text-xl border-b text-black border-gray-400 font-semibold">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <span className="ml-2">Details</span>
                  </p>
                  <div className="mt-5">
                    <div className="flex items-center justify-between font-semibold text-gray-900">
                      <div className="flex items-center">
                        <div>
                          {order?.customer_id ? (
                            <Link to={`/loyalty/${order.customer_id}`}>
                              <span>{order.customer_name}</span>
                              <FontAwesomeIcon
                                className="ml-2 cursor-pointer"
                                icon={faUserTag}
                              />
                            </Link>
                          ) : (
                            <span className="text-3xl font-semibold text-gray-900">
                              {order.customer_name}
                            </span>
                          )}
                        </div>
                        {order?.is_pickup ? (
                          <ShoppingBagIcon className="ml-2 h-6 w-6" />
                        ) : (
                          <FontAwesomeIcon className="ml-2" icon={faCar} />
                        )}
                      </div>
                      <div>
                        {order?.customer_id ? (
                          <Link to={`/loyalty/${order.customer_id}`}>
                            <span>{order?.customer_name}</span>
                            <FontAwesomeIcon
                              className="ml-2 cursor-pointer"
                              icon={faUserTag}
                            />
                          </Link>
                        ) : (
                          ""
                        )}
                        {isRefundableSale(order) &&
                          (refunds.length ? (
                            <div
                              onClick={() => {
                                analytics.track("Viewed Refund Warning", {
                                  stripePaymentIntentId:
                                    order.stripe_payment_intent_id,
                                  refunds,
                                });
                                setShowFraudModal(true);
                              }}
                              className="ml-4 cursor-pointer hover:opacity-80 hover:text-white inline-flex justify-center items-center py-2 px-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-400"
                            >
                              Refund{" "}
                              <ShieldExclamationIcon className="h-5 ml-2" />
                            </div>
                          ) : (
                            <a
                              onClick={(event: any) => event.stopPropagation()}
                              target="_blank"
                              rel="noreferrer"
                              href={`https://dashboard.stripe.com/payments/${order.stripe_payment_intent_id}`}
                              className="ml-4 cursor-pointer hover:opacity-80 hover:text-white inline-flex justify-center py-2 px-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-400"
                            >
                              Refund
                            </a>
                          ))}
                      </div>
                    </div>
                    <table className="mt-4 w-full">
                      <tbody>
                        <tr className="flex justify-between mt-2">
                          <td>Location:</td>
                          <td className="font-medium text-black">
                            {order?.location?.name}
                          </td>
                        </tr>
                        <tr className="flex justify-between mt-2">
                          <td>Phone:</td>
                          <td className="font-medium text-black">
                            {order?.customer_phone ??
                              order?.customer?.phone_number}
                          </td>
                        </tr>
                        {order?.provider?.revenue_center === "CATERING" && (
                          <tr className="flex justify-between mt-2">
                            <td>
                              Catering Subtotal:{" "}
                              <InformationPopover information="Actual charged subtotal for catering order" />
                            </td>
                            <td>
                              <CateringAdjustmentUI order={order} />
                            </td>
                          </tr>
                        )}
                        <tr className="flex justify-between mt-2">
                          <td>Provider:</td>
                          <td className="font-medium text-black">
                            {order?.provider?.name}
                          </td>
                        </tr>
                        <tr className="flex justify-between mt-2">
                          <td>Current Status:</td>
                          <td className="font-medium text-black">
                            {order?.status}
                          </td>
                        </tr>
                        {order?.provider?.id !== 1 && (
                          <tr className="flex justify-between mt-2">
                            <td>External ID:</td>
                            <td className="font-medium text-black">
                              {["localkitchens", "mobile"].includes(
                                order?.provider?.slug,
                              ) ? (
                                <a
                                  target="_blank"
                                  className="font-medium text-black cursor-pointer"
                                  href={`${process.env.REACT_APP_GUEST_WEB_URL}/store/${order?.location?.slug}/order/${order.external_id}`}
                                >
                                  {order.external_id}
                                  <FontAwesomeIcon
                                    className="ml-2"
                                    icon={faExternalLinkAlt}
                                  />
                                </a>
                              ) : (
                                <span>{order.external_id}</span>
                              )}
                            </td>
                          </tr>
                        )}
                        {order?.customer_name?.includes("TEST_ORDER") &&
                        window.location.href.includes("sandbox") ? (
                          <tr className="flex justify-between mt-2">
                            <td>Easter Egg 🐰🎁:</td>
                            <td className="font-medium text-black">
                              <span>
                                Use promo{" "}
                                <span className="font-bold text-green">
                                  LKDEV
                                </span>{" "}
                                for 30% off.
                              </span>
                            </td>
                          </tr>
                        ) : null}
                        {
                          // only show for DD orders
                          order?.provider?.id === 1 && (
                            <tr className="flex justify-between mt-2">
                              <td>Doordash ID:</td>
                              <td className="font-medium text-black">
                                {order?.external_delivery_id}
                              </td>
                            </tr>
                          )
                        }
                        <tr className="flex justify-between mt-2">
                          <td>Pickup Time:</td>
                          <td>
                            {isEditing ? (
                              <div>
                                <EditOrderSection
                                  order={order}
                                  setIsEditing={setIsEditing}
                                />
                              </div>
                            ) : (
                              <div className="flex flex-col justify-end">
                                <p className="font-medium text-black">
                                  {order.is_scheduled ? `Scheduled` : "ASAP"}
                                  &nbsp;
                                  <span>
                                    {`(${moment
                                      .unix(order.pickup_time / 1000)
                                      .format("YYYY-MM-DD hh:mmA")})`}
                                  </span>
                                  <Button
                                    className="ml-4"
                                    onClick={handleEditClick}
                                    disabled={!canEditPickupTime}
                                    backgroundColor={
                                      canEditPickupTime
                                        ? "bg-lfg-primary cursor-pointer"
                                        : "bg-gray-200 hover:opacity-100 cursor-not-allowed"
                                    }
                                  >
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                  </Button>
                                </p>
                              </div>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="ml-10 flex-1">
                  <p className="mb-7 text-xl border-b text-black border-gray-400 font-semibold">
                    <FontAwesomeIcon icon={faClock} />
                    <span className="ml-2">Timeline</span>
                  </p>
                  <OrderDetailTracker steps={steps} />
                </div>
              </div>
              <div className="mt-5">
                <p className="text-xl border-b text-black border-gray-400 font-semibold">
                  <FontAwesomeIcon icon={faReceipt} />
                  <span className="ml-2">Ticket Summary</span>
                </p>
                {ticketSummary(order)}
                {order?.provider?.revenue_center === "CATERING" &&
                  order?.catering_adjustment !== null && (
                    <>
                      <div className="mt-4 text-lg">
                        <div className="flex justify-between">
                          <p className="font-bold">Catering Adjustment</p>
                          <p className="font-bold text-black">
                            {usdFormatter.format(
                              (order?.catering_adjustment?.adjusted_subtotal -
                                order.subtotal) /
                                100,
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="mt-4 text-lg">
                        <div className="flex justify-between">
                          <p className="font-bold">Catering Subtotal </p>
                          <p className="font-bold text-black border-t-2 border-black">
                            {usdFormatter.format(
                              order?.catering_adjustment?.adjusted_subtotal /
                                100,
                            )}
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                <div className="mt-5 flex">
                  <div className="flex items-center">
                    <FontAwesomeIcon icon={faShoppingCart} />
                    <p className="ml-3 text-xl font-medium">Items</p>
                  </div>
                </div>
                {Object.entries(brandToLineItems).map(
                  ([brand, lineItems]: [String, any]) => {
                    return (
                      <>
                        <div className="mt-4 font-bold text-black">{brand}</div>
                        {lineItems.map((lineItem: any) => (
                          <div className="mt-1 text-lg" key={lineItem.id}>
                            <div className="flex justify-between">
                              <p>
                                <span className="font-bold mr-2">
                                  {lineItem.quantity}x
                                </span>{" "}
                                {lineItem.name}
                              </p>
                              <p className="font-bold text-black">
                                {usdFormatter.format(
                                  (lineItem.price * lineItem.quantity) / 100,
                                )}
                              </p>
                            </div>
                            <div>
                              {lineItem.modifier_items.map(
                                (modifierItem: any) => (
                                  <ModifierItem
                                    modifierItem={modifierItem}
                                    key={modifierItem.id}
                                  />
                                ),
                              )}
                            </div>
                          </div>
                        ))}
                      </>
                    );
                  },
                )}
              </div>
              <div className="mt-5">
                <p className="text-xl border-b text-black border-gray-400 font-semibold">
                  <FontAwesomeIcon icon={faAppleAlt} />
                  <span className="ml-2">Food Issues</span>
                </p>
                {order?.store_order_issues.length === 0 && (
                  <div className="flex justify-center items-center h-40">
                    <p className="font-bold text-3xl">
                      🎉 Yay! No issues for this order!
                    </p>
                  </div>
                )}
                {order?.store_order_issues.length > 0 && (
                  <div className="mt-5">
                    <Table
                      headers={[
                        "Issue Categories",
                        "Channel",
                        "Guest Feedback",
                        "Line Items",
                        "Comment",
                        "Resolution",
                        "Submitted by",
                      ]}
                    >
                      {order?.store_order_issues.map(
                        (issue: StoreOrderIssue, index: number) => (
                          <TableRow
                            key={index}
                            onClick={() => {
                              setStoreOrderIssue(issue);
                              setShowModal(true);
                            }}
                          >
                            <TableData>
                              {textLimiter(
                                issue.issue_categories
                                  .map(
                                    (category: IssueCategory) => category.name,
                                  )
                                  .join(", "),
                                25,
                              )}
                            </TableData>
                            <TableData>
                              {issue.guest_feedback_channel.name}
                            </TableData>
                            <TableData>
                              {textLimiter(issue.guest_feedback, 25)}
                            </TableData>
                            <TableData>
                              {issue.store_order_line_item_issues
                                .map(
                                  (lineItem: StoreOrderLineItemIssue) =>
                                    lineItem.store_order_line_item_name,
                                )
                                .join(", ")}
                            </TableData>
                            <TableData>
                              {textLimiter(issue.comment, 15)}
                            </TableData>
                            <TableData>{issue.resolution}</TableData>
                            <TableData>{issue.submitted_by}</TableData>
                          </TableRow>
                        ),
                      )}
                    </Table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </main>
        {showModal && (
          <Modal
            showModal={showModal}
            setShowModal={setShowModal}
            title={storeOrderIssue ? "Issue Details" : "Submit an Issue"}
          >
            <div className="mt-3">
              <IssueForm
                order={order}
                setShowModal={setShowModal}
                onSubmit={storeOrderIssue ? onUpdateIssue : onCreateIssue}
                storeOrderIssue={storeOrderIssue}
              />
            </div>
          </Modal>
        )}
        {showCateringOrderModal && (
          <Modal
            showModal={showCateringOrderModal}
            setShowModal={setShowCateringOrderModal}
            title={hasInvoiceAttached ? "Edit invoice" : "Attach invoice"}
          >
            <div className="mt-3">
              <CateringOrderForm
                order={order}
                setShowModal={setShowCateringOrderModal}
                onSubmit={
                  cateringOrder
                    ? updateCateringOrderMutation
                    : createCateringOrderMutation
                }
                cateringOrder={cateringOrder}
                loading={
                  createCateringOrderLoading || updateCateringOrderLoading
                }
              />
            </div>
          </Modal>
        )}
        <ConfirmationModal
          show={showCancelOrderModal}
          onCancel={() => setShowCancelOrderModal(false)}
          onConfirm={onCancelOrder}
          header="Cancel this order?"
          message="For orders close to their pickup time, you should reach out to
          the kitchen to confirm that they have stopped preparing the order.
          For orders that have been placed on DoorDash and UberEats, you will need to
          also cancel the order in the respective merchant platform in addition to here."
          confirmMessage="Cancel Order"
          cancelMessage="No, go back"
          hasConfirm
          hasCancel
        ></ConfirmationModal>
        <ConfirmationModal
          header="Related refunds"
          message="This order has been flagged as high risk for refunds. The order is attached to previous refunds, either related to the order itself or from the same email, payment method, or Stripe customer ID. This may be a low LTV guest or a fraudulent refund request."
          show={showFraudModal}
          confirmMessage="Acknowledge and continue"
          onConfirm={() => {
            analytics.track("Continued to High Risk Refund", {
              stripePaymentIntentId: order.stripe_payment_intent_id,
              refunds,
            });
            window.open(
              `https://dashboard.stripe.com/payments/${order.stripe_payment_intent_id}`,
            );
          }}
          hasConfirm
          hasCancel
          onCancel={() => setShowFraudModal(false)}
        >
          <div className="text-sm text-gray-500">
            <p className="mt-4">{refundText}</p>
            <p className="mt-4">
              You may want to report this in{" "}
              <a
                className="font-medium"
                href="https://localfoodgroup.slack.com/archives/C02S92GN48G"
              >
                #engineering-gx-help
              </a>{" "}
              or to your team.
            </p>
          </div>
        </ConfirmationModal>
      </>
    );
  }
};
