import { XMarkIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { MenuItem } from "../../../types";

type SearchBarProps = {
  placeholder: string;
  onChange?: (value: number) => void;
  data: MenuItem[];
  fetchPairings: (menuItedId: number) => void;
};

type EventProps = {
  target: {
    value: string;
  };
};

export const SearchBar = ({
  placeholder,
  data,
  onChange,
  fetchPairings,
}: SearchBarProps) => {
  const [filteredData, setFilteredData] = useState<MenuItem[]>([]);
  const [wordEntered, setWordEntered] = useState("");

  const handleFilter = (event: EventProps) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);
    const newFilter = data.filter((value: MenuItem) => {
      return value.name.toLowerCase().includes(searchWord.toLowerCase());
    });

    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }
  };

  const clearInput = () => {
    setFilteredData([]);
    setWordEntered("");
  };

  return (
    <div className="">
      <div className="flex flex-row mt-4">
        <div className="w-full">
          <input
            type="text"
            placeholder={placeholder}
            value={wordEntered}
            onChange={handleFilter}
            className="w-full"
          />
        </div>
        <div className="mt-2 ml-4">
          <XMarkIcon className="h-6" onClick={clearInput} />
        </div>
      </div>
      {filteredData.length !== 0 && (
        <div className="mt-4">
          {filteredData.slice(0, 8).map((value) => {
            return (
              <div
                className="w-[60%] mt-4 cursor-pointer"
                onClick={() => {
                  fetchPairings(value.id);
                  onChange?.(value.id);
                }}
              >
                <div className="ml-2">
                  <p>{value.name}</p>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
