import { PageContent } from "../../ui/components/PageContent/PageContent";
import { CustomHoursCalendar } from "./CustomHoursCalendar";

interface Props {}
export const CustomHours = (props: Props) => {
  return (
    <PageContent>
      <h1 className="text-3xl font-semibold text-gray-900 mr-3">
        Custom Hours
      </h1>
      <CustomHoursCalendar />
    </PageContent>
  );
};
