import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PeekImageView } from "./PeekImageView";

interface PeekPreviewProps {
  buildGridImgLink?: string;
  finalPlatedImgLink?: string;
  packagingImgLink?: string;
  closePeek: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const PeekPreview = ({
  buildGridImgLink,
  finalPlatedImgLink,
  packagingImgLink,
  closePeek,
}: PeekPreviewProps) => {
  return (
    <div className="h-full z-50 py-4 min-h-fit mt-[-121px]">
      <div className="flex flex-col w-full h-full bg-black rounded-xl border-2 border-gray-700 p-4">
        <div className="flex items-center justify-between h-[75px]">
          <div className="flex align-top flex-row items-center justify-end w-full">
            <div className="flex align-top flex-row items-center gap-4">
              <h3 className="h-full font-bold text-[36px] font-inter text-gray-200 ml-4 leading-[46px]">
                Peek
              </h3>
              <button
                onClick={(e) => closePeek(e)}
                className="flex h-[75px] items-center rounded-xl border-2 bg-black border-gray-700 px-2 py-2 text-2xl text-gray-200 font-inter font-semibold"
              >
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
            </div>
          </div>
        </div>

        {buildGridImgLink || finalPlatedImgLink ? (
          <div className="flex bg-black w-[calc(100vw-6rem)] pt-4 h-full justify-between">
            <div className="w-5/12">
              {buildGridImgLink && (
                <PeekImageView buildGridImage={buildGridImgLink || undefined} />
              )}
            </div>
            <div className="w-5/12">
              {finalPlatedImgLink && packagingImgLink && (
                <PeekImageView
                  finalPlatedImage={finalPlatedImgLink || undefined}
                  packagingImage={packagingImgLink || undefined}
                />
              )}
            </div>
          </div>
        ) : (
          // render packaging image if buildGridImgLink and finalPlatedImgLink are not available
          <div>
            <h1 className="text-white text-center text-lg">
              Build Grid and Final Plate images have not been uploaded.
              Displaying default images
            </h1>
            <div className="flex bg-black w-[calc(100vw-6rem)] pt-4 h-full justify-between">
              <div className="w-5/12">
                {<PeekImageView buildGridImage={packagingImgLink} />}
              </div>
              <div className="w-5/12">
                {
                  <PeekImageView
                    finalPlatedImage={packagingImgLink || undefined}
                    packagingImage={packagingImgLink || undefined}
                  />
                }
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PeekPreview;
