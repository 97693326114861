import { useState } from "react";
import useQueryParams from "../../utils/hooks/useQueryParams";
import { Employees } from "./Employees";
import { useListEmployees } from "./hooks/useListEmployees";

export const defaultFilters = (queryParams?: any) => {
  const queryParamOrDefault = (param: string) => {
    return queryParams?.get(param) || "";
  };

  return {
    firstName: queryParamOrDefault("firstName"),
    lastName: queryParamOrDefault("lastName"),
    email: queryParamOrDefault("email"),
    pinCode: queryParamOrDefault("pinCode"),
  };
};

export const EmployeesController = () => {
  const limit = 20;
  const [pageNumber, setPageNumber] = useState(1);
  const { params: queryParams } = useQueryParams();

  const [filterParams, setFilterParams] = useState(defaultFilters(queryParams));
  const { data } = useListEmployees({
    pageNumber,
    limit,
    ...filterParams,
  });

  return (
    <Employees
      employees={data?.employees?.employees}
      limit={limit}
      pageNumber={pageNumber}
      totalEmployees={data?.employees?.total_employees_count}
      setPageNumber={setPageNumber}
      setFilterParams={setFilterParams}
    />
  );
};
