import { gql, useMutation } from "@apollo/client";

const DELETE_GUEST_ACCOUNT_MUTATION = gql`
  mutation DeleteGuestAccountMutation(
    $input: DeleteGuestAccountInput
    $auth0_user_id: String
  ) {
    delete_guest_account(input: $input, auth0_user_id: $auth0_user_id) {
      success
    }
  }
`;

export const useDeleteGuestAccount = () => {
  const [deleteGuestAccount, { loading, error }] = useMutation(
    DELETE_GUEST_ACCOUNT_MUTATION,
  );

  return { deleteGuestAccount, loading, error };
};
