import { gql, useMutation } from "@apollo/client";
import { OPERATIONS_BRAND_QUERY } from "./useOperationsBrand";

const MENU_ITEM_BUILD_NOTES = gql`
  mutation MenuItemBuildNotes($input: BuildNotesInput) {
    create_or_update_menu_item_build_notes(input: $input) {
      id
      menu_item_id
      name
      modifier_list_id_order_override
      removal_modifier_id
      show_in_kds
      show_in_receipt
    }
  }
`;

export type CreateOrUpdateBuildNotesProps = {
  id: number | null;
  menu_item_id: number;
  name: string;
  modifier_list_id_order_override: number;
  removal_modifier_id: number | null;
  show_in_kds: boolean;
  show_in_receipt: boolean;
  custom_chit: any;
};

export const useCreateOrUpdateBuildNotesMutation = (brandSlug?: string) => {
  const [createOrUpdateBuildNotes, { loading }] = useMutation(
    MENU_ITEM_BUILD_NOTES,
  );

  const onCreateOrUpdateBuildNotes = async ({
    id,
    menu_item_id,
    name,
    modifier_list_id_order_override,
    removal_modifier_id,
    show_in_kds,
    show_in_receipt,
    custom_chit,
  }: CreateOrUpdateBuildNotesProps) => {
    return await createOrUpdateBuildNotes({
      variables: {
        input: {
          id,
          menu_item_id,
          name,
          modifier_list_id_order_override,
          removal_modifier_id,
          show_in_kds,
          show_in_receipt,
          custom_chit,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: OPERATIONS_BRAND_QUERY,
          variables: {
            input: {
              brand_slug: brandSlug,
            },
          },
        },
      ],
    });
  };

  return { onCreateOrUpdateBuildNotes, loading };
};
