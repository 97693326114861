import {
  ChevronDownIcon,
  ChevronUpIcon,
  PhotoIcon,
} from "@heroicons/react/24/solid";
import { useState } from "react";
import classNames from "../../../utils/classNames";

export interface ItemsContainerProps {
  title: string;
  items?: {
    id: number;
    name: string;
    image_url?: string | null;
  }[];
  shouldCollapse?: boolean;
  showImages?: boolean;
}

export const ItemsContainer = ({
  items = [],
  title,
  shouldCollapse = false,
  showImages = true,
}: ItemsContainerProps) => {
  const [isOpen, setIsOpen] = useState(!shouldCollapse);

  const toggleOpenItems = () => {
    setIsOpen((prev) => !prev);
  };

  const imageHidderClass = showImages ? "" : "hidden";

  return (
    <div className="bg-white shadow-md rounded p-2 flex flex-col w-full">
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-xl">{title}</h2>
        {isOpen ? (
          <ChevronUpIcon className="h-5 w-5" onClick={toggleOpenItems} />
        ) : (
          <ChevronDownIcon className="h-5 w-5" onClick={toggleOpenItems} />
        )}
      </div>

      <div className="flex flex-wrap gap-2">
        {isOpen && items?.length === 0 && (
          <div className="text-center">No items</div>
        )}

        {isOpen &&
          items &&
          items.map((item) => {
            return (
              <div
                key={item.id}
                role={"presentation"}
                className="border rounded border-gray-600 flex flex-col w-32 h-fit relative justify-end"
              >
                {item.image_url ? (
                  <img
                    src={item.image_url?.replace(".webp", "-320w.webp")}
                    className={classNames(
                      "object-cover w-32 h-32",
                      imageHidderClass,
                    )}
                    alt="item images"
                  />
                ) : (
                  <div
                    className={classNames(
                      "w-32 h-32 flex items-center justify-center",
                      imageHidderClass,
                    )}
                  >
                    <PhotoIcon className="w-5 h-5" />
                  </div>
                )}

                <div className="bg-gray-600 opacity-75 overflow-hidden">
                  <p className="text-white text-nowrap">{`${item.name}`}</p>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
