import { gql, useMutation } from "@apollo/client";

const CREATE_PRINTER = gql`
  mutation CreatePrinter($external_id: String, $location_id: Int) {
    create_printer(external_id: $external_id, location_id: $location_id) {
      external_id
      location_id
    }
  }
`;

export const useCreatePrinter = () => {
  const [createPrinter] = useMutation(CREATE_PRINTER, {
    refetchQueries: ["PRINTERS_BY_LOCATION_ID_QUERY"],
  });

  return createPrinter;
};
