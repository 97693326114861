import { useOperationsBrands } from "../KitchenNames/hooks/useOperationsBrands";
import { useAllStores } from "./hooks/useAllStores";
import useSyncEvents from "./hooks/useSyncEvents";
import { Menus } from "./Menus";
import { useListLocationsQuery } from "opsapi/hooks/operations/locations";

export const MenusController = () => {
  const { data: storesData, loading: storesLoading } = useAllStores();
  const { data: locations, isLoading: locationsLoading } =
    useListLocationsQuery({});
  const { data: syncEventData, loading: syncDataLoading } = useSyncEvents();
  const { data: operationsBrandsData, loading: operationsBrandsLoading } =
    useOperationsBrands();

  return (
    <Menus
      stores={storesData?.all_stores}
      locations={locations}
      syncEvents={syncEventData?.menu_sync_events}
      operationBrands={operationsBrandsData?.operations_brands}
      loading={
        storesLoading ||
        locationsLoading ||
        syncDataLoading ||
        operationsBrandsLoading
      }
    />
  );
};
