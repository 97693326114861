import { useListCategoriesQuery } from "opsapi/hooks/operations/categories";

import { useListItemsQuery } from "opsapi/hooks/operations/items";
import Loader from "../../../../ui/components/Loader/Loader";
import { PageContent } from "../../../../ui/components/PageContent/PageContent";
import { ItemsContainer } from "../../_components/ItemsContainer";

export const ListCategories = () => {
  const { data: categories, isLoading: isCategoriesLoading } =
    useListCategoriesQuery();

  const { data: items, isLoading: isItemsLoading } = useListItemsQuery();

  if (isCategoriesLoading || isItemsLoading) {
    return <Loader loading={true} />;
  }

  if (!categories) {
    return <div>No categories found</div>;
  }

  if (!items) {
    return <div>No items found</div>;
  }

  return (
    <PageContent>
      <div className="flex justify-between mb-4 items-center">
        <h1 className="text-3xl font-semibold text-gray-900">Categories</h1>
      </div>

      <div className="flex flex-col w-full h-full gap-2">
        {categories.map((category) => (
          <ItemsContainer
            key={category.id}
            items={items.filter((item) => item.category?.id === category.id)}
            title={category.name}
            shouldCollapse={false}
          />
        ))}
      </div>
    </PageContent>
  );
};
