import { ApolloError, gql, useQuery } from "@apollo/client";
import { PublicMenuItem } from "../../../../../types";

const OPERATIONS_MENU_ITEMS_QUERY = gql`
  query OperationsMenuItemsByLocation($location_id: Int!) {
    operations_menu_items(location_id: $location_id) {
      id
      name
      image_url
      blurhash
    }
  }
`;

export const useOperationMenuItems = (
  location_id: number,
): {
  data: PublicMenuItem[];
  loading: boolean;
  error?: ApolloError;
} => {
  const { data, error, loading } = useQuery(OPERATIONS_MENU_ITEMS_QUERY, {
    variables: {
      location_id,
    },
    skip: !location_id,
  });

  return { data: data?.operations_menu_items || [], error, loading };
};
