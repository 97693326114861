import { Dispatch, SetStateAction, useState } from "react";
import { defaultFilters } from "../../utils/defaultFilters";

import { Button } from "../../ui";
import useQueryParams from "../../utils/hooks/useQueryParams";
type FilterFormProps = {
  setPageNumber: Dispatch<SetStateAction<number>>;
  setFilterParams: Dispatch<SetStateAction<any>>;
};

export const EmployeeFilterForm = ({
  setPageNumber,
  setFilterParams,
}: FilterFormProps) => {
  const { params, setQueryParam, resetQueryParams } = useQueryParams();
  const filters = ["firstName", "lastName", "email", "pinCode"];
  const [{ firstName, lastName, email, pinCode }, setFilters] = useState(
    defaultFilters(params, filters),
  );

  const handleFilterChanges = (event: any) => {
    const { name, value } = event.target;
    setFilters((previousFilters) => ({ ...previousFilters, [name]: value }));
    setPageNumber(1);
    setFilterParams((previousFilters: any) => ({
      ...previousFilters,
      [name]: value,
    }));
    setQueryParam(name, value);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setPageNumber(1);
    setFilterParams({
      firstName,
      lastName,
      email,
      pinCode,
    });
  };

  const handleReset = (event: any) => {
    event.preventDefault();
    setPageNumber(1);
    setFilters(defaultFilters(null, filters));
    setFilterParams(defaultFilters(null, filters));
    resetQueryParams();
  };

  const formData = [
    {
      label: "First Name",
      name: "firstName",
      value: firstName,
    },
    {
      label: "Last Name",
      name: "lastName",
      value: lastName,
    },
    {
      label: "Email",
      name: "email",
      value: email,
    },
    {
      label: "Pin Code",
      name: "pinCode",
      value: pinCode,
    },
  ];

  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-8 divide-y divide-gray-200 ">
        <div className="grid grid-cols-5 gap-y-6 gap-x-4">
          {formData.map(({ label, name, value }, i) => (
            <div key={i}>
              <label
                htmlFor={name}
                className="block text-sm font-medium text-gray-700"
              >
                {label}
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name={name}
                  id={name}
                  autoComplete={name}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  value={value}
                  onChange={handleFilterChanges}
                />
              </div>
            </div>
          ))}
          <div className="flex justify-end items-center gap-4">
            <Button
              type="button"
              className="bg-white border border-gray-500"
              textColor="text-gray-700"
              onClick={(event: any) => handleReset(event)}
            >
              Reset
            </Button>
            <Button type="submit">Filter</Button>
          </div>
        </div>
      </div>
    </form>
  );
};
