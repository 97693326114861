import React from "react";

interface Props {
  status: string;
}

export const BrandStatusTag = ({ status }: Props) => {
  const color =
    status === "Active" ? "green" : status === "Inactive" ? "gray" : "yellow";
  return (
    <span
      className={`inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-${color}-100 text-${color}-800 border-${color}-800 border`}
    >
      {status}
    </span>
  );
};
