import { useUpdateLocationQuery } from "opsapi/hooks/operations/locations";
import { useContext } from "react";
import { useHistory, useParams } from "react-router";
import { PageContent } from "../../../ui/components/PageContent/PageContent";
import ToastContext from "../../../utils/contexts/ToastContext";
import { useTrack } from "../../../utils/hooks/useTrack";
import { LocationForm } from "./LocationForm";
import useAllLocationDetails from "./hooks/useAllLocationDetails";

interface Props {}

export const EditLocation = (props: Props) => {
  const { locationSlug } = useParams<{ locationSlug: string }>();
  const { data, loading } = useAllLocationDetails(locationSlug);
  const history = useHistory();
  const { track } = useTrack();
  const { showToast } = useContext(ToastContext);
  const { mutate: updateLocation } = useUpdateLocationQuery({
    params: { path: { slug: locationSlug } },
    reactQuery: {
      onSuccess: () => {
        showToast({
          description: `Submitted successfully`,
          variant: "success",
          seconds: 2,
        });
      },
      onError: (error) => {
        showToast({
          description: error.message,
          variant: "error",
          seconds: 10,
        });
      },
    },
  });

  const onSubmit = async (updateData: any) => {
    track({
      event: "Location Update Attempted",
      properties: {
        location: { ...data?.all_location_details },
        updateData,
      },
    });

    // Use the mutation function to update the location
    updateLocation(updateData);
  };

  if (loading) {
    return null;
  }
  return (
    <PageContent>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 flex items-center justify-between my-5">
        <div className="flex items-center">
          <h1 className="text-3xl font-semibold text-gray-900 mr-3">
            Editing {data?.all_location_details?.name}
          </h1>
        </div>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <LocationForm
          onSubmit={onSubmit}
          location={data?.all_location_details}
        />
      </div>
    </PageContent>
  );
};
