import { useQuery, gql } from "@apollo/client";

const LOCATION_ACCOUNTS_BY_LOCATION_ID_QUERY = gql`
  query LOCATION_ACCOUNTS_BY_LOCATION_ID_QUERY($location_id: Int) {
    location_accounts(location_id: $location_id) {
      id
      email
    }
  }
`;

export const useLocationAccountsByLocationID = (locationId: number) => {
  const { data, error, loading, refetch } = useQuery(
    LOCATION_ACCOUNTS_BY_LOCATION_ID_QUERY,
    {
      variables: { location_id: locationId },
    },
  );

  return { data, error, loading, refetch };
};
