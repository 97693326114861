import { useQuery, gql } from "@apollo/client";

export const OPERATIONS_GUEST_FEEDBACK_CHANNELS = gql`
  query OPERATIONS_GUEST_FEEDBACK_CHANNELS {
    guest_feedback_channels {
      id
      name
    }
  }
`;

export const useGuestFeedbackChannels = () => {
  const { data, error, loading, refetch } = useQuery(
    OPERATIONS_GUEST_FEEDBACK_CHANNELS,
    {
      fetchPolicy: "cache-and-network",
    },
  );
  return { data, error, loading, refetch };
};
