import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { Dispatch, SetStateAction, useContext } from "react";
import { Button, Modal } from "../../ui";
import ToastContext from "../../utils/contexts/ToastContext";
import usePermanentlyDeactivateStore from "./hooks/usePermanentlyDeactivateStore";

interface Props {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  store: any;
}

export const PermanentlyDeactivateModal = ({
  showModal,
  setShowModal,
  store,
}: Props) => {
  const { permanently_deactivate_store } = usePermanentlyDeactivateStore();
  const { showToast } = useContext(ToastContext);

  const onDeactivateHandler = async () => {
    try {
      await permanently_deactivate_store({
        variables: {
          store_id: store.id,
        },
      });
      setShowModal(false);
      showToast({
        description: "Store was successfully deactivated.",
        variant: "success",
        seconds: 2,
        onClose: () => {},
      });
    } catch (err) {
      showToast({
        description: "Store could not be deactivated.",
        variant: "error",
        seconds: 2,
        onClose: () => {},
      });
    }
  };

  return (
    <Modal
      title="Are you sure?"
      showModal={showModal}
      setShowModal={setShowModal}
    >
      <div className="my-5">
        <div className="rounded-md bg-yellow-50 p-4 my-5">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon
                className="h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-yellow-800">
                Local Kitchens Only
              </h3>
              <div className="mt-2 text-sm text-yellow-700">
                <p>
                  This will only deacitvate on Local Kitchens sites. If you want
                  to also deactivate for third parties, please contact
                  operations@localfoodgroup.com
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p>
            Are you sure you want to deactivate? This will make the brand
            disappear from the location's page on our website.
          </p>
        </div>
        <div className="mt-5">
          <Button className="mr-2" onClick={onDeactivateHandler}>
            Deactivate
          </Button>
          <Button
            type="button"
            backgroundColor="bg-gray-500"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};
