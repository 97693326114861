import { gql, useQuery } from "@apollo/client";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { BrandType, Media, OperationsBrand } from "../../graphql/types";
import { FormInput, FormLabel, FormTextarea } from "../../ui";
import { ConfirmationModal } from "../../ui/ConfirmationModal";
import {
  FormOption,
  FormSelect,
  Modal,
  Table,
  TableData,
  TableRow,
} from "../../ui/components";
import { Button } from "../../ui/components/Button/Button";
import ItemImage from "../../ui/components/Image/ItemImage";
import { Loading } from "../../ui/components/Loading";
import MultimediaDropZone from "../../ui/components/MultimediaDropZone";
import { PageContent } from "../../ui/components/PageContent/PageContent";
import ToastContext from "../../utils/contexts/ToastContext";
import convertToBase64 from "../../utils/convertToBase64";
import { getExtension, getResponsiveImageUrl } from "../../utils/formatters";
import FileDropZone from "../KitchenNames/components/FileDropZone";
import { useCreateBrand } from "./hooks/useCreateBrand";
import { useDeleteBrand } from "./hooks/useDeleteBrand";
import { useUpdateBrand } from "./hooks/useUpdateBrand";

const BRANDS_QUERY = gql`
  query BrandsQuery {
    operations_brands {
      id
      name
      ticker
      slug
      logo_url
      logo_blurhash
      food_hero_image_url
      food_hero_image_blurhash
      cuisine
      short_cuisine
      about
      tagline
      feature_header
      feature_body
      feature_image {
        id
        url
        blurhash
      }
      typ
      gallery_media {
        ... on Image {
          id
          url
          blurhash
        }
      }
      about_media {
        ... on Image {
          id
          url
          blurhash
        }
      }
      url
    }
  }
`;

export const Brands = () => {
  const { data } =
    useQuery<{ operations_brands: OperationsBrand[] }>(BRANDS_QUERY);

  const updateBrandMutation = useUpdateBrand();
  const createBrandMutation = useCreateBrand();
  const deleteBrandMutation = useDeleteBrand();

  const [showCreateBrandModal, setShowCreateBrandModal] =
    useState<boolean>(false);

  const onUpdateBrand = async (data: any) => {
    const { brand_id, ...brandUpdateData } = data;
    await updateBrandMutation({
      variables: {
        id: brand_id,
        update_brand_input: brandUpdateData,
      },
      refetchQueries: [{ query: BRANDS_QUERY }],
    });
  };

  const onDeleteBrand = async (data: any) => {
    const { id } = data;

    await deleteBrandMutation({
      variables: {
        id,
      },
      refetchQueries: [{ query: BRANDS_QUERY }],
    });
  };

  const onCreateBrand = async (data: any) => {
    await createBrandMutation({
      variables: {
        create_brand_input: data,
      },
      refetchQueries: [{ query: BRANDS_QUERY }],
    });
  };

  return (
    <PageContent>
      <div className="flex justify-between">
        <h1 className="text-xl font-semibold text-gray-900">BRANDS</h1>
        <Button
          className="flex items-center"
          type="button"
          onClick={() => setShowCreateBrandModal(true)}
        >
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          Create Brand
        </Button>
      </div>
      <BrandTableSection
        onUpdateBrand={onUpdateBrand}
        onDeleteBrand={onDeleteBrand}
      />
      <CreateBrandModal
        showModal={showCreateBrandModal}
        setShowModal={setShowCreateBrandModal}
        onCreateBrand={onCreateBrand}
      />
    </PageContent>
  );
};

type BrandTableSectionProps = {
  onUpdateBrand: any;
  onDeleteBrand: any;
};

export default function BrandTableSection({
  onUpdateBrand,
  onDeleteBrand,
}: BrandTableSectionProps) {
  const { data } =
    useQuery<{ operations_brands: OperationsBrand[] }>(BRANDS_QUERY);

  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [brandToDelete, setBrandToDelete] = useState<any>(null);
  const [brandToUpdate, setBrandToUpdate] = useState<any>(null);

  const clickUpdateButton = (brand: any) => {
    setBrandToUpdate({
      ...brand,
    });
    setShowUpdateModal(true);
  };

  const brands = data?.operations_brands || [];
  const tableHeaders = ["Name", "Slug", "Ticker", "Cuisine", "Header Image"];

  return (
    <>
      <Table headers={tableHeaders}>
        {brands.map((brand, index) => {
          const logoExtension = getExtension(brand.logo_url) || "jpg";
          const heroExtension =
            getExtension(brand.food_hero_image_url) || "jpg";
          return (
            <TableRow
              rowColor={index % 2 ? "bg-white" : "bg-gray-50"}
              onClick={(e: any) => {
                e.stopPropagation();
                clickUpdateButton(brand);
              }}
              key={index + 1}
            >
              <TableData className="flex items-center">
                {brand.logo_url ? (
                  <ItemImage
                    className="w-8 truncate text-ellipsis mr-2"
                    url={getResponsiveImageUrl(brand?.logo_url, "320w")}
                  />
                ) : null}
                <p className="w-36 truncate text-ellipsis">{brand.name}</p>
              </TableData>
              <TableData>
                <p className="w-36 truncate text-ellipsis">{brand.slug}</p>
              </TableData>
              <TableData>
                <p className="w-14 truncate text-ellipsis">{brand.ticker}</p>
              </TableData>
              <TableData>
                <p className="w-36 truncate text-ellipsis">{brand.cuisine}</p>
              </TableData>

              <TableData className="object-cover h-10">
                {brand.food_hero_image_url ? (
                  <ItemImage
                    className="truncate text-ellipsis mr-2 object-cover h-10 w-full min-w-[100px]"
                    url={getResponsiveImageUrl(
                      brand?.food_hero_image_url,
                      "320w",
                    )}
                  />
                ) : null}
              </TableData>
            </TableRow>
          );
        })}
      </Table>
      <UpdateBrandModal
        showModal={showUpdateModal}
        setShowModal={setShowUpdateModal}
        brandToUpdate={brandToUpdate}
        onUpdateBrand={onUpdateBrand}
      />
      <ConfirmationModal
        header="Confirm delete"
        message="Are you sure you want to delete this brand?"
        show={showConfirmDelete}
        confirmMessage="Delete"
        onConfirm={() => {
          onDeleteBrand(brandToDelete);
          setBrandToDelete(null);
          setShowConfirmDelete(false);
        }}
        hasConfirm
        hasCancel
        onCancel={() => {
          setBrandToDelete(null);
          setShowConfirmDelete(false);
        }}
      />
    </>
  );
}

type BrandFormProps = {
  onSubmit: any;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  brand?: OperationsBrand | null;
};

const BrandForm = ({ onSubmit, setShowModal, brand }: BrandFormProps) => {
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
  } = useForm();

  const { showToast } = useContext(ToastContext);

  const watchLogoUrl = watch("logo_url");
  const watchHeroImageUrl = watch("food_hero_image_url");
  const watchFeatureImageUrl = watch("feature_image_url");

  const onSubmitHandler = async (data: any) => {
    setLoading(true);
    const transformedData = {
      brand_id: brand ? brand.id : undefined,
      name: data.name,
      ticker: data.ticker,
      logo:
        data.logo_url && typeof data.logo_url === "object"
          ? await convertToBase64(data.logo_url)
          : undefined,
      logo_url: brand?.logo_url,
      logo_blurhash: brand?.logo_blurhash,
      food_hero_image:
        data.food_hero_image_url && typeof data.food_hero_image_url === "object"
          ? await convertToBase64(data.food_hero_image_url)
          : undefined,
      food_hero_image_url: brand?.food_hero_image_url,
      food_hero_image_blurhash: brand?.food_hero_image_blurhash,
      about: data.about,
      cuisine: data.cuisine,
      short_cuisine: data.short_cuisine,
      tagline: data.tagline,
      feature_header: data.feature_header,
      feature_body: data.feature_body,
      typ: data.typ,
      gallery_media_ids: data.gallery_media.map((m: Media) => m.id),
      about_media_ids: data.about_media.map((m: Media) => m.id),
      feature_image:
        data.feature_image_url && typeof data.feature_image_url === "object"
          ? await convertToBase64(data.feature_image_url)
          : undefined,
      url: data.url ?? undefined,
    };

    try {
      await onSubmit(transformedData);
      showToast({
        description: "Brand was updated",
        variant: "success",
        seconds: 2,
        onClose: () => {},
      });

      setShowModal(false);
    } catch (err) {
      showToast({
        description: "Brand could not be updated.",
        variant: "error",
        seconds: 2,
        onClose: () => {},
      });
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)} className="relative">
      {loading && (
        <div className="bg-gray-100 bg-opacity-50 w-full h-full absolute items-center flex rounded-lg">
          <Loading />
        </div>
      )}
      <div className="grid grid-cols-3 gap-6 my-5">
        <div className="col-start-1">
          <FormLabel title="Name" htmlFor="name" information="Brand's name" />
          <Controller
            name="name"
            control={control}
            defaultValue={brand ? brand.name : ""}
            rules={{ required: true }}
            render={({ field }) => (
              <FormInput type="text" id="name" {...field} />
            )}
          />
        </div>
        <div>
          <FormLabel title="Ticker" htmlFor="ticker" information="Ticker" />
          <Controller
            name="ticker"
            control={control}
            defaultValue={brand ? brand?.ticker : ""}
            rules={{ required: false }}
            render={({ field }) => (
              <FormInput type="text" id="ticker" {...field} />
            )}
          />
        </div>
        <div>
          <FormLabel
            title="Slug"
            htmlFor="slug"
            information="Slug will be set automatically based on the name of the Brand."
          />
          <Controller
            name="slug"
            control={control}
            defaultValue={brand ? brand.slug : ""}
            rules={{ required: false }}
            render={({ field }) => (
              <FormInput
                type="text"
                id="slug"
                {...field}
                disabled={true}
                className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-md sm:text-sm border-gray-300 bg-gray-100 cursor-not-allowed"
              />
            )}
          />
        </div>
        <div className="col-span-2">
          <FormLabel title="Cuisine" htmlFor="cuisine" information="Cuisine" />
          <Controller
            name="cuisine"
            control={control}
            defaultValue={brand && brand?.cuisine ? brand.cuisine : ""}
            rules={{ required: false }}
            render={({ field }) => (
              <FormInput type="text" id="cuisine" {...field} />
            )}
          />
        </div>
        <div>
          <FormLabel
            title="Short Cuisine"
            htmlFor="short_cuisine"
            information="Short cuisine"
          />
          <Controller
            name="short_cuisine"
            control={control}
            defaultValue={
              brand && brand?.short_cuisine ? brand.short_cuisine : ""
            }
            rules={{ required: false }}
            render={({ field }) => (
              <FormInput type="text" id="cuisine" {...field} />
            )}
          />
        </div>
        <div className="col-span-3">
          <FormLabel
            title="Discover More URL"
            htmlFor="url"
            information="Discover More URl"
          />
          <Controller
            name="url"
            control={control}
            defaultValue={brand?.url ?? ""}
            rules={{ required: false }}
            render={({ field }) => (
              <FormInput type="text" id="url" {...field} />
            )}
          />
        </div>
        <div>
          <FormLabel title="Tagline" htmlFor="tagline" information="Tagline" />
          <Controller
            name="tagline"
            control={control}
            defaultValue={brand && brand?.tagline ? brand.tagline : ""}
            rules={{ required: false }}
            render={({ field }) => (
              <FormInput type="text" id="tagline" {...field} />
            )}
          />
        </div>
        <div>
          <FormLabel
            title="Brand Type"
            htmlFor="typ"
            information="Is this brand a partnership or codeveloped?"
          />
          <Controller
            name="typ"
            control={control}
            rules={{ required: true }}
            defaultValue={brand?.typ ?? BrandType.Partnership}
            render={({ field }) => {
              return (
                <FormSelect {...field}>
                  {Object.entries(BrandType).map(([key, typ]) => (
                    <FormOption key={key} title={key} value={typ} />
                  ))}
                </FormSelect>
              );
            }}
          />
        </div>
        <div>
          <FormLabel
            title="Feature Header"
            htmlFor="feature_header"
            information="Feature Header"
          />
          <Controller
            name="feature_header"
            control={control}
            defaultValue={brand?.feature_header ?? ""}
            rules={{ required: false }}
            render={({ field }) => (
              <FormInput type="text" id="feature_header" {...field} />
            )}
          />
        </div>
        <div className="col-span-3">
          <FormLabel
            title="Feature Body"
            htmlFor="feature_body"
            information="Feature Body"
          />
          <Controller
            name="feature_body"
            control={control}
            defaultValue={
              brand && brand?.feature_body ? brand.feature_body : ""
            }
            rules={{ required: false }}
            render={({ field }) => (
              <FormTextarea id="feature_body" rows={3} {...field} />
            )}
          />
        </div>
        <div
          className={`${
            watchLogoUrl || brand?.logo_url ? "col-start-2" : "col-span-3"
          }`}
        >
          <FormLabel
            title="Feature Image"
            htmlFor="feature_image_url"
            information="Feature Image"
          />
          <Controller
            name="feature_image_url"
            control={control}
            defaultValue={brand ? brand?.feature_image?.url : ""}
            rules={{ required: false }}
            render={({ field }) => {
              const logoExtension =
                getExtension(brand?.feature_image?.url) || "jpg";
              return (
                <FileDropZone
                  className="h-40 text-center"
                  onChange={(files: File[]) => {
                    field.onChange(files?.[0]);
                  }}
                >
                  {brand?.feature_image?.url || watchFeatureImageUrl ? (
                    <img
                      src={
                        typeof watchFeatureImageUrl === "object"
                          ? URL.createObjectURL(watchFeatureImageUrl)
                          : brand?.feature_image?.url?.replace(
                              `.${logoExtension}`,
                              `-640w.${logoExtension}`,
                            )
                      }
                      className="h-40 object-contain"
                    />
                  ) : null}
                </FileDropZone>
              );
            }}
          />
        </div>
        <div className="col-span-3">
          <FormLabel
            title="Gallery Media"
            htmlFor="gallery_media"
            information="Media that appears at the header of the Brand Page"
          />
          <Controller
            name="gallery_media"
            control={control}
            defaultValue={brand?.gallery_media ?? []}
            render={({ field }) => <MultimediaDropZone {...field} />}
          />
        </div>
        <div className="col-span-3">
          <FormLabel title="About" htmlFor="about" information="About" />
          <Controller
            name="about"
            control={control}
            defaultValue={brand && brand?.about ? brand.about : ""}
            rules={{ required: false }}
            render={({ field }) => (
              <FormTextarea id="about" rows={5} {...field} />
            )}
          />
        </div>
        <div className="col-span-3">
          <FormLabel
            title="About Media"
            htmlFor="about_media"
            information="Media that appears next to the description"
          />
          <Controller
            name="about_media"
            control={control}
            defaultValue={brand?.about_media ?? []}
            render={({ field }) => <MultimediaDropZone {...field} />}
          />
        </div>
        <div
          className={`${
            watchLogoUrl || brand?.logo_url ? "col-start-2" : "col-span-3"
          }`}
        >
          <FormLabel
            title="Logo URL"
            htmlFor="logo_url"
            information="Logo URL"
          />
          <Controller
            name="logo_url"
            control={control}
            defaultValue={brand ? brand?.logo_url : ""}
            rules={{ required: false }}
            render={({ field }) => {
              const logoExtension = getExtension(brand?.logo_url) || "jpg";
              return (
                <FileDropZone
                  className="h-40 text-center"
                  onChange={(files: File[]) => {
                    field.onChange(files?.[0]);
                  }}
                >
                  {brand?.logo_url || watchLogoUrl ? (
                    <img
                      src={
                        typeof watchLogoUrl === "object"
                          ? URL.createObjectURL(watchLogoUrl)
                          : brand?.logo_url?.replace(
                              `.${logoExtension}`,
                              `-640w.${logoExtension}`,
                            )
                      }
                      className="h-40 object-contain"
                    />
                  ) : null}
                </FileDropZone>
              );
            }}
          />
        </div>
        <div className="col-span-3">
          <FormLabel
            title="Food Hero image URL"
            htmlFor="food_hero_image_url"
            information="Image Hero URL"
          />
          <Controller
            name="food_hero_image_url"
            control={control}
            defaultValue={brand ? brand?.food_hero_image_url : ""}
            rules={{ required: false }}
            render={({ field }) => {
              const heroExtension =
                getExtension(brand?.food_hero_image_url) || "jpg";
              return (
                <FileDropZone
                  className="h-40"
                  onChange={(files: File[]) => {
                    field.onChange(files?.[0]);
                  }}
                >
                  {brand?.food_hero_image_url || watchHeroImageUrl ? (
                    <img
                      src={
                        typeof watchHeroImageUrl === "object"
                          ? URL.createObjectURL(watchHeroImageUrl)
                          : brand?.food_hero_image_url?.replace(
                              `.${heroExtension}`,
                              `-640w.${heroExtension}`,
                            )
                      }
                      className="h-40 object-cover"
                    />
                  ) : null}
                </FileDropZone>
              );
            }}
          />
        </div>
      </div>
      <Button className="mr-3">Save</Button>
      <Button
        type="button"
        backgroundColor="bg-gray-500"
        onClick={() => {
          setShowModal(false);
        }}
      >
        Cancel
      </Button>
    </form>
  );
};

type CreateBrandModalProps = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  onCreateBrand: any;
};

const CreateBrandModal = ({
  showModal,
  setShowModal,
  onCreateBrand,
}: CreateBrandModalProps) => {
  return (
    <Modal title="New Brand" showModal={showModal} setShowModal={setShowModal}>
      <BrandForm
        onSubmit={onCreateBrand}
        setShowModal={setShowModal}
        brand={null}
      />
    </Modal>
  );
};

type UpdateBrandModalProps = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  onUpdateBrand?: any;
  brandToUpdate: any;
};

const UpdateBrandModal = ({
  showModal,
  setShowModal,
  onUpdateBrand,
  brandToUpdate,
}: UpdateBrandModalProps) => {
  return (
    <Modal title="Edit Brand" showModal={showModal} setShowModal={setShowModal}>
      <BrandForm
        onSubmit={onUpdateBrand}
        setShowModal={setShowModal}
        brand={brandToUpdate}
      />
    </Modal>
  );
};
