import { useMutation, gql } from "@apollo/client";

const OPERATIONS_CREATE_CATERING_ORDER = gql`
  mutation OPERATIONS_CREATE_CATERING_ORDER($input: CreateCateringOrderInput) {
    create_catering_order(input: $input) {
      id
      invoice_id
      external_id
      invoice_paid_at
      tax
      tax_rate
      tip
      tip_rate
      discount
      total
      subtotal
      customer_delivery_fee
      service_fee
      service_fee_rate
    }
  }
`;

export const useCreateCateringOrder = () => {
  const [createCateringOrderMutation, { loading }] = useMutation(
    OPERATIONS_CREATE_CATERING_ORDER,
  );

  return { createCateringOrderMutation, loading };
};
