import { Route, Switch, useRouteMatch } from "react-router";
import { ListModifiersLists } from "./pages/ListModifiersLists";

export const ModifiersListsController = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/`}>
        <ListModifiersLists />
      </Route>
    </Switch>
  );
};
