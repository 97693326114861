import { gql, useMutation } from "@apollo/client";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { XCircleIcon } from "@heroicons/react/24/solid";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { DndProvider, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import FileDropZone from "../../features/KitchenNames/components/FileDropZone";
import { Maybe, Media, Mutation } from "../../graphql/types";
import { Loading } from "../../ui/components/Loading";
import { DraggableItem } from "../../ui/components/SearchAndDrag/DraggableItem";
import { ItemTypes } from "../../ui/components/SearchAndDrag/SearchAndDrag";
import convertToBase64 from "../../utils/convertToBase64";

const UPLOAD_MEDIA_MUTATION = gql`
  mutation upload_media($input: UploadMediaInput!) {
    upload_media(input: $input) {
      ... on Image {
        id
        blurhash
        url
      }
    }
  }
`;

type Payload = {
  base64: string;
  type: string;
  name: string;
};

type MediaAndOrPayload = [Maybe<Media>, Maybe<Payload>];

type Props = {
  onChange: (media: Media[]) => void;
  value: Media[];
};

function MultimediaDropZone({ onChange, value }: Props) {
  const uploadedFilenames = useRef(new Set());
  const [, drop] = useDrop({ accept: ItemTypes.selectedItem });
  const [uploadMedia] = useMutation<Pick<Mutation, "upload_media">>(
    UPLOAD_MEDIA_MUTATION,
  );

  const [mediaAndPayloads, setMediaAndPayloads] = useState<MediaAndOrPayload[]>(
    value.map((media) => [media, undefined]),
  );

  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    onChange(
      mediaAndPayloads.filter((mp) => mp != null).map(([media]) => media!),
    );
  }, [mediaAndPayloads]);

  async function handleFileDrop(files: File[]) {
    setIsUploading(true);

    const filesToUpload = files.filter(
      (file) => !uploadedFilenames.current.has(file.name),
    );

    const payloads = await Promise.all(
      filesToUpload.map(async (file) => {
        const base64 = await convertToBase64(file);

        return {
          base64: base64?.toString() ?? "",
          type: file.type,
          name: file.name,
        };
      }),
    );

    const uploadedMedia = await Promise.all(
      payloads.map(async (payload) => {
        return await uploadMedia({
          variables: {
            input: {
              content: payload.base64,
              mimeType: payload.type,
            },
          },
        });
      }),
    );

    payloads.forEach((payload) => uploadedFilenames.current.add(payload.name));

    setMediaAndPayloads((prev) => [
      ...prev,
      ..._.zip(
        uploadedMedia.map((p) => p.data?.upload_media),
        payloads,
      ),
    ]);

    setIsUploading(false);
  }

  function handleRemove(mediaToRemove: Maybe<Media>) {
    setMediaAndPayloads((prev) =>
      prev.filter(([media, _]) => media?.id !== mediaToRemove?.id),
    );
  }

  function handleMoveCard(dragIndex: number, hoverIndex: number) {
    setMediaAndPayloads((prev) => {
      const res = prev.map((c) => c);
      res.splice(dragIndex, 1);
      res.splice(hoverIndex, 0, prev[dragIndex]);
      return res;
    });
  }

  return (
    <div className="relative max-h-72 w-full bg-gray-50 border border-2 border-dashed border-lightgray rounded overflow-y-scroll">
      {isUploading && (
        <div className="w-full h-full bg-black opacity-20 absolute flex items-center justify-center z-50">
          <Loading />
        </div>
      )}
      <FileDropZone
        className="p-4 flex items-center !justify-start "
        multi={true}
        onChange={async (files) => await handleFileDrop(files)}
      >
        {mediaAndPayloads.length > 0 ? (
          <div className="flex flex-wrap gap-4 w-full" ref={drop}>
            {mediaAndPayloads.map(([media, payload], index) => {
              const renderItem = () => (
                <div className="relative w-full h-full group">
                  <div
                    className="absolute right-0 m-1 z-50 rounded-full bg-white invisible group-hover:visible"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemove(media);
                    }}
                  >
                    <XCircleIcon className="h-7 w-7 text-red-500" />
                  </div>
                  <img
                    src={
                      payload
                        ? payload.base64
                        : media?.url?.replace(`.webp`, `-640w.webp`)
                    }
                    className="object-cover w-full h-full"
                  />
                </div>
              );

              return (
                <DraggableItem
                  className="h-36 w-36 !m-0 rounded-lg overflow-hidden"
                  renderItem={renderItem}
                  moveCard={handleMoveCard}
                  item={{
                    id: index,
                  }}
                  index={index}
                  key={index}
                  isNew={false}
                  isPlaceholder={false}
                />
              );
            })}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center h-full">
            <FontAwesomeIcon
              className="cursor-pointer"
              icon={faUpload}
              size="4x"
              color="rgb(129, 140, 248)"
            />
            <p className="text-center mt-2">Drag and drop or browse file(s)</p>
          </div>
        )}
      </FileDropZone>
    </div>
  );
}

export default React.forwardRef((props: Props, _) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <MultimediaDropZone {...props} />
    </DndProvider>
  );
});
