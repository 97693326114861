import { useMemo } from "react";
import { useAllStores } from "../../Menus/hooks/useAllStores";

export const useStoresAtLocation = (location_id: number) => {
  const { data: allStoresData, loading: allStoresLoading } = useAllStores();

  return useMemo(() => {
    if (allStoresData) {
      return allStoresData.all_stores.filter(
        (item: any) => item.location.id === location_id,
      );
    }
    return [];
  }, [allStoresData]);
};
