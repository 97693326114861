import {
  IssueCategory,
  StoreOrderIssue,
  StoreOrderLineItemIssue,
} from "../types";

export const getStoreOrderIssueFields = (storeOrderIssue?: StoreOrderIssue) => {
  if (storeOrderIssue) {
    const categories = storeOrderIssue.issue_categories.map(
      (category: IssueCategory) => ({
        value: category.id,
        label: category.name,
      }),
    );
    const lineItems = storeOrderIssue.store_order_line_item_issues.map(
      (lineItem: StoreOrderLineItemIssue) => ({
        value: lineItem.store_order_line_item_id,
        label: lineItem.store_order_line_item_name,
      }),
    );
    const guestFeedbackChannel = {
      value: storeOrderIssue.guest_feedback_channel.id,
      label: storeOrderIssue.guest_feedback_channel.name,
    };
    const guestFeedback = storeOrderIssue.guest_feedback;
    const comment = storeOrderIssue.comment;
    const resolution = {
      value: storeOrderIssue.resolution,
      label: storeOrderIssue.resolution,
    };

    return [
      categories,
      lineItems,
      guestFeedbackChannel,
      guestFeedback,
      comment,
      resolution,
    ];
  }

  return [];
};
