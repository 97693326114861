import { gql, useQuery } from "@apollo/client";

export const MARKETING_BILLBOARDS_QUERY = gql`
  query MarketingBillboards {
    operations_marketing_billboards {
      id
      title
      subtitle
      description
      image {
        url
        blurhash
      }
      portrait_image {
        url
        blurhash
      }
      redirect {
        ... on MarketingBillboardBrandPageRedirect {
          page
          brand_slug
          button_label
        }
        ... on MarketingBillboardCustomRedirect {
          page
          url
          button_label
        }
      }
      locations {
        id
        name
        slug
      }
      platforms
      visibility
      background_color
      start_time
      end_time
      ordinal
    }
  }
`;

const useMarketingBillboards = () => {
  return useQuery(MARKETING_BILLBOARDS_QUERY, { fetchPolicy: "network-only" });
};

export default useMarketingBillboards;
