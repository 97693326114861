import { XCircleIcon } from "@heroicons/react/24/solid";
import { Identifier } from "dnd-core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDrop } from "react-dnd";
import { MenuItemPairing } from "../../../types";
import { Loading } from "../Loading";
import { DraggableItem } from "./DraggableItem";

export const ItemTypes = {
  selectedItem: "selected-item",
  availableItem: "available-item",
};

export interface SearchAndDragProps<ObjectType extends { id: number }> {
  allItems: ObjectType[];
  onChange: (val: number[]) => void;
  selectedValues: number[];
  onSearchQueryChange?: (query: string) => void;
  searchResults?: ObjectType[];
  isSearchLoading?: boolean;
  copyPairingsItems?: MenuItemPairing[];
  maximumItems?: number;
  renderItem: (
    item: ObjectType,
    isPlaceholder: boolean,
    isDragging: boolean,
  ) => React.ReactNode;
}

export const SearchAndDrag = <ObjectType extends { id: number }>({
  allItems,
  onChange,
  selectedValues = [],
  onSearchQueryChange,
  searchResults,
  isSearchLoading,
  renderItem,
  maximumItems,
  copyPairingsItems,
}: SearchAndDragProps<ObjectType>) => {
  const [searchQuery, setSearchQuery] = useState("");
  const passedCopyPairingsItems = copyPairingsItems?.map((item) => item.id);
  const [selectionIDs, setSelectionIDs] = useState<number[]>(selectedValues);
  const [selectedItems, setSelectedItems] = useState<
    (ObjectType & { isPlaceholder?: boolean })[]
  >([]);

  useEffect(() => {
    if (
      passedCopyPairingsItems &&
      passedCopyPairingsItems.toString() !== selectedValues.toString()
    ) {
      setSelectionIDs(passedCopyPairingsItems);
    }
  }, [passedCopyPairingsItems]);

  useEffect(() => {
    if (allItems?.length) {
      setSelectedItems(
        selectionIDs
          .map((id) => allItems?.find((item) => item.id === id))
          .filter((item): item is ObjectType => Boolean(item)),
      );
    }
  }, [setSelectedItems, selectionIDs, allItems]);

  useEffect(() => {
    if (selectionIDs.toString() !== selectedValues.toString()) {
      onChange(selectionIDs);
    }
  }, [onChange, selectionIDs]);

  useEffect(() => {
    onSearchQueryChange?.(searchQuery);
  }, [onSearchQueryChange, searchQuery]);

  const filteredItems = useMemo(() => {
    return searchResults?.filter((item) => !selectionIDs.includes(item.id));
  }, [searchResults, selectionIDs]);

  const handleAvailableItemClick = useCallback(
    (item: ObjectType) => {
      setSelectionIDs((current) => [...current, item.id]);
    },
    [setSelectionIDs],
  );

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setSelectedItems((prevCards) => {
        const res = prevCards.map((c) => c);
        res.splice(dragIndex, 1);
        res.splice(hoverIndex, 0, prevCards[dragIndex]);
        return res;
      });
    },
    [setSelectedItems],
  );

  const addCard = useCallback(
    (item: ObjectType, hoverIndex: number) => {
      setSelectedItems((prevCards) => {
        const res = prevCards.map((c) => c);
        res.splice(hoverIndex, 0, { ...item, isPlaceholder: true });
        return res;
      });
    },
    [setSelectedItems],
  );

  const removeItem = useCallback(
    (index: number) => {
      setSelectionIDs((prevCards) => {
        const res = prevCards.map((c) => c);
        res.splice(index, 1);
        return res;
      });
    },
    [setSelectionIDs],
  );

  const saveItems = useCallback(() => {
    setSelectionIDs(selectedItems.map((item) => item.id));
  }, [selectedItems]);

  const [{ isOver }, drop] = useDrop<
    ObjectType,
    void,
    { handlerId: Identifier | null; isOver: boolean }
  >({
    accept: ItemTypes.availableItem,
    drop: (item) => {
      handleAvailableItemClick(item);
    },
    canDrop: (item, monitor) => {
      if (maximumItems && selectionIDs.length >= maximumItems) {
        return false;
      }

      return !selectionIDs.includes(item.id);
    },
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: monitor.isOver(),
        didDrop: monitor.didDrop(),
      };
    },
  });

  return (
    <div>
      <div
        ref={drop}
        className={`flex w-100 min-h-[8rem] gap-4 border-2 border-dashed rounded p-4 my-4 z-10 ${
          selectedItems.length ? "flex-wrap" : "items-center justify-center"
        }
        ${isOver ? "relative" : ""}`}
      >
        {isOver ? (
          <div className="absolute w-full h-full bg-gray-50 top-0 left-0 z-50 bg-opacity-75 flex items-center justify-center">
            <p className="font-lfg-semibold text-xl">Drop Items here</p>
          </div>
        ) : null}
        {selectedItems.length ? (
          selectedItems?.map((item, index) => {
            return (
              <div className="relative group" key={item.id}>
                <div
                  className="absolute top-0 right-0 z-50 cursor-pointer rounded-full bg-white invisible group-hover:visible hover:scale-125"
                  onClick={() => removeItem(index)}
                >
                  <XCircleIcon className="h-7 w-7 text-red-500" />
                </div>

                <DraggableItem
                  item={item}
                  index={index}
                  moveCard={moveCard}
                  dropCard={saveItems}
                  isNew={false}
                  isPlaceholder={item?.isPlaceholder || false}
                  renderItem={renderItem}
                />
              </div>
            );
          })
        ) : (
          <div className="w-100 h-100">
            <p>No items</p>
          </div>
        )}
      </div>
      <div className="flex flex-col gap-2">
        <h3>Available items</h3>
        <input
          className={
            "flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-md sm:text-sm border-gray-300"
          }
          type="text"
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyDown={(e) => {
            // Intercept enter key and do nothing. Otherwise the form closes on enter.
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
          value={searchQuery}
          placeholder="Search..."
        />
      </div>
      <div className="flex flex-wrap gap-4 max-h-72 min-h-[18rem] overflow-y-auto border-2 border-dashed rounded p-4 my-4 relative">
        {filteredItems?.map((item, index) => {
          return (
            <DraggableItem
              key={`av-${item.id}-${index}`}
              item={item}
              index={index}
              addCard={addCard}
              isPlaceholder={false}
              renderItem={renderItem}
              isNew
            />
          );
        })}
        {!filteredItems?.length ? (
          <div className="absolute w-full h-full bg-gray-50 top-0 left-0 z-50 bg-opacity-75 flex items-center justify-center">
            {isSearchLoading ? (
              <Loading />
            ) : (
              <p className="font-lfg-semibold text-xl">No items</p>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};
