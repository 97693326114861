import { gql, useMutation } from "@apollo/client";

const CREATE_STORE = gql`
  mutation CreateStore(
    $location_id: Int
    $brand_id: Int
    $toast_menu_id: String
  ) {
    create_store(
      location_id: $location_id
      brand_id: $brand_id
      toast_menu_id: $toast_menu_id
    )
  }
`;

const useCreateStore = () => {
  const [createStore, { error }] = useMutation(CREATE_STORE, {
    refetchQueries: ["STORES_QUERY"],
  });
  return { createStore, error };
};

export default useCreateStore;
