import * as Types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BulkUpdateMenuEntitiesStatusQueryVariables = Types.Exact<{
  start_timestamp: Types.Scalars['String'];
}>;


export type BulkUpdateMenuEntitiesStatusQuery = { __typename?: 'Query', bulk_update_menu_entities_status?: boolean | undefined };


export const BulkUpdateMenuEntitiesStatusDocument = gql`
    query BulkUpdateMenuEntitiesStatus($start_timestamp: String!) {
  bulk_update_menu_entities_status(start_timestamp: $start_timestamp)
}
    `;

/**
 * __useBulkUpdateMenuEntitiesStatusQuery__
 *
 * To run a query within a React component, call `useBulkUpdateMenuEntitiesStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateMenuEntitiesStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBulkUpdateMenuEntitiesStatusQuery({
 *   variables: {
 *      start_timestamp: // value for 'start_timestamp'
 *   },
 * });
 */
export function useBulkUpdateMenuEntitiesStatusQuery(baseOptions: Apollo.QueryHookOptions<BulkUpdateMenuEntitiesStatusQuery, BulkUpdateMenuEntitiesStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BulkUpdateMenuEntitiesStatusQuery, BulkUpdateMenuEntitiesStatusQueryVariables>(BulkUpdateMenuEntitiesStatusDocument, options);
      }
export function useBulkUpdateMenuEntitiesStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BulkUpdateMenuEntitiesStatusQuery, BulkUpdateMenuEntitiesStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BulkUpdateMenuEntitiesStatusQuery, BulkUpdateMenuEntitiesStatusQueryVariables>(BulkUpdateMenuEntitiesStatusDocument, options);
        }
export type BulkUpdateMenuEntitiesStatusQueryHookResult = ReturnType<typeof useBulkUpdateMenuEntitiesStatusQuery>;
export type BulkUpdateMenuEntitiesStatusLazyQueryHookResult = ReturnType<typeof useBulkUpdateMenuEntitiesStatusLazyQuery>;
export type BulkUpdateMenuEntitiesStatusQueryResult = Apollo.QueryResult<BulkUpdateMenuEntitiesStatusQuery, BulkUpdateMenuEntitiesStatusQueryVariables>;