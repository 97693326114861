import React, {
  ReactElement,
  Dispatch,
  SetStateAction,
  useContext,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { FormInput, FormLabel, Modal } from "../../ui";
import { Button } from "../../ui/components/Button/Button";
import ToastContext from "../../utils/contexts/ToastContext";
import useUpdateStoreCredentials from "./hooks/useUpdateStoreCredentials";

interface Props {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  store?: any;
}

const filterEmptyValues = (obj: any) => {
  return Object.keys(obj)
    .filter((field: string) => obj[field] !== "" && obj[field] !== undefined)
    .reduce(
      (previousValue, currentValue) => ({
        ...previousValue,
        [currentValue]: obj[currentValue],
      }),
      {},
    );
};

export default function CredentialsModal({
  showModal,
  setShowModal,
  store,
}: Props): ReactElement {
  const { control, handleSubmit, register } = useForm();
  const { updateStoreCredentials } = useUpdateStoreCredentials();
  const { showToast } = useContext(ToastContext);

  const onSubmitHandler = async (data: any) => {
    try {
      const transformedData = {
        store_id: parseInt(data.store_id),
        ubereats_credentials: filterEmptyValues(data.ubereats_credentials),
      };
      await updateStoreCredentials({ variables: transformedData });
      showToast({
        description: "Credentials have been updated",
        variant: "success",
        seconds: 2,
        onClose: () => {},
      });
    } catch (err) {
      showToast({
        description: "There was an error",
        variant: "error",
        seconds: 2,
        onClose: () => {},
      });
    }
  };

  return (
    <Modal
      title="Update Credentials"
      showModal={showModal}
      setShowModal={setShowModal}
    >
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <p className="font-light">Any blank inputs will not be updated</p>
        <div className="my-5">
          <p className="font-bold text-lg">Uber Credentials</p>
          <div className="grid grid-cols-3 gap-6">
            <input type="hidden" {...register("store_id")} value={store.id} />
            <div>
              <FormLabel title="Email" htmlFor="" />
              <Controller
                name="ubereats_credentials.email"
                control={control}
                rules={{ required: false }}
                // defaultValue={store ? store.menus[0].ubereats_menu_id}
                render={({ field }) => <FormInput type="email" {...field} />}
              />
            </div>
            <div>
              <FormLabel title="Password" htmlFor="" />
              <Controller
                name="ubereats_credentials.password"
                control={control}
                rules={{ required: false }}
                // defaultValue={store ? store.menus[0].ubereats_menu_id}
                render={({ field }) => <FormInput type="password" {...field} />}
              />
            </div>
            <div>
              <FormLabel title="Cookie" htmlFor="" />
              <Controller
                name="ubereats_credentials.cookie"
                control={control}
                rules={{ required: false }}
                // defaultValue={store ? store.menus[0].ubereats_menu_id}
                render={({ field }) => <FormInput type="text" {...field} />}
              />
            </div>
          </div>
        </div>
        <Button>Save</Button>
      </form>
    </Modal>
  );
}
