import { useCallback, useMemo } from "react";

export const useTrack = () => {
  const track = useCallback(({ event, properties }: any) => {
    analytics.track(
      event,
      {
        ...(properties
          ? {
              ...properties,
            }
          : {}),
      },
      {},
    );
  }, []);

  const page = useCallback(({ name }: { name: string }) => {
    analytics.page(name, {}, {});
  }, []);

  return useMemo(() => ({ track, page }), [track, page]);
};
