import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useAuth } from "@localkitchens/passwordless-auth";
import { Fragment, useContext, useMemo, useState } from "react";
import { Loading } from "../../ui/components/Loading";
import ToastContext from "../../utils/contexts/ToastContext";
import { useDeleteGuestAccount } from "./hooks/useDeleteGuestAccount";

interface DangerZoneProps {
  customerId: number;
  customerEmail: string;
  auth0UserId: string;
  deletedAt?: string;
  handleClose: () => void;
}

export const DangerZone = ({
  customerId,
  customerEmail,
  auth0UserId,
  handleClose,
  deletedAt,
}: DangerZoneProps) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [email, setEmail] = useState("");
  const { deleteGuestAccount, error, loading } = useDeleteGuestAccount();
  const { user: currentUser } = useAuth();
  const { showToast } = useContext(ToastContext);

  const isDeleteEnabled = useMemo(
    () => email === customerEmail,
    [customerEmail, email],
  );

  const onDelete = () => {
    analytics.track("Guest Account Deleted", {
      operationsUserId: currentUser?.sub,
      guest: {
        id: customerId,
        email: customerEmail,
        auth0UserId: auth0UserId,
      },
    });
    deleteGuestAccount({
      variables: {
        auth0_user_id: currentUser?.sub,
        input: {
          email: customerEmail,
          auth0_user_id: auth0UserId,
        },
      },
    })
      .then(() => {
        setEmail("");
        showToast({
          description: "Guest Account Successfully Deleted!",
          variant: "success",
          seconds: 2,
          onClose: () => {},
        });

        setShowDeleteModal(false);
        handleClose();
      })
      .catch(() => {
        showToast({
          description: "Failed to Delete Guest Account!",
          variant: "error",
          seconds: 2,
          onClose: () => {},
        });
        setShowDeleteModal(false);
      });
  };

  return (
    <div className="flex flex-col">
      <span className="">
        Welcome to the{" "}
        <span
          onClick={() =>
            (window.location.href = "https://youtu.be/UVRUxtPKK-w?t=150")
          }
          className="cursor-pointer text-blue hover:opacity-80"
        >
          danger zone
        </span>
        .
      </span>

      <div className="flex items-center w-64 mt-8">
        {deletedAt ? (
          "Customer deleted."
        ) : auth0UserId ? (
          <div
            onClick={() => setShowDeleteModal(true)}
            className="flex justify-center items-center flex-row rounded-md px-8 py-4 cursor-pointer text-white bg-red-500 hover:opacity-80"
          >
            Delete Guest Account
          </div>
        ) : (
          "No Auth ID. Contact engineering to delete account."
        )}
      </div>

      <Transition.Root show={showDeleteModal} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
        >
          <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                <div>
                  <div className="flex w-full flex-row justify-between">
                    <Dialog.Title
                      as="h3"
                      className="flex items-start justify-between text-2xl leading-6 font-medium text-gray-900"
                    >
                      Delete Guest
                    </Dialog.Title>

                    <XMarkIcon
                      onClick={() => setShowDeleteModal(false)}
                      className="cursor-pointer h-8 w-8 -mt-2 -mr-2"
                      aria-hidden="true"
                    />
                  </div>
                </div>
                <div className="flex flex-col my-4 space-y-4">
                  <div>
                    Please enter the guest email address, {customerEmail} and
                    click "Permanently Delete."
                  </div>
                  <input
                    type="email"
                    name="delete-email"
                    autoComplete="off"
                    placeholder="Enter the guest email address to delete..."
                    className="bg-transparent rounded-sm focus:outline-none focus:ring-lfg-primary-lighten focus:ring-2"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                  <div className="flex items-center w-64 mt-8">
                    {loading ? (
                      <Loading />
                    ) : (
                      <div
                        onClick={() => (isDeleteEnabled ? onDelete() : null)}
                        className={`flex justify-center items-center flex-row rounded-md px-8 py-4 text-white bg-red-500 ${
                          isDeleteEnabled
                            ? "hover:opacity-80 cursor-pointer"
                            : "opacity-60 cursor-not-allowed"
                        }`}
                      >
                        Permanently Delete
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};
