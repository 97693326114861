import { Dialog, Transition } from "@headlessui/react";
import { PauseIcon, PlayIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useQueryClient } from "@tanstack/react-query";
import {
  Storefront3PIntegrationStateEnum,
  StorefrontIntegrationSchema,
} from "opsapi/hooks/operations/storefrontIntegrations";
import {
  StorefrontSchema,
  StorefrontStateEnum,
  VIEW_STOREFRONT,
  useUpdateStorefrontStateQuery,
} from "opsapi/hooks/operations/storefronts";
import { Fragment, useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, FormCheckbox, FormLabel } from "../../../ui";
import ToastContext from "../../../utils/contexts/ToastContext";

interface IntegrationsStorefrontStateUpdateDialogProps {
  showModal: boolean;
  hideModal: () => void;
  storefront: StorefrontSchema;
  integrations: StorefrontIntegrationSchema[];
}

export const IntegrationsStorefrontStateUpdateDialog = ({
  showModal,
  hideModal,
  storefront,
  integrations,
}: IntegrationsStorefrontStateUpdateDialogProps) => {
  const queryClient = useQueryClient();
  const { showToast } = useContext(ToastContext);
  const targetState = {
    [StorefrontStateEnum.active]: StorefrontStateEnum.deactivated,
    [StorefrontStateEnum.deactivated]: StorefrontStateEnum.active,
  }[storefront.state];

  const {
    mutate: updateStorefrontState,
    isPending: isUpdateStorefrontStatePending,
  } = useUpdateStorefrontStateQuery({
    params: { path: { slug: storefront.slug } },
  });

  const { control, handleSubmit } = useForm();

  const onSubmitTriggerIntegrationsHandler = async (data: any) => {
    updateStorefrontState(
      {
        state: targetState,
      },
      {
        onSuccess: () => {
          showToast({
            description: `Submitted successfully`,
            variant: "success",
            seconds: 2,
          });
          queryClient.invalidateQueries({
            queryKey: [VIEW_STOREFRONT, storefront.slug],
          });
        },
        onError: (error) => {
          showToast({
            description: error.message,
            variant: "error",
            seconds: 10,
          });
        },
      },
    );
  };

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={showModal}
        onClose={() => {}}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
              <div>
                <div className="flex w-full flex-row justify-between">
                  <Dialog.Title
                    as="h3"
                    className="flex items-start justify-between text-2xl leading-6 font-medium text-gray-900"
                  >
                    {targetState === StorefrontStateEnum.active && (
                      <p>Activate `{storefront.name}`</p>
                    )}
                    {targetState === StorefrontStateEnum.deactivated && (
                      <p>Deactivate `{storefront.name}`</p>
                    )}
                  </Dialog.Title>

                  <XMarkIcon
                    onClick={() => hideModal()}
                    className="cursor-pointer h-8 w-8 -mt-2 -mr-2"
                    aria-hidden="true"
                  />
                </div>
              </div>
              <div className="flex flex-col my-4 space-y-4">
                <p className="font-bold">
                  Note: This will trigger state updates for active integrations.
                </p>
                <form
                  onSubmit={handleSubmit(onSubmitTriggerIntegrationsHandler)}
                  className="relative"
                >
                  <div className="grid grid-cols-3 gap-6 my-5">
                    <div className="col-span-2 bg-gray-200 p-4">
                      <FormLabel
                        title="Selected Integrations"
                        htmlFor="integration_ids"
                        information={
                          "Selected integrations to trigger status update. Only active integrations are impacted."
                        }
                      />
                      <Controller
                        name="integration_ids"
                        control={control}
                        render={({ field }) => (
                          <>
                            {integrations.map((integration) => (
                              <div
                                key={integration.id}
                                className="flex items-center"
                              >
                                <FormCheckbox
                                  type="checkbox"
                                  {...field}
                                  checked={
                                    integration.state ===
                                    Storefront3PIntegrationStateEnum.active
                                  }
                                  className="h-4 w-4"
                                  value={integration.id}
                                  disabled={true}
                                />
                                <label className="ml-2" htmlFor="column-name">
                                  {integration.name}
                                </label>
                              </div>
                            ))}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  {targetState === StorefrontStateEnum.active && (
                    <Button
                      className="hover:bg-green-700 flex items-center my-2 gap-2"
                      backgroundColor="bg-green-500"
                      loading={isUpdateStorefrontStatePending}
                    >
                      <PlayIcon className="h-5 w-5" />
                      Activate
                    </Button>
                  )}
                  {targetState === StorefrontStateEnum.deactivated && (
                    <Button
                      className="hover:bg-orange-700 flex items-center my-2 gap-2"
                      backgroundColor="bg-orange-500"
                      loading={isUpdateStorefrontStatePending}
                    >
                      <PauseIcon className="h-5 w-5" />
                      Deactivate
                    </Button>
                  )}
                </form>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
