import { useAuth } from "@localkitchens/passwordless-auth";
import * as Sentry from "@sentry/react";
import { Dispatch, SetStateAction, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { BrandManagerController } from "./features/BrandManager/BrandManagerController";
import { CouponsController } from "./features/Coupons/CouponsController";
import { CustomHoursController } from "./features/CustomHours/CustomHoursController";
import { EmployeesController } from "./features/Employees/EmployeesController";
import { MarketingBillboards } from "./features/FeatureTile";
import { Home } from "./features/Home";
import { LoyaltyController } from "./features/Loyalty";
import { MenuManagerController } from "./features/MenuManager/MenuManagerController";
import { OrdersController } from "./features/Orders";
import { OrderDetail } from "./features/Orders/OrderDetail";
import { StorefrontsController } from "./features/Storefronts/StorefrontsController";
import { LocationsController as IntLocationsController } from "./features/Stores/LocationsController";
import { StoresController } from "./features/Stores/StoresController";
import { TrackState } from "./index";
import { Loading } from "./ui/components/Loading";
import { PageLayout } from "./ui/components/PageLayout";
import { Toast } from "./ui/components/Toast/Toast";
import ToastContext from "./utils/contexts/ToastContext";
import { useToast } from "./utils/hooks/useToast";
import { useTrack } from "./utils/hooks/useTrack";

interface AppProps {
  setTrackingHandler?: Dispatch<SetStateAction<TrackState>>;
}

const App = ({ setTrackingHandler }: AppProps) => {
  const { user, isAuthenticated, isLoading, login } = useAuth();
  const { track } = useTrack();
  const { clearToast, showToast, toastProps } = useToast();
  const userRoles = user
    ? new Set(user["https://app.localkitchens.co/roles"])
    : new Set();
  const isEngineer = userRoles.has("ENGINEER");
  const isKitchen = userRoles.has("KITCHEN");

  useEffect(() => {
    if (user?.email) {
      analytics.identify(user.email, {
        name: user.name,
        nickname: user.nickname,
        email: user.email,
        email_verified: user.email_verified,
        sub: user.sub,
      });

      Sentry.setUser({
        id: user.user_id,
        email: user.email,
        username: user.name,
      });
    }
  }, [user]);

  useEffect(() => {
    setTrackingHandler?.({ track });
  }, [user, isAuthenticated, setTrackingHandler, track]);

  if (isLoading) {
    return (
      <PageLayout isEngineer={isEngineer} isKitchen={isKitchen}>
        <Loading />
      </PageLayout>
    );
  }

  if (!isAuthenticated) {
    return (
      <div
        className="relative bg-blue-100 h-screen flex items-center"
        onClick={() => login()}
      >
        <main className="mx-auto max-w-7xl px-4 mb-48 z-50">
          <div className="text-center">
            <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <div className="mt-3 rounded-md sm:mt-0 sm:ml-3">
                <button className="bg-gray-700 text-2xl w-full flex items-center justify-center px-16 py-4 border-2 font-bold border-transparent rounded-md text-white hover:bg-opacity-80">
                  Login
                </button>
              </div>
            </div>
          </div>
        </main>
        <img
          className="w-full absolute bottom-0"
          src="https://lfg-landing.s3-us-west-2.amazonaws.com/sf.png"
          alt=""
        />
      </div>
    );
  }

  return (
    <PageLayout isEngineer={isEngineer} isKitchen={isKitchen}>
      <ToastContext.Provider value={{ showToast, clearToast }}>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/brands">
            <BrandManagerController />
          </Route>
          <Route exact path="/feature-tiles">
            <MarketingBillboards />
          </Route>
          <Route exact path="/coupons">
            <CouponsController />
          </Route>
          <Route path="/custom-hours">
            <CustomHoursController />
          </Route>
          <Route path="/locations">
            <IntLocationsController />
          </Route>
          <Route path="/storefronts">
            <StorefrontsController />
          </Route>
          <Route exact path={["/loyalty/:customerId", "/loyalty"]}>
            <LoyaltyController />
          </Route>
          <Route exact path="/menu-manager">
            <MenuManagerController />
          </Route>
          <Route exact path={"/orders"}>
            <OrdersController />
          </Route>
          <Route exact path={"/orders/:orderId"}>
            <OrderDetail />
          </Route>
          <Route path="/stores">
            <StoresController />
          </Route>
          <Route path="/employees">
            <EmployeesController />
          </Route>
          <Redirect to="/" />
        </Switch>
        <Toast {...toastProps} />
      </ToastContext.Provider>
    </PageLayout>
  );
};

export default App;
