import { gql, useMutation } from "@apollo/client";
import { MARKETING_BILLBOARDS_QUERY } from "./useMarketingBillboards";

const CREATE_MARKETING_BILLBOARD_QUERY = gql`
  mutation CreateMarketingBillboard(
    $create_marketing_billboard_input: CreateMarketingBillboardInput!
  ) {
    create_marketing_billboard(input: $create_marketing_billboard_input) {
      id
      title
      subtitle
      description
      image {
        url
        blurhash
      }
      redirect {
        ... on MarketingBillboardBrandPageRedirect {
          page
          brand_slug
          button_label
        }
        ... on MarketingBillboardCustomRedirect {
          page
          url
          button_label
        }
      }
      locations {
        id
        name
        slug
      }
      platforms
      visibility
      background_color
      start_time
      end_time
    }
  }
`;

export const useCreateMarketingBillboard = () => {
  return useMutation(CREATE_MARKETING_BILLBOARD_QUERY, {
    refetchQueries: [{ query: MARKETING_BILLBOARDS_QUERY }],
  });
};
