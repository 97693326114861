import moment from "moment";
import { useState } from "react";
import { OrderFilterFormParams } from "../../types";
import useQueryParams from "../../utils/hooks/useQueryParams";
import { Orders } from "./Orders";
import { useSales } from "./hooks/useSales";

export const defaultCalendarDates = () => {
  const today = moment(new Date());
  const startUnixDatetime = moment(today)
    .subtract(1, "months")
    .startOf("day")
    .valueOf();
  const endUnixDatetime = moment(today).endOf("day").valueOf();

  return [startUnixDatetime, endUnixDatetime];
};

export const defaultFilters = (queryParams?: any): OrderFilterFormParams => {
  const [startDate, endDate] = defaultCalendarDates();

  const queryParamOrDefault = (param: string) => {
    return queryParams?.get(param) || "";
  };

  return {
    id: queryParamOrDefault("id"),
    customerName: queryParamOrDefault("customerName"),
    email: queryParamOrDefault("email"),
    phoneNumber: queryParamOrDefault("phoneNumber"),
    locationID: queryParamOrDefault("locationID"),
    brandID: queryParamOrDefault("brandID"),
    providerID: queryParamOrDefault("providerID"),
    startDate,
    endDate,
    externalID: queryParamOrDefault("externalID"),
    ticketNumber: queryParamOrDefault("ticketNumber"),
  };
};

export const OrdersController = () => {
  // Set page limit to default to 15
  const limit = 15;
  const { params: queryParams } = useQueryParams();
  const [pageNumber, setPageNumber] = useState(1);
  const [filterParams, setFilterParams] = useState(defaultFilters(queryParams));

  const { data, loading } = useSales({
    pageNumber,
    limit,
    ...filterParams,
  });

  return (
    <Orders
      data={data}
      limit={limit}
      pageNumber={pageNumber}
      setPageNumber={setPageNumber}
      totalOrderCount={data?.sales[0]?.total_order_count}
      loading={loading}
      filterParams={filterParams}
      setFilterParams={setFilterParams}
    />
  );
};
