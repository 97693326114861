import { Transition } from "@headlessui/react";
import React from "react";

type ConfirmationModalProps = {
  header: string;
  message: string;
  show: boolean;
  onCloseComplete?: () => void;
  hasCancel?: boolean;
  cancelMessage?: string;
  onCancel?: () => void;
  hasConfirm?: boolean;
  confirmMessage?: string;
  onConfirm?: () => void;
  hasClose?: boolean;
  children?: React.ReactNode;
};

export const ConfirmationModal = ({
  header,
  message,
  show,
  onCloseComplete,
  hasCancel,
  cancelMessage,
  onCancel,
  hasConfirm,
  confirmMessage,
  onConfirm,
  hasClose,
  children,
}: ConfirmationModalProps) => {
  return (
    <Transition show={show}>
      <div
        className="fixed z-50 inset-0 bg-fixed"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="flex items-center justify-center h-full w-full p-4">
          {/* <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    --> */}
          <Transition.Child
            enter="transition ease-out duration-300"
            enterFrom="transform opacity-0"
            enterTo="transform opacity-100"
            leave="transition ease-in duration-200"
            leaveFrom="transform opacity-100"
            leaveTo="transform opacity-0"
          >
            <div
              className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity"
              aria-hidden="true"
              onClick={() => {
                if (typeof onCloseComplete === "function") {
                  onCloseComplete();
                }
              }}
            ></div>
          </Transition.Child>

          {/* <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    --> */}
          <Transition.Child
            enter="transition ease-out duration-300"
            enterFrom="transform opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="transform opacity-100 translate-y-0 sm:scale-100"
            leave="transition ease-in duration-200"
            leaveFrom="transform opacity-100 translate-y-0 sm:scale-100"
            leaveTo="transform opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="absolute top-0 right-0 pt-4 pr-4">
                {hasClose && (
                  <button
                    type="button"
                    onClick={() => {
                      if (typeof onCloseComplete === "function") {
                        onCloseComplete();
                      }
                    }}
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lfg-primary"
                  >
                    <span className="sr-only">Close</span>
                    {/* <!-- Heroicon name: outline/x --> */}
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                )}
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  {/* <!-- Heroicon name: outline/exclamation --> */}
                  <svg
                    className="h-6 w-6 text-red-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3
                    className="text-lg leading-6 font-medium text-gray-900"
                    id="modal-title"
                  >
                    {header}
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">{message}</p>
                    {children}
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                {hasConfirm && (
                  <button
                    onClick={() => {
                      typeof onConfirm === "function" && onConfirm();
                      if (typeof onCloseComplete === "function") {
                        onCloseComplete();
                      }
                    }}
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    {confirmMessage || "Confirm"}
                  </button>
                )}
                {hasCancel && (
                  <button
                    onClick={() => {
                      typeof onCancel === "function" && onCancel();
                      if (typeof onCloseComplete === "function") {
                        onCloseComplete();
                      }
                    }}
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:w-auto sm:text-sm"
                  >
                    {cancelMessage || "Cancel"}
                  </button>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Transition>
  );
};
