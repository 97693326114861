import { gql, useQuery } from "@apollo/client";

const ORDER_EVENTS_QUERY = gql`
  query ORDER_EVENTS_QUERY($external_id: String!) {
    order_events(external_id: $external_id) {
      external_id
      name
      data
      inserted_at
      event_date
      provider
    }
  }
`;

type UseOrderEventsProps = {
  external_id: string;
};

export const useOrderEvents = ({ external_id }: UseOrderEventsProps) => {
  const { data, error, loading, refetch } = useQuery(ORDER_EVENTS_QUERY, {
    skip: !external_id,
    variables: {
      external_id,
    },
  });
  return { data, error, loading, refetch };
};
