import React from "react";

type FormSelectProps = {
  id?: string;
  value: any;
  required?: boolean;
  onChange?: (event: any) => void;
  children?: React.ReactNode;
  disabled?: boolean;
};

// React.forwardRef is used to address this issue: https://github.com/react-hook-form/react-hook-form/issues/3411
export const FormSelect = React.forwardRef(
  (
    { id, value, required, onChange, children, disabled }: FormSelectProps,
    ref: React.Ref<HTMLSelectElement>,
  ) => {
    return (
      <select
        id={id}
        value={value}
        required={required}
        onChange={onChange}
        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
        ref={ref}
        disabled={disabled}
      >
        {children}
      </select>
    );
  },
);
