import {
  faArrowLeft,
  faClock,
  faLock,
  faPowerOff,
  faRocket,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams, useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import { Button } from "../../ui";
import DropdownButtons from "../../ui/components/DropdownButtons/DropdownButtons";
import { PageContent } from "../../ui/components/PageContent/PageContent";
import classNames from "../../utils/classNames";
import ToastContext from "../../utils/contexts/ToastContext";
import dayjs from "../../utils/dayjs";
import useQueryParams from "../../utils/hooks/useQueryParams";
import { CustomHoursCreateModal } from "../CustomHours/CustomHoursCreateModal";
import ActivateStoreModal from "./ActivateStoreModal";
import CredentialsModal from "./CredentialsModal";
import { CustomClosuresTable } from "./CustomClosuresTable";
import { PermanentlyDeactivateModal } from "./PermanentlyDeactivateModal";
import StoreDetailForm from "./StoreDetailForm";
import { StoreMenuSyncModal } from "./StoreMenuSyncModal";
import useStoreById from "./hooks/useStoreById";
import useUpdateStore from "./hooks/useUpdateStore";

export default function StoreDetail() {
  const form = useForm();
  const { handleSubmit, register } = form;
  const history = useHistory();
  const { params } = useQueryParams();
  const { url } = useRouteMatch();
  const { storeId } = useParams<{ storeId: string }>();
  const { data: storeData, loading: storeLoading } = useStoreById({
    store_id: parseInt(storeId),
  });

  const [showCredentialsModal, setShowCredentialsModal] =
    useState<boolean>(false);
  const [showActivationModal, setShowActivationModal] =
    useState<boolean>(false);
  const [showStoreMenuSyncModal, setShowStoreMenuSyncModal] =
    useState<boolean>(false);
  const [showAddCustomClosureModal, setShowAddCustomClosureModal] =
    useState<boolean>(false);
  const [showPermanentlyDeactivateModal, setShowPermanentlyDeactivateModal] =
    useState<boolean>(false);

  const { showToast } = useContext(ToastContext);
  const { updateStore } = useUpdateStore();

  const onSubmitHandler = async (data: any) => {
    try {
      const transformedRequestData = {
        ...data,
        ordinal: storeData.ordinal,
        store_id: parseInt(data.store_id),
        start_timestamp: (dayjs(data.start_timestamp).unix() * 1000).toString(),
      };

      await updateStore({
        variables: transformedRequestData,
      });
      showToast({
        description: "Store has been updated",
        variant: "success",
        seconds: 2,
        onClose: () => {},
      });
    } catch (err) {
      showToast({
        description: (err as any).message || "Store has not been updated",
        variant: "error",
        seconds: 3,
        onClose: () => {},
      });
    }
  };

  const renderStoreActivationItem = (): JSX.Element => {
    if (storeData.store_by_id.is_active)
      return (
        <div className={`flex items-center`}>
          <div className="w-8">
            <FontAwesomeIcon icon={faPowerOff} />
          </div>
          <p>Deactivate</p>
        </div>
      );
    else
      return (
        <div className={`flex items-center`}>
          <div className="w-8">
            <FontAwesomeIcon icon={faRocket} />
          </div>
          <p>Activate</p>
        </div>
      );
  };

  const handleActivationClick = (event: any) => {
    if (storeData.store_by_id.is_active)
      setShowPermanentlyDeactivateModal(true);
    else setShowActivationModal(true);
  };

  if (storeLoading) {
    return null;
  }
  return (
    <PageContent>
      <Link to={`/locations/${storeData.store_by_id.location.slug}?tab=brands`}>
        <FontAwesomeIcon icon={faArrowLeft} className="mr-1" />
        Back to {storeData.store_by_id.location.name}
      </Link>
      <div className="flex items-center justify-between">
        <h1 className="text-3xl font-semibold text-gray-900 mr-3">
          {storeData.store_by_id.location.name} |{" "}
          {storeData.store_by_id.brand.name}
        </h1>
        <div className="flex items-center">
          <DropdownButtons
            mainText="Actions"
            items={[
              {
                children: (
                  <div className="flex items-center">
                    <div className="w-8">
                      <FontAwesomeIcon icon={faSync} />
                    </div>
                    <p>Sync Menu</p>
                  </div>
                ),
                onClick: () => setShowStoreMenuSyncModal(true),
              },
              {
                children: (
                  <div className="flex items-center">
                    <div className="w-8">
                      <FontAwesomeIcon icon={faClock} />
                    </div>
                    <p>Add Custom Closure</p>
                  </div>
                ),
                onClick: () => setShowAddCustomClosureModal(true),
              },
              {
                children: (
                  <div className="flex items-center">
                    <div className="w-8">
                      <FontAwesomeIcon icon={faLock} />
                    </div>
                    <p>3P Credentials</p>
                  </div>
                ),
                onClick: () => setShowCredentialsModal(true),
              },
              {
                children: renderStoreActivationItem(),
                onClick: handleActivationClick,
              },
            ]}
          />
          {/* <Button type="button" onClick={() => setShowActivationModal(true)}>
              Go Live!
            </Button> */}
        </div>
      </div>
      <nav className="my-4 flex space-x-4" aria-label="Tabs">
        <div
          className={classNames(
            params.get("tab") === "details"
              ? "border-lfg-primary text-lfg-primary"
              : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
            "cursor-pointer whitespace-nowrap py-2 px-1 border-b-4 font-medium text-sm",
          )}
          onClick={() => history.push(`${url}?tab=details`)}
        >
          Details
        </div>
      </nav>
      <form>
        <input
          type="hidden"
          {...register("store_id")}
          value={parseInt(storeId)}
        />
        <>
          <StoreDetailForm form={form} store={storeData.store_by_id} />

          <div className="flex items-left w-full justify-left mb-5 shadow">
            <p className="text-lg font-semibold mr-5">Custom closures</p>
          </div>
          <CustomClosuresTable store={storeData.store_by_id} />
        </>
      </form>
      <div>
        <Button
          className="mr-3"
          type="button"
          onClick={handleSubmit(onSubmitHandler)}
        >
          Save
        </Button>
      </div>
      <CredentialsModal
        showModal={showCredentialsModal}
        setShowModal={setShowCredentialsModal}
        store={storeData.store_by_id}
      />
      <ActivateStoreModal
        showModal={showActivationModal}
        setShowModal={setShowActivationModal}
        store={storeData.store_by_id}
      />

      <StoreMenuSyncModal
        showModal={showStoreMenuSyncModal}
        setShowModal={setShowStoreMenuSyncModal}
        storeId={storeData.store_by_id.id}
      />
      <CustomHoursCreateModal
        showModal={showAddCustomClosureModal}
        setShowModal={setShowAddCustomClosureModal}
        store={storeData.store_by_id}
      />
      <PermanentlyDeactivateModal
        showModal={showPermanentlyDeactivateModal}
        setShowModal={setShowPermanentlyDeactivateModal}
        store={storeData.store_by_id}
      />
    </PageContent>
  );
}
