import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Controller, get, useForm } from "react-hook-form";
import {
  BuildNote,
  OperationsMenuItem,
  OperationsModifierItem,
} from "../../../types";
import {
  Button,
  FormCheckbox,
  FormInput,
  FormLabel,
  FormOption,
  FormSelect,
} from "../../../ui";
import { filterOperationItems } from "../../../utils/filterOperationItems";

const DISPLAYS: { [key: number]: string } = {
  0: "Show only in KDS",
  1: "Show only in Receipt",
  2: "Show in Both",
};

type BuildNoteFormProps = {
  item: OperationsMenuItem | OperationsModifierItem;
  allItems: OperationsMenuItem[] | OperationsModifierItem[];
  onCreateOrUpdateBuildNotes: (args: any) => void;
  onDeleteMenuItemBuildNotes: (args: any) => void;
  currentBuildNote: BuildNote | undefined;
  setCurrentBuildNote: Dispatch<SetStateAction<BuildNote | undefined>>;
  setShowBuildNoteForm: Dispatch<SetStateAction<boolean>>;
};

export const BuildNoteForm = ({
  item,
  allItems,
  onCreateOrUpdateBuildNotes,
  onDeleteMenuItemBuildNotes,
  currentBuildNote,
  setCurrentBuildNote,
  setShowBuildNoteForm,
}: BuildNoteFormProps) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const [modifierItems, setModifierItems] = useState<any>([]);
  const [deleteAction, setDeleteAction] = useState(false);

  const onSubmitHandler = async (data: any) => {
    const {
      name,
      ordinality,
      display,
      modifierVisibility,
      isCustomChit,
      customChitKitchenName,
    } = data;

    const custom_chit_id = currentBuildNote?.custom_chit?.id
      ? currentBuildNote.custom_chit.id
      : null;

    if (currentBuildNote && currentBuildNote.id && deleteAction) {
      setDeleteAction(false);
      await onDeleteMenuItemBuildNotes({
        id: currentBuildNote.id,
        custom_chit_id,
      });
    } else {
      const displayNumber = parseInt(display, 10);

      let show_in_kds = false;
      let show_in_receipt = false;

      if (displayNumber === 2) {
        show_in_kds = true;
        show_in_receipt = true;
      }

      if (displayNumber === 1) {
        show_in_receipt = true;
      }

      if (displayNumber === 0) {
        show_in_kds = true;
      }

      const payload = {
        id:
          currentBuildNote && currentBuildNote.id ? currentBuildNote.id : null,
        menu_item_id: item.id,
        modifier_list_id_order_override: parseInt(ordinality, 10),
        removal_modifier_id: modifierVisibility
          ? parseInt(modifierVisibility, 10)
          : null,
        name,
        show_in_kds,
        show_in_receipt,
        custom_chit: {
          id: custom_chit_id,
          is_active: isCustomChit,
          target_id: currentBuildNote?.id ? currentBuildNote.id : null,
          target_class: "build_note",
          kitchen_name: customChitKitchenName,
          menu_item_ids: [],
        },
      };
      await onCreateOrUpdateBuildNotes(payload);
    }
  };

  // Build notes display configuration
  // 0: Show in KDS
  // 1: Show in Receipt
  // 2: Show in Both
  useEffect(() => {
    if (currentBuildNote) {
      let displayCount;
      if (currentBuildNote.show_in_kds && currentBuildNote.show_in_receipt) {
        displayCount = 2;
      }

      if (currentBuildNote.show_in_receipt && !currentBuildNote.show_in_kds) {
        displayCount = 1;
      }

      if (currentBuildNote.show_in_kds && !currentBuildNote.show_in_receipt) {
        displayCount = 0;
      }

      setValue("name", currentBuildNote.name);
      setValue("ordinality", currentBuildNote.modifier_list_id_order_override);
      setValue("display", displayCount);
      setValue(
        "modifierVisibility",
        currentBuildNote.removal_modifier_id
          ? currentBuildNote.removal_modifier_id
          : undefined,
      );
    }
  }, [currentBuildNote]);

  useEffect(() => {
    if (allItems && item) {
      const modifierListIds = item.modifier_lists.map(
        (modifierList: any) => modifierList.id,
      );

      const callbackFn = (item: any) => {
        const isModifierItem = item.item_type === "modifier_item";
        const isRemovalModifier = item.name.toLowerCase().startsWith("no ");

        // Early returns before going into deeper filters
        if (!isModifierItem) {
          return false;
        }

        if (!isRemovalModifier) {
          return false;
        }

        // is modifier list included in the list of the item modifier lists
        return modifierListIds.some((id: number) =>
          item.modifier_list_ids.includes(id),
        );
      };

      setModifierItems(filterOperationItems(allItems, callbackFn));
    }
  }, [allItems, item]);

  return (
    <div>
      <button
        className="text-lg font-medium hover:underline mb-1"
        onClick={() => setShowBuildNoteForm(false)}
      >
        <FontAwesomeIcon icon={faArrowLeft} size="sm" />
        <span className="text-lg font-medium ml-2">Go Back</span>
      </button>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <div className="mt-3">
          <div className="flex text-xl font-medium mb-2 border-b-2">
            <p className="mr-3">Build Note</p>
          </div>
        </div>
        <div className="mb-2">
          <FormLabel
            title="Name"
            htmlFor="name"
            labelClass="text-lg font-medium"
          />
          <div>
            <Controller
              name="name"
              control={control}
              rules={{ required: "Build Note name is required" }}
              defaultValue={currentBuildNote ? currentBuildNote.name : ""}
              render={({ field }) => (
                <FormInput
                  type="text"
                  id="name"
                  {...field}
                  onChange={(event: any) =>
                    setValue("name", event.target.value)
                  }
                />
              )}
            />
            {get(errors, "name.message") && (
              <div>
                <p className="mt-1 font-medium text-red-500">
                  {get(errors, "name.message")}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="mb-2">
          <FormLabel
            title="Ordinality"
            htmlFor="ordinality"
            labelClass="text-lg font-medium"
            information="Ordinality display in KDS and Receipts"
          />
          <Controller
            name="ordinality"
            control={control}
            rules={{ required: "Build Note ordinality is required" }}
            defaultValue={
              currentBuildNote
                ? currentBuildNote.modifier_list_id_order_override
                : 0
            }
            render={({ field }) => (
              <FormSelect {...field}>
                <FormOption key={0} title="Display as First" value={0} />
                {item.modifier_lists?.map((modifierList: any) => (
                  <FormOption
                    key={modifierList.id}
                    title={`After ${modifierList.name}`}
                    value={modifierList.id}
                  />
                ))}
              </FormSelect>
            )}
          />
        </div>
        <div className="mb-2">
          <FormLabel
            title="Displays"
            htmlFor="display"
            labelClass="text-lg font-medium"
            information="Display Build Note only in KDS, only in Receipt or in Both"
          />
          {/*
          0: Display in KDS
          1: Display in Receipt
          2: Display in Both
          */}
          <Controller
            name="display"
            control={control}
            rules={{ required: "Build Note display is required" }}
            defaultValue={2}
            render={({ field }) => (
              <FormSelect {...field}>
                {[0, 1, 2].map((displayIndex: number) => {
                  return (
                    <FormOption
                      key={displayIndex}
                      title={DISPLAYS[displayIndex]}
                      value={displayIndex}
                    />
                  );
                })}
              </FormSelect>
            )}
          />
        </div>
        <div className="mb-2">
          {modifierItems.length > 0 && (
            <>
              <FormLabel
                title="Removal Modifier Visibility"
                htmlFor="modifierVisibility"
                labelClass="text-lg font-medium"
                information="If chosen, Build Note will not appear in case removal modifier is present in the order"
              />
              <Controller
                name="modifierVisibility"
                control={control}
                defaultValue={
                  currentBuildNote
                    ? currentBuildNote.removal_modifier_id
                    : undefined
                }
                render={({ field }) => (
                  <FormSelect {...field}>
                    <FormOption
                      title="Choose a removal modifier"
                      value={undefined}
                    />
                    {modifierItems.map((modifierItem: any, index: number) => (
                      <FormOption
                        key={index}
                        title={modifierItem.name}
                        value={modifierItem.id}
                      />
                    ))}
                  </FormSelect>
                )}
              />
            </>
          )}
        </div>
        <div className="mt-3">
          <div className="flex text-xl font-medium mb-2 border-b-2">
            <p className="mr-3">Custom Chit Instructions</p>
          </div>
          <ul className="list-disc list-inside text-base mt-3 mb-6">
            <li>
              Toggle the IS CUSTOM CHIT field if you would like build note to be
              a custom chit
            </li>
            <li>
              Write the name that will display in the KDS and receipts for the
              custom chit
            </li>
            <li>
              Unchecking a custom chit will delete the custom chit and it's
              dependencies
            </li>
          </ul>
        </div>
        <div className="my-3">
          <FormLabel title="Is Custom Chit" htmlFor="isCustomChit" />
          <Controller
            name="isCustomChit"
            control={control}
            rules={{ required: false }}
            defaultValue={currentBuildNote?.custom_chit?.id ? true : false}
            render={({ field }) => (
              <FormCheckbox
                type="checkbox"
                id="isCustomChit"
                checked={field.value}
                {...field}
              />
            )}
          />
        </div>
        <div className="flex justify-between mt-3">
          <div>
            {currentBuildNote && currentBuildNote.id && (
              <button
                className="cursor-pointer hover:opacity-80 hover:text-white inline-flex justify-center py-2 px-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-400"
                onClick={() => setDeleteAction(true)}
              >
                Delete
              </button>
            )}
          </div>
          <Button>Save</Button>
        </div>
      </form>
    </div>
  );
};
