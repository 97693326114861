import { useMutation, gql } from "@apollo/client";

const DELETE_CATERING_ORDER = gql`
  mutation DeleteCateringOrder($id: Int!) {
    delete_catering_order(id: $id)
  }
`;

export const useDeleteCateringOrder = (callback?: () => void) => {
  const [deleteCateringOrderMutation, { loading }] = useMutation(
    DELETE_CATERING_ORDER,
    {
      onCompleted: () => {
        callback?.();
      },
      refetchQueries: ["OperationsCateringOrder"],
    },
  );

  return { deleteCateringOrderMutation, loading };
};
