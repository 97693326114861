import { LocationSchema } from "opsapi/hooks/operations/locations";
import { useContext } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  OperationsLocationFeaturedItemIdsDocument,
  useOperationsLocationFeaturedItemIdsQuery,
} from "../../../../graphql/useLocationFeaturedItemIds.query.generated";
import { useSetLocationFeaturedItemsMutation } from "../../../../graphql/useSetLocationFeaturedItems.mutation.generated";
import { Button, FormLabel, Loading } from "../../../../ui";
import ToastContext from "../../../../utils/contexts/ToastContext";
import { StoreMenuSearchAndDrag } from "./StoreMenuSearchAndDrag";
import { useOperationMenuItems } from "./hooks/useOperationsMenuItems";

type FormValues = {
  storeMenuItemIds: number[];
};

const MAXIMUM_FEATURED_ITEMS = 6;

const LocationFeaturedItems = ({ location }: { location: LocationSchema }) => {
  const { control, handleSubmit } = useForm<FormValues>();
  const { showToast } = useContext(ToastContext);

  const { data: menuItems, loading: loadingMenuitems } = useOperationMenuItems(
    location?.id,
  );
  const { data, loading: loadingFeaturedItems } =
    useOperationsLocationFeaturedItemIdsQuery({
      variables: {
        locationId: location.id,
      },
    });
  const [
    setLocationFeaturedItems,
    { loading: loadingSetLocationFeaturedItems },
  ] = useSetLocationFeaturedItemsMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: OperationsLocationFeaturedItemIdsDocument,
        variables: {
          locationId: location.id,
        },
      },
    ],
  });

  const selectedValues = (data?.featured_items || []).map(
    (item) => item.id as number,
  );

  if (loadingFeaturedItems || loadingMenuitems) return <Loading />;

  const onSubmit: SubmitHandler<FormValues> = ({ storeMenuItemIds }) => {
    setLocationFeaturedItems({
      variables: {
        locationId: location.id,
        storeMenuItemIds,
      },
    })
      .then(() => {
        showToast({
          description: "Featured items updated successfully",
          variant: "success",
        });
      })
      .catch(() => {
        showToast({
          description: "Failed to update featured items",
          variant: "error",
        });
      });
  };

  return (
    <form className="w-full p-8 bg-white" onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full flex justify-center">
        <Button type="submit" loading={loadingSetLocationFeaturedItems}>
          Save
        </Button>
      </div>
      <FormLabel
        title={`Menu Items (max. ${MAXIMUM_FEATURED_ITEMS} items)`}
        htmlFor="storeMenuItemIds"
        information="Menu items to show on menu module"
      />
      <Controller
        name="storeMenuItemIds"
        control={control}
        defaultValue={selectedValues}
        render={({ field: { value, onChange } }) => (
          <StoreMenuSearchAndDrag
            storeMenuItems={menuItems}
            selectedValues={value}
            onChange={onChange}
            maximumItems={MAXIMUM_FEATURED_ITEMS}
          />
        )}
      />
    </form>
  );
};

export default LocationFeaturedItems;
