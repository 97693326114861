import { Route, Switch, useRouteMatch } from "react-router";
import { ListCategories } from "./pages/ListCategories";

export const CategoriesController = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/`}>
        <ListCategories />
      </Route>
    </Switch>
  );
};
