import React, {
  ReactElement,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useRef,
} from "react";
import { Button, FormCheckbox, Modal } from "../../ui";
import { ordersTableCols } from "./constants";

interface Props {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  displayCols: { [key: string]: boolean };
  setDisplayCols: any;
}

export default function TableSettingsModal({
  showModal,
  setShowModal,
  displayCols,
  setDisplayCols,
}: Props): ReactElement {
  const initialMount = useRef(true);
  const [stateCols, setStateCols] = useState({ ...displayCols });

  const handleOnApply = () => {
    setDisplayCols({ ...stateCols });
    setShowModal(false);
  };

  const doToggleCol = (colName: string) => {
    let newCols = { ...stateCols };
    newCols[colName] = !stateCols[colName];
    setStateCols({ ...newCols });
  };

  useEffect(() => {
    if (initialMount.current) initialMount.current = false;
    else if (showModal) setStateCols(displayCols);
    //eslint-disable-next-line
  }, [showModal]);

  return (
    <Modal
      title="Hide/Show Columns"
      showModal={showModal}
      setShowModal={setShowModal}
    >
      <div className="my-5 flex flex-col flex-wrap">
        {ordersTableCols.map((col, index) => (
          <div key={index + 1} className={"block  h-10 w-36"}>
            <FormCheckbox
              type="checkbox"
              checked={stateCols[col.name]}
              onChange={() => doToggleCol(col.name)}
              name="column-name"
              className="focus:ring-indigo-500 h-4 w-4 text-lfg-primary border-gray-300 rounded align-middle"
            />
            <label className="ml-2" htmlFor="column-name">
              {col.headerName}
            </label>
          </div>
        ))}
      </div>
      <Button className="mr-3" onClick={handleOnApply}>
        Apply
      </Button>
      <Button backgroundColor="bg-gray-500" onClick={() => setShowModal(false)}>
        Cancel
      </Button>
    </Modal>
  );
}
