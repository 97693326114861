export const defaultFilters = (
  queryParams: URLSearchParams | null,
  filters: string[],
) => {
  const queryParamOrDefault = (param: string) => {
    return queryParams?.get(param) || "";
  };

  return filters.reduce((acc, filter) => {
    acc[filter] = queryParamOrDefault(filter);
    return acc;
  }, {} as { [key: string]: string });
};
