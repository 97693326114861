import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import StoreDetail from "./StoreDetail";

interface Props {}

export const StoresController = ({}: Props) => {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:storeId`}>
        <StoreDetail />
      </Route>
    </Switch>
  );
};
