import { useQuery, gql } from "@apollo/client";

const STORE_MENUS_QUERY = gql`
  query STORE_MENUS_QUERY($location_slug: String, $show_all: Boolean) {
    stores(location_slug: $location_slug, show_all: $show_all) {
      id
      brand {
        id
        name
      }
      location {
        id
        name
      }
      menus {
        id
        menu_categories {
          id
          name
          menu_items {
            id
            name
            modifier_lists {
              id
              name
              modifier_items {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const useStoreMenus = (locationSlug: String) => {
  const { data, error, loading, refetch } = useQuery(STORE_MENUS_QUERY, {
    variables: { location_slug: locationSlug, show_all: true },
  });

  return { data, error, loading, refetch };
};
