import { useMutation, gql } from "@apollo/client";

const OPERATIONS_UPDATE_CATERING_ORDER = gql`
  mutation OPERATIONS_UPDATE_CATERING_ORDER(
    $id: Int!
    $input: UpdateCateringOrderInput
  ) {
    update_catering_order(id: $id, input: $input) {
      id
      invoice_id
      external_id
      invoice_paid_at
      tax
      tax_rate
      tip
      tip_rate
      discount
      total
      subtotal
      customer_delivery_fee
      service_fee
      service_fee_rate
    }
  }
`;

export const useUpdateCateringOrder = () => {
  const [updateCateringOrderMutation, { loading }] = useMutation(
    OPERATIONS_UPDATE_CATERING_ORDER,
  );

  return { updateCateringOrderMutation, loading };
};
