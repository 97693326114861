import { Column, ReactGrid, Row } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  InputMaybe,
  LocationV2,
  PaginatedMenuEntitiesFilter,
  PaginatedMenuEntitiesFilterEnum,
  PaginatedMenuEntitiesSort,
} from "../../graphql/types";
import useLocations from "./hooks/useLocations";
import usePaginatedMenuEntities from "./hooks/usePaginatedMenuEntities";
import { DEFAULT_ORDER, DEFAULT_PAGE_SIZE } from "./lib/constants";
import { SortSelected, TargetType, UpdateCellInput } from "./lib/types";
import { getCells, getColumnsWithLocations, getHeaderRow } from "./lib/utils";
import { MenuEditorDropdown } from "./MenuEditorDropdown";
import { MenuEditorFilters } from "./MenuEditorFilters";
import MenuEditorFooter from "./MenuEditorFooter";

interface MenuEditorProps {
  onSubmit: () => Promise<void>;
  submitStatus?: boolean;
  onUpdateCell: (data: UpdateCellInput) => void;
  modifiedCells: {};
}

const MenuEditor = ({
  onSubmit,
  submitStatus,
  onUpdateCell,
  modifiedCells,
}: MenuEditorProps) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedType, setSelectedType] = useState<TargetType | undefined>();
  const [selectedItemName, setSelectedItemName] = useState<
    string | undefined
  >();
  const [selectedSort, setSelectedSort] = useState<SortSelected | undefined>(
    undefined,
  );
  const [selectedBrand, setSelectedBrand] = useState<number | undefined>();
  const [displayLocations, setDisplayLocations] = useState<
    LocationV2[] | undefined
  >(undefined);
  const [selectedLocation, setSelectedLocation] = useState<
    string | undefined
  >();
  const [sortBy, setSortyBy] = useState<PaginatedMenuEntitiesSort[]>([
    ...DEFAULT_ORDER,
  ]);

  const [filters, setFilters] = useState<PaginatedMenuEntitiesFilter[]>([]);

  const { data: locations } = useLocations(undefined);

  const { data, refetch, loading } = usePaginatedMenuEntities({
    page_number: pageNumber,
    page_size: 100,
    sort_by: sortBy,
    filters: filters,
  });

  const [columns, setColumns] = useState<Column[]>(
    [...getColumnsWithLocations(locations)].sort(
      (a, b) => a.ordinal - b.ordinal,
    ),
  );

  useEffect(() => {
    (async function () {
      if (submitStatus) {
        await refetch();
      }
    })();
  }, [submitStatus]);

  useEffect(() => {
    if (selectedLocation) {
      const _locations = locations?.filter((l) => l?.slug === selectedLocation);
      setDisplayLocations(_locations);
    } else {
      setDisplayLocations(locations);
    }
  }, [locations, selectedLocation]);

  useEffect(() => {
    setColumns(
      [...getColumnsWithLocations(displayLocations)].sort(
        (a, b) => a.ordinal - b.ordinal,
      ),
    );
  }, [displayLocations]);

  useEffect(() => {
    if (selectedLocation) {
      const selectedId = locations.find(
        (l) => l?.slug === selectedLocation,
      )?.id;

      setPageNumber(1);
      setFilters([
        {
          field: "location" as PaginatedMenuEntitiesFilterEnum,
          value: selectedId?.toString() as InputMaybe<string>,
          display: true,
        },
      ]);
      setSelectedBrand(undefined);
      setSelectedType(undefined);
      setSelectedItemName("");
    } else {
      setFilters([]);
      setSelectedBrand(undefined);
      setSelectedType(undefined);
      setSelectedItemName("");
    }
  }, [selectedLocation, setPageNumber, setFilters, setSelectedBrand]);

  useEffect(() => {
    if (selectedItemName) {
      const selectedId = locations.find(
        (l) => l?.slug === selectedLocation,
      )?.id;

      setPageNumber(1);
      setFilters([
        ...filters.filter((f) => f.field !== "item_name"),
        {
          field: "item_name" as PaginatedMenuEntitiesFilterEnum,
          value: selectedItemName?.trim() as InputMaybe<string>,
          display: true,
        },
      ]);
      setSelectedBrand(undefined);
    } else {
      setFilters([...filters.filter((f) => f.field !== "item_name")]);
    }
  }, [selectedItemName, setPageNumber, setFilters]);

  useEffect(() => {
    if (selectedType) {
      setPageNumber(1);
      setFilters([
        ...filters.filter((f) => f.field !== "target_type"),
        {
          field: "target_type" as PaginatedMenuEntitiesFilterEnum,
          value: selectedType as InputMaybe<string>,
          display: true,
        },
      ]);
    } else {
      setFilters([...filters].filter((f) => f.field !== "target_type"));
    }
  }, [selectedType, setPageNumber, setFilters]);

  useEffect(() => {
    if (selectedBrand) {
      let newFilters = [...filters].filter((f) => f.field !== "brand_id");
      setPageNumber(1);
      setFilters([
        ...newFilters,
        {
          field: "brand_id" as PaginatedMenuEntitiesFilterEnum,
          value: selectedBrand?.toString() as InputMaybe<string>,
          display: true,
        },
      ]);
    } else {
      setFilters([...filters].filter((f) => f.field !== "brand_id"));
    }
  }, [selectedBrand, setPageNumber, setFilters]);

  useEffect(() => {
    const sortByOrder = [...DEFAULT_ORDER.map((el) => ({ ...el }))];

    let sortFieldIndex: number | undefined = 0;
    if (selectedSort?.field === "brand") {
      sortFieldIndex = sortByOrder.findIndex((el) => el.field === "brand_name");
    } else if (selectedSort?.field == "type") {
      sortFieldIndex = sortByOrder.findIndex(
        (el) => el.field === "target_type",
      );
    } else if (selectedSort?.field === "name") {
      sortFieldIndex = sortByOrder.findIndex((el) => el.field === "name");
    }

    if (selectedSort !== undefined) {
      sortByOrder[sortFieldIndex] = {
        ...sortByOrder[sortFieldIndex],
        ordinal: 0,
        order: (selectedSort.order || "ASC") as InputMaybe<any>,
      };
    }

    setSortyBy(sortByOrder);
  }, [selectedSort, setSortyBy]);

  const handleTargetClick = (
    e: any,
    row: any,
    location: any,
    defaultState: string,
  ) => {
    const input = {
      displayStatus: e?.target?.checked,
      targetId: row.target_id,
      targetType: row.target_type,
      locationSlug: location.slug,
      locationId: location.id,
      defaultState,
    };

    onUpdateCell(input);
  };

  const handleSort = (field: string) => {
    if (selectedSort?.field === field) {
      if (selectedSort?.order === "ASC") {
        setSelectedSort({ field, order: "DESC" });
      } else {
        setSelectedSort(undefined);
      }
    } else {
      setSelectedSort({ field, order: "ASC" });
    }
  };

  const handleColumnResize = (ci: number | string, width?: number) => {
    setColumns((prevColumns) => {
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
  };

  const handleResetFilters = useCallback(() => {
    setSelectedLocation(undefined);
    setSelectedBrand(undefined);
    setFilters([]);
    setSelectedType(undefined);
    setSelectedItemName(undefined);
    setSelectedSort(undefined);
    setPageNumber(1);
  }, [
    setSelectedItemName,
    setSelectedLocation,
    setSelectedBrand,
    setSelectedType,
    setSelectedSort,
    setFilters,
    setPageNumber,
  ]);

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleBack = useCallback(() => {
    setPageNumber(Math.max(pageNumber - 1, 1));
  }, [pageNumber, setPageNumber]);

  const handleNext = useCallback(() => {
    setPageNumber(pageNumber + 1);
  }, [pageNumber, setPageNumber]);

  const rows = useMemo(() => {
    const cellRows: Row[] =
      data?.map?.((_row: any, index: number) => {
        return {
          rowId: `${_row.target_id}-${_row.target_type}`,
          cells: getCells(
            _row,
            displayLocations,
            handleTargetClick,
            modifiedCells,
          ),
        } as Row;
      }) || [];

    return [
      getHeaderRow(displayLocations, selectedSort, handleSort),
      ...cellRows,
      { rowId: Math.random(), cells: [] },
    ];
  }, [data, displayLocations, pageNumber, selectedSort, selectedLocation]);

  return (
    <>
      <div className={"relative max-w-7xl mx-auto px-4 sm:px-6 md:px-8 my-5"}>
        <div className={"absolute right-0 top-[-60px] z-[1000]"}>
          <MenuEditorDropdown
            handleSubmit={onSubmit}
            handleRefresh={handleRefresh}
          />
        </div>
        <div className={"sticky right-0"}>
          <MenuEditorFilters
            selectedLocation={selectedLocation}
            onSelectLocation={setSelectedLocation}
            itemName={selectedItemName}
            onItemNameChange={setSelectedItemName}
            selectedBrand={selectedBrand}
            onSelectBrand={setSelectedBrand}
            selectedType={selectedType}
            onSelectType={setSelectedType}
            onResetFilters={handleResetFilters}
          />
        </div>
        <div className={"mt-8 sticky top-[20px] h-fit"}>
          <div className={"h-[calc(100vh-485px)] w-full"}>
            {!loading && data?.length && (
              <ReactGrid
                columns={columns ?? []}
                rows={rows ?? []}
                stickyTopRows={1}
                stickyLeftColumns={2}
                onColumnResized={handleColumnResize}
                disableVirtualScrolling={true}
                verticalStickyBreakpoint={100}
                enableRowSelection={true}
                enableRangeSelection={true}
              />
            )}
          </div>
        </div>
        <MenuEditorFooter
          onNext={handleNext}
          onBack={handleBack}
          disableNext={!data?.length || data?.length < DEFAULT_PAGE_SIZE}
          disableBack={pageNumber <= 1}
        />
      </div>
    </>
  );
};

export default MenuEditor;
