import {
  GUEST_MOBILE_PROVIDER_ID,
  GUEST_WEB_PROVIDER_ID,
} from "../features/Orders/constants";
import { LocationV2, Sale, Store, StoreMenuItem } from "../types";

const FORTY_FIVE_MINUTES = 45 * 60 * 1000;

export const canCancelOrder = (order: Sale) => {
  // First, check if direct provider
  const isDirect =
    order.provider.revenue_center === "DIRECT_DIGITAL" ||
    order.provider.revenue_center === "KIOSK";

  // Next, check if order is not yet completed
  const isCompleted = order.status === "COMPLETED";

  const hasBeenCancelled = order.status === "CANCELED";

  return isDirect && !isCompleted && !hasBeenCancelled;
};

// See here to understand what these fields mean:
// https://www.notion.so/How-do-I-identify-an-active-menu-item-174007a83aa7410fa03e4e9b48fd9329?pvs=4
export const isStoreMenuItemActive = (
  storeMenuItem: Pick<StoreMenuItem, "is_active" | "is_visible">,
  location: Pick<LocationV2, "is_active">,
  store: Pick<Store, "is_active">,
) => {
  return store.is_active && location.is_active && storeMenuItem.is_visible;
};

export const getCanEditPickupTime = (order: Sale) => {
  const isPickup = Boolean(order?.is_pickup);

  const isLKDeliveryOrder = getIsLKDeliveryOrder(order);

  const isCateringOrder = order?.provider?.revenue_center === "CATERING";

  // If the pickup time is within 45 minutes, we don't allow the user to edit the pickup time
  const isWithin45Minutes =
    order?.pickup_time &&
    new Date(order?.pickup_time).getTime() - new Date().getTime() <
      FORTY_FIVE_MINUTES;

  if (isWithin45Minutes) {
    return false;
  }

  return isPickup || isCateringOrder || isLKDeliveryOrder;
};

export const getIsLKDeliveryOrder = (order: Sale) => {
  const isPickup = Boolean(order?.is_pickup);
  const isDelivery = !isPickup;

  return (
    isDelivery &&
    [GUEST_WEB_PROVIDER_ID, GUEST_MOBILE_PROVIDER_ID].includes(
      order?.provider?.id,
    )
  );
};
