import { Route, Switch, useRouteMatch } from "react-router";
import { ListModifiers } from "./pages/ListModifiers";

export const ModifiersController = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/`}>
        <ListModifiers />
      </Route>
    </Switch>
  );
};
