import { Controller, useForm } from "react-hook-form";
import { Button, FormInput, FormLabel } from "../../../ui";
import useCurrentLocation from "../../../utils/hooks/useCurrentLocation";

interface Props {
  onSubmitHandler: any;
  locationAccount?: any;
}

export const LocationAccountForm = ({
  onSubmitHandler,
  locationAccount,
}: Props) => {
  const { currentLocation } = useCurrentLocation();
  const { control, handleSubmit, register } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <div className="grid grid-cols-2 my-5">
        {!!locationAccount && (
          <input
            type="hidden"
            value={locationAccount?.id}
            {...register("location_account_id")}
          />
        )}
        <input
          type="hidden"
          value={currentLocation.id}
          {...register("location_id")}
        />
        <div className="col-span-1">
          <FormLabel title="Email" htmlFor="email" />
          <Controller
            name="email"
            defaultValue={!!locationAccount ? locationAccount.email : ""}
            control={control}
            rules={{ required: true }}
            render={({ field }) => <FormInput type="email" {...field} />}
          />
        </div>
      </div>
      <Button>Save</Button>
    </form>
  );
};
