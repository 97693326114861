import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import {
  BuildNote,
  OperationsMenuItem,
  OperationsModifierItem,
} from "../../../types";
import { TableData, TableRow } from "../../../ui";
import { BuildNoteForm } from "./BuildNoteForm";

type EditItemFormProps = {
  item: OperationsMenuItem | OperationsModifierItem;
  allItems: OperationsMenuItem[] | OperationsModifierItem[];
  onCreateOrUpdateBuildNotes: (args: any) => void;
  onDeleteMenuItemBuildNotes: (args: any) => void;
};

export const EditItemForm = ({
  item,
  allItems,
  onCreateOrUpdateBuildNotes,
  onDeleteMenuItemBuildNotes,
}: EditItemFormProps) => {
  const [showBuildNoteForm, setShowBuildNoteForm] = useState(false);
  const [buildNote, setBuildNote] = useState<BuildNote | undefined>();

  return (
    <>
      {!showBuildNoteForm && (
        <>
          <div className="my-5">
            <div className="flex text-xl font-medium mb-2 border-b-2">
              <p className="mr-3">Build Notes</p>
              <button
                className="flex items-center cursor-pointer hover:underline"
                onClick={() => {
                  setBuildNote(undefined);
                  setShowBuildNoteForm(true);
                }}
              >
                <FontAwesomeIcon icon={faPlusCircle} color="green" />
              </button>
            </div>
            {item.build_notes.length > 0 ? (
              <table className="min-w-full divide-y divide-gray-200">
                <tbody>
                  {item.build_notes?.map((buildNote: any, index: number) => (
                    <TableRow
                      key={`build-note-${index}`}
                      rowColor={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                      className="grid grid-cols-3 gap-6"
                    >
                      <TableData>
                        <button
                          className="hover:underline"
                          onClick={() => {
                            setBuildNote(item.build_notes[index]);
                            setShowBuildNoteForm(true);
                          }}
                        >
                          {buildNote.name}
                        </button>
                      </TableData>
                    </TableRow>
                  ))}
                </tbody>
              </table>
            ) : (
              <div>
                <p className="text-lg">
                  No Build Notes, click on the plus sign above to add
                </p>
              </div>
            )}
          </div>
        </>
      )}
      {showBuildNoteForm && (
        <BuildNoteForm
          item={item}
          allItems={allItems}
          onCreateOrUpdateBuildNotes={onCreateOrUpdateBuildNotes}
          onDeleteMenuItemBuildNotes={onDeleteMenuItemBuildNotes}
          currentBuildNote={buildNote}
          setCurrentBuildNote={setBuildNote}
          setShowBuildNoteForm={setShowBuildNoteForm}
        />
      )}
    </>
  );
};
