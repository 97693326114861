import { Dispatch, SetStateAction, useContext } from "react";
import { Modal } from "../../../ui";
import ToastContext from "../../../utils/contexts/ToastContext";
import { useUpdatePrinter } from "./hooks/useUpdatePrinter";
import { LocationPrinterForm } from "./LocationPrinterForm";

interface Props {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  locationPrinter: any;
}

export const EditLocationPrinterModal = ({
  showModal,
  setShowModal,
  locationPrinter,
}: Props) => {
  const updatePrinter = useUpdatePrinter();
  const { showToast } = useContext(ToastContext);

  const onSubmitHandler = async (data: any) => {
    try {
      await updatePrinter({
        variables: {
          printer_id: parseInt(data?.printer_id),
          external_id: data?.external_id,
        },
      });
      showToast({
        description: "Printer Updated",
        variant: "success",
        seconds: 2,
        onClose: () => {},
      });
      setShowModal(false);
    } catch (err) {
      showToast({
        description: (err as any).message,
        variant: "error",
        seconds: 2,
        onClose: () => {},
      });
    }
  };

  return (
    <Modal
      title="Edit Receipt Printer"
      showModal={showModal}
      setShowModal={setShowModal}
    >
      <LocationPrinterForm
        onSubmitHandler={onSubmitHandler}
        locationPrinter={locationPrinter}
      />
    </Modal>
  );
};
