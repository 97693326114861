import { gql, useMutation } from "@apollo/client";

const UPDATE_COUPON_MUTATION = gql`
  mutation UpdateCouponMutation($update_coupon_input: UpdateCouponInput) {
    update_coupon(update_coupon_input: $update_coupon_input) {
      id
      name
      code
      discount_rate
      max_amount
      credits
      brand_attrs
      is_active
      loyalty_account_attrs
      order_history_attrs
      brand_attrs
      location_attrs
      coupon_type
      free_item_ranks
      minimum_cart_items
      free_item_minimum_subtotal
      expiration_date
    }
  }
`;

export const useUpdateCoupon = () => {
  const [updateCoupon] = useMutation(UPDATE_COUPON_MUTATION, {
    refetchQueries: ["COUPONS_QUERY"],
  });
  return updateCoupon;
};
