import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PlaceholderPublicMenuItem } from "../../../features/Stores/Locations/components/StoreMenuSearchAndDrag";
import { MenuItem } from "../../../types";

type MenuItemDraggableProps = {
  item: MenuItem | PlaceholderPublicMenuItem;
  isPlaceholder: boolean;
  isDragging: boolean;
};

export function MenuItemDraggable({
  item,
  isPlaceholder,
  isDragging,
}: MenuItemDraggableProps) {
  return (
    <div
      role={"presentation"}
      className={`border rounded border-gray-600 flex flex-col w-32 h-32 relative justify-end overflow-hidden cursor-pointer
        ${isPlaceholder || isDragging ? "border-dashed border-2" : ""}
      `}
    >
      {item.image_url ? (
        <img
          src={item.image_url?.replace(".webp", "-640w.webp")}
          className={`absolute object-cover w-32 h-32 ${
            isPlaceholder || isDragging ? "opacity-0" : ""
          }`}
          alt="item images"
        />
      ) : (
        <div
          className={`w-32 h-32 flex items-center justify-center ${
            isPlaceholder || isDragging ? "opacity-0" : ""
          }`}
        >
          <FontAwesomeIcon
            className="cursor-pointer absolute  "
            icon={faImage}
            size="5x"
            color="rgb(129, 140, 248)"
          />
        </div>
      )}

      <div
        className={`bg-gray-600  ${
          isPlaceholder || isDragging ? "opacity-0" : "opacity-75"
        }`}
      >
        <p className={"text-white"}>{`${item.name}`}</p>
      </div>
    </div>
  );
}
