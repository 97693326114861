import { gql, useMutation } from "@apollo/client";
import { OPERATIONS_BRAND_QUERY } from "./useOperationsBrand";

const MENU_ITEM_UPLOAD_IMAGE = gql`
  mutation UploadMenuItemImage(
    $menuItemId: Int!
    $uploadInput: UploadMenuItemImageInput!
  ) {
    upload_menu_item_image(menuItemId: $menuItemId, input: $uploadInput) {
      kitchen_name
      id
      name
      image_url
      blurhash
    }
  }
`;

export type UploadMenuItemImageProps = {
  imageBase64: string;
  menuItemId: number;
  menuItemName: string;
};

export const useUploadMenuItemImageMutation = (brandSlug?: string) => {
  const [uploadMenuItemImage, { loading, data }] = useMutation(
    MENU_ITEM_UPLOAD_IMAGE,
  );

  const onUploadMenuItemImage = async ({
    imageBase64,
    menuItemId,
    menuItemName,
  }: UploadMenuItemImageProps) => {
    return uploadMenuItemImage({
      variables: {
        menuItemId,
        uploadInput: {
          imageBase64,
          name: menuItemName,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: OPERATIONS_BRAND_QUERY,
          variables: {
            input: {
              brand_slug: brandSlug,
            },
          },
        },
      ],
    });
  };

  return { onUploadMenuItemImage, loading, data };
};
