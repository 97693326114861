import { gql, useQuery } from "@apollo/client";

const BRANDS_QUERY = gql`
  query Brands {
    brands {
      id
      name
      slug
    }
  }
`;

const useBrands = () => {
  return useQuery(BRANDS_QUERY);
};

export default useBrands;
