import { Dispatch, SetStateAction, useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { Employee } from "../../types";
import { Button, FormInput, FormLabel } from "../../ui";
import ToastContext from "../../utils/contexts/ToastContext";

type EmployeeFormProps = {
  employee: Employee | undefined;
  onSubmit: any;
  setShowModal: Dispatch<SetStateAction<boolean>>;
};

export const EmployeeForm = ({
  employee,
  onSubmit,
  setShowModal,
}: EmployeeFormProps) => {
  const { showToast } = useContext(ToastContext);
  const { handleSubmit, control } = useForm();

  const onSubmitHandler = async (data: any) => {
    let mutationVariables = {};

    try {
      if (employee) {
        mutationVariables = {
          id: employee.id,
          employee_input: data,
        };
      } else {
        mutationVariables = {
          employee_input: data,
        };
      }

      await onSubmit({
        variables: mutationVariables,
        refetchQueries: ["LIST_EMPLOYEES_QUERY"],
      });

      setShowModal(false);
      showToast({
        description: "Employee submitted",
        variant: "success",
        seconds: 2,
        onClose: () => {},
      });
    } catch (err) {
      showToast({
        description: "There was an error. Perhaps duplicate pin codes?",
        variant: "error",
        seconds: 2,
        onClose: () => {},
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <div className="mt-3">
        <FormLabel htmlFor="first_name" title="First Name" />
        <Controller
          name="first_name"
          control={control}
          rules={{ required: true }}
          defaultValue={employee ? employee.first_name : ""}
          render={({ field }) => {
            return (
              <FormInput
                type="text"
                {...field}
                onChange={(value) => field.onChange(value)}
              />
            );
          }}
        />
      </div>
      <div className="mt-3">
        <FormLabel htmlFor="last_name" title="Last Name" />
        <Controller
          name="last_name"
          control={control}
          rules={{ required: true }}
          defaultValue={employee ? employee.last_name : ""}
          render={({ field }) => {
            return (
              <FormInput
                type="text"
                {...field}
                onChange={(value) => field.onChange(value)}
              />
            );
          }}
        />
      </div>
      <div className="mt-3">
        <FormLabel htmlFor="email" title="Email" />
        <Controller
          name="email"
          control={control}
          rules={{ required: true }}
          defaultValue={employee ? employee.email : ""}
          render={({ field }) => {
            return (
              <FormInput
                type="email"
                {...field}
                onChange={(value) => field.onChange(value)}
              />
            );
          }}
        />
      </div>
      <div className="mt-3">
        <FormLabel htmlFor="pin_code" title="Pin Code" />
        <Controller
          name="pin_code"
          control={control}
          rules={{ required: true }}
          defaultValue={employee ? employee.pin_code : ""}
          render={({ field }) => {
            return (
              <FormInput
                type="text"
                maxLength={6}
                {...field}
                onChange={(value) => field.onChange(value)}
              />
            );
          }}
        />
      </div>
      <div className="mt-5">
        {employee && <Button className="mr-3">Update</Button>}
        {!employee && <Button className="mr-3">Create</Button>}
        <Button
          type="button"
          backgroundColor="bg-gray-500"
          onClick={() => {
            setShowModal(false);
          }}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};
