import { CreateTagFields, UpdateTagFields } from "../../types";
import { Tags } from "./Tags";
import { useCreateTag } from "./hooks/useCreateTag";
import { useOperationsAllMenuItems } from "./hooks/useOperationsAllMenuItems";
import { useOperationsTags } from "./hooks/useOperationsTags";
import { useUpdateTag } from "./hooks/useUpdateTag";

export const TagsController = () => {
  const { tags, loading: loadingTags, refetch } = useOperationsTags();
  const { data: allMenuItems, loading: loadingAllMenuItems } =
    useOperationsAllMenuItems();

  const { createTag, loading: isCreatingTag } = useCreateTag();
  const { updateTag, loading: isUpdatingTag } = useUpdateTag();

  const onCreateTag = async (data: {
    tag: CreateTagFields;
    menu_item_ids?: [number];
  }) => {
    await createTag({
      variables: {
        ...data,
      },
    });
    refetch();
  };

  const onUpdateTag = async (data: {
    tag: UpdateTagFields;
    menu_item_ids?: [number];
  }) => {
    await updateTag({
      variables: {
        ...data,
      },
    });
    refetch();
  };

  return (
    <Tags
      tags={tags}
      allMenuItems={allMenuItems}
      onCreateTag={onCreateTag}
      onUpdateTag={onUpdateTag}
      loading={
        loadingTags || loadingAllMenuItems || isCreatingTag || isUpdatingTag
      }
    />
  );
};
