import { useQuery, gql } from "@apollo/client";

const OPERATIONS_PREP_STATION_TYPES = gql`
  query OPERATIONS_PREP_STATION_TYPES {
    prep_station_types {
      id
      name
    }
  }
`;

export const usePrepStationTypes = () => {
  const { data, error, loading, refetch } = useQuery(
    OPERATIONS_PREP_STATION_TYPES,
    {
      fetchPolicy: "cache-and-network",
    },
  );
  return { data, error, loading, refetch };
};
