export type Store = {
  id: number;
  brand: OperationsBrand;
  is_active: boolean;
  is_open: boolean;
  location: StoreLocation;
  store_hours: StoreHours[];
};

export type StoreLocation = {
  id: number;
  street: string;
  state: string;
  zipcode: string;
  name: string;
  slug: string;
  is_active: boolean;
};

export type ServiceHours = {
  start_day: number;
  end_day: number;
  start_time: string;
  end_time: string;
};

export type StoreHours = {
  id: number;
  day_of_week: number;
  hours: ServiceHours[];
  override_date: string;
};

export type BuildNote = {
  id: number;
  name: string;
  is_active: boolean;
  removal_modifier_id: number;
  modifier_list_id_order_override: number;
  show_in_kds: boolean;
  show_in_receipt: boolean;
  custom_chit: CustomChit;
};

export type OperationsMenuItem = {
  __typename: string;
  id: number;
  name: string;
  kitchen_name: string;
  item_type: string;
  prep_type?: string;
  image_url?: string;
  build_grid_img_url?: string;
  final_plated_img_url?: string;
  build_note_id: number;
  build_notes: [BuildNote];
  build_note_is_active: boolean;
  is_discrete: boolean;
  modifier_lists: [OperationsModifierList];
  custom_chit: CustomChit;
  tags?: [OperationsTag];
  store_menu_items?: [StoreMenuItem];
  external_id?: string;
};

export type OperationsModifierItem = {
  __typename: string;
  id: number;
  name: string;
  kitchen_name: string;
  item_type: string;
  prep_type?: string;
  image_url?: string;
  build_note_id: number;
  build_notes: [BuildNote];
  build_note_is_active: boolean;
  is_discrete: boolean;
  modifier_lists: [OperationsModifierList];
  custom_chit: CustomChit;
  external_id?: string;
};

export type OperationsModifierList = {
  id: number;
  name: string;
  ordinal: number;
  modifier_items: [OperationsModifierItem];
};

export type OperationsBrand = {
  id: number;
  name: string;
  slug: string;
  menu_items: OperationsMenuItem[];
  modifier_items: OperationsModifierItem[];
  ticker?: string;
  stores: Store[];
};

export type PrepStationTypeV2 = {
  id: number;
  name: string;
};

export type SalesProps = {
  data: any;
  pageNumber: number;
  limit: number;
  totalOrderCount: number;
  loading: boolean;
  filterParams: any;
  raw_cart_total: number;
  raw_cart_subtotal: number;
};

export type Sale = {
  id: number;
  customer_name: string;
  customer_phone: string;
  location: LocationV2;
  provider: ProviderV2;
  brands: BrandV2[];
  subtotal: string;
  inserted_at: string;
  is_pickup: Boolean;
  is_completed: Boolean;
  is_scheduled: Boolean;
  external_id: string;
  external_delivery_id: string;
  stripe_payment_intent_id: string;
  total_order_count: number;
  raw_cart_total: number;
  raw_cart_subtotal: number;
  raw_cart_tax: number;
  raw_cart_tip: number;
  raw_cart_service_fee: number;
  raw_cart_service_fee_rate: number;
  raw_cart_delivery_fee: number;
  raw_cart_credits_used: number;
  raw_cart_discount_amount: number;
  raw_orders_data: string;
  pickup_time: number;
  line_items: LineItemV2[];
  status: string;
  customer_id: number;
};

export type RawOrderData = {};

export type LocationV2 = {
  id: number;
  name: string;
  zipcode: string;
  street: string;
  state: string;
  slug: string;
  timezone: string;
  tax_rate: number;
  share_link: string;
  phone_number: string;
  stripe_location_id: string;
  prep_time: number;
  busy_mode: boolean;
  is_active: boolean;
  default_prep_time: number;
  default_busy_mode_time: number;
  busy_mode_length: number;
};

export type BrandV2 = {
  id: number;
  name: string;
  slug: string;
};

export type ProviderV2 = {
  id: number;
  name: string;
  slug: string;
  revenue_center: string;
};

export type LineItemV2 = {
  id: number;
  ordinal: number;
  name: String;
  customer_name: String;
  price: number;
  quantity: number;
  special_instructions: String;
  is_completed: Boolean;
  kitchen_name: String;
  prep_station_type_id: number;
  menu_item_name_override: String;
  menu_item_id: number;
};

export type OrderEvent = {
  external_id: string;
  inserted_at: number;
  event_date: number;
  name: string;
  provider: string;
  data: string;
};

export type Employee = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  pin_code: string;
};

export type MenuItemV2 = {
  id: number;
  name: string;
};

export type StoreMenuItem = {
  id: number;
  menu_item_id: number;
  price: number;
  tax_rate: number;
  direct_price: number;
  store_menu_category_id: number;
  is_active: boolean;
  is_visible: boolean;
  ordinal: number;
  toast_menu_item_id: number;
  location: StoreLocation;
  store: Store;
};

export type CustomChit = {
  id: number;
  kitchen_name: string;
  menu_items: MenuItemV2[];
};

export type StoreOrderIssue = {
  id: number;
  name: string;
  guest_feedback_channel: GuestFeedbackChannel;
  guest_feedback: string;
  comment: string;
  resolution: string;
  submitted_by: string;
  issue_categories: [IssueCategory];
  store_order_line_item_issues: [StoreOrderLineItemIssue];
};

export type IssueCategory = {
  id: number;
  name: string;
};

export type StoreOrderLineItemIssue = {
  id: number;
  store_order_line_item_id: number;
  store_order_line_item_name: string;
};

export type GuestFeedbackChannel = {
  id: number;
  name: string;
};

export type UOM = {
  id: number;
  name: string;
};

export type Ingredient = {
  id: number;
  name: string;
  uoms: UOM[];
};

export type ColumnInfo = {
  name: string;
  selector?: Function;
  headerName: string;
  defaultDisplay: boolean;
  ordinal: number;
};

export type ColumnVisibility = {
  [columnName: string]: boolean;
};

export const PlatformMap = {
  kiosk: "Kiosk",
  guest_web: "Website",
  landing: "Landing (localkitchens.com)",
  guest_mobile: "Mobile App",
  guest_kiosk: "Kiosk",
};

export type PlatformMapTypes = typeof PlatformMap;

export type PublicMenuItem = {
  id: number;
  menu_item_id: number;
  name: string;
  image_url?: string;
  blurhash?: string;
  description?: string;
};

export type MenuItem = {
  id: number;
  name: string;
  image_url?: string;
};

export type MenuItemPairing = {
  id: number;
  pairing_id: number;
  ordinal: number;
};

export type CateringOrder = {
  id: number;
  invoice_id?: string;
  external_id: string;
  invoice_paid_at?: string;
  tax?: number;
  tax_rate?: number;
  discount?: number;
  total?: number;
  subtotal?: number;
  tip?: number;
  tip_rate?: number;
  customer_delivery_fee?: number;
  service_fee?: number;
  service_fee_rate?: number;
};

export type OrderFilterFormParams = {
  id: string;
  customerName: string;
  email: string;
  phoneNumber: string;
  locationID: string;
  brandID: string;
  providerID: string;
  startDate: number;
  endDate: number;
  externalID: string;
  ticketNumber: string;
};

export type OperationsTag = {
  id: number;
  name: string;
  is_v2: boolean;
  is_active: boolean;
  is_filter: boolean;
  is_collection: boolean;
  slug: string;
  collection_name: string;
  ordinal: number;
  logo_url: string;
  logo_url_v2: string;
  logo_blurhash: string;
  logo_blurhash_v2: string;
  hero_image_url: string;
  hero_image_url_v2: string;
  hero_image_blurhash: string;
  hero_image_blurhash_v2: string;
  menu_item_tags: MenuItemTag[];
};

export type CreateTagFields = {
  logo?: string | ArrayBuffer | null | undefined;
  logo_v2?: string | ArrayBuffer | null | undefined;
  hero_image?: string | ArrayBuffer | null | undefined;
  hero_image_v2?: string | ArrayBuffer | null | undefined;
} & Partial<
  Pick<
    OperationsTag,
    | "name"
    | "is_v2"
    | "is_active"
    | "is_filter"
    | "is_collection"
    | "ordinal"
    | "logo_url"
    | "logo_url_v2"
    | "logo_blurhash"
    | "logo_blurhash_v2"
    | "hero_image_url"
    | "hero_image_url_v2"
    | "hero_image_blurhash"
    | "hero_image_blurhash_v2"
  >
>;

export type UpdateTagFields = { id: number } & Omit<CreateTagFields, "is_v2">;

export type MenuItemTag = {
  id: number;
  tag_id: number;
  menu_item_id: number;
  ordinal: number;
};
