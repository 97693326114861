import { Dispatch, SetStateAction } from "react";
import { Modal } from "../../../ui";
import { Brand, CreateMarketingBillboardInput, Location } from "../types";
import MarketingBillboardForm from "./MarketingBillboardForm";

type CreateMarketingBillboardModalProps = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  onCreateBillboard: (data: CreateMarketingBillboardInput) => Promise<void>;
  brands: Brand[];
  locations: Location[];
};

const CreateMarketingBillboardModal = ({
  showModal,
  setShowModal,
  onCreateBillboard,
  brands,
  locations,
}: CreateMarketingBillboardModalProps) => {
  return (
    <Modal
      title="New Feature Tile"
      showModal={showModal}
      setShowModal={setShowModal}
      size={5}
    >
      <MarketingBillboardForm
        onSubmit={onCreateBillboard}
        setShowModal={setShowModal}
        brands={brands}
        locations={locations}
      />
    </Modal>
  );
};

export default CreateMarketingBillboardModal;
