import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  from,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/link-context";
import { useAuth } from "@localkitchens/passwordless-auth";
import React, { useMemo } from "react";
import { Loading } from "../ui/components/Loading";

type ApolloWrapperProps = {
  children: React.ReactNode;
};

const uri = `${process.env.REACT_APP_API_URL}/graphql`;
const httpLink = createHttpLink({ uri });

const headersLink = (token?: string | undefined) => {
  const appendedHeaders: {
    Authorization?: string;
  } = {
    Authorization: `JWT ${token}`,
  };

  return setContext((_, { headers, ...rest }) => ({
    headers: {
      ...headers,
      ...appendedHeaders,
    },
    ...rest,
  }));
};

export const ApolloWrapper = ({ children }: ApolloWrapperProps) => {
  const { authToken, isLoading } = useAuth();
  const client = useMemo(() => {
    return new ApolloClient({
      link: from([headersLink(authToken), httpLink]),
      cache: new InMemoryCache({
        typePolicies: {
          Customer: {
            merge: true,
          },
        },
      }),
      connectToDevTools: true,
      name: "operations",
    });
  }, [authToken, isLoading]);
  if (isLoading) {
    return <Loading />;
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
