import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "@localkitchens/passwordless-auth";
import { Identifier } from "dnd-core";
import {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import { useDrop } from "react-dnd";
import { Controller, useForm } from "react-hook-form";
import {
  CreateTagFields,
  MenuItem,
  OperationsTag,
  UpdateTagFields,
} from "../../types";
import {
  FormCheckbox,
  FormInput,
  FormLabel,
  Modal,
  Table,
  TableData,
  TableRow,
} from "../../ui/components";
import { Button } from "../../ui/components/Button/Button";
import ItemImage from "../../ui/components/Image/ItemImage";
import { Loading } from "../../ui/components/Loading";
import { PageContent } from "../../ui/components/PageContent/PageContent";
import { DragItem } from "../../ui/components/SearchAndDrag/DraggableItem";
import { ItemTypes } from "../../ui/components/SearchAndDrag/SearchAndDrag";
import Toggle from "../../ui/components/Toggle";
import ToastContext from "../../utils/contexts/ToastContext";
import convertToBase64 from "../../utils/convertToBase64";
import { getExtension, getResponsiveImageUrl } from "../../utils/formatters";
import FileDropZone from "../KitchenNames/components/FileDropZone";
import { MenuItemSearchAndDrag } from "./MenuItemSearchAndDrag";
import useBulkUpdateTags from "./hooks/useBulkUpdateTags";

interface TagsProp {
  tags?: OperationsTag[];
  allMenuItems: MenuItem[];
  onCreateTag: (data: {
    tag: CreateTagFields;
    menu_item_ids?: [number];
  }) => void;
  onUpdateTag: (data: {
    tag: UpdateTagFields;
    menu_item_ids?: [number];
  }) => void;
  loading: boolean;
}

export const Tags = ({
  tags,
  allMenuItems,
  onCreateTag,
  onUpdateTag,
  loading: _loading,
}: TagsProp) => {
  const { user: currentUser, isLoading: isLoadingAuth } = useAuth();
  const loading = _loading || isLoadingAuth;

  const userRoles = currentUser
    ? new Set(currentUser["https://app.localkitchens.co/roles"])
    : new Set();

  const canCreateTags = userRoles.has("ENGINEER") || userRoles.has("ADMIN");

  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);

  if (loading) {
    return <Loading />;
  }

  return (
    <PageContent>
      <div className="flex justify-between">
        <h1 className="text-xl font-semibold text-gray-900">TAGS</h1>
        {canCreateTags && (
          <Button
            className="flex items-center"
            type="button"
            onClick={() => setShowCreateModal(true)}
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Create Tag
          </Button>
        )}
      </div>

      <TagTableSection
        tags={tags}
        allMenuItems={allMenuItems}
        onUpdateTag={onUpdateTag}
      />

      <CreateTagModal
        showModal={showCreateModal}
        setShowModal={setShowCreateModal}
        onCreateTag={onCreateTag}
        allMenuItems={allMenuItems}
      />
    </PageContent>
  );
};

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

type TagTableSectionProps = {
  tags?: OperationsTag[];
  onUpdateTag: (data: {
    tag: UpdateTagFields;
    menu_item_ids?: [number];
  }) => void;
  allMenuItems: MenuItem[];
};

export default function TagTableSection({
  onUpdateTag,
  tags: _tags = [],
  allMenuItems,
}: TagTableSectionProps) {
  const { showToast } = useContext(ToastContext);

  const [tags, setTags] = useState<OperationsTag[]>([]);

  useEffect(() => {
    const tempTags: OperationsTag[] = JSON.parse(JSON.stringify(_tags));
    tempTags.sort(tagSortFunction);

    setTags(tempTags);
  }, [_tags, setTags]);

  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
  const [tagToUpdate, setTagToUpdate] = useState<any>(null);

  const { bulkUpdateTags, loading: loadingBulkUpdateTags } =
    useBulkUpdateTags();

  const onClickTagRow = (tag: OperationsTag) => {
    setTagToUpdate({
      ...tag,
    });
    setShowUpdateModal(true);
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const reorderedTags = reorder(
      tags,
      result.source.index,
      result.destination!.index,
    );

    updateTags(reorderedTags);
  };

  // Sort tags using tagSortFunction
  const sortTags = useCallback((tags: OperationsTag[]) => {
    const sortedTags = JSON.parse(JSON.stringify(tags));
    sortedTags.sort(tagSortFunction);
    return sortedTags;
  }, []);

  // Update tags ordinals and handle API calls
  const updateTags = useCallback(
    (newTags: OperationsTag[]) => {
      const changedTags: Pick<OperationsTag, "id" | "ordinal">[] = [];
      const tagCallbacks: Function[] = [];

      const updatedTags = newTags.map((tag, index) => {
        if (tag.ordinal !== index) {
          tagCallbacks.push(() =>
            analytics.track("Tag Ordinal Updated", {
              tagId: tag.id,
              previousOrdinal: tag.ordinal,
              newOrdinal: index,
            }),
          );

          changedTags.push({ id: tag.id, ordinal: index });
        }

        return { ...tag, ordinal: index };
      });

      if (changedTags.length) {
        bulkUpdateTags({ variables: { tags: changedTags } })
          .then(() => {
            tagCallbacks.forEach((callback) => callback());
          })
          .catch((error) => {
            showToast({
              description: "Tag order has not been updated",
              variant: "error",
              seconds: 2,
              onClose: () => {},
            });
            window.location.reload();
          });
        setTags(sortTags(updatedTags));
      }
    },
    [sortTags, bulkUpdateTags, showToast],
  );

  const tableHeaders = [
    "Name",
    "Slug",
    "Is V2?",
    "Is Filter?",
    "Is Active?",
    "Header Image",
    "Header Image v2",
  ];

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="tags-droppable">
          {(droppableProvided) => (
            <Table
              headers={tableHeaders}
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
              {loadingBulkUpdateTags && <LoadingOverlay />}

              {tags.map((tag: OperationsTag, index: number) => {
                const toggleFilter = (e: MouseEvent<HTMLDivElement>) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onUpdateTag({
                    tag: { id: tag.id, is_filter: !tag.is_filter },
                  });
                };
                const toggleActive = (e: MouseEvent<HTMLDivElement>) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onUpdateTag({
                    tag: { id: tag.id, is_active: !tag.is_active },
                  });
                };
                return (
                  <Draggable
                    key={tag.id}
                    draggableId={"" + tag.id}
                    index={index}
                  >
                    {(draggableProvided) => {
                      return (
                        <TableRow
                          rowColor={index % 2 ? "bg-white" : "bg-gray-50"}
                          onClick={() => {
                            onClickTagRow(tag);
                          }}
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          style={draggableProvided.draggableProps.style}
                        >
                          <TableData className="w-36">
                            <div className="flex items-center">
                              {!tag.is_v2 && tag.logo_url ? (
                                <ItemImage
                                  className="w-8 truncate text-ellipsis mr-2"
                                  url={getResponsiveImageUrl(
                                    tag?.logo_url,
                                    "320w",
                                  )}
                                />
                              ) : null}
                              {tag.is_v2 && tag.logo_url_v2 ? (
                                <ItemImage
                                  className="w-8 truncate text-ellipsis mr-2"
                                  url={getResponsiveImageUrl(
                                    tag?.logo_url_v2,
                                    "320w",
                                  )}
                                />
                              ) : null}
                              <p className="w-36 truncate text-ellipsis">
                                {tag.name}
                              </p>
                            </div>
                          </TableData>
                          <TableData className="w-36">
                            <p className="w-36 truncate text-ellipsis">
                              {tag.slug}
                            </p>
                          </TableData>
                          <TableData
                            className="w-36"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            {/* Not allowed to change v1/v2 state after creation */}
                            <Toggle initialValue={tag.is_v2} disabled />
                          </TableData>
                          <TableData
                            className="w-36"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Toggle
                              initialValue={tag.is_filter}
                              onChange={toggleFilter}
                            />
                          </TableData>
                          <TableData
                            className="w-36"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Toggle
                              initialValue={tag.is_active}
                              onChange={toggleActive}
                            />
                          </TableData>
                          <TableData className="object-cover h-10 w-48">
                            {tag.hero_image_url ? (
                              <ItemImage
                                className="truncate text-ellipsis mr-2 object-cover h-10 w-full"
                                url={getResponsiveImageUrl(
                                  tag?.logo_url,
                                  "320w",
                                )}
                              />
                            ) : null}
                          </TableData>
                          <TableData className="object-cover h-10 w-48">
                            {tag.hero_image_url_v2 ? (
                              <ItemImage
                                className="truncate text-ellipsis mr-2 object-cover h-10 w-full"
                                url={getResponsiveImageUrl(
                                  tag?.logo_url_v2,
                                  "320w",
                                )}
                              />
                            ) : null}
                          </TableData>
                        </TableRow>
                      );
                    }}
                  </Draggable>
                );
              })}
              {droppableProvided.placeholder}
            </Table>
          )}
        </Droppable>
      </DragDropContext>
      <UpdateTagModal
        showModal={showUpdateModal}
        setShowModal={setShowUpdateModal}
        tagToUpdate={tagToUpdate}
        onUpdateTag={onUpdateTag}
        allMenuItems={allMenuItems}
      />
    </>
  );
}

type TagFormProps = {
  onCreateTag?: (data: {
    tag: CreateTagFields;
    menu_item_ids?: [number];
  }) => void;
  onUpdateTag?: (data: {
    tag: UpdateTagFields;
    menu_item_ids?: [number];
  }) => void;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  mode: "create" | "update";
  tag?: OperationsTag | null;
  allMenuItems: MenuItem[];
};

const TagForm = ({
  onCreateTag,
  onUpdateTag,
  setShowModal,
  mode,
  tag,
  allMenuItems,
}: TagFormProps) => {
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
  } = useForm();

  const { showToast } = useContext(ToastContext);
  const watchIsV2 = watch("is_v2");
  const watchLogoUrl = watch("logo_url");
  const watchLogoUrlV2 = watch("logo_url_v2");
  const watchHeroImageUrl = watch("hero_image_url");
  const watchHeroImageUrlV2 = watch("hero_image_url_v2");

  const onSubmitHandler = async (data: any) => {
    setLoading(true);

    const baseTagData = {
      is_filter: data.is_filter,
      is_active: data.is_active,
      is_collection: data.is_collection,
      ordinal: data.ordinal,
      logo_url: tag?.logo_url,
      logo_url_v2: tag?.logo_url_v2,
      logo_blurhash: tag?.logo_blurhash,
      logo_blurhash_v2: tag?.logo_blurhash_v2,
      hero_image_url: tag?.hero_image_url,
      hero_image_url_v2: tag?.hero_image_url_v2,
      hero_image_blurhash: tag?.hero_image_blurhash,
      hero_image_blurhash_v2: tag?.hero_image_blurhash_v2,
      logo:
        data.logo_url && typeof data.logo_url === "object"
          ? await convertToBase64(data.logo_url)
          : undefined,
      logo_v2:
        data.logo_url_v2 && typeof data.logo_url_v2 === "object"
          ? await convertToBase64(data.logo_url_v2)
          : undefined,
      hero_image:
        data.hero_image_url && typeof data.hero_image_url === "object"
          ? await convertToBase64(data.hero_image_url)
          : undefined,
      hero_image_v2:
        data.hero_image_url_v2 && typeof data.hero_image_url_v2 === "object"
          ? await convertToBase64(data.hero_image_url_v2)
          : undefined,
    };

    const createTagData: CreateTagFields = {
      ...baseTagData,
      name: data.name,
      is_v2: Boolean(data.is_v2),
    };

    const updateTagData: UpdateTagFields = {
      ...baseTagData,
      id: tag?.id!,
      name: tag?.name,
    };

    try {
      if (mode === "create") {
        await onCreateTag?.({
          tag: createTagData,
          menu_item_ids: data.tag_menu_item_ids || [],
        });
      } else {
        await onUpdateTag?.({
          tag: updateTagData,
          menu_item_ids: data.tag_menu_item_ids || [],
        });
      }

      showToast({
        description: `Tag was ${mode === "create" ? "created" : "updated"}.`,
        variant: "success",
        seconds: 3,
        onClose: () => {},
      });

      setShowModal(false);
    } catch (err) {
      showToast({
        description: `Tag could not be ${
          mode === "create" ? "created" : "updated"
        }.`,
        variant: "error",
        seconds: 3,
        onClose: () => {},
      });
    }
    setLoading(false);
  };

  const [, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null; isOver: boolean }
  >({
    accept: ItemTypes.selectedItem,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: monitor.isOver(),
      };
    },
  });

  if (loading) {
    return (
      <div className="bg-gray-100 bg-opacity-50 w-full h-full absolute items-center flex rounded-lg">
        <Loading />
      </div>
    );
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmitHandler)}
      ref={drop}
      className="relative"
    >
      <div className="grid grid-cols-3 gap-6 my-5">
        <div className="col-span-3">
          <FormLabel
            title="Name"
            htmlFor="name"
            information="Guest-facing name of the tag that appears on our platforms."
          />
          <Controller
            name="name"
            control={control}
            defaultValue={tag ? tag.name : ""}
            rules={{ required: false }}
            render={({ field }) => (
              <FormInput
                type="text"
                id="name"
                {...field}
                disabled={mode === "update"}
                className={`flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-md sm:text-sm border-gray-300 ${
                  mode === "update"
                    ? "cursor-not-allowed bg-gray-100"
                    : "cursor-text"
                }`}
              />
            )}
          />
        </div>
        <div className="col-span-3">
          <FormLabel
            title="Is V2?"
            htmlFor="is_v2"
            information={
              "V2 tags only show up in the redesigned UI. You can't change this after creating a tag!"
            }
            labelClass="text-base font-medium text-gray-700 cursor-pointer"
            disabled={mode === "update"}
          />
          <Controller
            name="is_v2"
            control={control}
            defaultValue={Boolean(tag?.is_v2)}
            render={({ field }) => (
              <FormCheckbox
                type="checkbox"
                id="is_v2"
                checked={field.value}
                {...field}
                disabled={mode === "update"}
              />
            )}
          />
        </div>
        <div className="col-span-3">
          <FormLabel
            title="Logo URL"
            htmlFor="logo_url"
            information="Logo URL"
            disabled={watchIsV2}
          />
          <Controller
            name="logo_url"
            control={control}
            defaultValue={tag ? tag?.logo_url : ""}
            rules={{ required: false }}
            render={({ field }) => {
              const logoExtension = getExtension(tag?.logo_url) || "jpg";
              return (
                <FileDropZone
                  className="h-40 text-center"
                  onChange={(files: File[]) => {
                    field.onChange(files?.[0]);
                  }}
                  disabled={Boolean(tag?.is_v2 || watchIsV2)}
                >
                  {tag?.logo_url || watchLogoUrl ? (
                    <img
                      alt="The logo for this tag"
                      src={
                        typeof watchLogoUrl === "object"
                          ? URL.createObjectURL(watchLogoUrl)
                          : tag?.logo_url.replace(
                              `.${logoExtension}`,
                              `-640w.${logoExtension}`,
                            )
                      }
                      className="h-40 object-contain"
                    />
                  ) : null}
                </FileDropZone>
              );
            }}
          />
        </div>

        <div className="col-span-3">
          <FormLabel
            title="Logo URL V2"
            htmlFor="logo_url_v2"
            information="Logo URL V2"
            disabled={!Boolean(tag?.is_v2 || watchIsV2)}
          />
          <Controller
            name="logo_url_v2"
            control={control}
            defaultValue={tag ? tag?.logo_url_v2 : ""}
            rules={{ required: false }}
            render={({ field }) => {
              const logoExtensionV2 = getExtension(tag?.logo_url_v2) || "jpg";
              return (
                <FileDropZone
                  className="h-40 text-center"
                  onChange={(files: File[]) => {
                    field.onChange(files?.[0]);
                  }}
                  disabled={!Boolean(tag?.is_v2 || watchIsV2)}
                >
                  {tag?.logo_url_v2 || watchLogoUrlV2 ? (
                    <img
                      alt="The logo for this tag"
                      src={
                        typeof watchLogoUrlV2 === "object"
                          ? URL.createObjectURL(watchLogoUrlV2)
                          : tag?.logo_url_v2?.replace(
                              `.${logoExtensionV2}`,
                              `-640w.${logoExtensionV2}`,
                            )
                      }
                      className="h-40 object-contain"
                    />
                  ) : null}
                </FileDropZone>
              );
            }}
          />
        </div>

        <div className="col-span-3">
          <FormLabel
            title="Food Hero image URL"
            htmlFor="hero_image_url"
            information="Image Hero URL"
            disabled={watchIsV2}
          />
          <Controller
            name="hero_image_url"
            control={control}
            defaultValue={tag ? tag?.hero_image_url : ""}
            rules={{ required: false }}
            render={({ field }) => {
              const heroExtension = getExtension(tag?.hero_image_url) || "jpg";
              return (
                <FileDropZone
                  className="h-40"
                  onChange={(files: File[]) => {
                    field.onChange(files?.[0]);
                  }}
                  disabled={Boolean(tag?.is_v2 || watchIsV2)}
                >
                  {tag?.hero_image_url || watchHeroImageUrl ? (
                    <img
                      alt="Displays as a larger background on the tag UI"
                      src={
                        typeof watchHeroImageUrl === "object"
                          ? URL.createObjectURL(watchHeroImageUrl)
                          : tag?.hero_image_url.replace(
                              `.${heroExtension}`,
                              `-640w.${heroExtension}`,
                            )
                      }
                      className="h-40 object-cover"
                    />
                  ) : null}
                </FileDropZone>
              );
            }}
          />
        </div>

        <div className="col-span-3">
          <FormLabel
            title="Food Hero Image URL V2"
            htmlFor="hero_image_url_v2"
            information="Image Hero URL V2"
            disabled={!Boolean(tag?.is_v2 || watchIsV2)}
          />
          <Controller
            name="hero_image_url_v2"
            control={control}
            defaultValue={tag?.hero_image_url_v2 ?? ""}
            rules={{ required: false }}
            render={({ field }) => {
              const heroExtensionV2 =
                getExtension(tag?.hero_image_url_v2) || "jpg";
              return (
                <FileDropZone
                  className="h-40"
                  onChange={(files: File[]) => {
                    field.onChange(files?.[0]);
                  }}
                  disabled={!Boolean(tag?.is_v2 || watchIsV2)}
                >
                  {tag?.hero_image_url_v2 || watchHeroImageUrlV2 ? (
                    <img
                      alt="Displays as a larger background on the tag UI"
                      src={
                        typeof watchHeroImageUrlV2 === "object"
                          ? URL.createObjectURL(watchHeroImageUrlV2)
                          : tag?.hero_image_url_v2.replace(
                              `.${heroExtensionV2}`,
                              `-640w.${heroExtensionV2}`,
                            )
                      }
                      className="h-40 object-cover"
                    />
                  ) : null}
                </FileDropZone>
              );
            }}
          />
        </div>

        <div className="col-span-3">
          <FormLabel
            title="Menu Items"
            htmlFor="tag_menu_item_ids"
            information={"Menu items to show on tag form"}
          />
          <Controller
            name="tag_menu_item_ids"
            control={control}
            defaultValue={
              tag?.menu_item_tags?.map((tag) => tag.menu_item_id) ?? []
            }
            rules={{ required: false }}
            render={({ field }) => (
              <MenuItemSearchAndDrag
                selectedValues={field.value}
                menuItems={allMenuItems}
                onChange={(val: number[]) => {
                  field.onChange({ target: { value: val } });
                }}
              />
            )}
          />
        </div>
      </div>
      <Button className="mr-3">Save</Button>
      <Button
        type="button"
        backgroundColor="bg-gray-500"
        onClick={() => {
          setShowModal(false);
        }}
      >
        Cancel
      </Button>
    </form>
  );
};
type UpdateTagModalProps = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  onUpdateTag: (data: {
    tag: UpdateTagFields;
    menu_item_ids?: [number];
  }) => void;
  tagToUpdate: OperationsTag;
  allMenuItems: MenuItem[];
};

const UpdateTagModal = ({
  showModal,
  setShowModal,
  onUpdateTag,
  tagToUpdate,
  allMenuItems,
}: UpdateTagModalProps) => {
  const [, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null; isOver: boolean }
  >({
    accept: ItemTypes.selectedItem,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: monitor.isOver(),
      };
    },
  });
  return (
    <Modal
      title="Edit Tag"
      showModal={showModal}
      setShowModal={setShowModal}
      size={6}
      ref={drop}
    >
      <TagForm
        onUpdateTag={onUpdateTag}
        setShowModal={setShowModal}
        tag={tagToUpdate}
        allMenuItems={allMenuItems}
        mode="update"
      />
    </Modal>
  );
};

type CreateTagModalProps = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  onCreateTag: (data: {
    tag: CreateTagFields;
    menu_item_ids?: [number];
  }) => void;
  allMenuItems: MenuItem[];
};

const CreateTagModal = ({
  showModal,
  setShowModal,
  onCreateTag,
  allMenuItems,
}: CreateTagModalProps) => {
  const [, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null; isOver: boolean }
  >({
    accept: ItemTypes.selectedItem,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: monitor.isOver(),
      };
    },
  });
  return (
    <Modal
      title="Create Tag"
      showModal={showModal}
      setShowModal={setShowModal}
      size={6}
      ref={drop}
    >
      <TagForm
        onCreateTag={onCreateTag}
        setShowModal={setShowModal}
        mode="create"
        allMenuItems={allMenuItems}
      />
    </Modal>
  );
};

export function tagSortFunction(tempA: OperationsTag, tempB: OperationsTag) {
  return tempA.ordinal < tempB.ordinal ? -1 : 1;
}

const LoadingOverlay = () => (
  <div className="absolute w-full h-full">
    <div className="absolute w-full h-full bg-white" />
    <div className="absolute w-full h-full">
      <Loading />
    </div>
  </div>
);
