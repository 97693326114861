import { gql, useMutation } from "@apollo/client";

const DELETE_PRINTER = gql`
  mutation DeletePrinter($printer_id: Int) {
    delete_printer(printer_id: $printer_id) {
      id
    }
  }
`;

export const useDeletePrinter = () => {
  const [deletePrinter] = useMutation(DELETE_PRINTER, {
    refetchQueries: ["PRINTERS_BY_LOCATION_ID_QUERY"],
  });

  return deletePrinter;
};
