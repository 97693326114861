import { useCreateLocationQuery } from "opsapi/hooks/operations/locations";
import { useContext } from "react";
import { useHistory } from "react-router";
import { PageContent } from "../../../ui/components/PageContent/PageContent";
import ToastContext from "../../../utils/contexts/ToastContext";
import { LocationForm } from "./LocationForm";

interface Props {}

export const AddLocation = (props: Props) => {
  const history = useHistory();
  const { mutate: addLocation } = useCreateLocationQuery({
    reactQuery: {
      onSuccess: () => {
        showToast({
          description: `Location was added successfully`,
          variant: "success",
          seconds: 2,
        });
      },
      onError: (error) => {
        showToast({
          description: error.message,
          variant: "error",
          seconds: 10,
        });
      },
    },
  });

  const { showToast } = useContext(ToastContext);

  const onSubmit = async (data: any) => {
    addLocation(data);
  };

  return (
    <PageContent>
      <h1 className="text-3xl font-semibold text-gray-900 mr-3">
        New Location
      </h1>
      <LocationForm onSubmit={onSubmit} />
    </PageContent>
  );
};
