import { useMutation, gql } from "@apollo/client";
import { OPERATIONS_BRANDS_QUERY } from "./useOperationsBrands";
import { OPERATIONS_BRAND_QUERY } from "./useOperationsBrand";

const MENU_ITEM_PREP_STATION_TYPE = gql`
  mutation MenuItemPrepStationType(
    $id: Int
    $prep_station_type_id: Int
    $menu_item_id: Int
    $menu_item_name_override: String
  ) {
    create_or_update_menu_item_prep_station_type(
      id: $id
      prep_station_type_id: $prep_station_type_id
      menu_item_id: $menu_item_id
      menu_item_name_override: $menu_item_name_override
    )
  }
`;

export type CreateOrUpdateMenuItemPrepStationTypeProps = {
  id: number;
  prep_station_type_id: number;
  menu_item_id: number;
  menu_item_name_override: string;
};

export const useCreateOrMenuItemPrepStationTypeProps = (brandSlug?: string) => {
  const [createOrUpdateMenuItemPrepStationType, { loading }] = useMutation(
    MENU_ITEM_PREP_STATION_TYPE,
  );

  const onCreateOrUpdateMenuItemPrepStationType = async ({
    id,
    prep_station_type_id,
    menu_item_id,
    menu_item_name_override,
  }: CreateOrUpdateMenuItemPrepStationTypeProps) => {
    return await createOrUpdateMenuItemPrepStationType({
      variables: {
        id,
        prep_station_type_id,
        menu_item_id,
        menu_item_name_override,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: OPERATIONS_BRAND_QUERY,
          variables: {
            input: {
              brand_slug: brandSlug,
            },
          },
        },
      ],
    });
  };

  return { onCreateOrUpdateMenuItemPrepStationType, loading };
};
