import { useMutation, gql } from "@apollo/client";

const ADD_CREDITS_TO_LOYALTY_ACCOUNT = gql`
  mutation AddCreditsToLoyaltyAccount($input: AddCreditsToLoyaltyAccountInput) {
    add_credits_to_loyalty_account(input: $input) {
      id
      credits
    }
  }
`;

type AddCreditsToLoyaltyAccountProps = {
  id: number;
  credits: number;
  reason: string;
};

export const useAddCreditsToLoyaltyAccount = () => {
  const [addCreditsToLoyaltyAccountMutation] = useMutation(
    ADD_CREDITS_TO_LOYALTY_ACCOUNT,
  );

  const addCreditsToLoyaltyAccount = async ({
    id,
    credits,
    reason,
  }: AddCreditsToLoyaltyAccountProps) => {
    return await addCreditsToLoyaltyAccountMutation({
      variables: {
        input: {
          id,
          credits,
          reason,
        },
      },
    });
  };

  return addCreditsToLoyaltyAccount;
};
