import { useMutation, gql } from "@apollo/client";

const BULK_UPDATE_STORES = gql`
  mutation BulkUpdateStores($stores: [StoreRecordInput]) {
    bulk_update_stores(stores: $stores)
  }
`;

const useBulkUpdateStores = () => {
  const [bulkUpdateStores, { error }] = useMutation(BULK_UPDATE_STORES, {
    refetchQueries: ["ALL_STORES_QUERY"],
  });
  return { bulkUpdateStores, error };
};

export default useBulkUpdateStores;
