import { useMutation, gql } from "@apollo/client";

const PERMANENTLY_DEACTIVATE_STORE = gql`
  mutation PERMANENTLY_DEACTIVATE_STORE($store_id: Int) {
    permanently_deactivate_store(store_id: $store_id)
  }
`;

const usePermanentlyDeactivateStore = () => {
  const [permanently_deactivate_store, { error }] = useMutation(
    PERMANENTLY_DEACTIVATE_STORE,
    {
      refetchQueries: ["StoreById"],
    },
  );
  return { permanently_deactivate_store, error };
};

export default usePermanentlyDeactivateStore;
