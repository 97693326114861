import { useQuery, gql } from "@apollo/client";

const STORE_BY_ID_QUERY = gql`
  query StoreById($store_id: Int) {
    store_by_id(store_id: $store_id) {
      id
      start_timestamp
      ubereats_store_id
      doordash_store_id
      is_active
      is_open
      updated_at
      ordinal
      location {
        id
        name
        slug
      }
      brand {
        id
        name
      }
      store_hours {
        id
        day_of_week
        hours {
          start_day
          end_day
          start_time
          end_time
        }
      }
      menus {
        doordash_menu_id
        ubereats_menu_id
        grubhub_menu_id
        toast_menu_id
      }
    }
  }
`;

interface StoreByIDProps {
  store_id: number;
}
const useStoreById = ({ store_id }: StoreByIDProps) => {
  const { data, error, loading, refetch } = useQuery(STORE_BY_ID_QUERY, {
    variables: {
      store_id,
    },
  });
  return { data, error, loading, refetch };
};

export default useStoreById;
