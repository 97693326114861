import { useQuery, gql } from "@apollo/client";

export const SALES_BRANDS_QUERY = gql`
  query SALES_BRANDS_QUERY {
    brands {
      id
      name
      slug
    }
  }
`;

export const useSalesBrands = () => {
  const { data, error, loading, refetch } = useQuery(SALES_BRANDS_QUERY, {
    fetchPolicy: "cache-and-network",
  });
  return { data, error, loading, refetch };
};
