import {
  faCopy,
  faEyeSlash,
  faImage,
  faPager,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import { get } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useHistory } from "react-router-dom";
import { OperationsBrandListItem } from "../../graphql/types";
import {
  OperationsBrand,
  OperationsMenuItem,
  OperationsModifierItem,
  PrepStationTypeV2,
} from "../../types";
import { Modal, TableData, TableRow } from "../../ui/components";
import ItemImage from "../../ui/components/Image/ItemImage";
import ToastContext from "../../utils/contexts/ToastContext";
import { getResponsiveImageUrl } from "../../utils/formatters";
import { isStoreMenuItemActive } from "../../utils/utils";
import { useOperationsAllMenuItems } from "../Tags/hooks/useOperationsAllMenuItems";
import { ActiveLocationPills } from "./components/ActiveLocationPills";
import { EditItemForm } from "./components/EditItemForm";
import { EditModifierItemForm } from "./components/EditModifierItemForm";
import { ItemTypePill } from "./components/ItemTypePill";
import { MenuItemTagPills } from "./components/MenuItemTagPill";
import { RecommendedPairingsModal } from "./components/RecommendedPairingsModal";
import UploadItemImageForm from "./components/UploadItemImageForm";
import UploadPeekWindowImageForm from "./components/UploadPeekWindowImageForm";
import { UpdateKitchenNamesProps } from "./hooks/useKitchenNameMutation";
import { UploadMenuItemBuildGridImageProps } from "./hooks/useUploadMenuItemBuildGridImage";
import { UploadMenuItemImageProps } from "./hooks/useUploadMenuItemImage";
import { UploadMenuItemPlatedImageProps } from "./hooks/useUploadMenuItemPlatedImage";

type KitchenNamesProps = {
  brandsList: OperationsBrandListItem[];
  brand?: OperationsBrand;
  prepStationTypes: PrepStationTypeV2[];
  onUpdateKitchenNames: ({ items }: UpdateKitchenNamesProps) => Promise<any>;
  onCreateOrUpdateBuildNotes: (args: any) => void;
  onDeleteMenuItemBuildNotes: (args: any) => void;
  onCreateOrUpdateMenuItemPrepStationType: ({
    id,
    prep_station_type_id,
    menu_item_id,
    menu_item_name_override,
  }: any) => Promise<any>;
  onUploadMenuItemImage: (args: UploadMenuItemImageProps) => Promise<any>;
  onUploadMenuItemBuildGridImage: (
    args: UploadMenuItemBuildGridImageProps,
  ) => Promise<any>;
  onUploadMenuItemPlatedImage: (
    args: UploadMenuItemPlatedImageProps,
  ) => Promise<any>;
  uploadingImage: boolean;
  onUpdateModifierItem: (item: any, data: any) => Promise<any>;
};

const headers = [
  {
    name: "Name",
    key: "name",
  },
  {
    name: "Type",
    key: "item_type",
  },
  {
    name: "Tags",
    key: "tags",
  },
  {
    name: "Kitchen Name",
    key: "kitchen_name",
  },
  {
    name: "Actions",
    key: "actions",
  },
  {
    name: "Active Locations",
    key: "active_locations",
  },
  {
    name: "External ID",
    key: "external_id",
  },
  {
    name: "Recommended Pairings",
    key: "recommended_pairings",
  },
  {
    name: "Peek Window Images",
    key: "peek_window_images",
  },
];

export const KitchenNames = ({
  brandsList,
  brand,
  uploadingImage,
  onUpdateKitchenNames,
  onCreateOrUpdateBuildNotes,
  onDeleteMenuItemBuildNotes,
  onUploadMenuItemImage,
  onUploadMenuItemBuildGridImage,
  onUploadMenuItemPlatedImage,
  onUpdateModifierItem,
}: KitchenNamesProps) => {
  const { showToast } = useContext(ToastContext);
  const [copied, setCopied] = useState<string | undefined>();
  const [items, setItems] = useState<
    | OperationsMenuItem[]
    | OperationsModifierItem[]
    | (OperationsModifierItem & OperationsMenuItem)[]
  >();

  const [item, setItem] = useState<
    | OperationsMenuItem
    | OperationsModifierItem
    | (OperationsModifierItem & OperationsMenuItem)
  >();
  const [showModal, setShowModal] = useState(false);
  const [changedItemsMap, setChangedItemsMap] = useState(
    new Map<number, any>(),
  );
  const [isLoading, setIsLoading] = useState(true);
  const [sortedField, setSortedField] = useState({ key: "", direction: "" });
  const [showEditMenuItemModal, setShowEditMenuItemModal] = useState(false);
  const [showEditModifierItemModal, setShowEditModifierItemModal] =
    useState(false);
  const [showUploadImageModal, setShowUploadImageModal] = useState(false);
  const [showUploadPeekImagesModal, setShowUploadPeekImagesModal] =
    useState(false);
  const [showRecommendedPairingsModal, setShowRecommendedPairingsModal] =
    useState(false);

  const history = useHistory();

  const handleBrandChanges = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const slug = event.target.value;
    setChangedItemsMap(new Map<number, any>());
    setIsLoading(true);
    history.push({
      search: `?tab=kitchen_names&brand=${slug}`,
    });
  };

  const handleKitchenNameChanges = (event: any) => {
    const { id, defaultValue, value } = event.target;

    if (defaultValue === value) {
      const itemChanged = changedItemsMap.get(id);
      if (itemChanged?.oldPrepType === itemChanged?.newPreptype) {
        changedItemsMap.delete(id);
        setChangedItemsMap(new Map(changedItemsMap));
      } else {
        setChangedItemsMap(
          new Map(
            changedItemsMap.set(id, {
              ...itemChanged,
              oldKitchenName: undefined,
              newKitchenName: undefined,
            }),
          ),
        );
      }
    } else {
      const itemChanged = changedItemsMap.get(id);
      setChangedItemsMap(
        new Map(
          changedItemsMap.set(id, {
            ...itemChanged,
            oldKitchenName: defaultValue,
            newKitchenName: value,
          }),
        ),
      );
    }
  };

  const handleUpdateKitchenNames = (event: any) => {
    event.stopPropagation();
    if (changedItemsMap.size > 0) {
      setShowModal(true);
    }
  };

  const onUpdateAll = useCallback(async () => {
    setIsLoading(true);

    if (items?.length) {
      const itemsToUpdate: any[] = Array.from(changedItemsMap.keys()).map(
        (key) => {
          const item = items[key];
          const changedItem = changedItemsMap.get(key);

          return {
            id: item.id,
            name: item.name,
            item_type: item.item_type,
            kitchen_name: changedItem.newKitchenName || item.kitchen_name,
            prep_type: changedItem.newPrepType || item.prep_type,
          };
        },
      );

      await onUpdateKitchenNames({ items: itemsToUpdate });
    }

    setShowModal(false);
    setIsLoading(false);
  }, [changedItemsMap]);

  const sortedItems = useMemo(() => {
    if (items) {
      let sortableItems = [...items];

      if (sortedField.key) {
        sortableItems.sort((a, b) => {
          const key: string = sortedField.key;

          if (get(a, key) < get(b, key)) {
            return sortedField.direction === "asc" ? -1 : 1;
          }
          if (get(a, key) > get(b, key)) {
            return sortedField.direction === "des" ? -1 : 1;
          }

          return 0;
        });
      }

      return sortableItems;
    }
  }, [items, sortedField]);

  const sortItems = (header: any) => {
    let direction = "asc";
    let key = header;

    if (
      sortedField &&
      sortedField?.key === header &&
      sortedField?.direction === "asc"
    ) {
      direction = "des";
    } else if (
      sortedField &&
      sortedField?.key === header &&
      sortedField?.direction === "des"
    ) {
      key = "";
    }

    setSortedField({ key, direction });
  };

  const toggleEditItemModal = (
    item: OperationsMenuItem | OperationsModifierItem,
  ) => {
    setItem(item);

    if (item.item_type === "menu_item") {
      setShowEditMenuItemModal(!showEditMenuItemModal);
    } else {
      setShowEditModifierItemModal(!showEditModifierItemModal);
    }
  };

  const { data: allMenuItems } = useOperationsAllMenuItems();

  const toggleRecommendedPairingsModal = (
    item: OperationsMenuItem | OperationsModifierItem,
  ) => {
    setItem(item);
    setShowRecommendedPairingsModal(true);
  };

  const toggleUploadImagesModal = (
    item: OperationsMenuItem | OperationsModifierItem,
  ) => {
    setItem(item);
    setShowUploadImageModal(true);
  };

  const togglePeekImagesModal = (
    item: OperationsMenuItem | OperationsModifierItem,
  ) => {
    setItem(item);
    setShowUploadPeekImagesModal(true);
  };

  useEffect(() => {
    if (brand) {
      const items = [...brand.menu_items, ...brand.modifier_items];

      setItems(items);
      if (item) {
        const updatedItem = items.find((i) => i.id === item.id);
        setItem(updatedItem);
      }
      setIsLoading(false);
    }
  }, [brand]);

  const handlePrepTypeChanged = (
    item: OperationsMenuItem | OperationsModifierItem,
    evt: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { value, id } = evt.target;
    const itemChanged = changedItemsMap.get(parseInt(id));
    changedItemsMap.set(parseInt(id), {
      ...itemChanged,
      oldPrepType: item.prep_type,
      newPrepType: value,
    });

    setChangedItemsMap(new Map(changedItemsMap));
  };

  const getRowColor = (
    brand?: OperationsBrand,
    item?: OperationsMenuItem | OperationsModifierItem,
  ) => {
    const inactiveColor = "bg-gray-50 opacity-50";
    const activeColor = "bg-white";

    // Check if the item is a menu item and active in any location
    if (item?.__typename === "OperationsMenuItem") {
      const menuItem = item as OperationsMenuItem;

      const isItemActiveSomewhere = menuItem.store_menu_items?.some(
        (storeMenuItem) =>
          isStoreMenuItemActive(
            storeMenuItem,
            storeMenuItem.location,
            storeMenuItem.store,
          ),
      );

      if (!isItemActiveSomewhere) {
        return inactiveColor;
      }
    }

    return activeColor;
  };

  return (
    <main className="flex-1 relative overflow-y-auto focus:outline-none">
      <div className="py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-semibold text-gray-900">BRANDS</h1>
          <div className="mt-3 w-full sm:w-1/2">
            <select
              id="brandId"
              name="brandId"
              autoComplete="brandId"
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              onChange={handleBrandChanges}
              value={brand?.slug}
            >
              {brandsList?.map((_brand, index: number) => {
                return (
                  <option
                    key={`brand-${index}-${brand?.id}`}
                    value={_brand?.slug}
                  >
                    {_brand?.name}
                    {!!_brand?.is_active === false ? " (deactivated)" : ""}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div className="flex justify-between items-center">
            <h2 className="my-5 text-xl font-semibold text-gray-900">ITEMS</h2>
            <button
              onClick={handleUpdateKitchenNames}
              className="cursor-pointer hover:opacity-80 hover:text-white inline-flex justify-center py-2 px-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-400"
            >
              Update Items
            </button>
          </div>
          <div className="mb-2">
            <p className="text-base">Items Modified: {changedItemsMap.size}</p>
          </div>

          <table className="w-max divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {headers.map((header: any, index: number) => (
                  <th
                    key={`header-${index}`}
                    scope="col"
                    className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                  >
                    <button onClick={() => sortItems(header.key)}>
                      <div className="flex font-bold p-1 items-center">
                        {header.name}
                        {header.key === sortedField.key && (
                          <div className="text-xl ml-2">
                            {sortedField.direction === "asc" ? (
                              <ChevronUpIcon className="text-gray-800 h-4 w-4" />
                            ) : (
                              <ChevronDownIcon className="text-gray-800 h-4 w-4" />
                            )}
                          </div>
                        )}
                      </div>
                    </button>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {sortedItems &&
                sortedItems.map(
                  (
                    sortedItem: OperationsMenuItem | OperationsModifierItem,
                    index: number,
                  ) => {
                    const buildGridImgUrl = (sortedItem as OperationsMenuItem)
                      ?.build_grid_img_url;
                    const finalPlatedImgUrl = (sortedItem as OperationsMenuItem)
                      ?.final_plated_img_url;

                    return (
                      <TableRow
                        key={`item-${index}`}
                        rowColor={getRowColor(brand, sortedItem)}
                      >
                        <TableData key={`name-${index}`}>
                          <div className="flex items-center justify-start max-w-xs flex-wrap">
                            <div className="w-8 h-6 flex mr-2 items-center justify-center">
                              {sortedItem.image_url ? (
                                <ItemImage
                                  url={getResponsiveImageUrl(
                                    sortedItem.image_url,
                                    "320w",
                                  )}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon={faEyeSlash}
                                  size="lg"
                                  color="rgb(255, 0, 0)"
                                />
                              )}
                            </div>
                            {sortedItem.name}
                          </div>
                        </TableData>
                        <TableData key={`item-type-${index}`}>
                          <ItemTypePill item={sortedItem} />
                        </TableData>
                        <TableData
                          key={`menu-item-tags-${index}-${sortedItem?.id}`}
                          className="w-24 truncate"
                        >
                          <MenuItemTagPills item={sortedItem} />
                        </TableData>
                        <TableData key={`kitchen-name-${index}`}>
                          <div
                            key={`brand-${brand?.id}-${sortedItem.kitchen_name}`}
                          >
                            <label
                              htmlFor={sortedItem.kitchen_name}
                              className="sr-only"
                            >
                              {sortedItem.kitchen_name}
                            </label>
                            <input
                              key={`brand-${brand?.id}-${sortedItem.kitchen_name}`}
                              type="text"
                              name={`brand-${brand?.id}-${sortedItem.kitchen_name}`}
                              id={`${index}`}
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              defaultValue={
                                sortedItem.kitchen_name
                                  ? sortedItem.kitchen_name
                                  : ""
                              }
                              onChange={handleKitchenNameChanges}
                            />
                          </div>
                        </TableData>
                        <TableData key={`item-edit-${index}`}>
                          <div className="flex items-center gap-2">
                            <FontAwesomeIcon
                              className="cursor-pointer m-1"
                              onClick={() => toggleEditItemModal(sortedItem)}
                              icon={faPen}
                              size="lg"
                              color="rgb(129, 140, 248)"
                            />
                            {sortedItem.item_type === "menu_item" && (
                              <FontAwesomeIcon
                                className="cursor-pointer m-1"
                                onClick={() =>
                                  toggleUploadImagesModal(sortedItem)
                                }
                                icon={faImage}
                                size="lg"
                                color="rgb(129, 140, 248)"
                              />
                            )}
                            {sortedItem.item_type === "menu_item" && (
                              <select
                                id={`${index}`}
                                name={`prepType-${brand?.id}-${index}`}
                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                onChange={(e) =>
                                  handlePrepTypeChanged(sortedItem, e)
                                }
                                defaultValue={sortedItem.prep_type}
                              >
                                <option value={"REGULAR"}>REGULAR</option>
                                <option value={"NONE"}>NONE</option>
                                <option value={"GRIDDLE"}>GRIDDLE</option>
                                <option value={"FRYER"}>FRYER</option>
                              </select>
                            )}
                          </div>
                        </TableData>
                        <TableData>
                          <ActiveLocationPills item={sortedItem} />
                        </TableData>
                        <TableData
                          key={`menu-item-tags-${index}`}
                          className="w-24 truncate"
                        >
                          {"external_id" in sortedItem && (
                            <div className={"flex flex-row"}>
                              <p
                                className={"w-40 mr-4 truncate"}
                                title={sortedItem?.external_id}
                              >
                                {sortedItem?.external_id}
                              </p>
                              <div
                                className={"cursor-pointer"}
                                onClick={() => {
                                  navigator?.clipboard?.writeText(
                                    sortedItem?.external_id || "",
                                  );
                                  showToast({
                                    description: "Copied to clipboard!",
                                    seconds: 2,
                                    variant: "success",
                                    onClose: () => {},
                                  });
                                  setCopied(sortedItem?.external_id || "");
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faCopy}
                                  color={
                                    sortedItem?.external_id === copied
                                      ? "green"
                                      : ""
                                  }
                                />
                              </div>
                            </div>
                          )}
                        </TableData>
                        <TableData>
                          <div className="text-center gap-2">
                            <FontAwesomeIcon
                              className="cursor-pointer m-1"
                              onClick={() =>
                                toggleRecommendedPairingsModal(sortedItem)
                              }
                              icon={faPager}
                              size="lg"
                              color="rgb(129, 140, 248)"
                            />
                          </div>
                        </TableData>
                        <TableData>
                          <div
                            className="text-center m-1 flex justify-center items-center gap-2"
                            onClick={() => togglePeekImagesModal(sortedItem)}
                          >
                            <ItemImage
                              url={getResponsiveImageUrl(
                                (sortedItem as OperationsMenuItem)
                                  ?.build_grid_img_url,
                                "320w",
                              )}
                              className="max-h-5 cursor-pointer"
                            />
                            <ItemImage
                              url={getResponsiveImageUrl(
                                (sortedItem as OperationsMenuItem)
                                  ?.final_plated_img_url,
                                "320w",
                              )}
                              className="max-h-5 cursor-pointer"
                            />
                            {(!buildGridImgUrl || !finalPlatedImgUrl) && (
                              <FontAwesomeIcon
                                className="cursor-pointer m-1"
                                onClick={() =>
                                  togglePeekImagesModal(sortedItem)
                                }
                                icon={faPager}
                                size="lg"
                                color="rgb(129, 140, 248)"
                                title="Upload Build Grid"
                              />
                            )}
                          </div>
                        </TableData>
                      </TableRow>
                    );
                  },
                )}
            </tbody>
          </table>
        </div>
      </div>
      {items?.length && changedItemsMap.size > 0 && (
        <Modal
          showModal={showModal}
          setShowModal={setShowModal}
          title={"Confirm Item Names"}
          size={5}
        >
          <div className="text-lg text-gray-600">
            <div className="my-4 flex justify-between items-center">
              <p className="w-4/12 font-bold">Item</p>
              <p className="w-4/12 font-bold">Kitchen Name</p>
              <p className="w-4/12 font-bold">Prep Type</p>
            </div>
            {Array.from(changedItemsMap.keys()).map((key: number) => {
              const changedItem = changedItemsMap.get(key);
              return (
                <div className="my-4 flex justify-between items-center">
                  <p className="w-4/12 font-bold">{items[key].name}</p>
                  <div className="w-4/12 flex justify-between items-center">
                    <p
                      className={`w-1/4 ${
                        changedItem.oldKitchenName ? "line-through" : ""
                      } text-red-700`}
                    >
                      {changedItem.oldKitchenName
                        ? changedItem.oldKitchenName
                        : "N/A"}
                    </p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-1/4 h-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                      />
                    </svg>
                    <p className="w-2/4">{changedItem.newKitchenName}</p>
                  </div>
                  <div className="w-4/12 flex justify-between items-center">
                    <p
                      className={`w-1/4 ${
                        changedItem.oldPrepType ? "line-through" : ""
                      } text-red-700`}
                    >
                      {changedItem.oldPrepType
                        ? changedItem.oldPrepType
                        : "N/A"}
                    </p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-1/4 h-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                      />
                    </svg>
                    <p className="w-2/4">{changedItem.newPrepType}</p>
                  </div>
                </div>
              );
            })}
            <div className="mt-5 flex justify-end">
              <button
                onClick={onUpdateAll}
                className="cursor-pointer hover:opacity-80 hover:text-white inline-flex justify-center py-2 px-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-400"
              >
                Update All
              </button>
            </div>
          </div>
        </Modal>
      )}
      {showRecommendedPairingsModal && item && (
        <DndProvider backend={HTML5Backend}>
          <RecommendedPairingsModal
            showModal={showRecommendedPairingsModal}
            setShowModal={setShowRecommendedPairingsModal}
            allMenuItems={allMenuItems}
            menuItem={item}
          />
        </DndProvider>
      )}
      {showEditMenuItemModal && item && (
        <Modal
          showModal={showEditMenuItemModal}
          setShowModal={setShowEditMenuItemModal}
          title={
            <div className="flex items-center justify-between text-2xl leading-6 font-medium text-gray-900">
              <p>Edit {item?.name}</p>
              <div className="ml-3">
                <ItemTypePill item={item} />
              </div>
            </div>
          }
        >
          <div className="mt-3">
            <EditItemForm
              item={item}
              allItems={sortedItems ? sortedItems : []}
              onCreateOrUpdateBuildNotes={onCreateOrUpdateBuildNotes}
              onDeleteMenuItemBuildNotes={onDeleteMenuItemBuildNotes}
            />
          </div>
        </Modal>
      )}
      {showEditModifierItemModal && item && (
        <Modal
          showModal={showEditModifierItemModal}
          setShowModal={setShowEditModifierItemModal}
          title={
            <div className="flex items-center justify-between text-2xl leading-6 font-medium text-gray-900">
              <p>Edit {item?.name}</p>
              <div className="ml-3">
                <ItemTypePill item={item} />
              </div>
            </div>
          }
        >
          <div className="mt-3">
            <EditModifierItemForm
              item={item}
              allItems={sortedItems ? sortedItems : []}
              onUpdateModifierItem={onUpdateModifierItem}
            />
          </div>
        </Modal>
      )}
      <Modal
        showModal={showUploadImageModal}
        setShowModal={setShowUploadImageModal}
        title={`Upload '${item?.name}' Image`}
      >
        <UploadItemImageForm
          item={item}
          onUploadMenuItemImage={onUploadMenuItemImage}
          loading={uploadingImage}
          onSuccess={() => setShowUploadImageModal(false)}
        />
      </Modal>
      <Modal
        showModal={showUploadPeekImagesModal}
        setShowModal={setShowUploadPeekImagesModal}
        title={`Upload '${item?.name}' Peek Window Images`}
        subTitle="Please upload jpg/jpeg images only with a 10MB limit."
        size={0}
      >
        <UploadPeekWindowImageForm
          item={item}
          onUploadMenuItemBuildGridImage={onUploadMenuItemBuildGridImage}
          onUploadMenuItemPlatedImage={onUploadMenuItemPlatedImage}
          loading={uploadingImage}
          onSuccess={() => setShowUploadPeekImagesModal(false)}
        />
      </Modal>
    </main>
  );
};
