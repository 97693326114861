import { useQuery, gql } from "@apollo/client";

const CUSTOM_HOURS_QUERY = gql`
  query CustomHoursQuery {
    custom_hours {
      id
      store_id
      override_date
      hours
      location_id
    }
  }
`;

export const useCustomHours = () => {
  const { data, loading, refetch } = useQuery(CUSTOM_HOURS_QUERY);

  return { data, loading, refetch };
};
