import { OperationsMenuItem, OperationsModifierItem } from "../../../types";
import { isStoreMenuItemActive } from "../../../utils/utils";
import { PillColor, TagPill } from "./TagPill";

interface ActiveLocationPillsProps {
  item: OperationsMenuItem | OperationsModifierItem;
}

export const ActiveLocationPills: React.FC<ActiveLocationPillsProps> = ({
  item,
}) => {
  if (item.__typename !== "OperationsMenuItem") return null;

  const activeLocations = new Set(
    (item as OperationsMenuItem).store_menu_items
      ?.filter((smi) => isStoreMenuItemActive(smi, smi.location, smi.store))
      .map((item) => item.location.name),
  );

  if (activeLocations.size === 0) {
    return <TagPill color={PillColor.red} content="Nowhere" />;
  }

  // Sort alphabetically
  const sortedLocations = Array.from(activeLocations).sort();

  return (
    <div className="flex gap-2 max-w-md flex-wrap">
      {sortedLocations.map((location) => (
        <TagPill key={location} color={PillColor.black} content={location} />
      ))}
    </div>
  );
};
