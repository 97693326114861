import React from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { Table, TableData, TableRow } from "../../ui";
import { Loading } from "../../ui/components/Loading";
import { useSales } from "../Orders/hooks/useSales";
import { usdFormatter } from "../Orders/usdFormatter";
import dayjs from "../../utils/dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { ProviderV2 } from "../../types";

interface Props {
  customerId: any;
}

interface Item {
  subtotal: number;
  raw_orders_data: string;
  raw_cart_total: number;
  provider: ProviderV2;
}

export const RecentOrders = ({ customerId }: Props) => {
  const { data, loading } = useSales({
    customerId: customerId.toString(),
    limit: 10,
  });
  const history = useHistory();
  if (loading) {
    return <Loading />;
  } else if (data.sales.length == 0) {
    return (
      <div className="flex justify-center">
        <p className="font-bold">No Orders</p>
      </div>
    );
  }

  const calculateTotal = (item: Item) => {
    let total = 0;
    const data = JSON.parse(item.raw_orders_data);
    if (item?.provider?.id === 7) {
      total += data.total;
    } else {
      total += item.raw_cart_total;
    }
    return usdFormatter.format(total / 100);
  };

  return (
    <Table headers={["Order Id", "Pickup Date", "Subtotal", "Total"]}>
      {data.sales.map((item: any, index: number) => {
        return (
          <TableRow>
            <TableData>
              <Link to={`/orders/${item.id}`}>
                <p>
                  <span className="mr-2">{item.id}</span>
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </p>
              </Link>
            </TableData>
            <TableData>
              <p>
                {dayjs.unix(parseInt(item.pickup_time) / 1000).format("llll")}
              </p>
            </TableData>
            <TableData>
              <p>{usdFormatter.format(item.subtotal / 100)}</p>
            </TableData>
            <TableData>
              {/* <p>{usdFormatter.format(item.raw_cart_total / 100)}</p> */}
              <p>{calculateTotal(item)}</p>
            </TableData>
          </TableRow>
        );
      })}
    </Table>
  );
};
