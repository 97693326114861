import { useListBrandsQuery } from "opsapi/hooks/operations/brands";
import { useListLocationsQuery } from "opsapi/hooks/operations/locations";
import {
  StorefrontTypeChoices,
  StorefrontTypeEnum,
  useCreateStorefrontQuery,
} from "opsapi/hooks/operations/storefronts";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";

import {
  Button,
  FormInput,
  FormLabel,
  FormOption,
  FormSelect,
  Table,
  TableData,
  TableRow,
  Toggle,
} from "../../../ui";
import Loader from "../../../ui/components/Loader/Loader";
import ToastContext from "../../../utils/contexts/ToastContext";

export const CreateStorefront = () => {
  const history = useHistory();
  const { locationSlug } = useParams<{ locationSlug: string }>();
  const [selectedBrandIds, setSelectedBrandIds] = useState<number[]>([]);

  const { showToast } = useContext(ToastContext);

  const { data: locations, isLoading: isLocationsLoading } =
    useListLocationsQuery();
  const { data: brands, isLoading: isBrandsLoading } = useListBrandsQuery();

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<{
    name: string;
    focal_brand_id: string | null;
    type: StorefrontTypeChoices;
  }>({
    defaultValues: {
      name: `Local Kitchens (${locationSlug})`,
      focal_brand_id: null,
      type: StorefrontTypeEnum.consolidated,
    },
  });

  const watchStorefrontType = watch("type");
  const watchFocalBrandId = watch("focal_brand_id");

  const { mutate: createStorefront, isPending: isStorefrontCreationPending } =
    useCreateStorefrontQuery({});

  const selectableBrands =
    watchStorefrontType === StorefrontTypeEnum.brand
      ? brands?.filter((brand) => !brand.is_focal_eligible)
      : brands;

  useEffect(() => {
    const lkBrand = selectableBrands?.find(
      (brand) => brand.slug === "localkitchens",
    );
    if (!selectableBrands || !brands || !lkBrand) {
      return;
    }

    if (watchStorefrontType === StorefrontTypeEnum.consolidated) {
      setSelectedBrandIds(selectableBrands.map((brand) => brand.id));
    } else if (watchStorefrontType === StorefrontTypeEnum.brand) {
      const autoSelectBrand =
        (watchFocalBrandId
          ? brands.find((brand) => brand.id.toString() === watchFocalBrandId)
          : null) ?? brands.filter((brand) => brand.is_focal_eligible)[0];
      setValue("focal_brand_id", autoSelectBrand.id.toString());
      setValue("name", `${autoSelectBrand.name} (${locationSlug})`);
      setSelectedBrandIds([autoSelectBrand.id, lkBrand.id]);
    }
  }, [brands, watchFocalBrandId, watchStorefrontType]);

  if (isLocationsLoading || isBrandsLoading) {
    return <Loader loading={true} />;
  }

  if (!locations) {
    return <div>No locations found</div>;
  }

  if (!brands) {
    return <div>No brands found</div>;
  }

  const currentLocation = locations.find(
    (location: { slug: string }) => location.slug === locationSlug,
  );

  if (currentLocation === undefined) {
    return <h1>Location not found</h1>;
  }

  const onSubmitHandler = async (data: any) => {
    data.location_id = currentLocation.id;
    data.brand_ids = selectedBrandIds;

    createStorefront(
      { ...data },
      {
        onSuccess: () => {
          showToast({
            description: `Submitted successfully`,
            variant: "success",
            seconds: 2,
          });
          history.push(`/storefronts/${locationSlug}/`);
        },
        onError: (error) => {
          showToast({
            description: error.message,
            variant: "error",
            seconds: 10,
          });
        },
      },
    );
  };

  const toggleBrandSelection = (brandId: number) => {
    if (selectedBrandIds.includes(brandId)) {
      setSelectedBrandIds(selectedBrandIds.filter((id) => id !== brandId));
    } else {
      setSelectedBrandIds([...selectedBrandIds, brandId]);
    }
  };

  const tableHeaders = ["Is Linked", "Name", "Brand State"];

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex justify-between w-full mb-4">
        <h1 className="text-3xl font-semibold text-gray-900">
          Create new storefront at {currentLocation.name}
        </h1>

        <button
          onClick={() => history.goBack()}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
        >
          Back
        </button>
      </div>

      <div className="flex w-full h-full">
        <form
          onSubmit={handleSubmit(onSubmitHandler)}
          className="relative w-full"
        >
          <div className="grid grid-cols-3 gap-6 my-5 w-6/12">
            <div className="col-start-1">
              <FormLabel
                title="Name"
                htmlFor="name"
                information="Storefront names are internal, autogenerated and not visible to guests via any channel"
              />
              <FormInput
                type="text"
                id="name"
                value={getValues("name")}
                disabled={true}
              />
            </div>
            <div>
              <FormLabel
                title="Slug"
                htmlFor="slug"
                information="Slug will be set automatically based on the location and type of the Storefront."
              />
              <FormInput type="text" id="slug" disabled={true} />
            </div>
            <div className="col-span-2">
              <FormLabel
                title="Type"
                htmlFor="type"
                information={`
                Select Type of the storefront.
                * Consolidated storefronts are used for 3P to represent multiple brands.
                * Direct storefronts will be used to represent stores at web/mobile/kiosk.
                * Brand storefronts are used for 3P to represent a single brand.
              `}
              />
              <Controller
                name="type"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <FormSelect {...field}>
                    {Object.values(StorefrontTypeEnum).map((type) => (
                      <FormOption
                        key={type}
                        title={type}
                        className="capitalize"
                        value={type}
                        disabled={type === StorefrontTypeEnum.direct}
                      />
                    ))}
                  </FormSelect>
                )}
              />
              <p className="text-red-800">{errors.type?.message?.toString()}</p>
            </div>
            {watchStorefrontType === StorefrontTypeEnum.brand && (
              <div className="col-span-2">
                <FormLabel
                  title="Focal Brand"
                  htmlFor="focal_brand_id"
                  information={`Select focal brand of the brand storefront.`}
                />
                <Controller
                  name="focal_brand_id"
                  control={control}
                  rules={{
                    required: watchStorefrontType === StorefrontTypeEnum.brand,
                  }}
                  render={({ field }) => (
                    <FormSelect {...field}>
                      {brands
                        .filter((brand) => brand.is_focal_eligible)
                        .map((brand) => (
                          // Only enable consolidated storefronts
                          <FormOption
                            key={brand.id}
                            title={brand.name}
                            value={brand.id}
                            disabled={brand.slug === "localkitchens"}
                          />
                        ))}
                    </FormSelect>
                  )}
                />
                <p className="text-red-800">
                  {errors.type?.message?.toString()}
                </p>
              </div>
            )}

            <div className="col-span-2">
              <FormLabel
                title="Storefront Brands"
                htmlFor="storefront_brands"
                information={`Select brands served by this storefront.`}
              />
              <Table headers={tableHeaders}>
                {selectableBrands &&
                  selectableBrands.map((brand, idx) => {
                    return (
                      <TableRow
                        key={idx}
                        rowColor={idx % 2 ? "bg-white" : "bg-gray-50"}
                      >
                        <TableData>
                          {watchStorefrontType === StorefrontTypeEnum.brand ? (
                            <Toggle
                              // autoselect localkitchens & focal brand
                              initialValue={
                                brand.slug === "localkitchens" ||
                                watchFocalBrandId === brand.id.toString()
                              }
                              onChange={() => toggleBrandSelection(brand.id)}
                            />
                          ) : (
                            <Toggle
                              initialValue={true}
                              onChange={() => toggleBrandSelection(brand.id)}
                            />
                          )}
                        </TableData>
                        <TableData>{brand.name}</TableData>
                        <TableData>{brand.state}</TableData>
                      </TableRow>
                    );
                  })}
              </Table>
            </div>
          </div>
          <Button className="mr-3 mt-5" loading={isStorefrontCreationPending}>
            Save
          </Button>
        </form>
      </div>
    </div>
  );
};
