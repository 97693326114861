import { useQueryClient } from "@tanstack/react-query";
import {
  LIST_MODIFIERS,
  useListModifiersQuery,
  useSyncModifiersQuery,
} from "opsapi/hooks/operations/modifiers";
import { useContext, useState } from "react";

import { Table, TableData, TableRow } from "../../../../ui";
import { PageContent } from "../../../../ui/components/PageContent/PageContent";
import ToastContext from "../../../../utils/contexts/ToastContext";
import { FlatButton } from "../../_components/FlatButton";

export const ListModifiers = () => {
  const queryClient = useQueryClient();
  const { showToast } = useContext(ToastContext);
  const [includeDeleted, setIncludeDeleted] = useState(false);
  const toggleIncludeDeleted = () => {
    setIncludeDeleted((prev) => !prev);
  };
  const modifiersParams = {
    params: { query: { include_deleted: includeDeleted } },
  };
  const { data: modifiers, isLoading: isModifiersLoading } =
    useListModifiersQuery(modifiersParams);
  const { mutate: syncModifiers, isPending: isSyncingPending } =
    useSyncModifiersQuery({
      reactQuery: {
        onSuccess: (data) => {
          showToast({
            description: `Result: ${JSON.stringify(data)}`,
            variant: "success",
            seconds: 5,
          });
          queryClient.invalidateQueries({
            queryKey: [LIST_MODIFIERS],
          });
        },
        onError: (error) => {
          showToast({
            description: error.message,
            variant: "error",
            seconds: 10,
          });
        },
      },
    });

  const tableHeaders = ["Brand", "Name", "Modifiers Lists"];

  if (isModifiersLoading) {
    return <div>Loading...</div>;
  }

  if (!modifiers) {
    return <div>No modifiers found</div>;
  }

  return (
    <PageContent>
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-3xl font-semibold text-gray-900">Modifiers</h1>
        <div className="flex items-center gap-2">
          <button className="underline" onClick={() => toggleIncludeDeleted()}>
            {includeDeleted ? "Hide" : "Show"} deleted
          </button>
          <FlatButton
            onClick={() => syncModifiers({})}
            loading={isSyncingPending}
          >
            Sync
          </FlatButton>
        </div>
      </div>

      <Table headers={tableHeaders}>
        {modifiers.map((modifier, idx) => (
          <TableRow
            key={modifier.id}
            rowColor={idx % 2 ? "bg-white" : "bg-gray-50"}
          >
            <TableData>{modifier.brand?.name ?? "-"}</TableData>
            <TableData>{modifier.name}</TableData>
            <TableData>
              <ul>
                {modifier.modifiers_lists.map((modifiers_list) => (
                  <li key={modifiers_list.id}>{modifiers_list.name}</li>
                ))}
              </ul>
            </TableData>
          </TableRow>
        ))}
      </Table>
    </PageContent>
  );
};
