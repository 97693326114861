import { Loading3QuartersOutlined } from "@ant-design/icons";
import { Spin } from "antd";

type Props = {
  loading: boolean;
};
const Loader = ({ loading }: Props) => {
  const antIcon = <Loading3QuartersOutlined className="text-[30px]" spin />;
  return (
    <Spin
      delay={0}
      spinning={loading}
      indicator={antIcon}
      className="z-50 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
    />
  );
};
export default Loader;
