import { gql, useMutation } from "@apollo/client";

const UPDATE_PREP_STATION = gql`
  mutation UpdatePrepStation(
    $name: String
    $prep_station_id: Int
    $prep_station_type_id: Int
    $printer_id: String
  ) {
    update_prep_station(
      name: $name
      prep_station_id: $prep_station_id
      prep_station_type_id: $prep_station_type_id
      printer_id: $printer_id
    ) {
      id
      name
      prep_station_type_id
      printer_id
    }
  }
`;

export const useUpdatePrepStation = () => {
  const [updatePrepStation] = useMutation(UPDATE_PREP_STATION, {
    refetchQueries: ["PREP_STATIONS_QUERY"],
  });

  return updatePrepStation;
};
