import { gql, useMutation } from "@apollo/client";
import { MARKETING_BILLBOARDS_QUERY } from "./useMarketingBillboards";

const DELETE_MARKETING_BILLBOARD = gql`
  mutation DeleteMarketingBillboard($id: Int!) {
    delete_marketing_billboard(id: $id)
  }
`;

export const useDeleteMarketingBillboard = () => {
  return useMutation(DELETE_MARKETING_BILLBOARD, {
    refetchQueries: [{ query: MARKETING_BILLBOARDS_QUERY }],
  });
};
