import { useMutation, gql } from "@apollo/client";

const DELETE_STORE_MENU_ITEM_PREP_STATION = gql`
  mutation DeleteStoreMenuItemPrepStation(
    $store_menu_item_prep_station_id: Int
  ) {
    delete_store_menu_item_prep_station(
      store_menu_item_prep_station_id: $store_menu_item_prep_station_id
    ) {
      id
    }
  }
`;

export const useDeleteStoreMenuItemPrepStation = () => {
  const [deleteStoreMenuItemPrepStation] = useMutation(
    DELETE_STORE_MENU_ITEM_PREP_STATION,
  );

  return deleteStoreMenuItemPrepStation;
};
