import React, { ReactElement } from "react";
import { InformationPopover } from "../InformationPopover/InformationPopover";

type HeaderObj = {
  text: string;
  information: React.ReactNode;
};

type THProps = React.ComponentProps<"th">;

type TableProps = {
  headers?: Array<HeaderObj | ReactElement<THProps> | string>;
  children: React.ReactNode;
  onClick?: () => void;
};

export const Table = React.forwardRef(
  ({ headers, children }: TableProps, ref: any) => {
    return (
      <div
        ref={ref}
        className="flex flex-col overflow-x-auto overflow-y-auto align-middle w-full shadow"
      >
        <table className="w-full divide-y divide-gray-200">
          {headers && (
            <thead className="bg-gray-100 sticky top-0 z-10 shadow">
              <tr className="bg-gray-100">
                {headers.map((header, index: number) => {
                  if (React.isValidElement(header)) {
                    return header;
                  }
                  if (typeof header === "string") {
                    return (
                      <th
                        key={`header-${index}`}
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {header}
                      </th>
                    );
                  }
                  return (
                    <th
                      key={`header-${index}`}
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider flex items-center justify-items-center"
                    >
                      <span className="mr-2">{header.text}</span>
                      <InformationPopover information={header.information} />
                    </th>
                  );
                })}
              </tr>
            </thead>
          )}
          <tbody>{children}</tbody>
        </table>
      </div>
    );
  },
);
