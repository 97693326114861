import { gql, useMutation } from "@apollo/client";

const MARK_AS_CUSTOM_CHIT = gql`
  mutation MarkAsCutomChit($modifier_item: CustomChitInput) {
    mark_items_as_custom_chit(modifier_item: $modifier_item) {
      id
    }
  }
`;

export const useMarkAsCustomChit = () => {
  const [markAsCustomChit, { loading }] = useMutation(MARK_AS_CUSTOM_CHIT);

  return { markAsCustomChit, loading };
};
