import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  LocationStateEnum,
  useListLocationsQuery,
} from "opsapi/hooks/operations/locations";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Table, TableData, TableRow } from "../../../ui";
import Loader from "../../../ui/components/Loader/Loader";
import { PageContent } from "../../../ui/components/PageContent/PageContent";
import { displayAsPhoneNumber } from "../../../utils/formatters";
import { useSyncRevenueCenters } from "./hooks/useSyncRevenueCenters";

export default function Locations() {
  const [includeDeleted, setIncludeDeleted] = useState(false);
  const { onSyncRevenueCenters, loading: revenueCenterSyncInProgress } =
    useSyncRevenueCenters();

  const locationParams = {
    params: { query: { include_deleted: includeDeleted } },
  };

  const { data: locations, isLoading: locationsLoading } =
    useListLocationsQuery(locationParams);

  const history = useHistory();

  const toggleIncludeDeleted = () => {
    setIncludeDeleted((prev: any) => !prev);
  };

  if (locationsLoading) {
    return <Loader loading={true} />;
  }

  if (!locations) {
    return <div>No locations found</div>;
  }

  return (
    <PageContent>
      <Loader loading={revenueCenterSyncInProgress} />
      <div className="flex items-center justify-between">
        <h1 className="text-3xl font-semibold text-gray-900 mr-3">Locations</h1>
        <div className="flex items-center">
          <Button
            onClick={() => toggleIncludeDeleted()}
            loading={locationsLoading}
          >
            {includeDeleted ? "Hide Deleted" : "Show Deleted"}
          </Button>
          <Button
            className="flex items-center ml-3"
            type="button"
            onClick={() => onSyncRevenueCenters({ location_id: null })}
          >
            Sync Revenue Centers
          </Button>
          <Button
            className="flex items-center ml-3"
            type="button"
            onClick={() => history.push("/locations/new")}
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            New Location
          </Button>
        </div>
      </div>

      <Table
        headers={[
          "Id",
          {
            text: "Status",
            information:
              'Once a location is live, you will want the status to be "Visible". When onboarding, make sure the status is "Not Visible", as you will not want guests to be able to see it in the location changer',
          },
          "Name",
          "Address",
          "Phone Number",
        ]}
      >
        {locations.map((item: any, index: number) => {
          return (
            <TableRow
              key={item.id}
              onClick={() => history.push(`/locations/${item.slug}?tab=brands`)}
            >
              <TableData>{item.id}</TableData>
              <TableData>
                <span
                  className={`inline-flex items-center px-2.5 py-0.5 rounded-md text-md font-medium border capitalize ${
                    item.state === LocationStateEnum.active
                      ? "bg-green-100 text-green-800 border-green-800"
                      : "bg-gray-100 text-gray-800 border-gray-800"
                  }`}
                >
                  {item.state}
                </span>
              </TableData>
              <TableData>
                <span className="mr-2">{item.name}</span>
              </TableData>
              <TableData>
                <p>
                  {item.street}, {item.state} {item.zipcode}
                </p>
              </TableData>
              <TableData>
                <p>{displayAsPhoneNumber(item.phone_number)}</p>
              </TableData>
            </TableRow>
          );
        })}
      </Table>
    </PageContent>
  );
}
