import {
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
  gql,
  useQuery,
} from "@apollo/client";
import { OperationsBrand } from "../../../types";

export const OPERATIONS_BRAND_QUERY = gql`
  query OPERATIONS_BRAND_QUERY($input: OperationsBrandsInput) {
    operations_brands(input: $input) {
      id
      name
      slug
      stores {
        is_active
        location {
          is_active
        }
      }
      menu_items {
        id
        name
        kitchen_name
        item_type
        image_url
        build_grid_img_url
        final_plated_img_url
        menu_item_prep_station_type_id
        menu_item_name_override
        prep_type
        external_id
        tags {
          id
          name
          is_active
          is_filter
          is_collection
          is_v2
          ordinal
        }
        store_menu_items {
          id
          is_active
          is_visible
          location {
            id
            name
            is_active
          }
          store {
            id
            is_active
          }
        }
        modifier_lists {
          id
          name
          ordinal
          modifier_items {
            id
            name
            kitchen_name
            prep_type
            custom_chit {
              id
              menu_item_id
              kitchen_name
            }
          }
        }
        build_notes {
          id
          name
          is_active
          modifier_list_id_order_override
          removal_modifier_id
          show_in_kds
          show_in_receipt
          custom_chit {
            id
            kitchen_name
          }
        }
        is_discrete
      }
      modifier_items {
        id
        name
        kitchen_name
        item_type
        prep_type
        modifier_list_ids
        modifier_list_names
        is_discrete
        external_id
        custom_chit {
          id
          kitchen_name
          menu_items {
            id
            name
          }
        }
      }
    }
  }
`;

type OperationsBrandsData = {
  operations_brands: OperationsBrand[];
};

export const useOperationsBrand = (
  brandSlug?: string,
): {
  data: OperationsBrand | undefined;
  loading: boolean;
  error?: ApolloError;
  refetch: (
    variables?: Partial<OperationVariables>,
  ) => Promise<ApolloQueryResult<OperationsBrandsData>>;
} => {
  const { data, error, loading, refetch } = useQuery<
    OperationsBrandsData,
    OperationVariables
  >(OPERATIONS_BRAND_QUERY, {
    variables: {
      input: {
        brand_slug: brandSlug,
      },
    },
    skip: !brandSlug,
    fetchPolicy: "cache-and-network",
  });

  const result = data?.operations_brands?.[0];
  return { data: result, error, loading, refetch };
};
