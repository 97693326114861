import { Route, Switch, useRouteMatch } from "react-router";
import { ListLocations } from "./pages/ListLocations";

export const LocationsController = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <ListLocations />
      </Route>
    </Switch>
  );
};
