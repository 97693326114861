export enum FieldKeys {
  TYPE = "type",
  NAME = "name",
  BRAND = "brand",
}

export enum TargetType {
  MODIFIER_ITEM = "modifier_item",
  MENU_ITEM = "menu_item",
}

export type SortSelected = {
  field: string;
  order: "ASC" | "DESC";
};

export interface UpdateCellInput {
  displayStatus: boolean;
  targetType: string;
  targetId: string;
  defaultState: string;
  locationId: number | string;
  locationSlug: string;
}
