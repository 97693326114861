import { gql, useQuery } from "@apollo/client";

export const OPERATIONS_DOORDASH_TRACKING_URL = gql`
  query OperationsDoordashTrackingURL($cart_id: String!) {
    get_doordash_tracking_url(cart_id: $cart_id)
  }
`;

export const useDoordashTrackingURL = (cart_id: string) => {
  const { data, error, loading, refetch } = useQuery(
    OPERATIONS_DOORDASH_TRACKING_URL,
    {
      variables: {
        cart_id,
      },
      skip: !cart_id,
      fetchPolicy: "cache-and-network",
    },
  );
  return {
    doordashTrackingURL: data?.get_doordash_tracking_url || null,
    error,
    loading,
    refetch,
  };
};
