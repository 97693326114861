import { gql, useMutation } from "@apollo/client";
import { OperationsMenuItem, OperationsModifierItem } from "../../../types";
import { OPERATIONS_BRAND_QUERY } from "./useOperationsBrand";

const UPDATE_KITCHEN_NAMES_MUTATION = gql`
  mutation updateKitchenNames($input: KichenNamesInput) {
    update_kitchen_names(input: $input) {
      __typename
      ... on OperationsMenuItem {
        id
        name
        kitchen_name
        item_type
      }
      ... on OperationsModifierItem {
        id
        name
        kitchen_name
        item_type
      }
    }
  }
`;

export type UpdateKitchenNamesProps = {
  items: (OperationsMenuItem | OperationsModifierItem)[];
};

export const useUpdateKitchenNamesMutation = (brandSlug?: string) => {
  const [updateKitchenNames, { loading }] = useMutation(
    UPDATE_KITCHEN_NAMES_MUTATION,
  );

  const onUpdateKitchenNames = async ({ items }: UpdateKitchenNamesProps) => {
    return await updateKitchenNames({
      variables: {
        input: {
          items,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: OPERATIONS_BRAND_QUERY,
          variables: {
            input: {
              brand_slug: brandSlug,
            },
          },
        },
      ],
    });
  };

  return { onUpdateKitchenNames, loading };
};
