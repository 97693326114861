import { gql, useMutation } from "@apollo/client";

const CREATE_EMPLOYEE_MUTATION = gql`
  mutation CREATE_EMPLOYEE($employee_input: EmployeeInput) {
    create_employee(employee_input: $employee_input) {
      id
      first_name
      last_name
      email
      pin_code
    }
  }
`;

export const useCreateEmployee = () => {
  const [createEmployee] = useMutation(CREATE_EMPLOYEE_MUTATION);
  return createEmployee;
};
