import { useMemo, useState } from "react";
import { Controller } from "react-hook-form";
import { useParams } from "react-router";
import { FormInput, FormLabel, FormOption, FormSelect } from "../../ui";
import { Loading } from "../../ui/components/Loading";
import useCurrentLocation from "../../utils/hooks/useCurrentLocation";
import { useOperationsBrands } from "../KitchenNames/hooks/useOperationsBrands";
import { useAllStores } from "../Menus/hooks/useAllStores";
import useToastMenuData from "./hooks/useToastMenuData";

interface Props {
  store?: any;
  form: any;
}
export default function StoreDetailForm({ store, form }: Props) {
  const {
    control,
    setValue,
    formState: { errors },
  } = form;

  const { currentLocation } = useCurrentLocation();
  const { locationSlug } = useParams<{ locationSlug: any }>();
  const { data: brandsData, loading: brandsLoading } = useOperationsBrands();
  const { data: storesData, loading: storesLoading } = useAllStores();
  const { data: toastData, loading: toastLoading } = useToastMenuData({
    location_id: !!store ? store.location.id : currentLocation?.id,
  });
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);

  const brandsNotAtLocationData = useMemo(() => {
    if (!!storesData && !!brandsData) {
      const brandsIdsAtLocation = storesData.all_stores
        .filter((item: any) => item.location.slug === locationSlug)
        .map((item: any) => item.brand.id);

      return brandsData.operations_brands.filter(
        (item: any) => !brandsIdsAtLocation.includes(item.id),
      );
    }
    return [];
  }, [storesLoading, brandsLoading, locationSlug]);

  if (storesLoading || brandsLoading || toastLoading) {
    return <Loading />;
  }

  return (
    <div className="my-3">
      <div className="grid grid-cols-4 gap-6 my-5">
        <div>
          <FormLabel title="Brand" htmlFor="brand_id" />
          <Controller
            name="brand_id"
            control={control}
            defaultValue={
              store ? parseInt(store.brand.id) : brandsNotAtLocationData[0].id
            }
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <FormSelect
                  {...field}
                  onChange={(e) =>
                    setValue("brand_id", parseInt(e.target.value))
                  }
                >
                  {brandsNotAtLocationData.map((item: any, index: number) => {
                    return (
                      <FormOption
                        key={index}
                        title={item.name}
                        value={item.id}
                      />
                    );
                  })}
                </FormSelect>
              );
            }}
          />
        </div>
        {!!store && (
          <>
            <div>
              <FormLabel title="Uber Eats Store ID" htmlFor="" />
              <Controller
                name="ubereats_store_id"
                control={control}
                defaultValue={
                  store && store.ubereats_store_id !== null
                    ? store.ubereats_store_id
                    : ""
                }
                render={({ field }) => <FormInput type="text" {...field} />}
              />
            </div>
            <div>
              <FormLabel
                title="DoorDash Store ID (Legacy)"
                htmlFor="doordash_store_id"
              />
              <Controller
                name="doordash_store_id"
                control={control}
                defaultValue={
                  store && store.doordash_store_id !== null
                    ? store.doordash_store_id
                    : ""
                }
                render={({ field }) => (
                  <FormInput
                    id="doordash_store_id"
                    type="text"
                    {...field}
                    disabled={true}
                  />
                )}
              />
            </div>
          </>
        )}
        <div>
          <FormLabel
            title="Toast Menu"
            htmlFor="toast_menu_id"
            information="Link the proper menu from toast. It should be the same name as the brand. However, this may not always be the case."
          />
          <Controller
            name="toast_menu_id"
            control={control}
            defaultValue={
              store && store.menus[0].toast_menu_id !== null
                ? store.menus[0].toast_menu_id
                : undefined
            }
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <FormSelect id="toast_menu_id" {...field}>
                  <FormOption title="--" value={null} />
                  {toastData?.toast_ids_by_location.map(
                    (item: any, index: number) => {
                      return (
                        <FormOption
                          key={index}
                          title={`${item.name} | ${item.id}`}
                          value={item.id}
                        />
                      );
                    },
                  )}
                </FormSelect>
              );
            }}
          />
          <p className="text-red-800">
            {errors.toast_menu_id?.type === "required" &&
              "This field is required"}
          </p>
        </div>
      </div>
    </div>
  );
}
