import React, { Dispatch, SetStateAction } from "react";
import { Employee } from "../../types";
import { Modal } from "../../ui";
import { EmployeeForm } from "./EmployeeForm";
import { useCreateEmployee } from "./hooks/useCreateEmployee";
import { useUpdateEmployee } from "./hooks/useUpdateEmployee";

type EmployeModalProps = {
  employee: Employee | undefined;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
};

export const EmployeeModal = ({
  employee,
  showModal,
  setShowModal,
}: EmployeModalProps) => {
  const onCreateEmployee = useCreateEmployee();
  const onUpdateEmployee = useUpdateEmployee();

  return (
    <Modal
      title={employee ? "Edit Employee" : "Create Employee"}
      showModal={showModal}
      setShowModal={setShowModal}
    >
      <EmployeeForm
        employee={employee}
        onSubmit={employee ? onUpdateEmployee : onCreateEmployee}
        setShowModal={setShowModal}
      />
    </Modal>
  );
};
