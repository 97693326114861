import { Dialog, Transition } from "@headlessui/react";
import React, { Dispatch, forwardRef, Fragment, SetStateAction } from "react";

type ModalProps = {
  title?: string | React.ReactNode;
  subTitle?: string;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  children: React.ReactNode;
  size?: number;
};

export const Modal = forwardRef(
  (
    { showModal, setShowModal, title, subTitle, children, size }: ModalProps,
    ref: React.Ref<any>,
  ) => {
    let modalSize: string;
    switch (size) {
      case 0:
        modalSize = "";
        break;
      case 1:
        modalSize = "w-full max-w-md";
        break;
      case 2:
        modalSize = "w-full max-w-lg";
        break;
      case 3:
        modalSize = "w-full max-w-xl";
        break;
      case 4:
        modalSize = "w-full max-w-2xl";
        break;
      case 5:
        modalSize = "w-full max-w-3xl";
        break;
      case 6:
        modalSize = "w-full max-w-4xl";
        break;
      default:
        modalSize = "w-full max-w-xl";
    }
    return (
      <Transition.Root show={showModal} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto w-full flex justify-center"
          open={showModal}
          onClose={setShowModal}
        >
          <div
            className={`flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 ${modalSize}`}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity">
                <div ref={ref} className={"w-full h-full"} />
              </Dialog.Overlay>
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className={`inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6 w-full`}
              >
                <div>
                  <Dialog.Title as="h3">
                    <div className="flex justify-between">
                      <div>
                        <div
                          className={
                            typeof title === "string"
                              ? "flex items-center justify-between text-2xl leading-6 font-medium text-gray-900"
                              : ""
                          }
                        >
                          <p>{title ? title : ""}</p>
                        </div>
                        <p className="font-small">{subTitle || ""}</p>
                      </div>
                      <div className="mb-3 flex align-end justify-end">
                        <button
                          type="button"
                          className="cursor-pointer"
                          onClick={() => setShowModal(false)}
                        >
                          <svg
                            className="fill-current text-black"
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                          >
                            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </Dialog.Title>
                  {children}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  },
);
