import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import {
  Button,
  FormInput,
  FormLabel,
  FormOption,
  FormSelect,
} from "../../../ui";
import { usePrepStationTypes } from "../../KitchenNames/hooks/usePrepStationTypes";

interface Props {
  currentLocation: any;
  onSubmitHandler: SubmitHandler<FieldValues>;
  prepStation?: any;
}

export const PrepStationForm = ({
  currentLocation,
  onSubmitHandler,
  prepStation,
}: Props) => {
  const { data: prepStationTypesData, loading: prepStationTypesLoading } =
    usePrepStationTypes();
  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
    register,
  } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <input
        type="hidden"
        {...register("location_id")}
        value={currentLocation?.id}
      />
      {prepStation && (
        <input
          type="hidden"
          {...register("prep_station_id")}
          value={prepStation?.id}
        />
      )}
      <div className="grid grid-cols-4 gap-6 items-center my-5">
        <div className="col-span-2">
          <FormLabel title="Name" htmlFor="name" />
          <Controller
            name="name"
            control={control}
            defaultValue={prepStation?.name || ""}
            rules={{ required: true }}
            render={({ field }) => <FormInput type="text" {...field} />}
          />
          <p className="text-red-800">
            {errors?.name?.type === "required" && "This field is required"}
          </p>
        </div>
        <div>
          <FormLabel title="Type" htmlFor="prep_station_type_id" />
          <Controller
            name="prep_station_type_id"
            defaultValue={prepStation?.prep_station_type_id || 1}
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <FormSelect
                {...field}
                onChange={(e) =>
                  setValue("prep_station_type_id", parseInt(e.target.value))
                }
              >
                {prepStationTypesData?.prep_station_types.map(
                  (prep_station_type: any, index: number) => {
                    return (
                      <FormOption
                        key={index}
                        title={prep_station_type.name}
                        value={prep_station_type.id}
                      />
                    );
                  },
                )}
              </FormSelect>
            )}
          />
        </div>
        <div className="col-span-2">
          <FormLabel
            title="Chit Printer Serial Number"
            htmlFor="printer_id"
            information={
              "The serial number for the chit printer being paired to " +
              (prepStation?.name || "this prep station") +
              ". Chit printers can only be paired to a single prep station. If left blank, a chit printer will not be paired with this prep station."
            }
          />
          <Controller
            name="printer_id"
            control={control}
            defaultValue={prepStation?.printer_id || ""}
            rules={{ required: false }}
            render={({ field }) => <FormInput type="text" {...field} />}
          />
        </div>
      </div>
      <Button>Save</Button>
    </form>
  );
};
