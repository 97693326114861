import { gql, useQuery } from "@apollo/client";

const OPERATIONS_BRANDS_QUERY = gql`
  query OPERATIONS_BRANDS_QUERY {
    operations_brands {
      id
      name
      slug
    }
  }
`;

export const useOperationsBrands = () => {
  const { data, error, loading, refetch } = useQuery(OPERATIONS_BRANDS_QUERY);
  return { data, error, loading, refetch };
};
