import React, { Dispatch, SetStateAction, useContext } from "react";
import { Modal } from "../../../ui";
import ToastContext from "../../../utils/contexts/ToastContext";
import { useUpdateLocationAccount } from "./hooks/useUpdateLocationAccount";
import { LocationAccountForm } from "./LocationAccountForm";

interface Props {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  locationAccount: any;
}

export const EditLocationAccountModal = ({
  showModal,
  setShowModal,
  locationAccount,
}: Props) => {
  const updateLocationAccount = useUpdateLocationAccount();

  const { showToast } = useContext(ToastContext);

  const onSubmitHandler = async (data: any) => {
    try {
      await updateLocationAccount({
        variables: {
          email: data.email,
          location_account_id: parseInt(data.location_account_id),
        },
      });
      showToast({
        description: "Account updated",
        variant: "success",
        seconds: 2,
        onClose: () => {},
      });
      setShowModal(false);
    } catch (err) {
      showToast({
        description: "Account could not be updated",
        variant: "error",
        seconds: 2,
        onClose: () => {},
      });
    }
  };

  return (
    <Modal
      title="Edit Linked Account"
      showModal={showModal}
      setShowModal={setShowModal}
    >
      <LocationAccountForm
        onSubmitHandler={onSubmitHandler}
        locationAccount={locationAccount}
      />
    </Modal>
  );
};
