import React, { Dispatch, SetStateAction, useContext } from "react";
import { Modal } from "../../../ui";
import ToastContext from "../../../utils/contexts/ToastContext";
import { useCreateLocationAccount } from "./hooks/useCreateLocationAccount";
import { LocationAccountForm } from "./LocationAccountForm";

interface Props {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}

export const AddLocationAccountModal = ({ showModal, setShowModal }: Props) => {
  const createLocationAccount = useCreateLocationAccount();
  const { showToast } = useContext(ToastContext);

  const onSubmitHandler = async (data: any) => {
    try {
      await createLocationAccount({
        variables: {
          email: data.email,
          location_id: parseInt(data.location_id),
        },
      });
      showToast({
        description: "Account added",
        variant: "success",
        seconds: 2,
        onClose: () => {},
      });
      setShowModal(false);
    } catch (err) {
      showToast({
        description: "Account could not be added",
        variant: "error",
        seconds: 2,
        onClose: () => {},
      });
    }
  };

  return (
    <Modal
      title="Link Account"
      showModal={showModal}
      setShowModal={setShowModal}
    >
      <LocationAccountForm onSubmitHandler={onSubmitHandler} />
    </Modal>
  );
};
