import { useState } from "react";
import ItemImage from "../../../ui/components/Image/ItemImage";
import { getResponsiveImageUrl } from "../../../utils/formatters";

type PeekImageViewProps = {
  buildGridImage?: string;
  finalPlatedImage?: string;
  packagingImage?: string;
};

export const PeekImageView = ({
  buildGridImage,
  finalPlatedImage,
  packagingImage,
}: PeekImageViewProps) => {
  const [showFinalPlatedImage, setShowFinalPlatedImage] = useState(true);
  let imageToShow;

  if (buildGridImage) {
    imageToShow = buildGridImage;
  } else if (showFinalPlatedImage) {
    imageToShow = finalPlatedImage;
  } else if (!showFinalPlatedImage) {
    imageToShow = packagingImage;
  }

  return (
    <div>
      <div className="w-full flex flex-col items-center justify-between bg-gray-800 rounded-xl border-2 border-gray-700">
        <div className="text-white font-inter font-bold	flex items-center justify-center m-2 px-2 py-5">
          Image doesn't include modifiers
        </div>
        {!imageToShow && (
          <div className="w-full px-4 mb-4">
            <div className="flex border-4 rounded border-yellow-300 bg-yellow-100 p-4 w-full gap-2">
              Fallback: Guest-facing image is shown!
            </div>
          </div>
        )}

        <ItemImage
          url={getResponsiveImageUrl(imageToShow, "640w")}
          className="object-cover w-10/12 h-full"
        />
        {(finalPlatedImage || packagingImage) && (
          <div className="text-white font-inter text-xl font-semibold w-full flex flex-row justify-center items-center divide-x-2 divide-gray-700">
            <button
              className={`${
                showFinalPlatedImage ? "bg-gray-800" : "bg-black"
              } flex flex-row justify-center items-center w-1/2 h-full rounded-bl-xl py-5 px-2`}
              onClick={() => setShowFinalPlatedImage(true)}
            >
              Packaging Photo
            </button>
            <button
              className={`${
                !showFinalPlatedImage ? "bg-gray-800" : "bg-black"
              } flex flex-row justify-center items-center w-1/2 h-full rounded-br-xl py-5 px-2`}
              onClick={() => setShowFinalPlatedImage(false)}
              disabled={!packagingImage}
            >
              Dish Photo
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
