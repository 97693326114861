import { Dispatch, SetStateAction } from "react";
import { Loading } from "../../ui/components/Loading";
import { PageContent } from "../../ui/components/PageContent/PageContent";
import { Pagination } from "../../ui/components/Pagination/Pagination";
import { FilterForm } from "./FilterForm";
import { Table } from "./Table";

type LoyaltyProps = {
  data: any;
  pageNumber: number;
  limit: number;
  totalOrderCount: number;
  loading: boolean;
  setPageNumber: Dispatch<SetStateAction<number>>;
  setFilterParams: Dispatch<SetStateAction<any>>;
};

export const Loyalty = ({
  data,
  pageNumber,
  limit,
  totalOrderCount,
  loading,
  setPageNumber,
  setFilterParams,
}: LoyaltyProps) => {
  return (
    <PageContent>
      <h1 className="text-3xl font-semibold text-gray-900">
        SEARCH FOR GUESTS
      </h1>
      <FilterForm
        setPageNumber={setPageNumber}
        setFilterParams={setFilterParams}
      />

      {loading ? <Loading /> : <Table customers={data?.customers.customers} />}

      <Pagination
        pageNumber={pageNumber}
        limit={limit}
        totalCount={totalOrderCount}
        setPageNumber={setPageNumber}
      />
    </PageContent>
  );
};
