import { useMutation, gql } from "@apollo/client";

const DELETE_CUSTOM_HOURS = gql`
  mutation DeleteCustomHours($custom_hours_ids: [Int]) {
    delete_custom_hours(custom_hours_ids: $custom_hours_ids)
  }
`;

const useDeleteCustomHours = () => {
  const [deleteCustomHours, { error }] = useMutation(DELETE_CUSTOM_HOURS, {
    refetchQueries: ["CustomHoursQuery"],
  });
  return { deleteCustomHours, error };
};

export default useDeleteCustomHours;
