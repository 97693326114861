import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "@localkitchens/passwordless-auth";
import { Dispatch, SetStateAction, useState } from "react";
import { Employee } from "../../types";
import { Button } from "../../ui";
import { PageContent } from "../../ui/components/PageContent/PageContent";
import { Pagination } from "../../ui/components/Pagination/Pagination";
import { EmployeeFilterForm } from "./EmployeeFilterForm";
import { EmployeeModal } from "./EmployeeModal";
import EmployeesTable from "./EmployeesTable";

type EmployeesProp = {
  employees: Employee[];
  limit: number;
  pageNumber: number;
  totalEmployees: number;
  setPageNumber: Dispatch<SetStateAction<number>>;
  setFilterParams: Dispatch<SetStateAction<any>>;
};

export const Employees = ({
  employees,
  pageNumber,
  limit,
  totalEmployees,
  setPageNumber,
  setFilterParams,
}: EmployeesProp) => {
  const [showEmployeeModal, setShowEmployeeModal] = useState(false);
  const [employee, setEmployee] = useState<Employee | undefined>(undefined);
  const { user } = useAuth();

  const userRoles = user
    ? new Set(user["https://app.localkitchens.co/roles"])
    : new Set();
  const canAddEmployee = userRoles.has("ENGINEER") || userRoles.has("ADMIN");

  const AddEmployeeButton = () => (
    <Button
      className="flex items-center"
      onClick={() => {
        setEmployee(undefined);
        setShowEmployeeModal(true);
      }}
    >
      <FontAwesomeIcon icon={faPlus} className="mr-2" />
      Add Employee
    </Button>
  );

  return (
    <PageContent>
      <div className="flex justify-between">
        <h1 className="text-3xl font-semibold text-gray-900">EMPLOYEES</h1>
        {canAddEmployee && <AddEmployeeButton />}
      </div>

      <h1 className="text-xl font-lfg-normal text-gray-900">
        Filter Employees
      </h1>
      <EmployeeFilterForm
        setPageNumber={setPageNumber}
        setFilterParams={setFilterParams}
      />

      <EmployeesTable
        employees={employees}
        setShowEmployeeModal={setShowEmployeeModal}
        setEmployee={setEmployee}
      />
      <Pagination
        pageNumber={pageNumber}
        limit={limit}
        totalCount={totalEmployees}
        setPageNumber={setPageNumber}
      />

      <EmployeeModal
        employee={employee}
        showModal={showEmployeeModal}
        setShowModal={setShowEmployeeModal}
      />
    </PageContent>
  );
};
