import { useMutation, gql } from "@apollo/client";

const DELETE_PREP_STATION = gql`
  mutation DeletePrepStation($prep_station_id: Int) {
    delete_prep_station(prep_station_id: $prep_station_id) {
      id
      name
    }
  }
`;

export const useDeletePrepStation = () => {
  const [deletePrepStation] = useMutation(DELETE_PREP_STATION, {
    refetchQueries: ["PREP_STATIONS_QUERY"],
  });

  return deletePrepStation;
};
