import { Route, Switch, useRouteMatch } from "react-router";
import { ListLocationsItems } from "./pages/ListLocationsItems";

export const LocationsItemsController = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/`}>
        <ListLocationsItems />
      </Route>
    </Switch>
  );
};
