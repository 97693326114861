import { ApolloError, gql, useQuery } from "@apollo/client";
import { PublicMenuItem } from "../../../../../types";

export const OPERATIONS_SEARCH_MENU_ITEMS_QUERY = gql`
  query OperationsSearchMenu(
    $query: String
    $location_id: Int
    $filter_active_modifiers: Boolean
    $filters: [String]
    $is_internal: Boolean
  ) {
    search_menu_items(
      query: $query
      location_id: $location_id
      filters: $filters
      is_internal: $is_internal
    ) {
      query_id
      items {
        id
        menu_item_id
        name
        description
        price
        brand_name
        brand_slug
        brand_id
        store_id
        count
        blurhash
        image_url
        is_active
        tags {
          id
          name
          is_active
          slug
        }
        modifier_lists {
          id
          name
          min
          max
          is_single_select
          modifier_items(filter_active_modifiers: $filter_active_modifiers) {
            id
            name
            price
            is_active
          }
        }
      }
    }
  }
`;

export const useOperationSearchMenuItems = (
  location_id: number,
  searchQuery: string,
  filters: string[],
): {
  data: PublicMenuItem[];
  loading: boolean;
  error?: ApolloError;
} => {
  const { data, error, loading } = useQuery(
    OPERATIONS_SEARCH_MENU_ITEMS_QUERY,
    {
      variables: {
        location_id,
        query: searchQuery,
        filters,
        filter_active_modifiers: false,
        is_internal: true,
      },
    },
  );

  return { data: data?.search_menu_items?.items || [], error, loading };
};
