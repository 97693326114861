import { gql, useLazyQuery } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";

const PAIRINGS_QUERY = gql`
  query PairingsQuery($menuItemId: Int!) {
    menu_item_pairings(menuItemId: $menuItemId) {
      id
      ordinal
    }
  }
`;

export const useLazyMenuItemPairings = () => {
  const [_fetchPairings, { data, loading }] = useLazyQuery(PAIRINGS_QUERY);

  const fetchPairings = useCallback(
    (menuItemId: number) => {
      if (!menuItemId) {
        return [];
      }

      _fetchPairings({ variables: { menuItemId } });
    },
    [_fetchPairings],
  );

  return { fetchPairings, data, loading };
};
