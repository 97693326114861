import {
  BrandsQueryResult,
  useBrandsQuery,
} from "../../../graphql/useBrands.query.generated";
import { BrandV2 } from "../../../graphql/types";

const useBrands = (locationSlug?: string) => {
  const result: BrandsQueryResult = useBrandsQuery({
    variables: {
      location_slug: locationSlug,
    },
  });

  const { data, loading, refetch } = result;
  return {
    data: (data?.brands || []) as BrandV2[],
    loading,
    refetch,
  };
};

export default useBrands;
