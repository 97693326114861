import { gql, useMutation } from "@apollo/client";

const UPDATE_TAG = gql`
  mutation UpdateTag($tag: UpdateTagInput!, $menu_item_ids: [Int]) {
    update_tag(tag: $tag, menu_item_ids: $menu_item_ids) {
      id
      name
      collection_name
      is_v2
      is_active
      is_filter
      is_collection
      ordinal
      slug
      logo_url
      logo_url_v2
      logo_blurhash
      logo_blurhash_v2
      hero_image_url
      hero_image_url_v2
      hero_image_blurhash
      hero_image_blurhash_v2
    }
  }
`;

export const useUpdateTag = () => {
  const [updateTag, { loading, error }] = useMutation(UPDATE_TAG);

  return { updateTag, loading, error };
};
