import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";
import classNames from "../../../utils/classNames";

interface Props {
  items: any;
  mainText: string;
  isOpen?: boolean;
}

export default function DropdownButtons({ items, mainText, isOpen }: Props) {
  return (
    <span className="relative inline-flex shadow-sm rounded-md focus:contents">
      <Menu as="span" className="-ml-px relative block">
        <Menu.Button className="relative inline-flex items-center px-2 py-2 rounded-md border border-gray-300 bg-lfg-primary text-sm font-medium text-white hover:opacity-80 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
          {mainText}
          <span className="sr-only">Open options</span>
          <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="z-20 absolute origin-top-right right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer">
            <div className="py-1">
              {items.map((item: any, index: number) => (
                <Menu.Item key={index}>
                  {({ active }) => (
                    <span
                      onClick={item.onClick}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm",
                      )}
                    >
                      {item.children}
                    </span>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </span>
  );
}
