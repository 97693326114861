import { Route, Switch, useRouteMatch } from "react-router";
import { ListBrands } from "./pages/ListBrands";

export const BrandsController = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <ListBrands />
      </Route>
    </Switch>
  );
};
