import { createContext } from "react";
import { ToastProps } from "../../ui/components/Toast/Toast";

type ToastContextType = {
  showToast: (props: ToastProps) => void;
  clearToast: () => void;
};

export default createContext<ToastContextType>({
  showToast: () => {},
  clearToast: () => {},
});
