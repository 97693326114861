import { Dispatch, SetStateAction } from "react";
import { Employee } from "../../types";
import { Table, TableData, TableRow } from "../../ui";

type EmployeeTableProps = {
  employees: Employee[];
  setShowEmployeeModal: Dispatch<SetStateAction<boolean>>;
  setEmployee: Dispatch<SetStateAction<Employee | undefined>>;
};

const EmployeesTable = ({
  employees,
  setShowEmployeeModal,
  setEmployee,
}: EmployeeTableProps) => {
  return (
    <Table headers={["ID", "First Name", "Last Name", "Email", "Pin Code"]}>
      {employees?.map((employee: Employee, index: number) => {
        return (
          <TableRow
            key={index}
            onClick={() => {
              setEmployee(employee);
              setShowEmployeeModal(true);
            }}
          >
            <TableData>{employee.id}</TableData>
            <TableData>{employee.first_name}</TableData>
            <TableData>{employee.last_name}</TableData>
            <TableData>{employee.email}</TableData>
            <TableData>{employee.pin_code}</TableData>
          </TableRow>
        );
      })}
    </Table>
  );
};

export default EmployeesTable;
