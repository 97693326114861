import { useMutation, gql } from "@apollo/client";

const OPERATIONS_UPDATE_ISSUE = gql`
  mutation OPERATIONS_UPDATE_ISSUE(
    $id: Int
    $store_order_issue_input: StoreOrderIssueInput
  ) {
    update_issue(id: $id, input: $store_order_issue_input) {
      id
    }
  }
`;

export const useUpdateIssue = () => {
  const [updateIssue] = useMutation(OPERATIONS_UPDATE_ISSUE);

  return updateIssue;
};
