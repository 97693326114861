import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Table, TableData, TableRow } from "../../ui";
import { Loading } from "../../ui/components/Loading";
import { convertToStandard } from "../CustomHours/CustomHoursDetailModal";
import { useCustomHours } from "../CustomHours/hooks/useCustomHours";
import useDeleteCustomHours from "../CustomHours/hooks/useDeleteCustomHours";
import { DeleteCustomClosureModal } from "./DeleteCustomClosureModal";

interface Props {
  store: any;
}
export const displayHours = (closure: any) => {
  const hours = JSON.parse(closure.hours);
  if (hours.length === 0) {
    return <p>CLOSED ALL DAY</p>;
  }
  return (
    <>
      {hours.map((interval: any, index: number) => (
        <div className="grid w-32 grid-cols-2">
          <p>Open:</p>
          <p className="justify-self-end">
            {convertToStandard(interval.start_time)}
          </p>
          <p>Close:</p>
          <p className="justify-self-end">
            {convertToStandard(interval.end_time)}
          </p>
        </div>
      ))}
    </>
  );
};
export const CustomClosuresTable = ({ store }: Props) => {
  const { data, loading } = useCustomHours();
  const { deleteCustomHours } = useDeleteCustomHours();
  const [selectedClosure, setSelectedClosure] = useState<number | null>(null);
  const [showDeleteCustomClosureModal, setShowDeleteCustomClosureModal] =
    useState<boolean>(false);

  if (loading) {
    return <Loading />;
  }
  const relevantHours = data.custom_hours
    .filter((hours: any) => hours.store_id === store.id)
    .sort(
      (first: any, second: any) =>
        parseInt(first.override_date) - parseInt(second.override_date),
    );

  return (
    <>
      <Table headers={["Date", "Custom Hours", "Actions"]}>
        {relevantHours.map((hours: any, index: number) => {
          return (
            <TableRow>
              <TableData>
                {new Date(hours.override_date).toDateString()}
              </TableData>
              <TableData>{displayHours(hours)}</TableData>
              <TableData>
                <Button
                  type="button"
                  backgroundColor="bg-red-500"
                  onClick={() => {
                    setSelectedClosure(hours);
                    setShowDeleteCustomClosureModal(true);
                  }}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </Button>
              </TableData>
            </TableRow>
          );
        })}
      </Table>
      {showDeleteCustomClosureModal && (
        <DeleteCustomClosureModal
          showModal={showDeleteCustomClosureModal}
          setShowModal={setShowDeleteCustomClosureModal}
          selectedClosure={selectedClosure}
        />
      )}
    </>
  );
};
