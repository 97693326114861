import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useHistory, useRouteMatch } from "react-router-dom";
import { PageContent } from "../../ui/components/PageContent/PageContent";
import classNames from "../../utils/classNames";
import useQueryParams from "../../utils/hooks/useQueryParams";
import { Brands } from "../Brands";
import { TagsController } from "../Tags/TagsController";

export const BrandManager = () => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { params } = useQueryParams();

  const tabSelected = params.get("tab");

  return (
    <PageContent>
      <div className="flex flex-col justify-between">
        <h1 className="text-3xl font-semibold text-gray-900 mr-3">
          Brand Manager
        </h1>
        <nav className="flex space-x-4" aria-label="Tabs">
          <div
            className={classNames(
              tabSelected === "brands" || !tabSelected
                ? "border-lfg-primary text-lfg-primary"
                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
              "cursor-pointer whitespace-nowrap py-2 px-1 border-b-4 font-medium text-sm",
            )}
            onClick={() => history.push(`${url}?tab=brands`)}
          >
            Brands
          </div>
          <div
            className={classNames(
              tabSelected === "tags"
                ? "border-lfg-primary text-lfg-primary"
                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
              "cursor-pointer whitespace-nowrap py-2 px-1 border-b-4 font-medium text-sm",
            )}
            onClick={() => history.push(`${url}?tab=tags`)}
          >
            Tags
          </div>
        </nav>
      </div>

      {tabSelected === "tags" ? (
        <DndProvider backend={HTML5Backend}>
          <TagsController />
        </DndProvider>
      ) : (
        <Brands />
      )}
    </PageContent>
  );
};
