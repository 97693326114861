import { Dispatch, SetStateAction, useContext } from "react";
import { Modal } from "../../../ui";
import ToastContext from "../../../utils/contexts/ToastContext";
import { useCreatePrinter } from "./hooks/useCreatePrinter";
import { LocationPrinterForm } from "./LocationPrinterForm";

interface Props {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}

export const AddLocationPrinterModal = ({ showModal, setShowModal }: Props) => {
  const createPrinter = useCreatePrinter();
  const { showToast } = useContext(ToastContext);

  const onSubmitHandler = async (data: any) => {
    try {
      await createPrinter({
        variables: {
          external_id: data.external_id,
          location_id: parseInt(data.location_id),
        },
      });
      showToast({
        description: "Printer Added",
        variant: "success",
        seconds: 2,
        onClose: () => {},
      });
      setShowModal(false);
    } catch (err) {
      showToast({
        description: (err as any).message,
        variant: "error",
        seconds: 2,
        onClose: () => {},
      });
    }
  };

  return (
    <Modal
      title="Pair New Receipt Printer"
      showModal={showModal}
      setShowModal={setShowModal}
    >
      <LocationPrinterForm onSubmitHandler={onSubmitHandler} />
    </Modal>
  );
};
