import { gql, useMutation } from "@apollo/client";

const CREATE_BRAND = gql`
  mutation CreateBrand($create_brand_input: BrandCreateInput!) {
    create_brand(input: $create_brand_input) {
      id
      name
      slug
      logo_url
      logo_blurhash
      food_hero_image_url
      food_hero_image_blurhash
      about
      cuisine
      short_cuisine
      tagline
      feature_header
      feature_body
      feature_image {
        url
        blurhash
      }
      typ
      url
    }
  }
`;

export const useCreateBrand = () => {
  const [createBrand] = useMutation(CREATE_BRAND);

  return createBrand;
};
