import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState } from "react";
import { Button, Table, TableData, TableRow } from "../../../ui";
import ToastContext from "../../../utils/contexts/ToastContext";
import useCurrentLocation from "../../../utils/hooks/useCurrentLocation";
import { usePrepStationTypes } from "../../KitchenNames/hooks/usePrepStationTypes";
import { EditLocationPrinterModal } from "../LocationPrinters/EditLocationPrinterModal";
import { useDeletePrinter } from "../LocationPrinters/hooks/useDeletePrinter";
import { usePrintersByLocationID } from "../LocationPrinters/hooks/usePrintersByLocationID";
import { EditPrepStationModal } from "./EditPrepStationModal";
import { useDeletePrepStation } from "./hooks/useDeletePrepStation";
import { usePrepStations } from "./hooks/usePrepStations";

export const PrepStations = () => {
  const [showEditPrepStationModal, setShowEditPrepStationModal] =
    useState(false);
  const [prepStationToEdit, setPrepStationToEdit] = useState({});

  const { currentLocation } = useCurrentLocation();
  const deletePrepStation = useDeletePrepStation();

  const { data: prepStationsData, loading: prepStationsDataLoading } =
    usePrepStations(currentLocation?.id);
  const { data: prepStationsTypeData, loading: prepStationsTypeLoading } =
    usePrepStationTypes();
  const { data: printerData, loading: printerDataLoading } =
    usePrintersByLocationID(currentLocation?.id);

  const { showToast } = useContext(ToastContext);

  const [selectedPrinter, setSelectedPrinter] = useState<any>(null);
  const [showEditLocationPrinterModal, setShowEditLocationPrinterModal] =
    useState<boolean>(false);

  const deletePrinter = useDeletePrinter();

  const handleEditPrinterModal = (printer: any) => {
    setSelectedPrinter(printer);
    setShowEditLocationPrinterModal(true);
  };
  const handlePrinterDelete = async (printer_id: number) => {
    try {
      await deletePrinter({ variables: { printer_id } });
      showToast({
        description: "Printer has been deleted",
        seconds: 2,
        variant: "success",
        onClose: () => {},
      });
    } catch (err) {
      showToast({
        description: "Printer could not be deleted",
        seconds: 2,
        variant: "error",
        onClose: () => {},
      });
    }
  };

  const openEditPrepStationModal = (prepStation: any) => {
    setPrepStationToEdit(prepStation);
    setShowEditPrepStationModal(true);
  };

  const handlePrepStationDelete = async (prep_station_id: number) => {
    try {
      await deletePrepStation({ variables: { prep_station_id } });
      showToast({
        description: "Prep Station has been deleted",
        seconds: 2,
        variant: "success",
        onClose: () => {},
      });
    } catch (err) {
      showToast({
        description: "Prep Station could not be deleted",
        seconds: 2,
        variant: "error",
        onClose: () => {},
      });
    }
  };

  if (
    !currentLocation ||
    prepStationsDataLoading ||
    prepStationsTypeLoading ||
    printerDataLoading
  ) {
    return null;
  }
  return (
    <>
      <div>
        <h1 className="text-xl text-gray-600">Receipt Printers</h1>
        <Table
          headers={[
            {
              text: "Receipt Printer Serial Number",
              information: (
                <div>
                  <span className="font-bold">
                    Receipt Printer Serial Numbers:
                  </span>
                  <p>
                    This column contains the serial numbers of receipt printers
                    that are currently paired to {currentLocation?.name}. When
                    multiple printers are paired to the same location, they will
                    print out duplicates of the same receipt.
                  </p>
                </div>
              ),
            },
            "Actions",
          ]}
        >
          {printerData?.printers.map((printer: any, index: number) => (
            <TableRow key={index}>
              <TableData>{printer.external_id}</TableData>
              <TableData>
                <Button
                  type="button"
                  className="mr-3"
                  onClick={() => handleEditPrinterModal(printer)}
                >
                  <FontAwesomeIcon icon={faPencilAlt} />
                </Button>
                <Button
                  type="button"
                  backgroundColor="bg-red-600"
                  onClick={() => handlePrinterDelete(printer.id)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </Button>
              </TableData>
            </TableRow>
          ))}
        </Table>
      </div>
      <div>
        <h1 className="text-xl text-gray-600">Prep Stations</h1>
        <Table
          headers={[
            "Name",
            "Type",
            {
              text: "Chit Printer Serial Number",
              information: (
                <div>
                  <span className="font-bold">
                    Chit Printer Serial Numbers:
                  </span>
                  <p>
                    This column contains the serial numbers of chit printers
                    that are currently paired to a given prep station. Each chit
                    printer can only be paired to a single prep station.
                  </p>
                </div>
              ),
            },
            "Actions",
          ]}
        >
          {prepStationsData?.location?.prep_stations.map(
            (prepStation: any, index: number) => (
              <TableRow key={index}>
                <TableData>{prepStation.name}</TableData>
                <TableData>
                  {prepStationsTypeData &&
                    prepStationsTypeData.prep_station_types.find(
                      (prep_station_type: any) =>
                        prep_station_type.id ===
                        prepStation.prep_station_type_id,
                    ).name}
                </TableData>
                <TableData>{prepStation.printer_id}</TableData>
                <TableData>
                  <Button
                    className="mr-3"
                    type="button"
                    onClick={() => openEditPrepStationModal(prepStation)}
                  >
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </Button>
                  <Button
                    backgroundColor="bg-red-600"
                    onClick={() => handlePrepStationDelete(prepStation.id)}
                    type="button"
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </Button>
                </TableData>
              </TableRow>
            ),
          )}
        </Table>
      </div>

      <EditPrepStationModal
        showModal={showEditPrepStationModal}
        setShowModal={setShowEditPrepStationModal}
        prepStation={prepStationToEdit}
      />
      <EditLocationPrinterModal
        showModal={showEditLocationPrinterModal}
        setShowModal={setShowEditLocationPrinterModal}
        locationPrinter={selectedPrinter}
      />
    </>
  );
};
