import {
  useLoyaltyAccountQuery,
  useUserInfoQuery,
} from "shopapi/hooks/operations";
import { Loading } from "../../ui/components/Loading";
import { LoyaltyModal } from "./LoyaltyModal";
import { useAddCreditsToLoyaltyAccount } from "./hooks/useAddCreditsToLoyaltyAccount";

type LoyaltyModalControllerProps = {
  id: number;
  showModal: boolean;
};
export const LoyaltyModalController = ({
  id,
  showModal,
}: LoyaltyModalControllerProps) => {
  const addCreditsToLoyaltyAccount = useAddCreditsToLoyaltyAccount();
  const {
    data: loyaltyAccount,
    isLoading: loadingLoyaltyAccount,
    refetch: refetchLoyaltyAccount,
  } = useLoyaltyAccountQuery({ params: { path: { customer_id: id } } });
  const { data: userInfo, isLoading: loadingUserInfo } = useUserInfoQuery({
    params: { path: { customer_id: id } },
  });
  if (
    loadingLoyaltyAccount ||
    loadingUserInfo ||
    !loyaltyAccount ||
    !userInfo
  ) {
    return <Loading />;
  }
  return (
    <LoyaltyModal
      addCreditsToLoyaltyAccount={addCreditsToLoyaltyAccount}
      loyaltyAccount={loyaltyAccount}
      refetchLoyaltyAccount={refetchLoyaltyAccount}
      userInfo={userInfo}
      showModal={showModal}
    />
  );
};
