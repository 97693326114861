import { Dispatch, SetStateAction } from "react";
import { Modal } from "../../ui/components";
import { CouponForm } from "./CouponForm";
type CreateCouponModalProps = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  onCreateCoupon: any;
};

export const CreateCouponModal = ({
  showModal,
  setShowModal,
  onCreateCoupon,
}: CreateCouponModalProps) => {
  return (
    <Modal title="New Coupon" showModal={showModal} setShowModal={setShowModal}>
      <CouponForm
        onSubmit={onCreateCoupon}
        setShowModal={setShowModal}
        coupon={null}
      />
    </Modal>
  );
};
