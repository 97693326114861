import { gql, useMutation } from "@apollo/client";
import { OPERATIONS_SALES_BY_ID_QUERY } from "./useSaleById";

const UPDATE_PICKUP_TIME = gql`
  mutation UpdatePickupTime($input: UpdatePickupTimeInput) {
    update_pickup_time(input: $input) {
      store_order_id
      status
      is_scheduled
      pickup_time
    }
  }
`;

export const useUpdatePickupTime = (orderId: number) => {
  const [updatePickupTime] = useMutation(UPDATE_PICKUP_TIME, {
    refetchQueries: [
      "OPERATIONS_SALES_QUERY",
      {
        query: OPERATIONS_SALES_BY_ID_QUERY,
        variables: {
          id: orderId,
        },
      },
    ],
  });

  return updatePickupTime;
};
