import "cropperjs/dist/cropper.css";
import { useCallback, useState } from "react";
import { OperationsMenuItem, OperationsModifierItem } from "../../../types";
import { Button } from "../../../ui";
import { Loading } from "../../../ui/components/Loading";
import convertToBase64 from "../../../utils/convertToBase64";
import { UploadMenuItemImageProps } from "../hooks/useUploadMenuItemImage";
import FileDropZone from "./FileDropZone";

interface UploadItemImageForm {
  item?: OperationsMenuItem | OperationsModifierItem;
  onUploadMenuItemImage: (args: UploadMenuItemImageProps) => Promise<any>;
  loading: boolean;
  onSuccess?: () => void;
}

const UploadItemImageForm = ({
  item,
  onUploadMenuItemImage,
  loading,
  onSuccess,
}: UploadItemImageForm) => {
  const [image, setImage] = useState<File>();

  const handleChangedFiles = useCallback((files: File[]) => {
    setImage(files[0]);
  }, []);

  const handleUploadImage = useCallback(async () => {
    if (image && item) {
      const base64Image = await convertToBase64(image);
      await onUploadMenuItemImage({
        imageBase64: base64Image as string,
        menuItemId: item.id,
        menuItemName: item.name,
      });
      onSuccess?.();
    }
  }, [image, item]);

  return (
    <div className="mt-3">
      {loading ? (
        <Loading />
      ) : (
        <div>
          <FileDropZone className="h-52 min-h-50" onChange={handleChangedFiles}>
            {image && (
              <div className="pl-2" onClick={(e) => e.stopPropagation()}>
                {image && (
                  <div className="w-full flex flex-col items-center justify-between bg-gray-800 rounded-xl border-2 border-gray-700">
                    <img
                      src={URL.createObjectURL(image)}
                      className="object-contain max-w-[850px] max-h-[600px]"
                    />
                    <Button className="my-2" onClick={handleUploadImage}>
                      Upload
                    </Button>
                  </div>
                )}
              </div>
            )}
          </FileDropZone>
        </div>
      )}
    </div>
  );
};

export default UploadItemImageForm;
