import { usePaginatedMenuEntitiesQuery } from "../../../graphql/usePaginatedMenuEntities.query.generated";
import { PaginatedMenuEntitiesInput } from "../../../graphql/types";

const usePaginatedMenuEntities = (input: PaginatedMenuEntitiesInput) => {
  const { data, loading, refetch } = usePaginatedMenuEntitiesQuery({
    variables: {
      input,
    },
  });

  return { data: data?.paginated_menu_entities, loading, refetch };
};

export default usePaginatedMenuEntities;
